.eventbrite-emails-modal {
  width: 500px;
}

.eventbrite-match-modal--account-wrapper,
.eventbrite-match-modal--contact-search-wrapper {
  padding: 16px 20px;
}

.eventbrite-match-modal--account-wrapper {
  border-bottom: solid 1px $border;
}

.eventbrite-match-modal--account-title {
  color: $gray500;
  font-size: $fontSizeSmall;
  margin-bottom: 16px;
}

.eventbrite-match-modal--account-data {
  display: flex;
  align-items: center;
}

.eventbrite-match-modal--account-text {
  padding-left: 20px;
  font-size: $fontSizeLarge;
}

.eventbrite-match-modal--account-email {
  color: $gray500;
}

.eventbrite-match-modal--results-wrapper {
  position: relative;
  margin-top: 12px;
  min-height: 40px;
}

.eventbrite-match-modal--contact-search-empty {
  border: 1px solid $border;
  text-align: center;
  padding: 12px 0;
  color: $gray150;
  font-style: italic;
}

.eventbrite-match-modal--contact-search-results {
  max-height: 280px;
  overflow-y: scroll;
}

.eventbrite-match-modal--contact-search-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border;
  margin: 12px 0;
  border-radius: 2px;
}
