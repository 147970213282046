.recommendation-modal-subtitle {
  @extend .title-subtitle-500-14;
  padding: 10px 0px 18px 0px;
}

.recommendation-modal-gift-amount-field {
  width: 150px !important;
  text-align: left !important;
}

.recommended-action-user-settings-form-input {
  padding: 8px 0px;
}
