.engagement-action-pill {
   display: inline-block;
   white-space: nowrap;
   background-color: #E8E8E8;
   border-radius: 3px;
   padding: 3px 4px 2px 4px;
   line-height: 1;
}

.engagement-action-pill--icon.engagement-icon {
   vertical-align: baseline;
}
