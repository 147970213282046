.bulk-csv-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    color: $green300;
  }
}

.bulk-csv-success-header {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.bulk-csv-status {
  margin-top: 10px;
}

.bulk-csv-message {
  font-size: 12px;
}

.bulk-csv-status-line {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  &.success {
    background-color: $green300;
  }
  &.error {
    background-color: $red500;
  }
}

.bulk-csv-error-block {
  background-color: $gray108;
  height: 120px;
  overflow-y: auto;
  border: 1px solid $gray150;
  padding: 10px;
  margin-top: 10px;
  max-width: 415px;
}
