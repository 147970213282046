$medium-screen: em(640);
$large-screen: em(860);

$modalConfirmPadding: 16px 20px;

.modal--overlay {
  @include position(fixed, 0px 0px 0px 0px);
  @include animation-duration(0.2s);
  @include animation-delay(0);
  background: rgba(0, 0, 0, 0.33);
  z-index: $modalZindex;
  overflow-y: auto;
}

.modal--dialog {
  @include animation-duration(0.2s);
  @include animation-delay(0);
  position: relative;
  background: $white;
  border-radius: 12px;
  width: 50%;
  margin: 5% auto 0 auto;

  @include media($medium-screen) {
    width: 60%;
  }

  @include media($large-screen) {
    width: 50%;
  }
}

.modal-confirm--header {
  @extend .clearfix;
  padding: $modalConfirmPadding;
  border-bottom: solid 1px $border;

  h3 {
    font-weight: 400;
    font-size: 20px;
  }
}

.modal-confirm--close {
  @include position(absolute, 19px 20px null null);
  color: $gray200;
  cursor: pointer;

  &:hover {
    color: $gray300;
  }
}

.modal-confirm--footer {
  padding: 16px;
  border-top: solid 1px $border;
  text-align: right;
}

.modal-confirm--submit {
  position: relative; // Need for saving spinner
  margin-left: 24px;
}

.modal-confirm--content {
  padding: $modalConfirmPadding;
}

.modal-confirm--error {
  margin: 25px 25px 40px;
  background-color: #fcedec;
  a {
    cursor: pointer;
  }
}

.modal-alert--message {
  background-color: $yellow108;
  padding: 15px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}
.modal-alert--message-gray {
  background-color: $gray105;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}
