.map-contacts--list {
  // sets the contacts table to be "fixed" ie will not shrink smaller than 350px (for readability)
  flex: none;
  width: 350px;
  overflow: scroll;
  position: relative;
  z-index: 3; // set contact list "on top" of map toolbar
}

.map-contacts--head {
  padding: 16px;
}

.map-contacts--list > .loading {
  top: 33%;
}