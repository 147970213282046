.cadence-category-list {
  padding: 8px;
  width: 250px;
  max-height: 276px;
  overflow-y: scroll;
}

.cadence-list {
  width: 328px;
  padding: 8px 8px 0px 8px;
  max-height: 323px;
  overflow-y: scroll;
}

.all-cadences-btn {
  padding: 7px 35px;
}

.cadence-group-list-item {
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: $gray105;
  }
}
.cadence-group-list-item.active {
  background-color: $gray110;
}

.max-cadence-alert {
  background-color: $gray110;
  padding: 10px;
  border-radius: 12px;
  margin: 10px 0px;
}
