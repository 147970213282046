.todo-calendar--extra-style {
  top: 50px;
  left: 0px;
  overflow: hidden;
  box-shadow: 0px 0px 2px grey;
  background-color: white;
  z-index: 10;
}
.todo-date-selection--today-button {
  text-align: left;
  color: $linkText;
}
.todo-date-selection--clear-button {
  text-align: left;
  color: $linkText;
  float: left;
  height: 100%;
}
.todo-date-selection--done-button {
  text-align: left;
  color: $linkText;
  float: right;
  height: 100%;
}
.todo-date-selection--button-wrapper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid $gray150;
}
