.map-info--wrap {
  min-height: 100px;
  min-width: 250px;
}

.map-marker-label {
  color: $white;
  font-weight: bold;
  text-align: center;
  display: block;
}

.map-search-controller {
  @include position(absolute, 0px 0px 0px 350px);
}

.map-search-controller--filters {
  @include position(absolute, 16px 35% 0 0);
  display: flex;
  z-index: 1;
  margin-right: 24px;
}

.map-container {
  .map {
    @include position(absolute, 0px 0px 0px 0px);
    border-top: solid 1px $border;
  }
}

.map-container--controls {
  @include position(absolute, 16px $gutter 0 0);
  width: 35%;
  z-index: 1;
}

.map-container--update {
  box-shadow: 0 2px 2px fade-out($black, 0.7);
  border-radius: 2px;
  background: $white;
  margin-right: 55px;
  padding: 8px;

  label.checkbox {
    margin-bottom: 0px;
    color: $linkMainColor;
    font-weight: normal;
  }

  .checkbox--box {
    margin-right: 8px;
  }

  &.is-active {
    background: $green300;
    cursor: pointer;

    .fa-refresh {
      margin-right: 8px;
    }

    label.checkbox {
      color: $white;
      font-weight: bold;
    }

    &:hover {
      background: darken($green300, 5%);
    }
  }
}

.map-container--feedback {
  @include position(absolute, null null 4px 4px);
  box-shadow: 0 2px 2px fade-out($black, 0.7);
  border-radius: 2px;
  background: $white;
  padding: 4px 8px;
}

.map-container--map {
  button {
    box-shadow: none;
  }
}
