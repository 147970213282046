$givingGreen: #63a318;
$noResultsWidth: 200px;

.giving-table {
  .table-with-borders tr td,
  .table-with-borders tr th {
    padding: 8px 16px;
  }
}

.giving-table--header {
  th {
    background: $gray105;
    color: $blue200;
  }

  table {
    border-radius: 2px 2px 0 0;
  }

  .gt-icon-arrow-up,
  .gt-icon-arrow-down {
    color: $gray200;
    margin-right: 4px;
  }
}

.giving-table--body {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  position: relative;
  transform: translateZ(0); // fix for scroll weirdness
  border-bottom: solid 1px $border;

  table {
    border-radius: 0 0 2px 2px;
  }
}

.giving-table--y {
  text-align: right;
}

.giving-table--show-more-less {
  float: right;
  cursor: pointer;
  margin-left: 20px;
}

.giving-line-label {
  display: inline-block;
  padding: 10px 20px;

  &:hover {
    cursor: default;
  }
}
