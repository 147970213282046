.gifts-feed {
  border: 1px solid $border;
  background-color: $white;
}

.gifts-feed--list {
  padding: 12px 12px 4px;
}

.gifts-feed--title {
  letter-spacing: 1px;
  color: $gray500;
  margin-bottom: 2px;
}

.gifts-feed--loading {
  border-top: 1px solid $border;
  text-align: center;
  padding: 12px;
}

.gifts-feed--load-more,
.gifts-feed--view-all {
  border-top: 1px solid $border;
  text-align: center;
  position: relative;
}

.gifts-feed--load-more {
  padding: 12px;
}

.gifts-feed--view-all {
  padding: 12px;
}
