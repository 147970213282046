$fixedTableHeaderHeight: 50px;

.fixed-table {
  @include position(absolute, 0px 0px 0px 0px);
}

.fixed-table--wrapper {
  @include position(absolute, 0px 0px 0px 0px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: $white;
}

.fixed-table--header {
  table-layout: fixed;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
  height: $fixedTableHeaderHeight;

  .table--header th {
    padding: 0;
    text-align: left;

    .dropdown--toggle {
      height: $fixedTableHeaderHeight;
      line-height: $fixedTableHeaderHeight / 2;
      padding: 10px 25px;
    }
  }
}

.fixed-table--body {
  background: $white;
  margin-top: 0;
  width: 100%;

  .table--body > tr > td {
    border-right: solid 1px $border;
    text-align: center;
  }
}

.fixed-table--vertical {
  @include position(absolute, $fixedTableHeaderHeight + 2 0px 0px 0px);
  overflow-x: hidden;
  overflow-y: auto;
}

.fixed-table--scrollbar {
  @include position(absolute, null 0px 0px 0px);
  padding: 0 12px 12px;

  .scrollbar {
    @include calc(width, "100% - 64px");
    display: inline-block;
    vertical-align: bottom;
  }
}

.fixed-table--scrollbar-left,
.fixed-table--scrollbar-right {
  @include vertical_align();
  background: $scrollbarBackground;
  border-radius: 3px 0 0 3px;
  color: $white;
  width: 24px;
  height: 24px;
  font-size: 25px;
  text-align: center;

  &:hover,
  &:focus {
    color: fade-out($white, 0.3);
  }

  i {
    width: 100%;
  }
}

.fixed-table--scrollbar-left {
  margin-right: 1px;
  border-radius: 3px 0 0 3px;
}

.fixed-table--scrollbar-right {
  border-radius: 0 3px 3px 0;
  margin-right: 8px;
}
