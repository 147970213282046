.selector-menu--content {
  border-bottom: solid 1px $border;
  min-width: 275px;
}

.selector-menu--input {
  padding: 15px;
  border-bottom: solid 1px $border;
}

.selector-menu--options {
  max-height: 255px;
  overflow-y: auto;
  text-align: left;
}

.selector-menu--group {
  border-bottom: solid 1px $border;
  padding: 8px;

  &:last-child {
    border-bottom: none;
  }
}

.selector-menu--group-label {
  padding-left: 8px;
  margin-bottom: 4px;
  display: block;
}

.selector-menu--item {
  cursor: pointer;
  padding: 4px 30px;
  line-height: 23px;
  position: relative;

  &.is-active {
    color: $purple500;
    background: $gray105;

    &.is-checked::after {
      @include position(absolute, 4px 4px null null);
      content: "Remove";
      color: $gray500;
      font-size: 12px;
    }
  }
}

.selector-menu--icon {
  @include position(absolute, null null null 0px);
  color: $purple500;
  line-height: 23px;
  font-weight: bold;
  font-size: 13px;
  width: 26px;
}

.selector-menu--header {
  padding: 3px;
  color: $gray900;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
}

.selector-menu--actions {
  display: block;
  padding: 15px;
  text-align: center;

  &.is-button {
    &:hover {
      color: $purple500;
    }
  }
}

.add-new-list--input.filter-text-input {
  width: 100%;
}

.selector-menu--load-more {
  display: block;
  width: 100%;
  text-align: center;
  padding: 8px;
}

