.team-management--table-wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 183px;
  bottom: 0px;
}

.team-management table {
  margin: 0;

  tbody {
    background: $white;
  }

  tr {
    border-bottom: none;
  }

  th {
    height: 42px;
    padding: 0;
    justify-content: center;
    text-align: center;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      padding-left: 20px;
    }
  }

  td {
    justify-content: center;
    text-align: center;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      padding-left: 20px;
    }
  }
}
