.profile-filters {
   padding: 32px;
   padding-bottom: 0;

   .profile-filters--row:last-child {
      border-bottom: none;
   }

   .date-picker-dropdown.advanced-combobox {
      float: none;
      display: inline-block;
      vertical-align: middle;
      width: 45%;
      max-width: 400px;
   }
}

.profile-filters--checkbox {
   display: inline-block;
   vertical-align: middle;
   margin-right: 32px;
   font-weight: normal;

   .checkbox--box {
      margin-right: 16px;
      vertical-align: bottom;
   }
}

.profile-filters--text {
   max-width: 400px;

   .filter-text-input {
      width: 100%;
   }
}

.interaction-date-dropdown {
   width: 45%;
   max-width: 280px;
   margin-top: 24px;
}

.profile-filters--col {
   width: 45%;
   max-width: 420px;
   padding-right: 16px;
   margin-right: 16px;
   position: relative;
   display: inline-block;
   vertical-align: middle;

   .advanced-combobox {
      width: 100%;
      max-width: 400px;
      float: none;
   }

   &.is-active {
      border-right: solid 1px $border;

      &::before {
         @include triangle(18px, $border, right);
         @include position(absolute, 33% -9px null null);
         @include calc(top, "50% - 9px");
         content: "";
      }

      &::after {
         @include triangle(18px, $white, right);
         @include position(absolute, 33% -8px null null);
         @include calc(top, "50% - 9px");
         content: "";
      }
   }
}