.pool-prospect-card {
  display: block;
  background: $white;
  border: solid 1px $border;
  padding: 8px 8px 12px 8px;
  border-radius: 3px;
  color: $baseFontColor;
  margin: 12px;
  position: relative;
  overflow: hidden;

  &.is-active {
    .pool-prospect-card--clear:not(:hover) {
      color: $purple500;
      border-color: $purple500;
    }
  }

  .is-deceased {
    display: inline-flex;
    align-items: center;
    color: $red500;
    a {
      color: $red500;
    }
  }
}

.pool-prospect-card--menu {
  margin-left: auto;
}

.pool-prospect-card--menu-inner {
  .action-menu-item--label {
    padding: 8px 0;
  }
}

.pool-prospect-card--card {
  @include calc(width, "100% - 60px");
  display: inline-block;
  vertical-align: middle;

  .new-contact-card--name {
    white-space: normal;
  }
}

.pool-prospect-card--action {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 16px;
  margin-right: 8px;
  text-align: center;
  cursor: pointer;

  .checkbox--box {
    margin-right: 0px;
  }
}

.pool-prospect-card--clear,
.pool-prospect-card--assign {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border: solid 1px $border;
  border-radius: 30px;
  padding: 4px;
  box-shadow: 0 1px 1px $border;
}

.pool-prospect-card--clear {
  &:hover {
    color: $red500;
    border-color: $red500;
  }
}

.pool-prospect-card--ask-divider {
  margin: 0 16px 0;
}

.pool-prospect-card--joint-avatars {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  width: 54px;
}

.pool-prospect-card--primary-avatar {
  position: absolute;
  left: 20px;
  border: solid 2px $border;
  background: $white;
}

.pool-prospect-card--secondary-avatar {
  margin-top: 10px;
}

.pool-prospect-card--divider {
  border-bottom: 1px solid $border;
  margin: 12px 0 10px;
}

.pool-prospect-card--data-value {
  color: $gray900;
}

.pool-prospect-card--dropdown-menu {
  align-self: flex-start;
  .et--actions-menu--menu-option {
    font-weight: normal;
    margin: 0;
  }
}

.pool-prospect-card--committed {
  margin: 0 6px;
}

.pool-prospect-card--key-icon {
  margin-left: 4px;
}
