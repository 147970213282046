.facebook-accounts-controller {
  .empty-state-message--action-area {
    .facebook-accounts--btn {
      margin: 0px;
      float: none;
      cursor: pointer;
    }
  }
}

.facebook-accounts-controller--body {
  position: absolute;
  top: 80px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: auto;
  padding: 24px;
}

.facebook-accounts--disconnect {
  display: block;
  text-align: right;
  margin-top: 17px;
}

.facebook-accounts--btn {
  margin-top: 5px;
  float: right;
  cursor: pointer;
}

.facebook-accounts--text {
  padding: 8px 0;
}

.facebook-accounts-table {
  border: 1px solid $border;
}

.facebook-accounts-table--header {
  background-color: $gray105;
  color: $gray300;

  th {
    font-weight: normal;
    padding-left: 16px;
  }
}

.facebook-accounts-table--body {
  td {
    padding-left: 16px;
  }
}

.facebook-accounts-table--icon {
  width: 30px;
  border-radius: 2px;
  vertical-align: middle;
  margin-right: 16px;
}

.facebook-accounts-table--connected {
  .gt-icon {
    color: $green300;
  }
}
