.confirm-reset-pools-modal {
  padding: 20px;
}

.confirm-reset-pools-modal--top-container {
  display: flex;
}

.confirm-reset-pools-modal--pool-list {
  flex-basis: 50%;
  margin-right: 10px;
}

.confirm-reset-pools-modal--actions {
  flex-basis: 50%;
  margin-left: 10px;
}

.confirm-reset-pools-modal--list {
  overflow-y: auto;
  padding-left: 12px;
  max-height: 300px;
}

.confirm-reset-pools-modal--check-icon {
  background: $gray150;
  border-radius: 50%;
  color: $white;
  margin-right: 8px;
}

input.confirm-reset-pools-modal--input {
  width: 260px;
  margin-right: 16px;
}

.confirm-reset-pools-modal--confirm-section {
  padding: 30px;
  background: $gray105;
}

.confirm-reset-pools-modal--disclaimer {
  padding: 16px 0;
}

.confirm-reset-pools-modal--confirm-string {
  padding-top: 8px;
  color: $red500;
  font-weight: $bold;
}

.confirm-reset-pools-modal--sub-label {
  padding-left: 30px;
}

.confirm-reset-pools--actions {
  display: flex;
}
