.gift-details-modal--contents {
  padding: 0 20px;
}

.gift-details-modal--icon {
  float: left;
}

.gift-details-modal--summary {
  border-bottom: 1px solid $border;
  @include clearfix;
  padding: 16px 0;
}

.gift-details-modal--related {
  border: 1px solid $border;
  @include clearfix;
  padding: 10px 16px 16px;
}

.gift-detail-modal--related-wrapper {
  display: flex;
}

.gift-details-modal--related-name {
  cursor: pointer;
}

.gift-details-modal--related-date {
  color: $gray500;
}

.gift-details-modal--summary-content,
.gift-details-modal--related-content {
  float: left;
  padding-left: 12px;
}

.gift-details-modal--type {
  color: $gray900;
  font-weight: bold;
}

.gift-details-modal--date {
  color: $gray500;
}

.gift-details-modal--amount {
  font-size: 20px;
  font-weight: bold;
  float: right;
  padding-top: 12px;
}

.gift-details-modal--details {
  padding: 12px 0;

  .engagement-card:before {
    left: 20px;
  }

  .engagement-card,
  .engagement-card--date,
  .engagement-card--icon-wrapper {
    padding: 0;
  }

  .engagement-card--wrapper {
    margin-left: 55px;
    padding-top: 0;
  }
}

.gift-details-modal--fact {
  margin-bottom: 10px;
}

.gift-details-modal--fact-label {
  color: $gray500;
}
