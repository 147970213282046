.app-invitation-modal {
  padding: 20px;
}

.app-invitation-modal--contact-card {
  padding: 16px 8px 24px;
}

.app-invitation-modal--alt-section {
  border-top: 1px solid $border;
  padding: 24px;

  input[type="text"] {
    margin-left: 32px;
    width: 324px;
  }
}

.app-invitation-modal--note {
  color: darken($gray500, 2%);
  font-weight: normal;
  margin-bottom: 8px;
}

.app-invitation-modal--checkbox,
.app-invitation-modal--label {
  display: inline-block;
  cursor: pointer;
}

.app-invitation-modal--heading {
  font-weight: $bold;
}

.app-invitation-modal--label {
  padding-top: 16px;
  color: $gray500;
  font-weight: 400;
}

.app-invitation-modal--primary-email {
  color: $gray500;
  display: block;
  margin-left: 32px;
}

.app-invitation-modal--sso-checkbox {
  display: flex;
  align-items: flex-start;
  max-height: 32px;
  min-height: 32px;
}

.app-invitation-modal--sso-section {
  padding: 0 24px 24px;
}

.app-invitation-modal--head-agent {
  margin-top: 12px;
}
