.collaborator-icon-list {
  display: inline-flex;
  align-items: center;

  .gt-icon {
    font-size: 20px;
    margin-right: 4px;
    vertical-align: text-bottom;
  }

  .gt-icon-settings {
    margin-left: 12px;
  }
}

.collaborator-icon-list--tooltip {
  display: inline-block;
  vertical-align: top;

  &.is-owner {
    .tooltip {
      margin-left: -35px;

      &::after {
        left: 13%;
      }
    }
  }

  .tooltip {
    width: 180px;
    min-width: 0;
    margin-left: -90px;
    padding: 5px;
    bottom: 140%;
    white-space: nowrap;

    &::after {
      left: 43%;
      top: 17px;
    }
  }
}

.collaborator-icon-list--icon {
  margin-right: 8px;
  height: 2vw;
  width: 2vw;
  max-height: 32px;
  border-radius: 3px;
}

.collaborator-icon-list--owner {
  display: inline-block;
  padding-bottom: 3px;
  margin-right: 5px;
  border-bottom: solid 2px $purple500;

  .collaborator-icon-list--icon {
    margin-right: 0px;
  }
}

.collaborator-icon-list--action {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
