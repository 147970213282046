// UNIVERSAL
.journey-modal--alert {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 0px;
}

.journey-modal--warning {
  background-color: #f2f9f2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 0px;
}

.journey-modal--warning-check {
  font-size: 16px;
  padding-right: 5px;
}

.journey-modal--data-container {
  // puts fields side by side
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

// EDIT DUE DATE MODAL
.task-edit-date-picker {
  border: solid 1px #d7d7d7;
  color: #454545;
  font-weight: normal;
}

button.date-picker-dropdown--btn.task-edit-date-picker.et--button-secondary {
  min-width: 35%;
}

// BULK MOVE MODAL
.journey-modal--radio {
  display: flex;
  text-align: left;
  padding: 16px 0px;
  margin-left: 0px !important;
  input {
    min-width: 22px;
  }
}

// End Journey Modal
.end-journey-form-field {
  display: flex;
  margin: 8px 0px 8px 0px;
}

.end-journey-current-task {
  margin-left: 50px;
}

.end-journey-listboxselect {
  margin-top: 5px;
}

.end-journey-selectnext--container {
  .radio-title {
    font-weight: 400;
    font-size: 14px;
  }
}

.end-journey--radio {
  margin: 10px 0px;
}

.end-journey-remove--radio {
  margin: 30px 0 0 0;
}

.delete-modal-button--group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.days-description {
  flex: 0 1 auto;
  margin-right: 10px;
  margin-left: 25px;
  margin-top: 30px;
  color: #FF033E;
  white-space: normal;
  line-height: 1.2;
}