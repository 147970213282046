.donor-info {
  overflow: hidden;

  dl {
    margin-left: 30px;
  }

  dl dt {
    color: darken($gray500, 2%);
    margin-top: 0;
  }
}

.donor-info--section {
  margin: 16px;
}

.donor-info--title-more {
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0px;
}

.donor-info--search {
  display: block;
  margin: 24px;
}

.donor-info--tooltip {
  &.tooltip-trigger--content {
    font-size: 12px;
  }
}

.donor-info--datalist {
  margin-bottom: 12px;

  dt {
    display: inline-block;
    vertical-align: text-top;
    width: 148px;
    text-align: right;
    margin: 0;
    word-break: break-word;
    line-height: $baseFontSize;
  }

  dd {
    @include calc(width, "100% - 148px");
    display: inline-block;
    vertical-align: top;
    padding: 0 16px;
  }

  .gt-icon-star {
    color: $gray150;
  }
}

.donor-info--empty-field {
  color: $gray150;
}

.top-fields-selector--modal {
  .sort-selector--active-wrapper {
    height: 380px;
  }

  .dnd-controller--list {
    height: 325px;
  }
}

.top-fields-selector--empty {
  width: 100%;
  margin: 10px auto;
}
