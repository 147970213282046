.ag-controller {
  @include position(absolute, 0px 0px 0px 0px);
}

.ag-controller--empty {
  padding: 56px;
}

.ag-reporting-contorller--header {
  @include position(absolute, 0px 0px null 0px);
  z-index: 12;
}

.ag-reporting-controller--content {
  padding: 16px;
}

.ag-reporting-controller--test-banner {
  background-color: $blue200;
  text-align: center;
  padding: 8px;
  font-weight: bold;
  color: $white;
  text-transform: capitalize;

  a {
    font-weight: bold;
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  &.is-live {
    background-color: $green200;
  }

  &.is-test {
    background-color: $yellow150;
  }
}

.ag-gifts-controller {
  @include position(absolute, 0px 0px 0px 0px);
}

// AG Pages Controller

.ag-pages-controller--header {
  height: 80px;
  border-bottom: 1px solid $border;
  background-color: $white;
}

.ag-pages-controller--title {
  display: inline-block;
  font-size: 20px;
  margin-top: 28px;
  margin-left: 24px;
}
