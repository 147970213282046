.task-contact-card--container {
  // puts button next to contact card
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-options {
  // fields side by side
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

button.task-calendar {
  // overwrite some styling
  border: solid 1px #d7d7d7;
  color: #454545;
  font-weight: normal;
  min-width: auto;
  margin: 0px;
}

.add-to-calendar-modal {
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 15px;
    width: 226px;
    margin-left: 0 !important;
  }
}

.google-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  font-size: 17px;
  background: url($googleIconSprite);
  background-repeat: no-repeat;
  background-size: 32px;
}

.microsoft-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  background: url($microsoftIconSprite);
  background-repeat: no-repeat;
  background-size: 16px;
}

.add-to-calendar-btn {
  display: flex;
}
