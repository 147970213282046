.events-matched-controller {
  height: 100%;
}

.events-page-controller--matched-table {
  height: 100%;
  position: relative;
}

.events-matched-controller--controls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.events-matched-controller--controls-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.events-matched-controller--searchbox {
  width: 300px;

  input {
    width: 100%;
  }
}

.events-matched-controller--toggle {
  margin-left: 12px;
}

.events-matched-controller--footer {
  text-align: center;
  padding-top: 10px;
  border-bottom: 1px solid $border;
  margin-bottom: 40px;
}

.events-matched-controller--pagination {
  margin-left: 20px;
  margin-bottom: 20px;
}
