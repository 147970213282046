.proposal-detail {
  margin: 32px;
}

.proposal-detail--header {
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $border;
}

.proposal-detail--title-section {
  display: flex;
  align-items: center;
}

.proposal-detail--title {
  font-size: 20px;
  font-weight: $bold;
}

.proposal-detail--actions {
  display: flex;
}

.proposal-detail--action-button {
  font-weight: $bold;
  font-size: 14px;
  margin-right: 32px;
}

.proposal-detail--edit-icon {
  color: $blue300;
}

.proposal-detail--key-proposal {
  color: $yellow150;
}

.proposal-detail--body {
  padding-top: 16px;
  display: flex;
}

.proposal-detail--column {
  flex: 30%;
}

.proposal-detail--column-center {
  flex: 40%;
  margin: 0 100px;
}

.proposal-detail--heading {
  color: $gray500;
  font-weight: $bold;
  margin-top: 16px;
}

.proposal-detail--column-section {
  margin-bottom: 32px;
}

.proposal-detail--show-more-link {
  color: $blue300;
}

.proposal-detail--show-more-interactions {
  margin-left: 16px;
}

.proposal-detail--primary-contact {
  img {
    border: 2px solid $purple500;
  }
}

.proposal-detail--interaction-header {
  display: flex;
  justify-content: space-between;
}

.proposal-detail--interaction {
  display: flex;
  align-items: center;
}

.proposal-detail--interaction-updated {
  font-size: $fontSizeSmall;
  color: $gray500;
}

.proposal-detail--interaction-icon {
  border: 1px solid $gray110;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue300;
  margin: 8px;
}

.proposal-detail--add-interaction-button {
  color: $blue300;
  margin: 8px 40px 0;
  padding: 4px;
  height: 40px;
  width: 48px;
}

.proposal-detail--light-text {
  color: $gray200;
}

.proposal-detail--stages-section {
  padding-bottom: 32px;
  border-bottom: 1px solid $gray110;
}

.proposal-detail--select-stage {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.proposal-detail--time-in-stage {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.proposal-detail--confidence-score {
  display: flex;
  align-items: center;
}

.proposal-detail--confidence-score-percent {
  margin-right: 16px;
}

.proposal-detail--reporting-true {
  color: $green300;
}

.proposal-detail--reporting-false {
  color: $red300;
}

.proposal-detail--amounts-row {
  display: flex;
  justify-content: space-between;
}

.proposal-detail--amount {
  font-weight: $bold;
}

.proposal-detail--mapped-amount {
  font-weight: 700;
  font-size: 28px;
}

.proposal-detail--back-button {
  padding: 4px;
  height: 40px;
  min-width: 48px;
  padding-top: 5px;
}
