.eventbrite-settings--header {
  background: $white;
  border-bottom: solid 1px $border;
  height: 75px;
  position: relative;
  z-index: 10;
}

.eventbrite-settings--back {
  @include position(absolute, 22px null null 0px);
}

.eventbrite-settings--header-text {
  position: absolute;
  left: 40px;
  top: 26px;
  font-size: 20px;
}

.eventbrite-settings {
  .module--body {
    padding: 16px;
  }
}

.eventbrite-settings--body {
  padding: 32px;
}

.eventbrite-settings--account-text {
  font-size: 16px;
  margin: 8px 0 24px;
}

.eventbrite-settings--account-table {
  border: 1px solid $border;
}

.eventbrite-settings--account-table-head {
  background-color: $gray105;
  color: $gray300;

  th {
    font-weight: normal;
    padding-left: 16px;
  }
}

.eventbrite-settings--account-table-body {
  td {
    padding-left: 16px;
  }
}

.eventbrite-settings--account-check {
  color: $green300;
  font-size: 20px;
  margin-right: 7px;
}

.eventbrite-settings--connect {
  width: 600px;
  margin: 0 auto;
  text-align: center;
}

.eventbrite-settings--title {
  font-size: 20px;
  margin: 16px 0;
}

.eventbrite-settings--connect-text {
  width: 400px;
  margin: 0 auto;
}

.eventbrite-settings--connect-btn {
  width: 240px;
  margin-top: 16px;
}

.eventbrite-settings--account-pending {
  color: $white;
  background-color: #e3be4f;
  border-radius: 50px;
  font-size: 20px;
  width: 20px;
  margin-right: 12px;
}

.eventbrite-settings--annotation-text {
  font-size: 12px;
  color: $gray500;
  font-style: italic;
  margin-left: 32px;
}
