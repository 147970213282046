$tableBorder: $border;
$tableCellPadding: 2px 0;
$tableHeaderPadding: 10px 0;

$tableSortColor: $gray300;
$tableSortIcon: $gray500;
$tableSortIconActive: $gray150;

$tableHoverColor: lighten($gray105, 1%);

.table {
  table-layout: fixed;
  border-collapse: inherit;
  margin: 0;

  td,
  th {
    border-bottom: solid 1px $tableBorder;
  }
}

.table--header {
  th {
    padding: $tableHeaderPadding;
    border-bottom: solid 1px $tableBorder;
    border-top: solid 1px $blue200;
  }
}

.table--body {
  td {
    padding: $tableCellPadding;
  }
}

.table-sort {
  th {
    color: $tableSortColor;

    a {
      @extend .ellipsis-overflow;
      color: $gray900;
      font-weight: 500;

      &:hover {
        background: $blue106;
      }
    }
  }

  .fa-unsorted {
    color: $tableSortIconActive;
  }
}

.table-sort--icon {
  margin-left: 5px;
  color: $tableSortIcon;
}

.table-hover {
  tr:hover td {
    @include transition(background 0.1s ease-in);
    background-color: $tableHoverColor;
    cursor: pointer;
  }
}

.table-col-sorted {
  background: $gray105;
}

.table--center-aligned {
  tr td,
  tr th {
    text-align: center;
  }
}

.table--left-aligned {
  tr td,
  tr th {
    text-align: left;
  }
}

.table-module {
  @extend .module;

  > .table {
    margin: 0;
  }
}

.table-with-padding {
  tr td,
  tr th {
    padding: 10px;
  }
}

.table-with-borders {
  border-radius: 3px;
  border: solid 1px $border;

  tr {
    td,
    th {
      border-right: solid 1px $border;
      padding: 10px;

      &:last-child {
        border-right: none;
      }
    }

    &:last-child {
      td,
      th {
        border-bottom: none;
      }
    }
  }
}

.paged-col-table--header {
  @extend .table-sort;

  tr th {
    background: $blue106;
    color: $gray900;
    padding: 15px 10px;

    &:last-child {
      padding-right: 20px;
    }
  }
}

.paged-col-table--btn {
  @include position(absolute, 0px 0px 0px 0);
  background: fade-out($white, 0.3);
  border-left: solid 1px $border;
  padding: 12px;
  padding-top: 13px;

  .fa {
    margin: 0;
    color: fade-out($gray900, 0.4);
  }

  &:hover {
    .fa {
      color: $gray900;
    }
  }

  &.is-disabled {
    cursor: default;
    background: transparent;

    .fa {
      color: fade-out($blue300, 0.3);
    }
  }
}

.paged-col-table--body-empty {
  padding: 15px !important;
}

.sticky-header-module {
  overflow: auto;
}

.sticky-header {
  @include users_table__sub-header();
}

.sticky--header {
  @include position(absolute, 0px 0px null 0px);
  z-index: 3;
}

.sticky--inner {
  position: relative;
}

.admin-table {
  @extend .contact-list;

  .table--body > tr > td,
  .table--header > tr > th {
    border-right: solid 1px $border;
  }
}

.admin-table--org {
  padding: 15px;
  text-align: left;
}

.striped-table {
  tr td,
  tr th {
    padding: 0;
    height: 34px;
    border-right: 1px solid $blue300;
  }

  td:last-child,
  th:last-child {
    border-right: none;
  }

  tr:nth-of-type(odd) {
    background: #fafafa;
  }
}
