.new-contact-card {
  position: relative;
  padding: 8px;
  width: 320px;
}

.new-contact-card--body {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 4px 8px;

  &.is-stacked {
    flex-direction: column;
    text-align: center;
  }
}

.new-contact-card--content {
  padding: 8px 16px;
}

.new-contact-card--datalist-label {
  color: $gray500;
}

.new-contact-card--datalist-value {
  font-weight: 500;
}

.new-contact-card--deceased {
  @extend .badge-sq-error;
  margin-left: 4px;
}

.new-contact-card--name {
  &.is-deceased,
  .is-deceased {
    display: flex;
    align-items: flex-start;

    a,
    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $red500;
    }
  }
}
