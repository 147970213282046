.filter-panel-row {
   padding: 12px 0;
}

.filter-panel-row--label {
   display: inline-block;
   vertical-align: middle;
   width: 260px;
   padding-right: 32px;
   font-weight: bold;
}

.filter-panel-row--control {
   @include calc(width, "100% - 260px");
   display: inline-block;
   vertical-align: middle;
}
