$chartTooltipWidth: 185px;

.chart-tooltip {
   position: absolute;
   width: 0;
   height: 0;
}

.chart-tooltip--body {
   position: absolute;
   bottom: 0;
   left: 0;
   width: $chartTooltipWidth;
   margin-left: -($chartTooltipWidth / 2);
   margin-bottom: 8px;
   background-color: $white;
   border: 1px solid $border;
   padding: 10px;
   z-index: 2;
   border-radius: 2px;

   &::before {
      @include triangle(18px, $border, down);
      @include position(absolute, null null -9px 33%);
      @include calc(left, "50% - 9px");
      content: "";
   }

   &::after {
      @include triangle(18px, $white, down);
      @include position(absolute, null null -8px 33%);
      @include calc(left, "50% - 9px");
      content: "";
   }
}
