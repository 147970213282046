.filter-picker--trigger {
  font-weight: normal;
  color: $gray900;
  height: 39px;
  margin-right: 10px;
  padding: 10px 16px;
  display: flex;
  align-items: center;

  .overflow-text {
    max-width: 150px;
  }
}

.filter-picker--trigger--icon {
  color: $gray300;
}

.filter-picker--content {
  width: 360px;

  .search-input--input {
    margin-bottom: 10px;
  }

  //TODO: Update this in et-parts
  .et--clearable-input--icon,
  .et--clearable-input--clear-icon {
    top: 44%;
  }
}

.filter-picker--steps {
  min-height: 180px;

  .et--loading {
    // To make the "flash" of the loading indicator look nicer
    background: none;
  }
}

.filter-picker--search {
  padding: 16px 16px 0 16px;
}

.filter-picker--title {
  font-weight: $bold;
  color: $gray900;
}

.filter-picker--recent-heading {
  margin-bottom: 8px;
  font-weight: $bold;
  color: $gray900;
}

.filter-picker--recent {
  margin: 16px;
}

.filter-picker--recent-link {
  overflow: hidden;
}

.filter-picker--browse {
  font-size: $baseFontSize;
  margin: 0 16px;
}

.filter-picker--back-button {
  display: flex;
  align-items: center;
}

.filter-picker--back-chevron {
  font-size: 20px;
  width: 1em;
}

.filter-picker--options {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin: 0 8px 16px 8px;
}

.filter-picker--option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  margin: 16px 0 0 0;
  flex-basis: 25%;
  color: $gray500;

  i {
    font-size: 20px;
  }
}

.filter-picker--list-heading {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

.filter-picker--list-title {
  font-size: $fontSizeLarge;
  display: flex;
  position: relative;
}

.filter-picker--home-icon {
  font-size: $baseFontSize;
}

.filter-picker--list-icon {
  color: $gray500;
  margin-right: 8px;
}

.filter-picker--search-icon {
  color: $gray500;
  font-size: $fontSizeLarge;
}

.filter-picker--list-icon-selected {
  color: $purple500;
  font-weight: bold;
}

.filter-picker--list-option {
  margin: 8px 0;
}

.filter-picker--count {
  font-size: $fontSizeSmall;
  line-height: 13px;
  color: $gray500;
  border-bottom: 1px solid $gray110;
  padding: 0 16px 16px 16px;
}

// This class is to prevent the scroll bar from extending beyond the bottom right border radius of the popover
.filter-picker--group-wrapper {
  overflow-y: hidden;
  max-height: 200px;
  border-bottom-right-radius: 16px;
}

.filter-picker--group-wrapper-scroll {
  padding: 8px 16px 16px 16px;
  overflow-y: auto;
  max-height: 200px;
}

.filter-picker--no-results {
  margin: 16px;
}

.extra-filters-parent-body {
  display: flex;
}

.extra-filters-left-side {
  margin-right: 24px;
}

.extra-filters-right-side {
  width: 65%;
}

.new-filter-div-scrollbar {
  overflow: auto;
  max-height: 70vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.new-filter-div-scrollbar::-webkit-scrollbar {
  display: none;
}