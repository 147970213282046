$height: 250px;
$headerHeight: 40px;

.hub-kpicard--options-selection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hub-kpicard--options-selection-text {
  width: 80px;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.hub-kpicard--wrap {
  position: relative;
  height: $height;
}

.hub-kpicard--header {
  height: $headerHeight;
  padding: 0 12px;
  border-bottom: 1px solid $gray110;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hub-kpicard--header-icon {
  margin-right: 6px;
  font-size: 18px;
}

.hub-kpicard--content-wrap {
  height: $height - $headerHeight;
}

.hub-kpicard--content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.hub-kpicard--sub-content {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.metric {
    width: 50%;
  }

  .CircularProgressbar {
    padding: 24px;
  }
}

.hub-kpicard--main-stat {
  font-size: 42px;
  line-height: 56px;
  text-align: center;
  font-weight: 500;
}

.hub-kpicard--main-stat-label,
.hub-kpicard--sub-stat-label {
  font-size: 15px;
  padding-bottom: 6px;
  text-align: center;
}

.hub-kpicard--sub-stat {
  font-weight: 500;
  font-size: 18px;
  padding-left: 4px;
}

.hub-kpicard--percentage {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
}

.hub-kpicard--percentage-label {
  font-size: 15px;
}

.hub-kpicard--footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  margin: 20px 0;

  .et--progress-bar {
    margin-top: 4px;
  }
}

.hub-kpicard--footer-stat-label {
  font-size: 15px;
}

.hub-kpicard--footer-stat {
  font-weight: 500;
  font-size: 18px;
}

.hub-kpicard--footer-divider {
  width: 1px;
  height: 30px;
  background-color: $gray150;
  margin-top: 5px;
}

// Copied from node_modules because couldn't figure out how to get the file
// to get imported correctly
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
  width: 100%;
  /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
  vertical-align: middle;
}
