.security-session-manager--header {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.security-session-manager--actions {
  margin: 16px 16px 60px 16px;
  display: block;
}

.security-session-manager--help-text {
  display: inline-block;
  max-width: 500px;
  font-size: 16px;
}

.security-session-manager--logout-container {
  float: right;
}

.security-session-manager--active-device-table {
  td {
    height: 61px;
    padding: 15px 0px;
  }
}

.security-session-manager--table-header {
  background: $blue106;
  border-top: 1px solid $blue200;
  text-align: center;
}

.security-session-manager--table-row {
  text-align: center;
}

.security-session-manager--device-app {
  font-size: 11px;
  color: $gray500;
}

.security-session-manager--current-device {
  color: $gray300;
  font-weight: bold;
}
