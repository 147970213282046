.outreach--nav {
  @extend .sub-nav;
}

.outreach-container {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
}

.outreach-heading h2 {
  font-size: 16px !important;
}

.outreach-tab-group {
  width: 300px;
  padding: 15px 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 16px 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
}

.outreach-tab-group--item {
  flex-grow: 1;
  text-align: center;
  padding: 0 10px;
}

.tab-text {
  color: #007c9e;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}

.active-tab .tab-text {
  color: #a54ab5 !important;
}

.border-span {
  font-size: 20px;
  color: #d7d7d7;
  padding-left: 0px;
}

.active-tab {
  color: #a54ab5 !important;
  background-color: #fcf8fc;
  border-radius: 11px;
  font-weight: bold;
}

.outreach-card {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  padding: 30px 0 8px 1px;

  p {
    @extend .ellipsis-overflow;
    width: 100%;
    margin-bottom: 0;
  }

  .outreach--draft-card--header > p {
    width: 100%;
  }

  .outreach--draft-card--header > p:first-child {
    width: 968px;
    margin-bottom: 3px;
  }
}

@mixin checkbox-style {
  input.et--checkbox--input {
    &:checked + .et--checkbox--box {
      @content;
    }
  }
}

.outreach--checkbox-small {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  width: 15px;

  @include checkbox-style {
    background: #a54ab5;

    &::before {
      color: #ffffff;
      width: 14px;
      height: 14px;
      font-size: 11px;
      font-weight: bold;
    }
  }

  .et--checkbox--box {
    width: 15px;
    height: 15px;
    line-height: 15px;
  }
}

.outreach-card-date-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
}

.outreach-card-recipient-name {
  color: #007c9e;
}

.outreach-card-body p {
  color: #737373;
  white-space: nowrap;
  width: 842px;
}

.outreach-card-tab-group {
  padding: 2px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 18px 8px 0px;
}

.outreach-card-tab-group--item {
  padding: 6px 9px 6px 9px !important;
  background-color: #f6f6f6;
  border-radius: 20px;
  flex-grow: 1;
  text-align: left;
  padding: 0px;
}

.outreach-custom-active-pill,
.outreach-custom-signal-video {
  padding-right: 8px;
}

.outreach-custom-active-pill p,
.outreach-custom-active-pill i {
  font-size: 14px !important;
  color: #454545;
  padding: 3px;
  font-weight: 400;
}

.outreach-card-tab-group-icon-video {
  margin-right: 0px !important;
  font-size: 14px !important;
}

.outreach-card-tab-group-border {
  color: #f3f3f3;
  margin-right: 8px;
  font-size: 22px;
}

.outreach-card-tab-group--item .tab-text {
  color: #454545;
  font-weight: normal;
}

.outreach-card-thread-count {
  font-size: larger;
  span {
    font-weight: 500;
  }
}

.scheduled-detail-view--header-container {
  background-color: $gray105;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  border-radius: 12px;
  margin: 0px 25px;
  font-size: 13px;
}

.scheduled-detail-view--body {
  margin: 10px 25px;
  padding: 3px 10px;
  display: flex;
  flex-direction: column;
}

.thread-body-container {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  cursor: 'pointer';
}

.thread-detail-view--body {
  margin: 10px;
  padding: 3px 10px;
  display: flex;
  flex-direction: column;

  hr {
    margin: '8px 0';
    border: 'none';
    border-top: '1px solid #ccc';
  }
}
