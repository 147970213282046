$buttonSelectBorderRadius: 3px;

// Should be 40px height
// padding around icon should be 10px (between icon and text is 11px)
.button-select {
  appearance: none;
  display: inline-block;
  white-space: nowrap;
}

.button-select--item {
  @include transition(all, 0.1s ease-in);
  appearance: none;
  display: inline-block;
  border: 1px solid $border;
  border-right-width: 0;
  padding: 11px 12px 11px 10px;
  font-size: $baseFontSize;
  font-weight: 500;
  text-align: center;
  line-height: $baseFontSize + 1px;
  color: $linkText;
  cursor: default;
  box-shadow: none;
  background: $white;
  margin: 0;
  border-radius: 0;

  &.is-large {
    font-size: $baseFontSize;
    padding: 8px 16px;
  }

  &:hover,
  &:focus {
    &:not(.is-disabled):not(.is-selected) {
      cursor: pointer;
      background-color: $gray105;
      color: $purple500;
      border: 1px solid $gray150; // Need to override default button hover border
      border-right-width: 0;

      &:last-child {
        border-right-width: 1px;
      }
    }
  }

  &:first-child {
    border-top-left-radius: $buttonSelectBorderRadius;
    border-bottom-left-radius: $buttonSelectBorderRadius;
  }

  &:last-child {
    border-right-width: 1px;
    border-top-right-radius: $buttonSelectBorderRadius;
    border-bottom-right-radius: $buttonSelectBorderRadius;
  }

  &.is-selected {
    background-color: $purple105;
    color: $purple500;

    &:hover,
    &:focus {
      border: 1px solid $gray150; // Need to override default button hover border
      border-right-width: 0;

      &:last-child {
        border-right-width: 1px;
      }
    }
  }

  &.is-disabled {
    background-color: $gray105;
    color: $gray500;
    &:hover,
    &:focus {
      color: $gray500;
    }
  }

  // this is just to beat the specificity of '.module-header i'
  i.button-select--item-icon {
    margin: 0;
  }
}

.button-select--item-label {
  @include appearance(none);
  padding: 0 2px;
  vertical-align: middle;
}

.button-select--item-icon {
  @include appearance(none);
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  width: 21px;
  margin: 0;

  + .button-select--item-label {
    margin-left: 5px;
  }
}
