.bookmark-sharing-modal--url-copy {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.bookmark-sharing-modal--heading {
  font-weight: $bold;
  padding-bottom: 8px;
}

.bookmark-sharing-modal--org-section {
  background-color: $gray108;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.bookmark-sharing-modal--edit-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.bookmark-sharing-modal--user-name {
  width: 160px;
  margin-left: 8px;
}
