$emptyDataWidth: 200px;
$emptyDataHeight: 30px;

// Bar Chart
.charting-wrapper {
  position: relative;
}

.charting--bar {
  opacity: 1;

  &.is-hovered {
    cursor: pointer;
    opacity: 0.4;
  }
}

.charting--tooltip {
  position: absolute;
}

.charting--xaxis,
.charting--yaxis {
  opacity: 0.4;
}

.charting--yaxis-tick {
  fill: $gray500;
  text-anchor: end;
}

.charting--xaxis-tick {
  fill: $gray500;
  text-anchor: middle;
  text-transform: uppercase;
}

.charting--horizontal-bar,
.charting--vertical-bar {
  stroke: $gray500;
  opacity: 0.4;
}

.charting--empty-message {
  @include calc(left, "50% - #{$emptyDataWidth}/2");
  @include calc(top, "50% + #{$emptyDataHeight}/2");
  position: absolute;
  width: $emptyDataWidth;
  height: $emptyDataHeight;
  text-align: center;
  color: $gray150;
  line-height: 1.9;
  font-size: 24px;
}

.charting-legend {
  border: 1px solid $gray150;
  padding: 10px 0;
  display: inline-block;
}

.charting-legend--group {
  color: $gray900;
  margin: 0 20px;
}

.charting-legend--color {
  margin-right: 5px;
  display: inline-block;
  height: 10px;
  width: 10px;
}
