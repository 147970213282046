.query-builder--group {
  margin-bottom: 10px;
}

.query-builder--section {
  position: relative;
  border-left: 5px solid $gray200;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.query-builder--row {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding: 10px 21px;

  .query-builder--filter-selector {
    padding-right: 10px;
    .et--dropdown--body.is-open {
      min-width: 235px;
    }
  }

  .query-builder--filter-remove {
    i {
      font-size: 16px;
    }
  }
}

.query-builder--operator-value {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.query-builder--logic-remove {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  padding: 5px;
  border: 1px solid $gray150;
}

.query-builder--buttons {
  display: flex;
  justify-content: center;
  position: relative;
  top: 16px;
  button {
    color: $linkText;
    &:disabled {
      color: $gray150;
    }
    i:before {
      background-color: $white;
    }
  }
}

.filter-operator {
  display: flex;
  button {
    color: $gray900;
    margin-right: 10px;
    &.has-value {
      background-color: $green106;
      border: 1px solid $green200;
    }
  }
}
.dxo-filter-operator{
  display: flex;
  button {
    color: $gray900;
    margin-right: 10px;
  }
}

.filter-operator--value .calendar-picker-dropdown {
  width: 120%;
}

.query-logic {
  .et-listbox--label {
    font-weight: 700;
  }
}
