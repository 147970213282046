.user-settings-page--content {
  padding: $pageContainerPadding;

  &.fixed-page-wrapper {
    top: 92px; // main-sub-header is 50, sub-nav is 40, plus 2 for borders
  }
}

.profile-settings-header {
  background: $white;
  padding: 10px;
  display: flex;
}
