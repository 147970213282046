$contactListAvatarPos: 3px;
$contactListAvatarColSize: 50px;

.contact-list {
  margin-bottom: 0;
  margin-top: 0;
  height: 100%;

  thead tr {
    background: $gray105;

    th {
      padding: 15px 10px;
      position: relative;

      &:last-child {
        padding-right: 20px;
      }
    }
  }

  td,
  th {
    padding: 10px;
    text-align: center;

    &:first-child,
    &:nth-child(2) {
      border-right: solid 1px $border;
    }

    &:first-child {
      padding: 0;
    }

    .contact-card td {
      border: none;
    }
  }
}

.contact-column {
  td:first-child {
    width: 80px;
  }

  td,
  td:first-child {
    padding: 5px;
    text-align: left;
    border: none;
  }
}

.contact-list--avatar {
  width: $contactListAvatarColSize;
  float: left;
  margin-right: 15px;
}

.contact-list--checkbox {
  @include position(absolute, 0px 0px 0px 0px);
  display: block;
  cursor: pointer;
}

.contacts-table {
  .checkbox {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  .checkbox--box {
    @include position(absolute, 50% 0 0 50%);
    margin-top: -10px;
    margin-left: -10px;
  }
}

.contacts-table--pagination {
  margin-top: 15px;
}

.contact-name {
  display: inline-block;
  color: inherit;

  &:empty {
    width: 10em;
    height: 1em;
    background: $gray105;
  }
}

.contact-name--ellipsis .contact-name {
  display: inline;
}

.contact-table--map-toggle {
  margin-right: 8px;
  padding-right: 12px;
  align-self: center;
}
