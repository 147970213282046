.tv-video--action {
  i {
    color: grey;
    font-size: 22px;
    cursor: pointer;
  }
}

.tv-library-container {
  overflow: auto; /* Allows scrolling */
  scrollbar-width: none; /* Firefox */
  padding-top: 20px;
  max-height: 500px;
}

.tv-library-container::-webkit-scrollbar {
  display: none;
}

.tv-btn-icon {
  margin-right: 5px;
}
