.share-settings {
  padding: 20px;

  .loading-container {
    min-height: 50px;
  }

  .table {
    margin: 0;
  }
}

.share-settings--modal {
  .modal-confirm--footer {
    display: none;
  }
}

.share-settings--invite {
  background: $gray105;
  padding: 10px;

  .text-label {
    font-size: 11px;
    margin-bottom: 10px;
    margin-left: 1px;
  }
}

.share-settings--message {
  text-align: left;
  font-weight: normal;
  margin-top: 5px;
}

.share-settings--notify {
  text-align: left;
  font-weight: normal;
  margin: 10px 0;
}

.share-settings--users {
  @include users_table();
  min-height: 0;
  margin-bottom: 20px;
  border: solid 1px $border;
  border-radius: 3px;
}

.share-settings--users-heading {
  @include users_table__sub-header();
}

.share-settings--users-body {
  max-height: 230px;
  overflow: auto;
}

.share-settings--delete {
  @include users_table__delete();
}
