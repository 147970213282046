.homepage-main-content {
  height: 100%;
  width: 100%;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-direction: column;
}

.homepage-top {
  display: flex;
}

@media (max-width: 1200px) {
  .homepage-top {
    flex-direction: column;
  }
}

.homepage-card-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage-card-heading-column {
  display: flex;
  flex-direction: column;
}

.chart-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resource-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.homepage-card-title {
  margin: 50px 0 20px 5px;
}
.resource-title-upcoming{
  margin: 0px 0 20px 5px
}

.resource-section-title {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.metrics-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.metrics-button-title {
  margin-right: 20px;
}
