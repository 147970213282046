.relationship-card--avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: top;
}

.relationship-card--info {
  @include calc("width", "100% - 60px");
  display: inline-block;
  vertical-align: middle;
}

.relationship-card > div {
  padding-left: 16px;
}

.relationship-card--name {
  font-weight: $bold;
  font-size: 14px;

  &.is-deceased-link {
    color: $red500;
  }

  &:hover.is-deceased-link {
    color: $red500;
  }

  &.is-deceased {
    color: $red500;
  }
}

.relationship-card--inferred-type {
  @extend .ellipsis-overflow;
  font-size: 14px;
  color: $gray500;
}

.relationship-card--remote-id {
  font-size: 14px;
  color: $gray500;
}

.relationship-card--deceased {
  @extend .badge-sq-error;
  font-size: 12px;
  display: inline-block;
  margin-left: 76px;
}
