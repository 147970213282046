.pool-report-controller--table {
  padding: 0 16px 16px;
  margin-top: -12px;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .et--flex-table--wrapper {
    font-size: 14px;
  }
  .et--flex-table--header-cell,
  .et--flex-table--sort-header-cell {
    white-space: pre-wrap;
  }
}

.pool-report--link-text {
  padding: 8px;
  padding-right: 0;
}

.pool-report--performance {
  margin-top: 8px;
}

.volunteers-stats-table--contact-col {
  &.et--flex-table--header-cell,
  .et--flex-table--sort-header-cell {
    padding-left: 12px;
  }
}

.volunteers-stats-table--table {
  th {
    border-top: solid 1px $blue200 !important;
    border-right: none !important;
    background: $blue106;
    color: $gray900;
    padding: 15px 10px;
  }
}
