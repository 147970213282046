.map-toolbar {
  // position toolbar at top of the map
  position: absolute;
  top: 10px;
  height: 40px;

  z-index: 2; // place toolbar on top of map, behind contacts list
  
  // display toolbar items on left and right of map
  width: 100%; 
  display: flex;
  justify-content: space-between;
}

.map-toolbar--top-left {
  padding-left: 10px;

}

.map-toolbar--top-right {
  padding-right: 10px;
  flex: none; // does not shrink
  display: flex;
  justify-content: flex-end;
}

// MAP MARKERS 

.map-tooltip {
  padding: 0 8px;
  .new-contact-card--body {
    padding: 0;
  }
}

.gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
}
