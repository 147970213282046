.volunteer-management-table {
  top: 180px;

  .new-contact-card--body {
    padding: 0 8px;
  }

  tr,
  th {
    height: 51px;
  }

  .fixed-table--body .table--body tr td {
    text-align: left;
    padding-left: 22px;
    height: 92px;
  }

  .fixed-table--horizontal {
    span {
      margin-left: 14px;
    }
  }
  .fixed-table--vertical {
    overflow-y: overlay;
  }

  .fixed-table--footer {
    text-align: center;
    padding-bottom: 1vh;
  }

  &.all-selector-visible {
    top: 226px;
  }

  .pagination {
    margin-bottom: 40px;
  }
}

.volunteer-management-table--contact {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.volunteer-mangement-table--auth-section {
  display: flex;
}

.volunteer-mangement-table--authed-icon {
  color: $blue200;
}

.volunteer-mangement-table--auth-icon-wrapper {
  flex-basis: 15%;
}

.volunteer-management-table--invite-sent-icon {
  color: $gray150;
}
