$tripControlsHeight: 70px;
$tripLogHeaderHeight: 70px;

.trip-log {
  @include position(absolute, -$tripControlsHeight 0px 0px null);
  width: 480px;
  background: lighten($gray105, 1%);
  border: solid 1px $border;
  box-shadow: 0 1px 2px 1px fade-out($gray500, 0.8);
  z-index: $tripLogZindex;
}

.trip-log--header {
  height: $tripLogHeaderHeight;
  border-bottom: solid 1px $border;
  background: $white;
}

.trip-log--toggle {
  display: inline-block;
  width: $tripLogHeaderHeight;
  height: $tripLogHeaderHeight;
  line-height: $tripLogHeaderHeight + 10px;
  border-right: solid 1px $border;
  text-align: center;
  vertical-align: top;
}

.trip-log--title {
  @include calc(width, "100% - #{$tripLogHeaderHeight}");
  display: inline-block;
  vertical-align: top;
  padding: 12px 16px;

  h3 {
    font-size: 20px;
    letter-spacing: 0.3px;
  }
}

.trip-log--body {
  @include position(absolute, $tripLogHeaderHeight 0px 0px 0px);
  overflow: auto;
}

.trip-log--empty {
  color: $gray500;
  text-align: center;
  padding: 32px 16px;
}

.trip-log--feed {
  .interaction {
    @include display(flex);
  }

  .interaction::before {
    display: none;
  }

  .interaction--content {
    width: 100%;
    border-radius: 0;
    border: none;
    margin: 0;
    padding-left: 0;
  }

  .interaction-labels--trip {
    display: none;
  }
}
