.filters-modal-quick {
  display: flex;
  margin-bottom: 30px;
  .quick-filters {
    margin-right: 12px;
  }
}

.filters-modal-header {
  color: $gray900;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.filters-modal-count {
  display: flex;
  align-items: center;
  * {
    margin-right: 12px;
  }
}

.filters-pie-chart {
  height: 30px;
  width: 30px;
  border: 1px solid $gray150;
  border-radius: 16px;
}

.filters-modal-cancel {
  font-size: $fontSizeLarge;
  margin-right: 16px;
}
