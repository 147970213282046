.custom-tooltip {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: black;
}

.custom-tooltip p {
  margin-bottom: 0;
}

.custom-tooltip-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 3px;
}
