.profile-summary--column-left {
  width: 66.7%;
  float: left;
}

.profile-summary--column-right {
  width: 33.3%;
  float: left;
}

.profile-summary--card {
  padding: 4px;
}

@media screen and (max-width: 992px) {
  .profile-summary--column-left,
  .profile-summary--column-right {
    width: 100%;
    float: none;
    margin: auto;
  }
}
