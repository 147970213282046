.validate-phone-title {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
}

.validate-phone-button {
  position: absolute;
  right: 18px;
}

.validate-phone-body-message {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

.validate-phone-body-number {
  text-align: center;
  font-weight: 700;
  font-size: 28px;
}
