.combobox-with-images {
  .checkbox {
    width: 100%;
  }
}

.combobox-with-images--item {
  display: flex;
  align-items: center;
}

.combobox-with-images--icon {
  width: 35px;
  height: 35px;
}

.combobox-with-images--label {
  margin-left: 10px;
}
