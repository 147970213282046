.export-search {
  position: relative;

  .modal-confirm--content {
    text-align: left;
  }

  .modal-confirm--submit .fa {
    margin: 0 0 0 10px;
  }

  .filters--display {
    @include scrolling-shadows();
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    font-size: 14px;

    .fa-times {
      display: none;
    }

    li {
      margin-bottom: 10px;
    }
  }
}

.export-confirm {
  padding: 20px;
}

.exports-table {
  th {
    font-weight: normal;
  }

  tr.is-unread td {
    background: fade-out($green300, 0.95);

    &:first-child {
      border-left: solid 3px $green300;
      font-weight: bold;
    }

    &:last-child {
      border-right: solid 3px $green300;
    }
  }
}

.exports-table--empty {
  display: block;
  width: 350px;
  margin: 0 auto;
}

.export-toast,
.bulk-add-to-list-toast {
  h5 {
    font-size: 1.2em;
  }

  p {
    margin: 14px 0;
    line-height: 1.3em;
    font-weight: normal;

    &.bulk-add-to-list-toast--success-message {
      font-weight: bold;
      margin: 0;
      padding-bottom: 8px;
    }
  }

  .progress-bar-info-inverse {
    margin: 15px 0 0 0;
  }

  .toast--actions {
    margin-top: 0;
    padding: 0;
  }
}

.export-toast--actions {
  display: flex;
  justify-content: space-between;
}

.exports-table--action,
.exports-table--action-download {
  cursor: pointer;

  i {
    margin-right: 8px;
  }
}

.exports-table--action-download {
  margin-left: 16px;
}

.exports-table--error,
.exports-table--scheduled-error {
  @extend .text-placeholder;
  text-align: center;
  margin: 0 16px 0;
}

.exports-table--help-text {
  margin-left: 8px;
}

.exports-table--action {
  padding: 30px;
  white-space: nowrap;
}
