$tripMapMeetingsWidth: 350px;

.trip-map {
  @include position(absolute, 0px 0px 0px 0px);
}

.trip-map--meetings {
  @include position(absolute, 0px null 0px 0px);
  width: $tripMapMeetingsWidth;
  background: $white;
  border-right: solid 1px $border;
  overflow-y: auto;
  overflow-x: hidden;
}

.trip-map--meeting {
  &:first-child {
    .trip-map--meeting-header {
      border: none;
    }
  }
}

.trip-map--meeting-header {
  background: $gray105;
  padding: 8px 16px;

  border-top: solid 1px $border;
}

.trip-map--meetings-empty {
  padding: 8px;
  border-top: solid 1px $border;
  text-align: center;
  color: $gray500;
}

.trip-map--meeting-card {
  position: relative;
  margin: 8px;

  .meeting-card {
    padding: 20px 20px 50px;
    max-width: inherit;
    height: 100%;
  }
}

.trip-map--card-meta {
  @include position(absolute, null 16px 22px null);

  i,
  span {
    vertical-align: middle;
  }
}

.trip-map--info-directions {
  margin-left: 24px;
}

.trip-map--container {
  @include position(absolute, 0px 0px 0px $tripMapMeetingsWidth);
}

.trip-map--info-window {
  .meeting-card {
    height: auto;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 4px 0 8px 0;

    &:hover {
      background: $white;

      .meeting-card--name {
        color: $purple500;
      }
    }
  }

  .timeblock-card,
  .timeblock-card:hover {
    background: $white;
  }

  .gt-icon {
    font-size: 16px;
  }

  .gt-icon-directions {
    font-size: 21px;
    vertical-align: middle;
    margin-right: 4px;
  }
}
