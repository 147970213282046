$pageHeaderHeight: 75px;

.page-header {
  background: $white;
  position: relative;

  a {
    cursor: pointer;
  }
}

.page-header--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $pageHeaderHeight;

  .page-header--label {
    flex-basis: 100%;
  }
}

.page-header--image {
  margin-right: 15px;
  img {
    border: 1px solid $border;
  }
}

.page-header--label {
  font-size: 20px;
  line-height: 30px;
  padding: 16px;

  &.has-back {
    padding-left: 0px;
  }
}

.page-header--subtitle {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
}

.page-header--state {
  padding: 0 16px;

  span {
    display: inline-block;
  }

  .btn-action {
    padding: 16px;
    margin-left: 8px;
    width: 130px;
  }
}
