.kpi-card {
   display: inline-block;
   vertical-align: top;
   padding: 4px 8px 8px 24px;
   margin-bottom: 16px;
   border-right: solid 1px lighten($border, 5%);
   min-height: 75px;

   &:last-child {
      border-right: none;
   }
}

.kpi-card--title {
   @extend .text-label;
   text-transform: uppercase;
   letter-spacing: 1px;
   display: inline-block;
   margin-bottom: 5px;
}

.kpi-card--value {
   font-weight: bold;
   font-size: 28px;
}
