.membership {
  @include display(flex);
  @include flex-direction(column);
  height: 100%;
  padding-top: 16px;

  &.is-loading {
    min-height: 115px;
  }

  .profile-card-header {
    padding: 16px;
    margin-bottom: 8px;
  }
}

.membership--summary {
  padding: 16px;
  color: $gray500;
  text-align: center;
}

.membership--empty {
  padding: 0 27px 20px;
  color: $gray500;
  text-align: center;
}

.membership--view-all {
  .membership--list {
    min-width: 100%;
    border: none;
  }

  .membership--groups {
    padding: 16px 8px;
  }

  .membership--owner-comma {
    display: none;
  }

  .profile-card-subtitle {
    padding-bottom: 4px;
    margin-bottom: 8px;
  }
}

.membership--groups {
  @include display(flex);
  @include flex-flow(row wrap);
  height: 100%;
  margin-bottom: 16px;
}

.membership--list {
  flex-grow: 2;
  width: 33%;
  padding: 0 16px 16px 16px;
  border-right: solid 1px $border;

  .profile-card-subtitle {
    border-bottom: solid 1px $border;
    margin-bottom: 8px;
  }

  &:last-child {
    border-right: none;
  }
}

.membership--title-more {
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0px;
}

.membership--item {
  padding: 4px 0;

  .gt-icon-cancel {
    width: 16px;
    text-align: right;
    color: $gray150;
    font-size: 16px;
    cursor: pointer;
    vertical-align: top;
    line-height: $baseLineHeight;

    &:hover {
      color: $purple500;
    }
  }
}

.membership--item-label {
  @include calc(width, "100% - 16px");
  display: inline-block;
}

.membership--owner {
  color: $gray500;
}

.membership--item-icon {
  width: 16px;
  text-align: left;
}
