.score-breakdown {
  padding: 24px 0px 12px;
}

.score-breakdown--label {
  line-height: 10px;
}

.score-breakdown--sub-score {
  margin-bottom: 16px;
}

.score-breakdown--progress {
  width: 100%;
  margin-left: 0;

  .score-progress--wrapper {
    background: $background;
    height: 6px;
  }

  .score-progress--inner {
    height: 6px;
  }

  .score-progress--label {
    font-weight: normal;
  }
}
