.selected-items-toolbar {
  display: flex;
  align-items: center;
  border: solid 1px $border;
  margin-right: 8px;
  height: 100%;
  border-radius: 3px;
  padding: 8px;

  .et--checkbox {
    padding-right: 4px;
    background: none;
    border-radius: 2px;
    margin-bottom: 0;

    label {
      // override
      margin-bottom: 0;
    }
  }

  .et--checkbox--box {
    margin: 0;
  }

  .addlist-popover {
    white-space: nowrap;
  }
}

.selected-items-toolbar--item {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  padding: 8px;
  background: none;
  margin-right: 8px;
}
