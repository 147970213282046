// THIS IS STYLING FOR A DEPRECATED COMPONENT; PREFER TO USE THE ONE DEFINED IN ET-COMPONENTS OR PROGRESS-BAR-WITH-LABEL

.progress-bar {
  width: 100%;
  position: relative;
}

.progress-bar--outer {
  padding: 2px;
  background: #f5f5f5;
  border-radius: 10px;
}

.progress-bar--inner {
  @include transition(width 0.3s ease-in);
  height: 10px;
  background-color: $linkText;
  border-radius: 10px;
  position: relative;

  &::before {
    @include position(absolute, 0px 0px 0px 0px);
    background-image: url($progressImg);
    border-radius: 10px;
    opacity: 0.25;
    content: "";
  }
}

.progress-bar-processing {
  .progress-bar--inner {
    background-color: $gray150;
  }
}

.progress-bar-info-inverse {
  .progress-bar--outer {
    background: #2e3b40;
    border: solid 1px #2e3b40;
    padding: 0px;
  }

  .progress-bar--inner {
    background-color: $gray900;
  }

  &.progress-bar-processing {
    .progress-bar--inner::before {
      opacity: 0.1;
    }
  }
}

.progress-bar-plain {
  .progress-bar--outer {
    border-radius: 2px;
    padding: 0px;
  }

  .progress-bar--inner {
    background-color: fade-out($gray500, 0.5);
    border-radius: 2px;

    &::before {
      background-image: none;
    }
  }
}
