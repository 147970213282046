.profile-top-fields--list {
  padding: 0 20px 20px;

  dl dt {
    color: darken($gray500, 2%);
  }
}

.profile-top-fields--empty-field {
  color: $gray150;
}
