.pool-form-modal--assign-section {
  padding: 8px;
}

.pool-form-modal--row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-field {
    width: 45%;
  }

  .form-field--label {
    display: block;
    width: 100%;
    line-height: 21px;
  }

  .form-field--control {
    width: 100%;
  }
}

.pool-form-modal--switch-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 8px;
}
.pool-form-modal--giving-stages {
  display: flex;
  justify-content: space-between;

  .form-field {
    flex: 1;
    min-width: 228px;
  }

  .et--dropdown--toggle {
    white-space: nowrap;
  }
}

.pool-form-modal--date-picker--body {
  width: 580px;

  .calendar-date-picker {
    display: flex;
  }
  .calendar-date-picker--from,
  .calendar-date-picker--to {
    flex-basis: 50%;
    float: none;
  }
}

.pool-form-modal {
  .et--advanced-combobox--selected-text {
    white-space: nowrap;
  }
}

.pool-form-modal--stages {
  margin-left: 28px;
}

.pool-form-modal--goals {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

input[type="text"].pool-form-modal--url {
  width: 189px;
}
