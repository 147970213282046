.volunteer-pools-table-controller {
  tr,
  th {
    height: 51px;
  }

  .fixed-table--body .table--body tr td {
    text-align: left;
    padding-left: 22px;
    height: 92px;
  }

  .fixed-table--horizontal {
    span {
      font-weight: normal;
      margin-left: 14px;
    }
  }
  .fixed-table--vertical {
    overflow-y: overlay;
  }

  .fixed-table--footer {
    text-align: center;
    padding-bottom: 1vh;
  }

  .fixed-table--footer {
    text-align: center;
    padding-bottom: 1vh;
  }
}

.volunteer-pools-table-controller--checkbox-cell {
  display: flex;
}

.volunteer-pools-table-controller--selection-mode-label {
  color: $gray500;

  &.disabled {
    color: $gray500;
  }
}
.volunteer-pools-table-controller--assigned-rate {
  text-align: right;
  padding-right: 16px;
}
