// Tooltip Variables
$tooltip-background: $gray105;
$tooltip-width: 18em;
$tooltip-font-size: $baseFontSize;
$tooltip-arrow-width: 8px;
$tooltip-arrow-height: 16px;
$tooltip-shadow-top: 0 2px 0 $border;
$tooltip-shadow-bottom: 0 -2px 0 $border;
$tooltip-shadow-left: 2px 0 0 $border;
$tooltip-shadow-right: -2px 0 0 $border;

.global-tooltip {
  width: $tooltip-width;

  &.is-visible .tooltip {
    @include position(relative, 0px 0px 0px 0px);
    position: relative !important;
    margin: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-item {
  position: relative;
  line-height: 0.9em;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }

  p {
    margin-bottom: 0;
  }
}

.tooltip {
  @include box-sizing(border-box);
  @include transition(opacity 0.2s ease-in-out);
  background-color: $tooltip-background;
  border-radius: 3px;
  // Make sure you use -webkit-backface-visibility: hidden; on the body element to prevent 1px nudging bugs.
  line-height: 1.5em;
  opacity: 0;
  padding: 1em;
  text-align: center;
  visibility: hidden;
  z-index: 1000;
  font-weight: normal;
  font-size: $tooltip-font-size;
  border: solid 1px $border;
  border-bottom-width: 2px;
  position: absolute;

  &::after,
  &::before {
    border: $tooltip-arrow-width solid transparent;
    color: $tooltip-background;
    font-size: 1.4em;
    pointer-events: none;
  }
}

.tooltip--top {
  @include position(absolute, 0 0 0 50%);
  @include tooltip($tooltip-width, $tooltip-shadow-top);
  bottom: 160%;
  margin-left: -($tooltip-width / 2);

  &::after {
    @include position(absolute, 0 0 0 46%);
    content: "▼";
    margin-left: -$tooltip-arrow-width;
  }
}

.tooltip--bottom {
  @include position(absolute, 0 0 0 50%);
  @include tooltip($tooltip-width, $tooltip-shadow-bottom);
  top: 180%;
  margin-left: -($tooltip-width / 2);

  &::after {
    @include position(absolute, -28% 0 0 46%);
    content: "▲";
    margin-left: -$tooltip-arrow-width;
  }
}

.tooltip--left {
  @include position(absolute, -100% 180% 0 0);
  @include tooltip($tooltip-width, $tooltip-shadow-left);
  margin-left: -$tooltip-width;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px -22px 0 0;
    content: "▶";
  }
}

.tooltip--right {
  @include position(absolute, -100% 0 0 180%);
  @include tooltip($tooltip-width, $tooltip-shadow-right);
  margin-right: -$tooltip-width;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    margin: 3px 0 0 -22px;
    content: "◀";
  }
}
