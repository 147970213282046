.filters-bar {
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-bar-filter-actions {
  display: flex;
  > * {
    margin-right: 16px;
  }
}
