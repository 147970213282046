//Toast colors
$errorToast: (
  text: $red500,
  background: $red106,
  border: $red300,
);
$successToast: (
  text: $green500,
  background: $green106,
  border: $green300,
);
$warningToast: (
  text: $gray900,
  background: $yellow108,
  border: $yellow150,
);
$defaultToast: (
  text: $gray1400,
  background: $gray150,
  border: $gray300,
);

$toastAlertIconWidth: 48px;

@mixin toastType($colorMap) {
  color: map-get($colorMap, "text");
  background: map-get($colorMap, "background");
  border-color: map-get($colorMap, "border");
}

.toast-container {
  @include position(fixed, 15px 15px null null);
  z-index: 10000;
}

.toast {
  @include transition(all 0.2s ease-in);
  @include animation-duration(0.4s);
  @include toastType($defaultToast);
  width: 300px;
  border: 1px solid;
  border-radius: 8px;
  position: relative;
  margin-bottom: 15px;
  min-height: 48px;

  a {
    text-decoration: underline;
  }
}

.toast--success {
  @include toastType($successToast);
}

.toast--error {
  @include toastType($errorToast);
}

.toast--warning {
  @include toastType($warningToast);
}

.toast--icon {
  width: $toastAlertIconWidth;
  height: $toastAlertIconWidth;
  display: inline-block;
  vertical-align: top;
  font-size: 24px;
  padding: 11px;
}

.toast--close {
  @include position(absolute, 15px 12px 0 0);
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: fade-out($black, 0.5);
  }
}

.toast--message {
  @include calc(width, "100% - #{$toastAlertIconWidth}");
  padding: 14px 32px 14px 0;
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
}

.toast--body {
  font-weight: normal;
  padding-top: 8px;
}

.toast--actions {
  padding: 0 16px 14px $toastAlertIconWidth;
}

.toast-action-lg {
  .toast--actions a {
    width: auto;
  }
}

.toast--header {
  padding-bottom: 8px;
}
