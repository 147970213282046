.click-to-input {
  width: 100%;

  &.is-disabled {
    .click-to-input--label {
      cursor: default;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }

  &.is-edit {
    background-color: $gray110;
    padding: 5px;
    border-radius: 10px;
  }
}

.click-to-input--label {
  position: relative;
  color: $gray900;
  cursor: pointer;
  display: inline-block;
  padding-right: 35px;
  vertical-align: middle;
  max-width: 90%;
  border-bottom: dotted 1px transparent;

  &:hover {
    border-bottom: dotted 1px $gray150;
    color: $gray900;

    .fa-pencil {
      color: $linkText;
    }
  }

  .overflow-text {
    vertical-align: text-bottom;
    width: 100%;
  }

  .overflow-text--tip {
    font-size: $baseFontSize;
  }
}

.click-to-input--text {
  vertical-align: text-bottom;
}

.click-to-input--pencil {
  @include position(absolute, null 0px 3px null);
  color: $gray150;
  margin-left: 5px;
  font-size: 0.9em;
  width: 30px;
}

input.click-to-input--input {
  border: none;
  background: $gray105;
  border-radius: 0;
  margin-bottom: 0;
  margin-top: -3px;
  color: $gray500;
  vertical-align: middle;
  max-width: 90%;
  float: left;

  &:focus {
    border: none;
    background: $gray105;
    color: $gray500;
  }
}

.click-to-input--button-container,
.click-to-input--input-container {
  display: flex;
  flex-direction: row;
}

.click-to-input--button {
  font-size: 14px;
  padding-left: 15px;
}

.click-to-input--save-button {
  @extend .click-to-input--button;
  font-weight: 500;
}

.click-to-input--cancel-button {
  @extend .click-to-input--button;
  font-weight: 400;
  padding-right: 15px;
}
