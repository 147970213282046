.pp-proposal-modal--top {
  margin: 20px;
  display: flex;
  align-items: center;

  .button-select {
    margin-right: 80px;
  }
}

.pp-proposal-modal--top-stat {
  color: $gray500;
  margin-right: 20px;
  width: 150px;
}

.pp-proposal-modal--stat-label {
  margin-bottom: 6px;
}

.pp-proposal-modal--stat {
  font-size: 22px;
  font-weight: 500;
}

.pp-proposal-modal--table-wrap {
  position: relative;
  background: $background;
  padding: 30px 0;

  table {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    background: #fff;
  }
}

.pp-proposal-modal--empty-state {
  text-align: center;
  font-style: italic;
}

.pp-proposal-modal--triangle {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #fff;
  left: 118px;
  position: absolute;
  top: 0;
}

.pp-proposal-modal--col {
  flex: 300px;
  flex-direction: column;
  align-items: flex-start;
}

.pp-proposal-modal--sort-col button {
  font-weight: 500;
  text-align: left;
}

.pp-proposal-modal--sort-col .et--flex-table--sort-header-cell:hover,
.pp-proposal-modal--sort-col .et--flex-table--sort-header-cell:hover:not(:disabled) {
  background: $gray105;
}

.pp-proposal-modal--title-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.pp-proposal-modal--title {
  font-weight: 500;
}

.pp-proposal-modal--inactive {
  font-size: 10px;
  border: 1px solid $border;
  padding: 0 5px;
  background: $background;
  line-height: 14px;
  border-radius: 2px;
}

.pp-proposal-modal--footer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
