// TASK ROW MAIN CONTAINER
.journeys-task-table--row {
  border-bottom: solid 1px #f3f3f3;
  width: 100%;
}

// TASK ROW SUMMARY
.journeys-task-table-row--summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  overflow: hidden;
  padding: 16px;
}

.journeys-task-table-row--summary:hover {
  background-color: #f2f2f2;
}

.journeys-task-table-row--summary-container {
  display: flex;
  flex-direction: row;

  .journeys-task-table-row-icon {
    display: flex;
    justify-content: center;
    margin-right: 0px;
  }

  &.no-journey-tasks .journeys-task-table-row-icon {
    margin-left: 35px;
  }
}

.journeys-task-table-row--summary-details {
  padding-left: 12px;
}

.journeys-task-table--task-icon {
  color: $gray300;
  font-size: 18px;
}

.journeys-task-table-row--header {
  color: $gray500;
  font-weight: 500;
  font-size: 16px;
  padding-top: 20px;
  padding-left: 20px;
}

.journeys-task-table-row--sub-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
}

.journey-task-table-row--action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 5px;
}

// TASK ROW DETAILS
.journeys-task-table-row--details {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;
}

// makes all nested items flex boxes
.journeys-task-table-row--details > div {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.journeys-task-table-row--details:hover {
  background-color: #f2f2f2;
}

.journeys-task-table-row--details.active {
  background-color: #f2f2f2;
}

.journeys-task-table-row--details--due-date-text {
  color: rgb(204, 53, 54);
  text-align: center;
  margin-right: 10px;
}
.journeys-task-table-row--details--completed-date-text {
  text-align: center;
  margin-right: 10px;
}

.recommended-action-row--container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.recommended-action-row--contact {
  display: flex;
  align-items: center;
}

.giving-amount {
  color: #53a744;
}

.giving-date {
  color: #737373;
  font-size: 12px;
  font-weight: 400;
}

.tooltip-icon {
  color: #939393;
  padding-left: 3px;
  margin: auto;
}

//empty state message class
.journeys-task-table--empty-state {
  margin-left: 30px;
  text-align: left;
  .empty-state-message--icon{
    font-size: 30px !important
  }
  .empty-state-message--text{
    margin-bottom: 16px;
  }
}

.journey-empty-state-message-title{
  padding:10px 0 10px 0;
}

.journey-empty-state-message{
  font-size: 16px;
}

.journey-empty-state-message-list{
  list-style: disc;
  padding: 10px 0 10px 0;
  margin-left: 15px;
}
.journey-empty-state-message>p{
  padding-top: 20px;
}