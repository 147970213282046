.reset-pools-modal {
  padding: 16px;
  .et--advanced-combobox {
    max-width: 340px;
  }
}
.reset-pools-modal--checkbox-section {
  padding-left: 12px;
  padding-top: 12px;

  .et--checkbox {
    padding: 12px 0;
  }
}

.reset-pools-modal--sub-section {
  padding-left: 72px;
}

.reset-pools-modal--radios {
  .radio {
    margin-right: 96px;
  }
}
