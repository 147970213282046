// most of these are borrowed from dashboard.scss and pool-card.scss

$clarityCardWidth: 336px;
$clarityCardHeight: 250px;

.clarity-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 16px;
  width: 100%;
}

.clarity-section {
  flex: 0 1 100%;
  margin-bottom: 16px;

  h3 {
    margin-bottom: 16px;
  }
}

.clarity-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.clarity-title {
  @include title-style($clarityBanner);
  height: 150px;
}

.clarity-title-containter {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  margin: -115px 0 0;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  opacity: 0.9;
  box-sizing: border-box;

  h2 {
    margin-bottom: 4px;
  }
}

.clarity-card {
  width: $clarityCardWidth;
  height: $clarityCardHeight;
  overflow: hidden; // to clip any images that are too large
}

.clarity-link,
.clarity-link:hover {
  color: inherit;
  cursor: inherit !important;
}

.clarity-empty {
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clarity-empty--icon {
  font-size: 60px;
  margin-bottom: 30px;
}

.clarity-empty--text {
  width: 375px;
}

.clarity--loading {
  margin-top: 100px;
}

.et--card--heading {
  font-weight: 500;
}

.clarity-filter {
  padding: 16px;
}

.clarity-empty-category {
  padding: 24px;
  background: #f5f5f5;
  border-radius: 8px;
  text-align: center;
  width: 100%;

  p {
    color: $gray900;
    font-size: 18px;
    margin: 0;
  }
}

.clarity-subheader {
  font-size: 16px;
  font-weight: normal;
  margin: 5px 0px;
  width: 80%;
  line-height: 1.4;
  white-space: normal;
  word-wrap: break-word;
  margin-top: 10px;
  margin-right: 20px;
}
