.chat-bot {
  display: flex;
  flex-direction: column;
  height: 90%;

  .chat-messages-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 408.5px;
    padding: 10px;
    cursor: auto;
    flex-direction: column;
    position: relative;

    .suggested-searches {
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .heading {
        display: flex;
        flex-direction: column;
        gap: 7px;
        padding: 0px 9px;

        h3 {
          font-size: 16.5px;
          font-weight: 500;
        }

        p {
          color: $gray500;
        }
      }
    }

    .suggested-search-list {
      list-style-type: none;
      padding: 0px 9px;
      position: relative;
      width: -webkit-fill-available;
      margin-top: 50px;
    }

    .suggested-search-item-header {
      margin-bottom: 9px;
      cursor: pointer;
    }

    .suggested-search-item {
      margin-bottom: 9px;
      cursor: pointer;
    }

    .suggested-search-item-header-box {
      padding: 10px;
      display: flex;
      align-items: center;
      // justify-content: center;
      background-color: $gray108;
      border-radius: 16px;
      font-size: 14px;
      width: fit-content;
    }

    .suggested-search-item-box {
      border: 1px solid $gray150;
      display: flex;
      justify-content: left;
      color: #007C9E;
      border-radius: 14px;
      font-size: 13px;
      padding: 11px 20px 11px 8px;
      width: fit-content;
    }

  }

}

.drawer.fullscreen .drawer--content.ai-drawer {

  .chat-bot {
    .chat-messages-centered {
      .suggested-searches {
        width: 100%;

        .heading{
          width: 100%;
        }

        .suggested-search-list {
          margin-top: 80px;
        }
      }
    }
  }
}

.chat-input-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2px 3px 2px;
  margin-top: auto;
  position: relative;

  textarea {
    flex: 1;
    background-color: $gray110;
    margin-right: 10px;
    margin-left: 10px;
    border: 0px solid #ccc;
    border-radius: 38px;
    height: 36px;
    padding: 10px 38px 10px 19px;
  }

  button {
    padding: 8px 12px;
    background-color: $blue500;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

.chat-submit-button {
  position: absolute;
  top: 57%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: $gray900;
  color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.chat-messages {
  height: 408.5px; //explicitly adding height as after adding the DnD the unused space in ai chat body is not considered in the body height.
  overflow-y: auto;
  padding: 10px;

  .message-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding: 0 8px 0 8px;

    &.request {
      justify-content: flex-end;
      align-items: baseline;

      .request-copy-icon {
        display: none;
        justify-content: end;
        cursor: pointer;
        align-items: baseline;
        gap: 5px;
        padding: 2px 8px;
        color: $gray500;
        position: relative;
      }
    }

    &.response {
      justify-content: flex-start;
      align-items: baseline;
      flex-direction: column;

      .response-copy-icon {
        display: none;
        cursor: pointer;
        align-items: baseline;
        gap: 5px;
        padding: 0px 8px;
        color: $gray500;
        position: relative;
        left: 25px;
      }
    }

    &.request:hover {
      .request-copy-icon {
        display: flex;
      }
    }

    &.response:hover {
      .response-copy-icon {
        display: flex;
      }
    }

    .message {
      margin-bottom: 5px;
      color: $gray900;
      text-align: right;
      word-break: break-word;
      display: inline-block;
      width: auto;
      max-width: 80%;

      &.request {
        .message-body {
          background-color: $gray108;
          padding: 10px;
          border-radius: 12px;
          text-align: left;
          width: fit-content;
        }
      }

      &.response {
        display: flex;
        color: $gray900;
        text-align: left;
        gap: 14px;

        .ai-spark-icon {
          height: 20px;
          width: 17px;
          margin: 2px;
        }

        .ai-chat-ellipses {
          width: 30px;
          height: 16px;
        }
      }
    }
  }

  .fa-fw {
    font-size: 14px;
  }
}

.chat-bot-warning {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  margin-left: 24px;

  p {
    margin: 0px 0px 0px 5px;
  }
}

.request-id {
  p {
    font-size: 12px;
    margin: 4px 0 10px 25px;
  }
}

.chat-submit-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ai-chat-confirmation-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}

.modal-confirm.ai-modal-confirm {
  .modal-confirm--header {
    padding: 16px 20px;
  }

  .modal-confirm--content {
    padding: 2px 2px;
  }

  .ai-chat-confirmation-body {
    padding: 12px 15px 0px 15px;

    .p {
      margin: 0 0 17px;
    }
  }
}