.todo--cell {
  padding: 8px;
  border: 1px solid $linkText;
  border-width: thin;
  margin-bottom: 4px;
  border-radius: 20px;
  color: $gray900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .gt-icon-todolistcircle {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 20px;
    cursor: pointer;
    float: left;
  }
  .gt-icon-check-circle {
    margin-left: 4px;
    margin-right: 4px;
    color: $linkText;
    font-size: 20px;
    cursor: pointer;
    float: left;
  }
  .todo-item--cell-text {
    width: 100%;
    float: left;
    &:hover {
      color: $purple500;
    }
  }
}

.todo-list--wrapping-div {
  width: 100%;
  float: left;
}
.todo-list--section {
  padding: 8px;
  margin-bottom: 4px;
}
.todo-list--message {
  padding: 4px;
  padding-bottom: 0px;
  color: $gray500;
  font-size: 18px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.todo-list--text-button {
  float: left;
  width: 100%;
  display: block;
  padding: 4px;
  padding-left: 8px;
  padding-top: 8px;
  color: $blue300;
  font-weight: bold;
  cursor: pointer;
  border-top: 1px solid $gray150;
}
.todo-list--completed-cell {
  text-decoration: line-through;
}

@keyframes animate_selection {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}

.todo--cell-click-animate {
  opacity: 0;
  animation-name: animate_selection;
  animation-duration: 1.5s;
}
