.bulk-invite-volunteers-modal--main {
  max-height: 600px;
  overflow-y: auto;
  padding: 20px;
}

.bulk-invite-volunteers-modal--contact-section {
  border-bottom: 1px solid $border;
  padding-bottom: 8px;

  &:last-child {
    border-bottom: none;
  }
}

.bulk-invite-volunteers-modal--contact-card {
  padding: 0;
}

input.bulk-invite-volunteers-modal--alt-email-input {
  max-width: 300px;
}
