.interaction-details {
  margin-bottom: 40px;
}

.interaction-details--header {
  text-transform: uppercase;
  color: $gray500;
  // seemed to render darker than text color so used a lighter one
  border-bottom: 1px solid #d3cece;
  width: 100%;
  letter-spacing: 1px;
  line-height: 20px;
  padding-bottom: 8px;
}

.interaction-details--labels-item {
  display: block;
  color: $gray500;
  padding: 8px 0px 0;
}

.interaction-details--value {
  color: $gray900;
}
