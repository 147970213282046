.address-card {
  border: solid 1px $border;
  max-width: 640px;
  margin: 0 auto;
}

.address-card--header {
  padding: 16px;
  text-align: left;
  border-bottom: solid 1px $border;
}

.address-card--location-icon {
  color: $gray150;
  vertical-align: top;
}

.address-card--enrichment-icon {
  color: $gray500;
  margin-left: 5px;
  margin-top: 5px;
}

.address-card--meta-data {
  @extend .clearfix;

  dl {
    width: 50%;
    float: left;
    height: 72px;
    padding: 16px;
    position: relative;
    margin: -1px 0 0 -1px;
    border-top: solid 1px $border;
    border-left: solid 1px $border;

    dt {
      @extend .text-label;
      margin: 0;
    }

    dd {
      font-size: 16px;
      font-weight: bold;
      .address-card--empty {
        font-weight: normal;
        color: $gray500;
      }
    }
  }

  .address-card--meta-data--median {
    color: $purple500;
  }
}

.address-card--google-img {
  @extend .clearfix;
  height: 175px;
  background: $background;
  overflow: hidden;

  .empty-state-message {
    padding-top: 55px;
  }

  img {
    width: 640px;
    max-width: none;
  }
}

.address-card--empty {
  color: $blue300;
}
