.back-header {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: solid 1px $border;
  background: $white;
  padding: 16px 8px;
}

.back-header--header-text {
  font-size: 20px;
}
