$tripCardWidth: 336px;
$tripCardHeight: 236px;

.trip-card {
  width: $tripCardWidth;
  height: $tripCardHeight;
}

.trip-card--header {
  padding: 16px 16px 0 16px;
  border-bottom: solid 1px $border;
  height: 140px;
}

.trip-card--title {
  display: block;
  font-weight: 500;
  font-size: 18px;

  i {
    padding-right: 5px;
  }
}

.trip-card--menu {
  @include position(absolute, 8px 8px null null);
  z-index: 10;
}

.trip-card--menu-content {
  padding: 16px;
}

.trip-card--meta {
  padding: 16px 0;
}

.trip-card--user-empty {
  padding: 8px 0;
  margin-right: 8px;

  a {
    letter-spacing: 1px;
    font-size: $baseFontSize;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.trip-card--single-avatar {
  display: flex;
  align-items: center;
  font-weight: 500;

  .trip-card--avatar {
    vertical-align: middle;
    float: none;
    margin-right: 8px;
  }
}

.trip-card--avatar-hover {
  width: 300px;
}

.trip-card--analytics {
  padding: 16px 0;
  a {
    font-weight: 500;
    border: 1px solid #1e7fac;
    border-radius: 4px;
    padding: 5px;
    margin-left: 5px;

    &:hover {
      border: 1px solid $purple500;
    }
  }
}

.trip-card--analytics-dollar {
  color: $green300;
}

.trip-card--loader {
  position: relative;

  .loading--wrapper {
    width: 100%;
    margin-left: 0;
    left: 0;
    top: 0;
  }

  .loading--spinner {
    margin: 0;
  }
}

.trip-card--date {
  display: flex;
  justify-content: space-between;
}

.trip-card--options {
  display: flex;
  .gt-icon {
    color: $gray500;
  }
}
