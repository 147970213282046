.export-appeal-controller--contents {
   @include clearfix;
   padding: 16px 32px;
}

.export-appeal-controller--label {
   margin-top: 16px;
}

.export-appeal-controller--campaigns {
   float: none;
   margin-top: 16px;
}

.export-appeal-controller--name {
   @extend .search-box;
   width: 100%;
   height: 45px;
   padding: 0 20px;
   display: inline-block;
   vertical-align: top;
   box-shadow: none;
   margin-top: 16px;
   border-radius: 2px;

   &,
   &:focus {
      border: 1px solid $border;

   }
}
