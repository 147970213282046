.marketing-banner {
  position: relative;
  height: 215px;
  width: 215px;
  margin: 0 auto;
  margin-top: 18px;
  background-size: contain;
  background-repeat: no-repeat;
}

.marketing-banner--btn {
  @include position(absolute, null null 12px null);
  @include calc(left, "50% - 72px");
  width: 144px;
}
