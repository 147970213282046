.volunteer-management-header {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .search-box--input {
    height: 100%;
  }
}

.volunteer-management-header--search-bar {
  flex-basis: 340px;
}

.volunteer-management-header--dropdown {
  max-width: 300px;
}
