.pool-solicitors--card {
  padding-left: 11px;

  .pool-contact-card--checkbox {
    display: none;
  }

  &.is-selected {
    border-left: solid 4px $purple500;
    padding-left: 8px;
  }
}

.pool-solicitors--pagination {
  display: block;
  padding: 16px;
  text-align: center;
  background: $white;
  border-top: solid 1px $border;
}
