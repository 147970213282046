.link-prospects-modal {
  min-width: 500px;

  .modal-confirm--submit,
  .modal-confirm--cancel {
    max-width: 132px;
  }

  .modal-confirm--helper-text {
    float: left;
    margin: 0;
  }
}

.link-prospects-modal--card {
  border-bottom: solid 1px $border;
  display: flex;
  align-items: center;
  background: $white;
  position: relative;

  .new-contact-card {
    width: 60%;
  }

  .new-contact-card--name {
    @extend .ellipsis-overflow;
  }

  &:last-child {
    border-bottom: none;
  }
}

.link-prospects-modal--swap {
  position: absolute;
  bottom: -15px;
  left: 16px;
  z-index: 1;
  background: $white;
  border: solid 1px $border;
  padding: 4px 8px;
  border-radius: 2px;
}

.link-prospects-modal--selector {
  background: $background;
  max-height: 400px;
  overflow: auto;
}

.link-prospects-modal--text {
  padding-left: 16px;
  flex-grow: 2;
}

.link-prospects-modal--search {
  padding: 16px 16px 0 16px;

  .search-box {
    margin-top: 0;
  }
}

.link-prospects-modal--results-group {
  margin-bottom: 16px;

  h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    margin: 8px 0;
    color: #888;
  }
}

.link-prospects-modal--results {
  position: relative;
  padding: 16px;
  min-height: 175px;
}

.link-prospects-modal--item {
  display: flex;
  align-items: center;
  border: solid 1px $border;
  border-bottom: none;
  background: $white;
  cursor: pointer;

  &:hover {
    background: fade-out($white, 0.5);
    .checkbox--box {
      border: solid 1px $purple500;
    }
  }

  &:last-child {
    border-bottom: solid 1px $border;
  }

  .new-contact-card {
    flex-grow: 2;
  }

  .match-result--checkbox {
    float: none;
    width: 75px;
    display: block;
    padding: 16px;
  }
}
