$timelineItemWidth: 850px;
$timelineDateMargin: 160px;

$timelineTriangleSize: 20px;
$timelineTrianglePos: -40px;

$timelinePointInnerSize: 12px;
$timelinePointOuterSize: 18px;

.timeline {
  position: relative;

  &::before {
    @include position(absolute, -10px null 0px 121px);
    content: " ";
    display: block;
    width: 4px;
    background: $border;
  }
}

.timeline-item {
  margin: 0 0 20px $timelineDateMargin;
  max-width: $timelineItemWidth;
  position: relative;
}

.timeline-item--content {
  padding: 20px;
  overflow-y: auto;
}

.timeline-label {
  @include position(absolute, 20px null null (-$timelineDateMargin));
  text-align: right;
  width: 100px;

  // Fancy Dot for Point on Timeline
  &::after,
  &::before {
    @include position(absolute, 9px -33px null null);
    content: " ";
    display: block;
    border-radius: 50px;
  }

  &::after {
    height: $timelinePointInnerSize;
    width: $timelinePointInnerSize;
    margin: 4px;
    background: $gray300;
  }

  &::before {
    height: $timelinePointOuterSize;
    width: $timelinePointOuterSize;
    background: $white;
    border: solid 1px $gray300;
  }
}
