$activeColumnsHeight: 500px;
$allSearchHeight: 55px;
$allColumnsHeight: $activeColumnsHeight - $allSearchHeight;

.sort-selector {
  background: $gray105;

  .dnd-controller--list-available {
    border-right: 1px solid $border;
    padding-right: 6px;
  }

  .dnd-controller--list-active {
    padding-left: 6px;
  }
}

.column-selector {
  .modal-confirm--header {
    padding-left: 8px;
  }
}

.sort-selector--content {
  .dnd-controller--drag-list {
    @include user-select(none);
    width: 50%;
    border-radius: 2px 2px 0 0;
  }
}

.drag-list--item {
  display: flex;
  align-items: center;
  position: relative;
  border: solid 1px $border;
  margin-bottom: 4px;
  border-bottom: none;
  background: $white;
  padding: 10px;
  cursor: pointer;

  .icon-action {
    color: $gray150;
  }
  &.is-last,
  &:last-child {
    border-bottom: solid 1px $border;
  }

  &:hover {
    background: $gray105;
  }

  i {
    font-size: 1.3em;
  }
}

.drag-list--header {
  border-top: solid 1px $border;
  color: $gray500;
  margin: 8px 0 4px;

  &:first-child {
    border-top: none;
  }
}

.drag-list--label {
  width: 75%;
  display: inline-block;
  vertical-align: middle;
}

.drag-list--icons {
  @include position(absolute, 0px 0px null null);
  display: block;
  padding: 11px 10px 10px 0;
  text-align: right;
  width: 15%;

  &:hover {
    i {
      color: $purple500;
    }
  }
}

.drag-list--handle {
  width: 10%;
  text-align: left;
}

.drag-list--ghost {
  background: $gray150;

  i,
  span {
    visibility: hidden;
  }
}

.drag-list--empty {
  height: 1px;
  overflow: visible;

  .empty-state-message {
    padding: 56px;
  }
}

.sort-selector--content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;

  ul {
    flex-basis: 50%;
    height: $allColumnsHeight;
    padding-top: 0;
    overflow: auto;
    position: relative;

    &.is-disabled {
      .drag-list--item {
        color: $gray500;

        &.is-disabled {
          opacity: 0.5;
        }
        &:hover {
          background: $white;
        }
      }

      .drag-list--icons {
        opacity: 0.5;
      }
    }
  }
}
.drag-list--item {
  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.sort-selector--col {
  width: 50%;
  padding: 5px 8px 0 8px;
  border: solid 1px $border;
  border-bottom: none;
  float: left;
}

.sort-selector--heading {
  background: $white;
  padding: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid $border;

  h4 {
    @include vertical_align();
    width: 65%;
  }

  a {
    @include vertical_align();
    width: 35%;
    text-align: right;

    i {
      margin-left: 5px;
    }
  }
}

.sort-selector--search {
  .search-box {
    width: auto;
    margin: 8px;
  }
}
.sort-selector--active-instruction-content {
  color: $gray500;
  padding: 8px;
}
.sort-selector--actions {
  text-align: right;
}

.sort-selector--top-row {
  display: flex;
}

.sort-selector--available {
  border-right: 1px solid $border;
}

.sort-selector--active,
.sort-selector--available {
  flex-basis: 50%;
}
