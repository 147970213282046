.tag-input {
  cursor: text;
  border: solid 1px $gray200;
  border-radius: 8px;
  position: relative;
  background: $gray105;
  min-height: 40px;

  &:focus-within {
    border: solid 1px $purple300;
    background: $purple105;
  }

  &.is_disabled {
    cursor: not-allowed;
    border: solid 1px $gray150;
    background: $gray110;
  }
}

input[type="text"].tag-input--input {
  display: inline-block;
  width: 240px;
  border: none;
  margin-bottom: 0;
  padding: 4px 8px;
  line-height: $baseLineHeight;

  &::-ms-clear {
    display: none;
  }

  &:hover,
  &:focus {
    border: none;
  }
}

// TODO: Remove duplication from .filters--selected
.tag-input--tags {
  display: inline-block;
  padding: 4px 0px 0px 4px;

  > li {
    background: $gray105;
    display: inline-block;
    border-radius: 2px;
    border: solid 1px $border;
    font-weight: 500;
    margin: 0px 8px 4px 0px;
    padding: 4px 8px;
    cursor: pointer;

    i {
      color: $gray200;

      &:hover {
        color: $purple500;
      }
    }

    &.is-invalid {
      background: none;
      border: none;
      border-bottom: dotted 1px $red500;
      padding-right: 0px;
      border-radius: 0px;
    }

    &.is-error {
      background-color: fade-out($red500, 0.9);
      border-color: fade-out($red500, 0.7);
      color: $red500;

      i {
        color: $red500;
      }
    }
  }
}

li.tag-input--wrapper {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.tag-input--autocomplete {
  @include position(absolute, 100% 0px 0 0px);
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: solid 1px $border;
  box-shadow: 0 1px 2px $gray150;
  border-radius: 3px;
  background: $white;
  z-index: 1000;
  font-weight: normal;

  > li {
    cursor: pointer;

    &.is-selected {
      background: $gray105;
    }
  }

  &.is-empty {
    display: none;
  }
}

.tag-input--empty {
  padding: 10px 20px;
  font-size: 1.1em;
  color: $gray500;
}

.tag-input--search-icon {
  margin-left: 4px;
  color: $gray200;
  font-weight: bold;
}
