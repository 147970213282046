$locationSearchHeight: 40px;
$locationSearchShadow: 0 2px 2px fade-out($black, 0.7);

.map-location-search {
  @include outer-container;
  @include fill-parent;
  width: 100%;
  position: relative;
}

input.map-location-search--input {
  @include span-columns(10);
  @include calc(width, "100% - 55px");
  height: $locationSearchHeight;
  margin-right: 0px;
  margin-bottom: 4px;
  padding: 0 16px;
  border: none;
  box-shadow: $locationSearchShadow;
  border-radius: 2px 0 0 2px;

  &:focus {
    border: none;
    border-radius: 2px 0 0 0;
    box-shadow: none;
  }

  &:hover {
    border: none;
  }
}

.map-location-search--btn {
  width: 55px;
  height: $locationSearchHeight;
  margin-left: 0;
  box-shadow: $locationSearchShadow;
  border-radius: 0 2px 2px 0;

  i {
    margin-right: 0px;
    font-weight: bold;
  }

  &:hover {
    box-shadow: $locationSearchShadow;
  }
}

.map-location-search--geolocation {
  @include position(absolute, 12px 65px 0 0);
  font-size: 1.3em;
  color: $gray500;

  &:hover {
    color: $linkMainColor;
    cursor: pointer;
  }

  &.is-disabled {
    color: $gray150;
    cursor: default;
  }
}

.map-location-search--current {
  display: block;
  box-shadow: $locationSearchShadow;
  border-top: solid 1px $border;
  border-radius: 0px 0px 2px 2px;
  background: $white;
  margin: 0 55px 4px 0;
  padding: 8px;
  color: $linkMainColor;
  cursor: pointer;
}
