$meetingCalendarColWidth: 260px;
$meetingCalendarHeaderHeight: 43px;
$meetingCalendarMetaHeight: 20px;

.meeting-calendar {
  height: 100%;
  margin: 0 16px;
}

.meeting-calendar--table {
  min-width: 100%;
  margin: 0;
  height: 100%;
}

.meeting-calendar--body {
  @include position(absolute, $meetingCalendarHeaderHeight auto 0px 0px);
  // TODO: Try to get horizontal scrolling with drag and drop + fixed headers to work
  // If you un-comment these, headers will be fixed but drag and drop wont scroll horizontally
  // overflow-y: scroll;
  // overflow-x: hidden;
  z-index: 1;
}

.meeting-calendar--columns {
  height: 100%;
  margin: 0;
  border-collapse: separate;
  table-layout: fixed;
}

.meeting-calendar--col {
  width: $meetingCalendarColWidth;
  padding: 0;
  vertical-align: top;
  border-right: solid 1px $white;
  border-left: solid 1px $white;
  height: 100%;
  margin-bottom: 20px;
}

.meeting-calendar--col-header {
  background: $white;
  text-align: center;
}

.meeting-calendar--col-header-icon {
  font-size: 18px;
  color: $gray150;
  margin-right: 6px;
}

.meeting-calendar--col-body {
  padding: 8px;
}

.meeting-calendar--meta {
  text-align: center;
  color: $gray500;
  font-size: 12px;
  height: $meetingCalendarMetaHeight;
}

.meeting-calendar--empty {
  @include position(absolute, 0px 0px 0px 0px);
  background-color: fade-out($white, 0.5);
  z-index: 10;
  font-size: 16px;
  text-align: center;
  color: $gray500;
  margin: 0 16px;
}

.meeting-calendar--empty-content {
  @include position(absolute, 50% 0px 0px 50%);
  margin-top: -155px;
  margin-left: -160px;
  width: 320px;
}

.meeting-calendar--empty-image {
  margin: 0 auto;
  width: 323px;
  display: block;
}

.meeting-calendar--empty-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.meeting-calendar--empty-action {
  margin-top: 8px;
}
