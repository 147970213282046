.homepage-card {
  width: 100%;
  overflow: hidden;
}

.homepage-card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage-today-cadence-steps--view {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 29px;
}

.homepage-today-other-tasks {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.homepage-today-other-tasks--view {
  padding-right: 15px;
}

.homepage-today-other-tasks--add {
  display: flex;
}
