.module-personal-information {
  width: calc(100% / 6 * 4);
  flex-grow: 1;
}

.module-photo-information {
  width: calc(100% / 6 * 2);
  padding-right: 1rem;
}

.profile-settings-input--name {
  display: flex;
  justify-content: center;

  .first-name {
    padding-right: 1rem;
    width: 25%;
    margin-bottom: 0;
  }
  .last-name {
    width: 25%;
    margin-bottom: 0;
  }
}

.profile-settings-icon {
  color: $yellow150;
}

.profile-settings-input {
  width: 50%;
  margin: 1rem auto;

  input[type="text"]:disabled {
    background: #f2f6f7;
  }

  .icon {
    color: $border;
  }

  .classification {
    line-height: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .error {
    border: #ff0000 solid 1px;
    border-radius: 3px;
  }
  .error-feedback {
    color: #ff0000;
    font-size: 12px;
    margin-bottom: -1.5rem;
  }
  .email-disclaimer {
    font-weight: 200;
    font-size: 12px;
    margin-bottom: -1.5rem;
  }
}

.profile-settings-buttons {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin: 2rem auto;

  .cancel {
    margin-right: 1rem;
  }
}

.profile-photo-uploader--body {
  width: 100%;
  height: 320px;

  .photo-overlay {
    position: relative;
    width: 15rem;
    height: 15rem;
    border-radius: 10rem;
    margin: 2rem auto;
    border: solid 1px $border;

    .hover-remove {
      display: none;
    }

    &:hover .hover-remove {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(235, 83, 75, 0.3);
      top: -2rem;
      color: $white;
      width: 15rem;
      height: 15rem;
      border-radius: 10rem;
      font-weight: 500;
      font-size: 16px;
      margin: 2rem auto;
      border: 1px solid $red500;
      cursor: pointer;
    }
  }

  .photo-overlay img {
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    object-fit: cover;
  }

  .photo-overlay:hover:after {
    opacity: 1;
  }

  .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flagged-test-button {
    margin-top: 1rem;
    &:hover {
      background-color: $purple500;
      color: white;
    }
  }

  .upload-select {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .flex-photo-controls {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
  }

  .profile-photo-uploader--label {
    margin-bottom: 0px;
  }
}

.profile--email-status {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  width: 400px;
  padding: 14px 20px;
  align-items: center;

  em {
    font-style: italic;
  }
  .email-status-check {
    font-size: 2.5rem;
    color: $green300;
    margin-right: 8px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
