.facepile--item {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}

.facepile--avatar {
  border-radius: 100%;
}

.facepile--popover,
.facepile--tooltip {
  display: inline-block;
  vertical-align: middle;
}

.facepile--popover-content {
  padding: 8px;
  border: solid 1px $border;
  border-radius: 2px;
}

.facepile--more {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background: $gray105;
  color: $gray500;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  text-align: center;
  margin-right: 4px;
}
