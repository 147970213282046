// .event-response-dropdown {

// }

.event-response-dropdown--item {
   position: relative;
}

.event-response-dropdown--checkbox {
   display: inline-block;
   vertical-align: middle;
}

.event-response-dropdown--label {
   display: inline-block;
   vertical-align: middle;
}

.engagement-icon.event-response-dropdown--icon {
   position: absolute;
   top: 50%;
   right: 0;
   margin-top: -8px;
}
