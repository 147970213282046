.engagement-card {
  padding: 8px 16px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    min-height: 10px;
    height: 100%;
    left: 35px;
    top: 26px;
    border-right: 1px solid $gray150;
  }

  &:last-child::before {
    content: none;
  }
}

.engagement-card--icon-wrapper {
  float: left;
  padding-right: 32px;
}

.engagement-card--icon {
  position: relative;
}

.engagement-card--network-icon-background {
  @include position(absolute, null -5px -5px null);
  width: 20px;
  height: 20px;
  background-color: $white;
  border-radius: 50%;
  padding: 2px;
  color: $white;
  text-align: center;
  line-height: 16px;
}

.engagement-card--network-icon {
  border: 1px solid $gray500;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  padding-top: 3px;
  color: $gray500;
}

.engagement-card--wrapper {
  margin-left: 64px;
  padding-top: 4px;
}

.engagement-card--comment {
  border: solid 1px $border;
  background: fade-out($blue300, 0.8);
  padding: 8px;
  border-radius: 3px;
  margin: 8px;
}

.engagement-card--date {
  width: 350px;
  color: $gray500;
  padding-left: 8px;

  .facebook-post--actions {
    display: none;
  }
}

.engagement-card--metadata {
  color: $gray500;
  padding-left: 8px;
}
