.controlled-numeric-input--with-icon {
  display: flex;
  align-items: center;
  border-radius: 4px;

  &.align-right {
    input[type="text"] {
      border-radius: 8px 0 0 8px;
    }
  }

  &.align-left {
    input[type="text"] {
      border-radius: 0 8px 8px 0;
    }
  }
}
.controlled-numeric-input--icon {
  display: inline-flex;
  background-color: $white;
  color: $gray500;
  padding: 11px 0;
  border: solid 1px $gray200;
  border-radius: 8px 0 0 8px;

  &.align-right {
    border-left: none;
    border-radius: 0 8px 8px 0;
  }

  &.align-left {
    border-right: none;
  }

  &.is-active {
    background-color: $purple300;
    border: solid 1px $purple300;
    color: $white;
  }
}
