.bulk-list-controller--name,
.bulk-list-controller--search {
  width: 100%;
  border-color: $border;
  box-shadow: none;
}

.bulk-list-controller--label {
  margin-top: 24px;
  // color: $gray500;
  font-weight: normal;

  &:first-child {
    margin-top: 8px;
  }
}

.bulk-list-controller--verified-tokens,
.bulk-list-controller--emails {
  margin-top: 16px;
  margin-bottom: 16px;
  min-height: 128px;
  max-height: 240px;
  overflow-y: auto;
  border-color: $border;
  box-shadow: none;
}

.bulk-list-controller--confirm {
  min-height: 120px;
}

.bulk-list-controller--summary {
  padding: 8px;
}

.bulk-list-controller--error {
  margin-top: 4px;
  background-color: fade-out($red500, 0.9);
  padding: 8px;
  max-height: 160px;
  overflow: auto;
  padding-left: 20px;
}

.bulk-list-controller--error-list {
  color: $red500;
}

.bulk-list-controller--prompt {
  padding-top: 16px;
  margin-top: 24px;
  border-top: 1px solid fade-out($border, 0.5);
}
