.interaction-detail {
  @include position(absolute, 0px 0px 0px 0px);
  background: $white;
}

.interaction-detail--header {
  height: 80px;
  background: $white;
  padding: 16px 32px;

  .interaction-meta {
    width: 70%;
    line-height: 48px;
    font-size: 16px;
    display: inline-block;
    margin-left: 12px;
  }

  .interaction-meta--details {
    display: none;
  }

  .action-menu--button {
    width: auto;
  }
}
.interaction-meta{
  align-self: center;
}

.interaction-detail--header-back {
  @include position(absolute, 25px null null 0px);
  width: 30px;
}

.interaction-detail--body {
  @include position(absolute, 80px 0px 0px 0px);
  @include display(flex);
  margin: 24px;
  padding: 8px;
  overflow: auto;
}

.interaction-detail--body-tv {
  @include position(relative, 0px 0px 0px 0px);
  @include display(flex);
  margin: 24px;
  padding: 8px;
  overflow: auto;
}

.interaction-detail--light-text {
  color: $gray500;
}

.interaction-detail--dark-text {
  color: $gray900;
}

.interaction-detail--main {
  display: flex;
}

.interaction-detail--summary-text {
  flex: 3;
  word-break: break-all;
}

.interaction-detail--details {
  flex: 2;
  margin-left: 24px;
}

.interaction-detail--top {
  margin-bottom: 24px;
}

.interaction-detail--container {
  width: 100%;
}

.interaction-detail--type {
  font-weight: bold;
  font-size: 14px;
  color: $gray900;
}

.interaction-detail--summary {
  margin-bottom: 4px;
  font-weight: bold;
  color: $gray900;
  font-size: 16px;
}

.thankview-detail-reply{

}

.interaction-detail--reply {
  @include display(flex);
  margin: 24px;
  padding: 8px;
  overflow: auto;
}

.interaction-detail--replytv {
  @include position(relative, 0px 0px 0px 0px);
  @include display(flex);
  margin: 24px;
  padding: 8px;
  overflow: auto;
}