.map-cluster-contacts--empty {
  width: 100%;
  margin: 0;
  color: $gray150;
}

.map-cluster-contacts--empty-wrapper {
  border-bottom: none;
}

.map-cluster-contacts--content {
  width: 300px;
  max-height: 310px;
  overflow: auto;
  position: relative;

  .map-cluster-contacts--row {
    margin-top: 8px;
  }

  .map-cluster-contacts--row .score-progress {
    width: 124px;
  }

  .new-contact-card--body {
    padding: 0;
  }
}
