.hub-kpicard--table {
  .et--flex-table--header-cell {
    padding: 5px 16px;
  }

  .et--flex-table--header-row {
    min-height: auto;
  }
}

.hub-kpicard--table-more {
  padding: 16px;
  color: $blue500;
}

.hub-kpicard--text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hub-kpicard--table-col-1 {
  flex: 33%;
}

.hub-kpicard--table-col-right {
  justify-content: flex-end;
}

.hub-kpicard--icon {
  color: $gray300;
  margin-right: 4px;
}
