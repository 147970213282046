// Templates Library List
.templates-library--container {
  border-right: 1px solid #d7d7d7;
  width: 470px;

  .et--search-box {
    margin: 20px 0 15px 20px;
  }

  button {
    display: block;
  }

  .create-template-btn {
    margin: 20px auto 30px;
  }

  label.email-form--label {
    width: 100%;
  }
}

.template-library--list {
  height: 45vh;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.template-library--empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dynamic-popup {
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
}

.template-library--list::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.email-template-card {
  display: flex;
  border-bottom: 1px solid #d7d7d7;
  padding: 15px 0 15px 20px;

  p {
    @extend .ellipsis-overflow;
    width: 385px;
    margin-bottom: 0;
  }

  .email--template-card--header > p {
    width: 306px;
  }

  .email--template-card--header > p:first-child {
    margin-bottom: 3px;
  }
}

.email-template--icon-container {
  display: flex;
  justify-content: space-around;
  width: 88px;
  align-items: center;

  i {
    cursor: pointer;
    font-size: 22px;
    color: grey;
  }

  i:hover {
    color: #a2a2a2;
  }
}

.email-template-card-body > :nth-child(n + 2) {
  display: none;
}

// Template Preview
.email-template--preview-container {
  padding: 20px 15px;
  width: 55%;

  .email-template--preview {
    background-color: #f7f7f7;
    padding: 25px;
    height: 460px;
    overflow: scroll;

    div > p {
      margin: 0;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    ol,
    ul {
      list-style-type: revert;
    }
  }
}

.edit-email-template--preview-container {
  padding: 20px 15px 60px;
  width: 55%;
  height: auto;

  .email-template--preview {
    background-color: #f7f7f7;
    padding: 25px;
    height: 100%;
    border-radius: 20px;
    overflow-y: auto;

    div > p {
      margin: 0;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    ol,
    ul {
      list-style-type: revert;
    }
  }
}

// Template Creator

.templates-library--container {
  .email-form {
    div {
      .email-form--body {
        .email-form--body-inner {
          overflow: hidden;
          .ql-toolbar.ql-snow {
            margin-left: 0;
          }
          .ql-toolbar {
            .ql-formats {
              .ql-picker {
                .ql-picker-options {
                  bottom: auto;
                }
              }
              button,
              .ql-picker {
                &::after {
                  top: -20px;
                }
              }
            }
          }
          .ql-container {
            .ql-tooltip {
              left: 114px !important;
              margin-top: 0px !important;
            }
          }
        }
      }
    }
  }
}
