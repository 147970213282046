.pool-solicitor-card {
  display: flex;
  flex-direction: column;
  background: $white;
  border: solid 1px $border;
  padding: 8px 8px 12px 8px;
  border-radius: 3px;
  color: $baseFontColor;
  margin: 12px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: $gray105;
    color: $baseFontColor;
  }

  &.is-selected {
    .pool-solicitor-card--checkbox {
      color: $purple500;
      border-color: $purple500;
    }
  }
}

.pool-solicitor-card--menu {
  margin-left: auto;
  position: absolute;
  right: 23px;
  top: 8px;
  z-index: 1;
}

.pool-solicitor-card--menu-inner {
  margin-top: 20px;
}

.pool-solicitor-card--card {
  width: calc(100% - 60px);
  padding: 0 8px;
  display: inline-block;
  vertical-align: middle;

  .new-contact-card td {
    padding-bottom: 0px;
  }

  .new-contact-card--content {
    position: relative;
  }
}

.pool-solicitor-card--checkbox {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: solid 1px $border;
  border-radius: 30px;
  padding: 4px;
  color: $border;
  text-align: center;
  margin-left: 16px;
  margin-right: 8px;
}

.pool-solicitor-card--has-authed,
.pool-solicitor-card--has-invite {
  background-color: $blue200;
  width: 20px;
  border-radius: 30px;
  height: 20px;
  display: block;

  i {
    color: $white;
  }
}

.pool-solicitor-card--has-authed {
  margin-right: 8px;
}
.pool-solicitor-card--has-invite {
  background-color: $gray150;
}

.pool-solicitor-card--assignment {
  margin-bottom: 8px;
}
