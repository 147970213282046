.map-address-filter {
  box-shadow: 0 2px 2px fade-out($black, 0.7);
  border-radius: 2px;
  background: $white;
  padding: 8px;
  width: auto;
  height: 40px;
  margin-right: 8px;
  color: $linkMainColor;
  cursor: pointer;

  &:hover {
    background: $gray105;
    color: $purple500;
  }

  .gt-icon-chevron-down {
    display: block;
    margin-top: -5px;
    margin-left: 14px;
  }
}

.map-address-filter--text {
  vertical-align: middle;
  padding: 0 6px 0 2px;
  color: $purple500;
}

.map-address-filter--content {
  box-shadow: 0 2px 2px fade-out($black, 0.7);
  width: 275px;
}

.map-address-filter--header {
  background: $gray900;
  border-radius: 3px 3px 0 0;
  padding: 12px;
  color: $white;
  font-weight: bold;
}

.map-address-filter--body {
  @include transform(translateZ(0));
  padding: 12px;
  max-height: 275px;
  overflow: auto;

  label.et--checkbox {
    padding: 4px 0;

    &:hover {
      color: $purple500;
      background: $gray105;
    }
  }
}

.map-address-filter--footer {
  padding: 12px;
  border-top: solid 1px $border;

  .btn-secondary,
  .btn {
    padding: 12px 16px;
    margin-left: 8px;
    display: inline-block;
    width: 46%;
  }
}

.map-address-filter--apply {
  margin-left: 16px;
}
.no-filters {
  text-align: center;
  font-weight: bold;
  size: 16px;
}
    
.map-address-filter--body {
    @include transform(translateZ(0));
    padding: 12px;
    max-height: 275px;
    overflow: auto;
  
    label.et--checkbox {
      padding: 4px 0;
  
      &:hover {
        color: $purple500;
        background: $gray105;
      }
    }
}
  
.map-address-filter--footer {
    padding: 12px;
    border-top: solid 1px $border;
  
    .btn-secondary,
    .btn {
      padding: 12px 16px;
      margin-left: 8px;
      display: inline-block;
      width: 46%;
    }
}
  
.map-address-filter--apply {
    margin-left: 16px;
  }
  .no-filters{
    position: inherit;
}
.map-address-filter--text {
    vertical-align: middle;
    padding: 0 6px 0 2px;
    color: $purple500;
    border-radius: 16px;
}  
.loading-body{
  padding: 60px;
  .loading--wrapper.is-center{
    top:35%;
  }
}.map-address-filter--body {
    @include transform(translateZ(0));
    padding: 12px;
    max-height: 275px;
    overflow: auto;
  
    label.et--checkbox {
      padding: 4px 0;
  
      &:hover {
        color: $purple500;
        background: $gray105;
      }
    }
}
  
.map-address-filter--footer {
    padding: 12px;
    border-top: solid 1px $border;
  
    .btn-secondary,
    .btn {
      padding: 12px 16px;
      margin-left: 8px;
      display: inline-block;
      width: 46%;
    }
}
  
.map-address-filter--apply {
    margin-left: 16px;
  }
  .no-filters{
    position: inherit;
}
.map-address-filter--text {
    vertical-align: middle;
    padding: 0 6px 0 2px;
    color: $purple500;
    border-radius: 16px;
}  
.loading-body{
  padding: 60px;
  .loading--wrapper.is-center{
    top:35%;
  }
}