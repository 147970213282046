button.date-picker-dropdown--btn {
  display: flex;
  min-width: 100%;
}

.date-picker-dropdown--btn {
  margin-top: 8px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.date-picker-dropdown--cal-icon {
  margin-right: 11px;
  color: $gray200;
}
