.todo-item--info-wrapper {
  width: 100%;

  .gt-icon-check-circle {
    font-size: 14px;
    padding-top: 3px;
  }
  .gt-icon-alarm {
    margin-left: 4px;
    margin-right: 4px;
    color: $gray900;
    cursor: pointer;
    float: left;
    font-size: 14px;
    padding-top: 3px;
  }

  .gt-icon-event {
    margin-left: 4px;
    margin-right: 4px;
    color: $gray900;
    cursor: pointer;
    float: left;
    font-size: 14px;
    padding-top: 3px;
  }

  .gt-icon-notes {
    margin-left: 4px;
    margin-right: 4px;
    color: $gray900;
    cursor: pointer;
    float: left;
    font-size: 12px;
    padding-top: 3px;
  }
  .gt-icon-delete,
  .gt-icon-edit {
    margin-left: 4px;
    margin-right: 4px;
    color: $gray900;
    font-size: 20px;
    cursor: pointer;
    float: left;
    text-align: left;
    padding-bottom: 5px;
  }
}

.todo-item--info-text-holder {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
}

.todo-item--info-area-description {
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  float: right;
  width: 90%;
  color: #9b9b9b;
  font-style: italic;
}
.todo-item--info-notes-image-holder {
  width: 10%;
  height: 100%;
  float: left;
}
.todo-item--info-area-date-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.todo-item--info-area-duedate {
  width: 50%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #9b9b9b;
}

.todo-item--info-area-reminder {
  width: 50%;
  // margin-left: 20%;
  float: right;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #9b9b9b;
}

.todo-info--action-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 25px;
}
.todo-info--delete-button,
.todo-info--edit-button {
  margin: 0px;
}
