.gift-details {
  @extend .giving-gift-panel;
}

.gift-details--content {
  @include display(flex);
  @include justify-content(space-between);
  padding: 8px;
}

.gift-details--payment,
.gift-details--designations {
  width: 45%;
}

.gift-details--title-text {
  font-size: 16px;
  line-height: 20px;
  color: $gray500;
  font-weight: 500;
}

.gift-details--panel-heading {
  color: $gray500;
  border-bottom: 1px solid $border;
  margin: 16px 0;
  padding-bottom: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.gift-details--date-time-info {
  padding-bottom: 8px;

  span {
    color: $gray900;
    margin-left: 12px;
    font-size: 14px;
  }

  i {
    color: $gray150;
  }
}

.gift-details--gift-amount {
  padding-top: 8px;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: bold;
  color: $green300;

  &.is-test {
    color: $yellow150;
  }
}

.gift-details--date {
  color: $gray500;
}

.gift-details--gift-feedback-label {
  color: $gray500;
}

.gift-details--recurring-popover-label {
  color: $gray500;
  display: block;
}

.gift-details--gift-amount-title {
  color: $gray900;
}

.gift-details--gift-feedback-item {
  margin: 16px 0;
}
