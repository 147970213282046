.avatar-edit-modal {
  padding: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: "center";
  height: 200px;
}

.avatar-edit-modal--icon {
  color: $red300;
}

.avatar-edit-modal--link {
  margin: 10px 0;
  display: block;
}
