// AG Layout
$agHeaderHeight: 75px;

.ag-layout {
  @include position(absolute, 0px 0px 0px 0px);
}

.ag-layout-page {
  @include position(absolute, 0px 0px 0px 0px);
}

.ag-layout--header-area {
  @include position(absolute, 0px 0px null 0px);
  // overflow: hidden;
  height: 0;
  z-index: 12;
}

.ag-layout--contents-area {
  @include position(absolute, 0px 0px 0px 0px);
  overflow-y: auto;
  z-index: 11;

  &.has-footer {
    border-bottom: 1px solid $border;
  }
}

.ag-layout-page--footer-area {
  @include position(absolute, null 0px 0px 0px);
  // overflow: hidden;
  height: 0;
}

// AG Header

.ag-header {
  background-color: $white;
  height: $agHeaderHeight;
  position: relative;
}

.ag-header--left {
  display: inline-block;
  vertical-align: middle;
  // margin-top: 28px;

  &::before {
    content: " ";
    display: inline-block;
    height: $agHeaderHeight;
    width: 16px;
    vertical-align: middle;
  }
}

.ag-header--left-items {
  font-size: 20px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.ag-header--right {
  float: right;
  height: 100%;

  &::after {
    content: " ";
    display: inline-block;
    height: $agHeaderHeight;
    width: 16px;
    vertical-align: middle;
  }
}

.ag-header--right-items {
  display: inline-block;
  vertical-align: middle;
}

.ag-header--slash {
  margin-left: 8px;
  margin-right: 8px;
}

// .ag-header--title {

// }

.ag-header-banner {
  height: 40px;
}

.ag-header--contents {
  @include position(absolute, 80px 0px 0px 0px);
  overflow-y: auto;

  &.has-banner {
    top: 120px;
  }
}

// ag-page-controller.scss

.ag-page-controller--contents {
  @include position(absolute, 24px 24px 24px 24px);
}

.ag-page-controller--tab-content {
  @include position(absolute, 0px 0px 0px 240px);
  background: $white;
  border: solid 1px $border;
  padding: 24px;
  overflow: auto;
}

.ag-page-controller--tab-header {
  font-size: 18px;
  margin-bottom: 24px;
}
