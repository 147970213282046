$interactionFormHeight: 400px;
$interactionFormSelectWidth: 300px;

.interaction-form {
  .dropdown--body {
    box-shadow: 0 0 2px $border;
  }
}

.interaction-form--fields {
  padding: 8px 16px 0px;

  textarea {
    height: 184px;
    margin-bottom: 0px;
  }

  .calendar-picker-dropdown {
    max-width: inherit;
  }

  .numeric-input--container {
    width: 100%;
  }
}

.interaction-form--column {
  display: inline-block;
  vertical-align: middle;
  min-width: 200px;

  .form-field--control {
    width: 179px;
  }

  .dropdown--body {
    min-width: $interactionFormSelectWidth;
  }
}

.et--dropdown--toggle {
  height: 39px;
}

.et--advanced-combobox--selected-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.interaction-form--solicitors {
  .advanced-combobox--selected-text {
    @extend .ellipsis-overflow;
  }
}

.interaction-form--solicitors-full-width {
  width: 100%;
}

.interaction-form--field {
  padding-bottom: 16px;

  input[type='text']:disabled,
  textarea:disabled {
    opacity: 0.5;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.interaction-form--gt-comment {
  padding-top: 8px;

  .note-textarea--avatar {
    display: none;
  }

  .note-textarea--input-wrapper {
    width: 100%;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .note-textarea--form-actions {
    border-top: solid 1px $border;
    margin-top: 24px;
    padding: 16px;
  }

  .note-textarea--form-save {
    margin-left: 16px;
  }
}

.interaction-form--label {
  .advanced-combobox--list {
    max-height: 280px;
  }
}

.interaction-form--label-footer {
  text-align: center;
  padding: 12px;
  border-top: solid 1px $border;
}

.interaction-form--meta {
  .form-field--label-wrap {
    word-wrap: break-word;
  }
}

.interaction-form--date-solicitor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interaction-form--date {
  flex-basis: 280px;
}

.interaction-form--solicitors {
  flex-basis: 224px;
}

.interaction-form--constituent-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interaction-form--type {
  flex-basis: 280px;
}

.interaction-form--substantive {
  flex-basis: 224px;
}

.interaction-form--toggle {
  padding-top: 8px;
  display: block;
}

.interaction-form--tags {
  width: 100%;
}

.interaction-form--household-checkbox {
  padding-bottom: 5px;
}

.interaction-form--dxo-ask {
  display: flex;
  justify-content: space-between;

  .form-field {
    flex-basis: 45%;
  }
}

.form-limit {
  .form-field {
    padding-bottom: 0px;
    margin: 0px;
  }
}

.interaction-form {
  .ql-toolbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0px;

    .ql-formats {
      margin-right: 10px;
    }
  }

  .ql-toolbar,
  .ql-snow {
    border: 1px solid #ccc;
    border-bottom: none;
    position: relative;
    z-index: auto;
  }

  .ql-container {
    width: 100%;
  }

  .ql-container,
  .ql-snow {
    border: 1px solid #ccc;
    height: auto;
    margin-top: 0;
    box-sizing: border-box;
  }

  .ql-editor {
    min-height: 250px;
    padding: 10px;
    overflow-y: auto;
  }
}


.interaction-form--solicitor-with-type-header {
  padding: 0 0 16px 0;
  font-size: 20px;
  font-weight: bold;

  span {
    font-size: $baseFontSize;
    font-weight: normal;
  }

  .interaction-form--list-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border;
    height: 60px;
    justify-content: space-between;

    hr {
      border-bottom: 1px solid #a54ab5;
      width: 48px;
    }
  }

  .interaction-form--solicitor-column {
    display: flex;
    align-items: center;
  }

  .interaction-form--cancel {
    color: $gray300;
    cursor: pointer;
    font-size: 24px;
    align-self: center;

    &:hover {
      color: $red500;
    }
  }

  .interaction-form--add-item-row {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    height: 60px;
  }


  .interaction-form--form-field-dropdown {
    width: 244px;
  }

}