@mixin activity-item-card {
  background: $white;
  border: solid 1px $border;
  border-radius: 2px;
}

.list-activity--comment-box {
  .note-textarea--input-wrapper {
    width: 100%;
  }

  .note-textarea--input,
  .note-textarea--mentions {
    background: $white;
  }
}

.list-activity--comment-box--new {
  background: $white;
  padding: 16px;
  border-bottom: 1px solid $border;
}

.list-activity--module {
  .module--header {
    padding: 0px;
  }
}

.list-activity--header {
  padding: 10px;
  padding-left: 68px;
  line-height: 30px;
  color: $gray500;
  font-size: 1.25em;
}

.list-activity--module-icon {
  float: left;
  line-height: 30px;
  width: 45px;
}

.list-activity--options {
  > li {
    border-top: solid 1px $border;
    font-size: 1.25em;
    padding: 10px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      color: $purple500;
      background: $gray105;
    }

    i {
      margin-right: 20px;
      width: 30px;
    }
  }
}

.list-activity--feed {
  padding: 15px;
  background: $gray105;

  .comment {
    padding: 0;
  }

  .comment--image,
  .comment--name {
    display: none;
  }

  .comment--content {
    float: none;
    margin-left: 0px;
    width: 100%;
    background: none;
    padding: 0;
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }

  .comment--text {
    border-radius: 2px;
    margin-bottom: 0;
    width: 100%;
  }

  .comment--actions {
    top: -28px;

    .dropdown--toggle {
      padding: 0;

      &.is-open,
      &:hover {
        background: none;
      }
    }

    .dropdown--body {
      border-top: solid 1px $border;
      margin-top: 3px;

      &::before {
        @include triangle(10px, $border, up);
        @include position(absolute, -6px 2px null null);
        content: '';
        display: block;
      }

      &::after {
        @include triangle(10px, $white, up);
        @include position(absolute, -4px 2px null null);
        content: '';
        display: block;
      }
    }
  }
}

.list-activity--feed-item {
  padding: 10px 0;

  .comment--date {
    display: none;
  }
}

.list-activity--card {
  @include activity-item-card;
  padding: 10px;
  margin-top: -2px;
}

.list-activity--label {
  margin-bottom: 8px;
  font-size: 0.95em;

  .is-empty {
    background: darken($gray105, 5%);
    width: 55px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }
}

.list-activity--target {
  .activity-card-group--item {
    @include activity-item-card;
    margin-top: -2px;
  }
}

.list-activity--facepile {
  @include activity-item-card;
  padding: 10px;
}

.list-activity--constituents {
  @include activity-item-card;
  padding: 0 10px;
  line-height: 1.3em;
  margin-top: -2px;

  table {
    margin: 0px;
  }

  .contact-card {
    margin: 0;

    td:first-child {
      width: 50px;
      padding-right: 10px;
    }

    .avatar-link {
      width: 100%;
      height: 100%;
    }

    &:last-child td {
      border-bottom: none;
    }
  }
}

.list-activity--paging {
  background: darken($gray105, 2%);
  text-align: center;
  padding: 5px 0;
  border-top: solid 1px $border;
}

.list-activity--similar {
  .module {
    border: none;
  }

  .module--header {
    display: none;
  }
}

.activity-card-group {
  position: relative;

  &.is-closed {
    -webkit-box-shadow: 0 5px 0 -4px #fff, 0 5px 0 -3px $border, 0 11px 0 -8px #fff, 0 11px 0 -7px $border;
    -moz-box-shadow: 0 5px 0 -4px #fff, 0 5px 0 -3px $border, 0 11px 0 -8px #fff, 0 11px 0 -7px $border;
    box-shadow: 0 5px 0 -4px #fff, 0 5px 0 -3px $border, 0 11px 0 -8px #fff, 0 11px 0 -7px $border;
  }
}

.activity-card-group--toggle {
  @include position(absolute, null null -10px 50%);
  background: $white;
  border-radius: 30px;
  box-shadow: 0 1px 0 $border;
  color: $linkText;
  font-size: 1.2em;
  width: 25px;
  height: 25px;
  padding: 6px 0;
  margin-left: -12px;
  cursor: pointer;

  &:hover {
    color: $purple500;
  }
}

.activity-facepile--avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  vertical-align: middle;
  margin-right: 10px;
}

.activitiy-facepile--modal {
  .modal-confirm--header {
    padding-right: 40px;
  }

  .modal-confirm--body {
    padding: 16px;
  }
}

.activity-facepile--table {
  line-height: 1.3em;
  margin: 0;

  td {
    border-bottom: none;
  }

  td:first-child {
    width: 70px;

    .avatar-link {
      width: 100%;
      height: 100%;
    }
  }
}

.activity-facepile--more {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: $gray150;
  color: $gray500;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center;
}

.activity-facepile--link {
  font-size: 0.9em;
  cursor: pointer;
}
