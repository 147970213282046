$interactionGTBackground: fade-out($blue300, 0.8);

.interaction {
  clear: both;
  position: relative;
}

.interaction--image {
  width: 56px;
  display: inline-block;
  vertical-align: top;
  position: relative;

  img {
    border-radius: 40px;
    width: 40px;
    height: 40px;
  }
}

.interaction--summary {
  font-weight: bold;
  font-size: 14px;
  color: $gray900;
}

.interaction--content {
  @include calc(width, "100% - 56px");
  display: inline-block;

  &.is-snippet {
    .interaction--text {
      word-break: break-word;
      white-space: normal;
    }
  }
}

.interaction-pinned-tag{
  font-size: 12px;
  color: #737373;
}

.interaction--body {
  position: relative;

  &.is-gt-comment {
    .comment--mention {
      background: none;
      font-weight: bold;
    }

    .interaction-meta--type {
      color: $gray500;
      font-weight: 500;
    }
  }

  &.is-empty {
    border: none;
    margin-top: 0;
  }
}

.interaction--text {
  word-break: break-word;
  white-space: pre-line;

  .interaction--rich-text-view * {
    all: revert;
  }
}


.interaction--date {
  color: $gray500;
  font-weight: normal;
}

.interaction--menu {
  @include position(absolute, 8px 8px null null);
  color: $gray500;
}

.interaction--menu-icon {
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: $purple500;
  }
}

.interaction--menu-inner {
  width: 100px;
  border: solid 1px $border;
  border-radius: 2px;
  padding: 4px 0;

  > li {
    padding: 8px;
    cursor: pointer;
    color: $linkText;

    i {
      font-size: 18px;
      margin-right: 8px;
    }

    &:hover {
      color: $purple500;
    }
  }
}

.interaction-meta--details {
  white-space: nowrap;
}

.interaction--details {
  font-weight: 500;
  font-size: 14px;
  color: $gray900;
}

.interaction-meta--type {
  font-weight: normal;
}

.interaction-substantive--text {
  color: $gray500;
  font-size: $fontSizeSmall;
  font-weight: normal;
}

.interaction-substantive--icon {
  color: $purple500;
}
