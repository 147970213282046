.update-stage-modal--radios {
  display: flex;
  padding: 10px 0;
}

.update-stage-modal--body {
  border-top: 1px solid $border;
  padding: 16px;
  margin: 0 20px;
}

.remove-prospect-checkbox {
  padding-top: 25px;
}
