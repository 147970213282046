.events-page-controller {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.events-page-controller--notice-wrapper {
  padding: 12px;
}

.events-page-controller--table-wrapper {
  position: relative;
  height: 100%;
}

.events-page-controller--not-found {
  margin-top: 100px;
}

.event-detail-actions {
  .action-menu-item--label {
    border-bottom: none;
  }

  .action-menu-item--icon {
    font-size: 18px;
  }
}
