.email-prompts--suggested {
  border-left: solid 1px #f6f6f6;
  width: 100%
}


.email-prompts--lists-container {
  margin: 0 auto;
}

.email-prompts--list {
  border-bottom: 1px solid #f6f6f6;
  padding: 10px 0;
  text-align: left;
  cursor: pointer;
}

.email-prompts--list-item {
  margin: 10px;
  display: flex;
  align-items: baseline;
  margin-left: 20px;

  img {
    width: 20.77px;
    height: 17.92px;
    margin-bottom: 10px;
  }

  .list-item-text {
    margin-left: 5px;
  }
}


.email-template-ai--container {
  background: #F9F9F9;
  position: relative;
}

.email-template-ai--actions {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  gap: 8px;
  display: flex;
  align-items: center;
}


.email-template-ai--regenerate {
  cursor: pointer;
  border-right: 1px solid #B7B7B7;
  padding: 0px 12px 0px 8px;
  height: 20px;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }
}


.email-template-ai--copy-button {
  color: black;
  padding: 0px 8px 0px 8px;
  border: none;
  cursor: pointer;
  background: rgba(249, 249, 249, 1);
  display: flex;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
  }
}

.email-template-ai--copy-button :hover {
  color: #007c9e;
}

.email-template-ai--copy-icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}

.email-template-ai--text {
  margin-top: 30px;
  width: 100%;
  white-space: pre-line;
  padding: 20px;
}

.email-template--form-field-dropdown {
  width: 300px;
  background: #F9F9F9;
  border-radius: 5px;
  justify-content: left;
  margin: 16px 0;
}

.email-prompts--description-container {
  border-bottom: 1px solid #f6f6f6;
}

.email-prompts--description {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: rgba(153, 92, 211, 0.08);
  margin: 12px;
  border-radius: 12px;

  img {
    width: 25.14px;
    height: 27.66px;
    margin: 8px;
    margin-bottom: 12px;
  }

  p {
    margin: 8px;
  }
}

.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-option-right {
  flex-shrink: 0;
  margin-left: 10px;
  color: #888;
}


.custom-option .gt-icon {
  margin-left: auto;
}

.email-template--dropdown {
  display: flex;
  align-items: center;
  background: rgba(249, 249, 249, 1);
  width: fit-content;
  padding: 13px;
  justify-content: space-between;

  .et--advanced-combobox--compact {
    min-width: 300px;
    flex-grow: 1;

    .et--dropdown--header .gt-icon {
      font-size: 28px;
    }
  }

  .et--advanced-combobox-item {
    align-items: center;
  }
}

.dropdown-selected-header {
  .dropdown-selected-text {
    display: flex;
    flex-direction: column;
  }
}

.copy-text {
  padding: 10px;
  cursor: pointer;
}

.copy-text:hover {
  color: #007c9e;
}

.email-template-ai--container-header {
  padding: 20px;
  background: rgba(249, 249, 249, 1);

  .email-template-ai--tone {
    margin-right: auto;
    position: absolute;
    top: 2px;

    .form-field {
      display: flex;
      align-items: center;
      gap: 8px;

      .form-field--label {
        width: 100%;
      }
    }
  }

}

.email-template-ai {
  .form-field--label-wrap {
    font-weight: 500;
  }
}