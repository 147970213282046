.eventbrite-emails-modal--contents {
  border-top: 1px solid $border;
  padding: 20px;
}

.eventbrite-emails-modal--title {
  margin: 16px 16px 0;
  padding: 12px 0;
  border-bottom: 1px solid $border;
  color: $gray500;
}

.eventbrite-emails-modal--list {
  padding: 16px;
}

.eventbrite-emails-modal--list-item {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &.is-empty {
    color: $gray500;
  }

  &:last-child {
    margin-bottom: none;
  }
}

.eventbrite-emails-modal--icon {
  flex-grow: 0;
  margin-right: 8px;
  color: $gray500;

  &.is-eventbrite {
    color: #ff8000;
  }
}

.eventbrite-emails-modal--email {
  flex-grow: 1;
}

.eventbrite-emails-modal--source {
  flex-grow: 0;
}

.eventbrite-emails-modal--status-container {
  min-height: 120px;
}

.eventbrite-emails-modal--error-description {
  padding: 12px 36px;
  text-align: center;
}
