.bookmarked-search--unauthorized {
  margin: 16px;
}

.bookmarked-search--name-heading {
  font-size: $fontSizeSmall;
  color: $gray500;
  line-height: 1;
}

.bookmark-modal {
  min-height: 100px;
}

.bookmark-modal--info {
  font-size: $fontSizeLarge;
  font-weight: $bold;
}

.bookmark-modal--loading {
  border-radius: 8px;
}

.bookmarked-search--scrollable-content {
  height: 160px;
  overflow-y: scroll;
}
