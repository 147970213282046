.banner-notice {
  @include clearfix;
  padding: 12px;
  background-color: #5898aa;
  position: relative;
  margin-bottom: 24px;
  border-radius: 2px;

  .banner-notice--icon {
    color: mix(#5898aa, #fff, 35%);
    position: absolute;
    left: 10px;
    font-size: 32px;
    margin-top: -16px;
    top: 50%;
  }

  .banner-notice--text {
    padding-left: 50px;
    color: #6c787f;
    font-size: $fontSizeLarge;

    a {
      text-decoration: underline;
    }
  }
}
