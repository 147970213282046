.quick-filters {
  display: flex;
  button {
    color: $gray900;
    &.has-value {
      background-color: $green106;
      border: 1px solid $green200;
    }
  }
}
