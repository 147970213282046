$poolBodyHeight: 180px;

.pools--controls {
  padding: 16px;
  display: flex;

  .search-box {
    max-width: 400px;
    flex: 1;
  }
}

.pools--button-select {
  align-self: center;
  margin-left: auto;
}

.pools--body {
  @include position(absolute, $poolBodyHeight 0px 0px 0px);
  padding: 0 12px;
  overflow: auto;
}

.pools--cards {
  display: flex;
  flex-wrap: wrap;
}

.pools--empty {
  text-align: center;
}

.pools--title {
  font-size: 20px;
}

.pools--subtitle {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
}

.pools--pagination {
  text-align: center;
  padding: 16px 0;
  margin-bottom: 40px;
}

.pools--popover {
  width: auto;
  .et--button-popover--contents {
    left: 0;
    right: 0;
    margin-top: 0;
  }
}

.pools--popover-content {
  min-width: 305px;
  background: $white;
  border-radius: 3px;
  font-size: 16px;
  padding: 24px 14px 24px 34px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.22);
}

.pools--popover-actions {
  display: block;

  &.first {
    padding-bottom: 24px;
  }
}

.pools--bulk-actions {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid $border;
  background: none;
  border-radius: 2px;
  margin-right: 8px;

  .et--button-popover {
    width: 100%;
  }
}

.pools-bulk-actions--disabled,
.pools-bulk-actions--disabled:hover,
.pools-bulk-actions--disabled:focus {
  color: $gray150;
}

.pools--reset-assignments {
  white-space: nowrap;
}

.pools--reset-icon {
  .action-menu-item--icon {
    font-size: 28px;
  }
}
.pool--bulk-menu {
  min-width: 260px;
}
