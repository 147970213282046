.solicitor-goals-form--modal {
  min-width: 500px;
}
.solicitor-goals-form--form-field {
  padding-bottom: 5px;
  width: 200px;
  margin-bottom: 8px;
}

.solicitor-goals-form--row {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0 16px 0 16px;
  justify-content: space-between;
  align-items: center;
}

.solicitor-goals-form--fiscal-year-text {
  flex: 1;
  align-self: flex-start;
  color: $gray500;
  margin-bottom: 16px;
}

.solicitor-goals-form--clear-all {
  color: $purple500;
}

.solicitor-goals-form--form-field {
  .form-field--label {
    margin-bottom: 4px;
  }
}
