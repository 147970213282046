.calendar-sync-modal {
  padding: 20px;
}

.calendar-sync-modal--header {
  margin: 16px 0;
}

.calendar-sync-modal--item {
  display: flex;
  margin-bottom: 8px;
}
