.module--header {
  padding: 8px 16px;
  color: $gray500;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &.has-icon {
    padding: 16px 16px;
  }

  .helptip-item {
    font-size: $baseFontSize;
  }
}

.module--header-title {
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
}

.module--header-title-gray {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  height: auto;
  color: $gray900;
}

.module--icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: solid 2px $gray150;
  color: $gray150;
  margin-right: 4px;
  text-align: center;
  margin: 0 8px 0 -4px;
  vertical-align: middle;

  &::before {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;
    text-align: center;
  }
}

.module--body {
  padding: 16px;

  .is-empty {
    font-style: italic;
    color: $gray500;
  }
}
