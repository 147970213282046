.ai-search-home {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;

  .ai-search-header {
    padding: 5px;
  }

  .ai-search-container {
    width: 100%;
    .total-results {
      padding: 5px;
      margin: 21px 0px 21px;
    }
  }

  .error-page{
    top: 17%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    width: 45%;
    left: 26%;

    .error-title{
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }

    .suggested-search-list{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .suggested-search-item {
      margin-bottom: 9px;
      cursor: pointer;
      width: 100%;
    }

    .suggested-search-item-header-box {
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      width: fit-content;
    }

    .suggested-search-item-box {
      border: 1px solid $gray150;
      display: flex;
      justify-content: center;
      color: #007C9E;
      border-radius: 14px;
      font-size: 13px;
      padding: 11px 20px 11px 8px;
      width: 100%;
    }

    .learn-more-title{
      margin-top: 30px;
      text-align: center;
    }
  }
}


  .ai-search-card {
    padding: 10px;
    display: flex;
    align-items: baseline;
    gap: 5px;

    .ai-search-card-header {
      display: flex;
      gap: 6px;
    }
  }


  .ai-search-table-container {
    overflow-x: auto;
    margin: 1rem 0;

    .table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ddd;
      font-size: 14px;
      text-align: left;
    }

    .table th {
      background-color: #f4f4f4;
      border: 1px solid #ddd;
      padding: 10px;
      font-weight: bold;
    }

    .table td {
      border: 1px solid #ddd;
      padding: 10px;
    }
  }

  .ai-search-content{
    border: 1px solid #D7D7D7;
    border-radius: 12px;
    padding: 9px;
    display: flex;
    width: 75%;
    align-items: center;
    gap: 4px;
  }

  .original-query{
    padding: 10px;
  }

  .renderable-query {
    .text-highlighted-field {
      align-items: center;
      padding: 7px;
      border-radius: 100px;
      color: #007C9E;
      cursor: pointer;
      background: #F3F3F3;
      font-weight: bold;
    }

    .date-highlighted-field {
      align-items: center;
      padding: 7px;
      border-radius: 100px;
      color: #007C9E;
      cursor: pointer;
      background: #F3F3F3;
      font-weight: bold;
    }
  }

  .text-popover-content {
    display: flex;
    width: 314px;
    padding: 16px;
    flex-direction: column;

    .input-title {
      font-size: 15.5px;
      margin-bottom: 12px;
    }
  }

  .query-submit.et--button-secondary{
      min-width: auto;
    }