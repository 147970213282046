.date-panel {
  display: flex;
  align-items: center;
  width: 85%;
  color: $gray900;
  height: 100%;
  float: left;
  button:focus:not(:disabled),
  button:hover:not(:disabled) {
    background-color: transparent;
    color: $border;
  }
}
.date-panel--decrement-button,
.date-panel--increment-button {
  color: $border;
  width: 20px;
  float: left;
  height: 100%;
  background-color: transparent;
  box-shadow: inset 0 0 0 0;
  border: 0px;
  &:hover {
    background-color: transparent;
  }
  .gt-icon-chevron-left,
  .gt-icon-chevron-right {
    font-size: 1.7em;
  }
}
.date-panel--date-holder {
  display: flex;
  text-align: left;
  width: 40%;
  position: relative;
  align-items: center;
}
.date-panel--day-holder {
  height: 100%;
  padding-left: 24px;
  padding-right: 5px;
  font-size: 30px;
}
.date-panel--date-info-holder {
  height: 100%;
}
.date-panel--month-year-holder {
  white-space: nowrap;
  width: 100%;
  padding-top: 5px;
  font-size: 12px;
  height: 20px;
  color: $linkText;
}
.date-panel--week-day-holder {
  width: 100%;
  font-size: 12px;
}
.date-panel--date-selection-button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
}
