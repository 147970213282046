$mapToolBarHeight: 40px;
$mapToolbarBoxShadow: 0 2px 2px fade-out($black, 0.7);

.map-toolbar .map-toolbar--button {
  // overwrite stuff from "et-parts--button-secondary" and explicitly apply styles (borrowed from listbox-trigger) to buttons in the toolbar
  width: $mapToolBarHeight;
  height: $mapToolBarHeight;
  margin-left: 5px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  background-color: #f9f9f9;
  border-color: #d7d7d7;
  color: #454545;
}

// RADIUS CLEAR BUTTON 

.map-radius--clear-btn {
  color: #007c9e;
  padding: 15px 14px 5px;
}

.map-radius--clear-btn:hover {
  color: #a54ab5;
}

// ZOOM BUTTONS

.map-zoom--container > .map-zoom--button {
  // override general stylings
  margin: 0px;
}

// make the zoom buttons look like one big box
.map-zoom--container > button:first-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

// make the zoom buttons look like one big box
.map-zoom--container > button:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
