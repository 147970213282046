$matchedCheckboxSize: 23px;
$matchActionWidth: 25%;

.match-facebook-modal--header {
  padding: 15px 0;
  border-bottom: 1px solid $border;

  .match-facebook-modal--header-info {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;

    strong {
      display: block;
    }
  }

  .match-facebook-modal--header-info-data {
    display: flex;
    > img {
      margin-right: 18px;
    }
  }
}

.match-facebook-modal--header-divider {
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background: $border;
}

.match-facebook-modal-page-image {
  margin-right: 18px;
  border: 1px solid $gray300;
}

.match-facebook-modal-text-title {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  margin-bottom: 4px;
}

.match-facebook-modal--section {
  padding: 20px 25px;
  line-height: 16px;
}

.match-facebook-modal--body {
  padding: 15px 15px 0;
  background: $background;
  position: relative;
  height: 350px;
  overflow-y: scroll;
  width: 570px;
}

.match-facebook-modal-post-info {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid $border;
  padding: 15px 25px;
  margin-top: 25px;
}

.match-facebook-modal-warning {
  display: flex;
  align-items: center;
  margin: 5px 0 10px;
}

.match-facebook-modal-warning--icon {
  color: $yellow150;
  margin-right: 15px;
}

.match-facebook-modal--contact {
  background: $white;
  padding: 10px 0;
  border-bottom: 1px solid $border;
  display: flex;
  justify-content: center;

  .new-contact-card--body {
    max-width: 350px;
  }
}

.match-facebook-list {
  overflow: auto;
  background: $white;
  min-height: 346px;

  .match-facebook-modal-post-info {
    margin-top: 0;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid $border;
    }
  }
}

.match-facebook-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $border;

  .new-contact-card {
    padding: 0 8px;
  }

  .checkbox--box {
    margin-right: 25px;
    border-radius: $matchedCheckboxSize / 2;
    width: $matchedCheckboxSize;
    height: $matchedCheckboxSize;
  }

  input.checkbox--input:checked + .checkbox--box {
    background: $purple500;
    border-color: $purple500 !important;

    &::before {
      color: $white;
      font-size: $fontSizeLarge;
      font-weight: 100;
      top: -1px;
      left: -1px;
      width: $matchedCheckboxSize;
      height: $matchedCheckboxSize;
    }
  }
}

.match-facebook-result-list {
  min-height: 60px;
  border: solid 1px $border;
  border-bottom: none;
  border-radius: 2px;
  background: $white;
  margin-bottom: 15px;
}

.match-facebook-search-box {
  width: 60%;
  margin: 0 auto;
}

.match-facebook-result:hover {
  cursor: pointer;
}

.match-facebook-confirm--modal {
  width: 100%;
  background: $background;
  height: 635px;
}

.match-facebook-modal-additional-matches {
  background-color: $white;
  padding: 15px 25px;

  .match-facebook-modal-post-info {
    margin-top: 0;
  }
}

.match-facebook-no-additional {
  display: flex;
  padding: 15px;
  line-height: 16px;
  align-items: center;
  border: 1px solid $border;
  background: $white;
}

.facebook-post-link {
  display: flex;
  > img {
    margin-right: 18px;
  }
}

.match-facebook-modal-name {
  max-width: 150px;
}
