$cardPadding: 8px;
$cardMinWidth: 250px;

.card {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(193, 193, 193, 0.5);
  background: $white;
  border-radius: 2px;
  min-width: $cardMinWidth;
}

.card--header-section {
  padding: $cardPadding;
  border-bottom: 1px solid $border;
}

.card--heading {
  font-size: 16px;
  color: $gray900;
}

.card--main {
  padding: $cardPadding;
}
