$andOrToggleWidth: 50px;
$andOrToggleHeight: 40px;
$andOrTogglePadding: 10px;
$andOrToggleRadius: 3px;

// Height of Row - Height of Element - 1px Border
$andOrToggleRowHeight: 60px;
$andOrConnectorHeight: $andOrToggleRowHeight - $andOrToggleHeight;

.and-or-toggle {
  @include vertical_align();
  text-align: center;
  position: relative;
}

.and-or-toggle--item {
  @include vertical_align();
  text-transform: uppercase;

  button {
    background-color: $white;
    padding: $andOrTogglePadding;
    width: $andOrToggleWidth;
    height: $andOrToggleHeight;
    border: solid 1px $border;
    border-radius: $andOrToggleRadius;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 500;
    color: $gray900;

    &:hover:not(:disabled) {
      background: $gray105;
      color: $purple500;
    }
  }

  &.and button {
    border-right: none;
    border-radius: $andOrToggleRadius 0 0 $andOrToggleRadius;
  }

  &.or button {
    border-radius: 0 $andOrToggleRadius $andOrToggleRadius 0;
  }

  &.is-selected button,
  &.is-selected button:hover:not(:disabled),
  &.is-selected button:focus {
    color: $white;
    background: $gray500;
    border-color: $gray500;
  }
}
