.facebook-posts-controller {
  @include position(absolute, 0px 0px 0px 0px);

  .page-container {
    @include position(absolute, 115px 0px 0px 0px);
    overflow: auto;
  }
}

.facebook-interactions-controller {
  @include position(absolute, 0px 0px 0px 0px);
  overflow: hidden;

  .page-container {
    @include position(absolute, 115px 0px 0px 0px);
    overflow: auto;
  }
}

.facebook-header-wrapper {
  top: 0;
  right: 0px;
  left: 0px;
  width: 100%;
  background: $white;
  z-index: 10;
}

.facebook-header--recent-posts {
  margin-bottom: 16px;
}

.active-filters-header--text {
  color: $gray500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: $fontSizeSmall;
  margin: 0 5px;
}

.active-filters-header--label {
  font-size: 20px;
}

.facebook-filter-panel {
  .facebook-filters--col {
    float: left;
  }

  .facebook-filters--text {
    max-width: 437px;
    padding-right: 35px;
    width: 47%;
  }

  .date-picker-dropdown {
    float: left !important;
  }
}

.facebook-filter--help {
  font-size: 20px;
  vertical-align: middle;

  .fa-question-circle {
    color: $gray200;
    line-height: 40px;
    width: 25px;
    text-align: center;

    &::before {
      float: left;
    }

    &:hover {
      color: $purple500;
    }
  }
}

.facebook-nav {
  position: relative;
  z-index: 10;
}

.facebook-header--subheader {
  padding: 18px 16px;
}

.facebook-header--text {
  font-size: 20px;
  padding-bottom: 2px;
}

.facebook-header--actions {
  position: absolute;
  right: 16px;
  top: 17px;
}

.facebook-post-stats {
  @extend .clearfix;
  min-width: 715px;

  .facebook-post-container {
    float: left;
  }

  dd {
    color: $gray900;
    font-size: 20px;
  }

  dt {
    color: $gray500;
    border-bottom: $border solid 1px;
    padding-bottom: 16px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.facebook-post-stats--card {
  float: left;
  margin-left: 30px;
  width: 32%;
  background: $white;
}

.facebook-post-stats--body {
  padding: 0 0 0 16px;
}

.facebook-post-stats--value {
  padding-top: 16px;
}

.facebook-post-stats--label {
  color: $gray500;
  font-size: $fontSizeLarge;
  padding: 13px 0 14px 10px;
}

.facebook-post-stats--reaction-icon {
  width: 18px;
  background: $white;
  padding: 0 2px 0 0;
  margin-right: -2px;
  position: relative;
  border-radius: 50px;

  &:first-child {
    margin-left: 8px;
  }
}

.facebook-post-container {
  background: $white;
  background-image: url($facebookLoading);
  background-repeat: no-repeat;
  width: 500px;
  min-height: 150px;
  border-radius: 3px;

  .fb-post {
    z-index: $fbPostZindex;
    min-height: 130px;
  }
}

.facebook-post--actions {
  z-index: $fbActionsZindex;
  position: relative;
  top: -2px;

  a {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
}

.post-details {
  .module--body {
    padding: 20px 30px;
  }
}

.post-details--header {
  .facebook-post--actions {
    display: none;
  }
}

.post-details--contacts {
  margin-top: 30px;

  .module--header .button-select {
    margin-top: -6px;
  }
}

.post-details--contacts-table {
  position: relative;
  height: 600px;

  .fixed-page-wrapper {
    top: 72px;
  }

  .selected-contacts-string {
    margin-left: 12px;
  }
}

.post-details--results {
  background: lighten($background, 2%);
  padding: 20px 30px;
  border-bottom: solid 1px $border;
}

.post-details--engagers {
  @include display(flex);
  @include flex-wrap(wrap);

  .post-engagers--profile {
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    font-size: 14px;
    min-height: 165px;
    width: 220px;
    margin: 16px;
    border: solid 1px $border;
    position: relative;
    padding: 24px;

    .btn-secondary,
    .btn {
      font-size: 14px;
    }

    .btn-secondary:visited {
      color: $gray900 !important;
    }
  }

  .post-engagers--profile-link {
    margin-bottom: 5px;

    img {
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.3);
        filter: brightness(80%);
      }
    }
  }
}

.post-engagers {
  @include position(absolute, 0px 0px 0px 0px);
  min-height: 200px;
  overflow: auto;
}

.facebook-module--header {
  h5 {
    display: inline-block;
    vertical-align: middle;
  }

  .combobox {
    display: inline-block;
    width: 35%;
    vertical-align: middle;
  }
}

.facebook-posts-controller--warning {
  display: flex;
  background: $white;
  border: 1px solid $border;
  border-left: solid 3px $gray500;
  border-radius: 2px;
  margin-bottom: 12px;
  box-shadow: 0 1px 0 $border;

  span {
    padding: 16px;
  }

  p {
    margin: 4px 0 0 0;
  }
}

.facebook-posts-controller--icon {
  color: $gray500;
  display: flex;
  padding: 16px 0 16px 8px;
}

.recent-engagement {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 65%;
}
