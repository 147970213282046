.proposal-search-controller--filter-bar {
  padding: 16px;
  display: flex;
}

.proposal-search-controller--filter-yours {
  min-width: 220px;
  margin-right: 16px;
}

.proposal-search-controller--filter-solicitors,
.proposal-search-controller--filter-active {
  min-width: 184px;
  margin-right: 16px;
}

.proposal-search-controller--filter-stage {
  margin-right: 16px;
}

.proposal-search-controller--filter-selected {
  .et--dropdown--toggle {
    border-color: $green200;
    background-color: $green106;
  }
}

.proposal-search-controller--table-settings {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 16px;
}

.proposal-search-controller--row-count {
  font-weight: $bold;
}

.proposal-search-controller--pagination {
  text-align: center;
}

.proposal-table {
  padding: 16px;

  table {
    min-height: 400px;

    tbody {
      tr {
        min-height: 60px;
      }
    }
  }
}

.proposal-table--first-col {
  display: flex;
  align-items: center;
}

.proposal-table--first-col-header {
  padding-left: 40px;
}

.proposal-table--key-proposal-star {
  color: $yellow150;
  flex: none;
}

.proposal-table--proposal-star {
  color: $gray150;
  flex: none;
}

.proposal-table--prospect {
  display: flex;
  align-items: center;
}

.proposal-table--avatar {
  margin-right: 8px;
}

.proposal-table--inactive-badge {
  @extend .profile-proposals--inactive-badge;
}
