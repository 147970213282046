.ag-gifts--table--info {
   padding: 12px;

}

.ag-gifts-controller {
   tr {
      cursor: pointer;

      &:hover {
         background-color: #FAFAFA;
      }

   }
}

