.segment-selector--header {
  .modal-confirm--header {
    padding: 16px;
  }
}

.segment-selector--controls {
  background: $gray105;
  border-bottom: solid 1px $border;
  padding: 16px;

  .button-select {
    width: 100%;
  }

  .button-select--item {
    width: 50%;
    font-size: 1.5;
  }
}

.segment-selector--search {
  padding: 16px;

  .search-box {
    display: block;

    input[type="search"]:focus {
      border-color: $border;
    }

    .fa-search.is-active {
      color: $green300;
    }
  }
}

.segment-selector--container {
  position: relative;
  max-height: 425px;
  min-height: 100px;
  overflow: auto;
}

.segment-selector--list {
  color: $gray500;

  > li {
    position: relative;
    margin: 0 8px;
    padding: 8px;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &.is-selected {
      background: $gray105;
      cursor: pointer;

      a {
        color: $purple500;
      }
    }
  }
}

.segment-selector--featured {
  .segment-selector--list > li:hover {
    background: $gray105;
    cursor: pointer;

    a {
      color: $purple500;
    }
  }

  > li:first-child {
    .segment-selector--featured-title {
      border-top: none;
    }
  }
}

.segment-selector--featured-title {
  border-top: solid 1px $border;
  border-bottom: solid 1px $border;
  padding: 16px;
  font-size: 20px;
  text-align: center;
}

.segment-selector--featured-subtitle {
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
  color: $gray500;
}
