$manageUsersPadding: 30px;
$manageUsersActionBarHeight: 90px;
$manageUsersActionBtnHeight: 40px;

// Absolute position user management page
.manage-users--module.fixed-page-wrapper {
  top: $manageUsersPadding;
  right: $manageUsersPadding;
  left: $manageUsersPadding;
  bottom: $manageUsersPadding;
}

.manage-users--module .module--body {
  @include position(absolute, 51px 0px 0px 0px); // module--header is 50 plus 1 for border
  padding: 0;
}

.manage-table--fixed-wrap {
  @include position(absolute, $manageUsersActionBarHeight 0px 0px 0px);
}

// Search, Filter and Invite
.manage-users--top-actions {
  padding: 20px;
  height: $manageUsersActionBarHeight;
}

.manage-users--search {
  width: 256px;
  margin-right: 16px;
}

.invite-users-btn--icon {
  @include vertical_align;
}

.invite-users-btn--text {
  @include vertical_align;
}

// manage users table
.manage-users--table {
  margin: 0;

  table {
    margin-bottom: 30px;
  }

  .table--header th {
    height: 50px;
    line-height: 25px;

    &:last-child {
      border-right: none;
    }
  }

  .table--body td:last-child {
    border-right: none;
  }

  .user-card--details {
    text-align: left;
  }
}

// needs to match style of table headers with sort dropdowns in them
.table--header th.manage-users--actions {
  padding: 10px 25px;
  font-weight: 500;
  padding-bottom: 14px;
  height: 50px;
}

td.manage-users--actions {
  height: 90px;
  justify-content: center;
  align-items: center;
}

.manage-users--actions-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.manage-users--action {
  @include users_table__delete();
  width: 60px;
  display: inline-block;
}

.manage-users--solicitor-id {
  position: relative;
}

.solicitor-id--remove {
  position: absolute;
  right: 15px;
}

// User invite modal
.user-invite-modal {
  .modal-confirm--body {
    position: relative;
    padding: 15px;
    background: $background;
  }

  textarea {
    min-height: 75px;
    margin-bottom: 0;
  }
}

.exceed-license-warning {
  margin-top: 15px;
}

.manage-users--role,
.user-invite--selector {
  width: 125px;
  margin: 0 auto;
  float: none;
}

.user-invite--selector {
  @include position(absolute, 30px 30px 0 0);
}

// Edit User Modal
.edit-user--users {
  margin: 15px 50px;
  width: 450px;
}

// Remove User Modal
.delete-user--users {
  border: solid 1px $border;
  width: 85%;
  margin: 15px auto;
}

.delete-user--text {
  text-align: center;
  margin: 0 auto 20px auto;
  width: 75%;
}
