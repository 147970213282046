.similar-profiles {
  min-height: 160px;
}

.similar-profiles--header {
  margin-bottom: 8px;
}

.similar-profiles--title {
  font-size: 16px;
  line-height: 20px;
  color: $gray500;
  font-weight: 500;
}

.similar-profiles--body {
  .new-contact-card {
    width: 33%;
    display: inline-block;
    padding: 0 24px 24px 24px;
  }

  .new-contact-card--avatar {
    width: 50px;
  }

  .new-contact-card--body {
    padding: 0px;
    align-items: normal;
  }

  .new-contact-card--content {
    padding: 0px 16px;
  }
}

.similar-profiles--left {
  width: 50%;
  float: left;
  padding-right: 24px;
}

.similar-profiles--right {
  width: 50%;
  float: left;
  padding-left: 24px;
}

.similar-profiles--contacts {
  .contact-card {
    td {
      padding: 16px 0;

      &:first-child {
        width: 80px;
      }
    }
  }

  tr:last-child td {
    border-bottom: none;
  }
}

.similar-profiles--modal {
  padding: 0 16px;
}

.similar-profiles--empty {
  text-align: center;
  padding: 0 20px;
}
