.add-event-subtitle {
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 20px;
}

.event-type-row {
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 30px;
  align-items: center;
}

.event-type-row--content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-type-row--radio {
  margin-bottom: 0;

  .radio--circle {
    float: none;
    vertical-align: middle;
  }
}

.event-type-row--icon {
  font-size: 50px;
  margin-right: 20px;
  margin-left: 20px;
  color: $gray500;

  &.is-selected {
    color: $purple500;
  }
}

.event-type-row--text {
  text-align: left;
  color: $gray900;
  font-size: 12px;
  line-height: 16px;
}

.event-type-row--text-title {
  font-size: 14px;
  font-weight: 500;
}
