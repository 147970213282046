.get-mobile--text-me {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.get-mobile--lower {
  margin-top: 8px;
}

.mobile-success {
  padding: 16px;
}
