.segments-page-controller {
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.segments-page--disabled {
  margin-top: 50px;
  .et--empty-state-message--text {
    line-height: 1.2em;
  }
}

.segments-page--empty {
  min-width: 500px;
  width: 30%;
  margin: 15px auto 0;
  padding: 20px;
  .et--card--main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.segments-page--empty-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: $gray500;
}

.segments-page--recent-item {
  display: block;
}

.segments-page--recent-item:not(:last-child) {
  margin-bottom: 10px;
}

.segments-page--empty-subtitle {
  text-align: center;
  font-size: 12px;
}

.segments-page--empty-featured {
  margin-top: 15px;
  width: 85%;
}

.segments-page--table,
.segments-page--table-folder {
  width: 100%;
  .et--flex-table--wrapper {
    overflow: visible;
  }
  .et--flex-table--sort-header-cell {
    text-align: left;
  }

  .et--flex-table--body {
    overflow: visible !important;
  }

  .frequency-selection {
    min-width: 100px;
    display: flex;
    align-items: center;

    i:first-child {
      margin-right: 10px;
    }
  }
}

.segments-page--table {
  width: 70%;
}

.segments-page--table-folder {
  width: 100%;
}

.segments-page--side {
  width: 30%;
  .et--card--heading {
    padding: 20px 0 0 20px;
  }
}

.segments-page--search {
  width: 250px;
  margin: 20px;
}

.segments-page--featured {
  a {
    display: block;
  }
}

.segments-page--featured:not(:last-child) {
  margin-bottom: 10px;
}
