.reminder-overlay {
  color: $white;
  line-height: 1.4em;
  font-weight: 400;
}

.reminder--content {
  background: $gray900;
  padding: 8px 16px;
  border-radius: 3px;
}

.reminder-overlay--got-it {
  color: $white !important;
  text-decoration: underline;
}
