// MAIN Dialer Container
.dialer {
  height: 100%;
  padding: 24px;
  display: flex;
}

// RIGHT SIDE (Input Fields & Keypad)
.dialer-right-container {
  width: 50%;
}

// Header (Contains Input Fields and Dropdown)
.dialer--header {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

// Input Fields (Number and Extension Inputs)
.dialer--input {
  display: flex;
  margin-bottom: 20px;
}

.dialer--input-box {
  font-size: 22px;
  border: none;
  outline: none;
}

.extension-box {
  width: 60px;
}

.phone-box {
  width: 170px;
  padding-right: 0px;
}

// Keypad
.dialer--grid-container {
  height: 60%;
  border-top: solid 1px #f3f3f3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding-bottom: 10px;
}

.dialer--grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

// LEFT SIDE (Notepad)
.dialer-left-container {
  margin-right: 20px;
  width: 50%;
  height: 100%;
}

.dialer--input-note {
  display: flex;
}

.dialer-note-textarea {
  resize: none;
  height: 450px;
}

.dialer--timer {
  width: 20%;
  font-size: 17px;
}

.voip-error-message {
  padding: 20px;
  margin: 20px;
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  border-radius: 4px;
  color: #cc0000;
  text-align: center;
  font-weight: 500;
}
