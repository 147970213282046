$linkMainColor: $linkText;
$linkMainHighlight: $purple500;

a {
  @include transition(color 0.1s linear);
  color: $linkMainColor;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    color: $linkMainHighlight;
  }

  &:active,
  &:focus {
    color: $linkMainColor;
  }

  &:visited {
    color: $linkMainColor;

    &:hover {
      color: $linkMainHighlight;
    }
  }

  &.is-disabled {
    color: $gray900;

    &:hover {
      color: $gray900;
    }

    &:focus {
      color: $gray900;
    }
  }
}

.link-inherit {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.link-destroy {
  color: $red500;

  &:hover {
    color: darken($red500, 10%);
  }
}
