.text-kpi-value {
  font-weight: bold;
  font-size: 1.2em;
}

.text-label {
  color: darken($gray500, 2%);
  font-weight: normal;
}

.text-placeholder {
  color: $gray500;
  font-style: italic;
  font-weight: normal;
}

.text-placeholder-light {
  color: $gray150;
  font-weight: normal;
}

.text-empty-message {
  text-align: center;
  padding: 20px;
  padding-top: 30px;
}

.text-weight-normal {
  font-weight: normal;
}

.text-dollar-value {
  color: $green300;
}

.text-count {
  color: $gray500;
}

.text-bold {
  font-weight: $bold;
}

.text-light {
  color: $gray500;
}

.text-muted {
  color: $gray150;
}

.text-warning {
  color: $red500;
}

.text-info {
  color: $gray500;
}

.text-danger {
  color: $red500;
}

.text-bright {
  color: $purple500;
}

.text-highlight {
  background: $green106;
}

.text-highlight--secondary {
  background: $green200;
  color: $white;
}

.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-word-break {
  word-wrap: break-word;
}

.text-removed-user {
  color: $gray500;
  font-style: italic;
}

.text-muted-hover {
  color: $gray150;
  &:hover {
    color: $gray150;
  }
}

// Overflowing Text
.overflow-text {
  position: relative;
}

.ellipsis-overflow {
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 15px;
}

.ellipsis-overflow-one-line {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-overflow-two-lines {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-overflow--content {
  border: 1px solid $border;
  padding: 3px 6px;
  border-bottom-width: 2px;
  border-radius: 3px;
  background: $gray105;
  white-space: nowrap;

  * {
    font-size: $baseFontSize;
    font-weight: normal;
  }

  &.is-max-width {
    white-space: normal;
    width: 800px;
  }
}

.main-sub-header {
  background: $white;
  padding: 10px $pageContainerPadding;
}

.link-to-search {
  .link-to-search--icon {
    display: none;
  }

  &:hover .link-to-search--icon {
    display: inline-block;
  }
}

.link-to-search--icon {
  padding-left: 5px;
  font-size: 0.9em;
}

.count-of {
  font-size: $baseFontSize;
  color: $gray500;
}
