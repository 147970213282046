$annualGivingBarHeight: 21px;

.annual-giving {
  &.is-loading {
    min-height: 200px;
  }

  .profile-card-header {
    padding: 16px;
    margin-bottom: 0px;
  }
}

.annual-giving--section {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: solid 1px $border;

  .profile-card-subtitle {
    border-bottom: none;
  }
}

.annual-giving--gave {
  margin-bottom: 16px;
}

.annual-giving--score-outer {
  height: $annualGivingBarHeight;
  background: $background;
  border-radius: 2px;
  vertical-align: middle;
}

.annual-giving--score-inner {
  height: $annualGivingBarHeight;
  border-radius: 2px;
  background-color: $green300;
}

.annual-giving--score-label {
  margin-top: 16px;
  font-size: 14px;
  text-align: bottom;
}

.annual-giving--numerator {
  line-height: 1;
  font-weight: bold;
  color: $gray500;
  font-size: 24px;
}

.annual-giving--divider {
  line-height: 1;
  color: $gray500;
  margin: 0 4px;
  font-size: 16px;
}

.annual-giving--denominator {
  line-height: 1;
  color: $gray500;
  font-size: 16px;
}

.annual-giving--checkmark {
  @include vertical-align;
  background-color: $green300;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  padding-top: 4px;
  font-size: 16px;
  color: $white;

  &.is-negative {
    background-color: $gray150;
  }
}

.annual-giving--gift-info {
  font-size: 14px;
  text-align: bottom;
}

.annual-giving--value {
  @include vertical-align;
  font-size: 16px;
  margin-left: 8px;
}
