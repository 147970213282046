$givingFiltersHeight: 52px;

.giving-module {
  overflow: hidden;

  .calendar-dropdown {
    float: right;
  }
}

.giving-module--menu {
  @extend .sub-nav;
  border-bottom: solid 1px $border;
  padding-left: 16px;
}

.giving-module--filters {
  @include position(absolute, null 0px 0px 0px);
  height: $givingFiltersHeight;

  &.is-collapsed {
    left: 100%;
  }

  .dropdown--header,
  .date-picker--header {
    background: $white;
    box-shadow: inset 0 -2px 0px lighten($border, 8%);
  }

  .button-select {
    margin-top: 3px;
  }

  .advanced-combobox--list {
    max-height: 300px;
  }
}

.giving-module--chart {
  margin: 20px 0;
  position: relative;
  min-height: 300px;

  .fa-unsorted {
    color: $gray200;
  }
}

.giving-module--body {
  margin: 20px;
  position: relative;
}

.giving-module--label-dropdown {
  float: left;
  width: 45%;

  .dropdown--body {
    min-width: 300px;
  }
}

.giving-module--calendar-dropdown {
  &.popover-trigger {
    float: left !important;
    margin-left: 10px;
    width: 45%;
  }
}

.giving-module--title {
  width: 75%;
  float: left;

  h4 {
    font-size: 1.7em;
    font-weight: normal;
  }
}

.giving-module--sub-title {
  font-size: 16px;
  margin-top: 4px;
}

.giving-module--toggle {
  float: right;

  .button-select--item {
    border: none;
    color: $linkText;
    padding: 4px 8px;
    font-weight: normal;

    &:hover {
      border: none !important;
      background-color: $white !important;
    }

    &:last-child {
      border-left: solid 1px $border !important;
    }

    &.is-selected {
      background-color: $white;
      color: $purple500;
    }
  }
}

.giving-module--filters-title {
  text-align: center;
}

.giving-module--tooltip {
  line-height: 18px;
  text-align: center;

  .text-label {
    font-size: 0.8em;
  }
}

.giving-module--tooltip-empty {
  font-size: 16px;
  color: $gray500;
}

.giving-module--tooltip-value {
  font-size: 18px;
  line-height: 24px;
}

.giving-module--legend {
  text-align: center;
}

.giving-module--footer {
  position: relative;
  height: $givingFiltersHeight;
}

.giving-filters--toggle {
  @include transition(right 0.1s linear);
  display: block;
  background: $white;
  height: $givingFiltersHeight - 2px; //for border
  padding: 12px 10px 10px 10px;
  z-index: 100;
  border-bottom: none;
  line-height: 24px;
  float: left;

  &.is-collapsed {
    @include position(absolute, 0px 0px 0 0);
    top: 0px;
    right: -88px;
    width: 150px;
    height: $givingFiltersHeight;
    float: none;
    border-top: solid 1px $border;
    border-left: solid 1px $border;
    box-shadow: 0px 0px 2px 0 fade-out($black, 0.9);

    .gt-icon {
      line-height: 20px;
    }
  }

  .gt-icon-filter {
    margin-left: -5px;
  }

  &:hover {
    &.is-collapsed {
      right: 0px;
    }
  }
}

.giving-filters--toggle-label {
  margin-left: 8px;
  vertical-align: middle;
}

.giving-module--filters-panel {
  @include position(absolute, null 0px 0px -1px); // -1 hides border
  border-top: solid 1px $border;
  border-left: solid 1px $border;
  background: $white;
  height: $givingFiltersHeight;
}

.giving-module--filters-control {
  @include position(absolute, null 0px 0px 0px);
  height: $givingFiltersHeight;

  &.is-collapsed {
    left: 100%;
  }
}

.giving-module--filters-controls {
  padding: 4px;
  padding-left: 150px;
}
