body,
html {
  height: 100%;
  color: $baseFontColor;
  background: $background;
  position: relative;
  font-family: $baseFontFamily;
  font-weight: normal;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// STANDARD TYPOGRAPHY
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25em;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: $baseFontSize * 2;
}

h3 {
  font-size: $baseFontSize * 1.75;
}

h4 {
  font-size: $baseFontSize * 1.5;
}

h5 {
  font-size: $baseFontSize * 1.25;
}

h6 {
  font-size: $baseFontSize;
}

p {
  margin: 0 0 $baseLineHeight;
}

strong,
.emphasis {
  font-weight: $bold;
}

main:focus {
  outline: none;
}

// TABLE ELEMENTS
table {
  border-collapse: separate;
  margin: ($baseLineHeight / 2) 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid $border;
  font-weight: $bold;
  padding: ($baseLineHeight / 2) 0;
  text-align: left;
}

td {
  border-bottom: 1px solid $border;
  padding: ($baseLineHeight / 2) 0;
}

tr,
td,
th {
  vertical-align: middle;
}

// LISTS
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

dl {
  line-height: $baseLineHeight;
  margin-bottom: $baseLineHeight / 2;

  dt {
    color: $gray500;
    font-weight: normal;
    margin-top: $baseLineHeight / 2;
  }

  dd {
    margin: 0;
  }
}

// MISC ELEMENTS
hr {
  border: none;
  border-bottom: 1px solid $border;
  margin: $baseLineHeight 0;
}

img {
  margin: 0;
  max-width: 100%;
}

// MISC ClASSES
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix {
  @include clearfix();
}

.clear {
  clear: both;
  display: block;
}

.formatted-string {
  white-space: pre-line;
}

.block {
  display: block;
}

.text-block {
  padding-bottom: 10px;
}

.bold-text {
  font-weight: bold;
}

.padded-sixteen {
  padding: 16px;
}

.padding-complete {
  padding-top: 5px;
}

.inline {
  display: inline-block;
}

.flex {
  display: flex;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.horizontal-divider {
  margin: 8px 0 15px 0;
}

.vertical-line {
  height: 100%;
  border-right: 1px solid $blue300;
}

.round-button {
  padding: 11px !important;
  border: solid 1px #00a0cc !important;
}

.staging-data-tag {
  @include position(fixed, null null 10px 10px);
  background: $yellow150;
  color: $white;
  padding: 3px 10px;
  border-radius: 3px;
  width: 185px;
  z-index: 999;
  font-weight: $bold;
  text-align: center;
}

.et-avatar {
  background: url('../images/et_avatar_user_2.png');
  background-repeat: no-repeat;
  border-radius: 50%;
  object-fit: cover;
}

// Browser Nag Notification
#outdated .btn-update-browser {
  width: 250px;
}

.status-page {
  text-align: center;
  font-size: 1.5em;
  padding-top: 100px;

  img {
    margin-bottom: 20px;
  }
}

// Google autocomplete container
.pac-container {
  z-index: $googAutocompleteZindex;
}

.et--advanced-combobox {
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}

#et--components--dropdown-layer-node {
  z-index: 1000;
  position: relative;
}

:focus {
  outline: $purple300 solid;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none !important;
}

.et--page-header {
  line-height: 6px;
  white-space: nowrap;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.settings-back {
  color: #939393;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: darken(#939393, 30%);
  }
}

.red-button {
  background-color: $red500;
  color: white;
  border: none;
}
