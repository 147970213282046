.events-unmatched-controller {
  height: 100%;
}

.events-unmatched-controller--unmatched-table {
  height: 100%;
  position: relative;
}

.events-unmatched-controller--footer {
  text-align: center;
  padding-top: 10px;
  border-bottom: 1px solid $border;
  margin-bottom: 40px;
}

.events-unmatched-controller--pagination {
  margin-left: 20px;
  margin-bottom: 20px;
}

.events-unmatched-controller--unmatched-table {
  @include position(absolute, 0px 16px 0px 16px);
  border-left: solid 1px $border;

  tr,
  th {
    height: 51px;
    padding-left: 40px;
  }

  .fixed-table--body .table--body tr td {
    text-align: left;
    padding-left: 10px;
    height: 92px;
  }

  .fixed-table--horizontal {
    span {
      font-weight: normal;
      margin-left: 14px;
    }
  }

  .disabled {
    opacity: 0.6;
  }
}
