.invite {
  top: 80px;
  padding: 30px;

  .module--body {
    padding: 0;
  }
}

.invite--block-error {
  padding: 15px;
  background-color: #fcedec;
  div::first-letter {
    text-transform: uppercase;
  }
}

.invite--block-success {
  padding: 15px;
  background-color: #a4d073;
  margin-bottom: 10px;
  position: relative;
}

.invite-block-success-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: $gray900;
}

.invite--table {
  margin: 15px;
  border: 1px solid #d2d2d2;

  thead tr th {
    background-color: #f3f6f7;
    color: #6a90a1;
    font-weight: 500;
    padding-left: 10px;
  }

  td {
    padding: 7px 7px 15px;
    border-bottom: 0;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;

    input {
      padding: 10px;
      border-radius: 2px;
      border: 1px solid #d2dde0;
      font-size: 14px;
      width: 100%;
      height: 43px;

      &::placeholder {
        color: $gray900;
      }

      &:disabled {
        opacity: 0.7;
      }

      &.error,
      &.error:hover,
      &.error:focus {
        border: 1px solid red;
      }
    }

    .inline-error {
      font-size: 12px;
      color: red;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 10px;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .dropdown--body {
      min-width: auto;
    }
  }

  td.error {
    padding-bottom: 20px;
    .dropdown {
      border: 1px solid red;
    }
  }
}

.invite--add {
  display: block;
  padding: 10px 5px 20px;
  cursor: pointer;
}

.invite--submit {
  margin: 15px 0 15px 15px;
  min-width: 160px;
}

.invite--clear {
  min-width: 160px;
}
