$poolCardWidth: 336px;
$poolCardHeight: 196px;
$poolCardHeaderHeight: 60px;

.pool-card {
  width: $poolCardWidth;
  height: $poolCardHeight;
}

.pool-card--header {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
  height: $poolCardHeaderHeight;
  align-items: flex-start;
}

.pool-card--title {
  display: block;
  font-size: 18px;
  margin-right: 8px;
}

.pool-card--preview {
  padding: 0 16px;
  height: 60px;
}

.pool-card--user-empty {
  padding: 12px 0;
  margin-right: 8px;

  a {
    letter-spacing: 1px;
    font-size: $baseFontSize;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.pool-card--analytics {
  padding: 16px 0;
  border-top: 1px solid $border;
}

// icons are missized on fortAwesome so this is a HACK
.pool-card--popover {
  .gt-icon-reset-assignments {
    font-size: 27px;
    margin-left: -5px;
    margin-right: 8px;
  }

  .gt-icon-reset-stages {
    font-size: 27px;
    margin-left: -5px;
    margin-right: 8px;
  }
}

.pool-card--chat-icon {
  .action-menu-item--label {
    padding: 0;
  }
}
