.global-autocomplete-results {
   padding: 24px 32px;
}

.global-autocomplete-results--details {
   padding-left: 16px;
   padding-bottom: 24px;
}

.global-autocomplete-results--module {
   background: $white;
   border: solid 1px $border;
   border-radius: 3px;
   margin-bottom: 32px;

   &:last-child {
      margin-bottom: 0px;
   }
}

.global-autocomplete-results--empty {
   background: $white;
   border: solid 1px $border;
   padding: 16px;
}

.global-autocomplete-results--browse-link {
   margin-top: 8px;
   font-size: 16px;
}

.global-autocomplete-results--module-header {
   padding: 16px;
}

.global-autocomplete-results--module-label {
   display: inline-block;
   width: 50%;
   font-size: 20px;
}

.global-autocomplete-results--meta {
   width: 50%;
   display: inline-block;
   text-align: right;

   a {
      margin-left: 16px;
      display: inline-block;
   }
}

.global-autocomplete-results--module-body {
   .note-feed {
      padding: 16px 0;
      position: relative;
   }
}
