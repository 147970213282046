.tv-cta-button {
  background-color: #424242;
  color: #ffffff;
  border-radius: 2px;

  &:hover {
    background-color: #f2f2f2;
    color: #424242;
  }
}

.tv-cta-button--margin {
  width: auto;
}

.tv-dropdown-content {
  position: absolute;
  right: 0px;
  bottom: 60px !important;
  z-index: 1000;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  display: block;
  top: unset !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.tv-dropdown-content a {
  color: black;
  width: 150px;
  padding: 8px 2px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 1px #f4f4f4 solid;

  &:hover {
    background-color: #f1f1f1;
  }
}

.tv-dropdown-content a:last-child {
  border-bottom: none;
}

.thankview-recorder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
  width: 100%;
}

.video-container {
  max-width: 640px;
  // margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: black;
  height: 480px;
  position: relative;
  color: #fff;

  &:fullscreen,
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: none;
  }

  /* Customizing the video player itself nested within video-container */
  video {
    width: 100%;
    height: 100%;
  }

  /* Styling the control bar nested within video-container */
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3367f2;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    line-height: normal;

    /* Icon button styling nested within controls */
    button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 18px;
    }

    /* Styling the progress bar nested within controls */
    input[type='range'] {
      flex-grow: 1;
    }

    /* Time display styling nested within controls */
    .time-display {
      min-width: 75px;
      text-align: center;
      font-weight: 800;
      font-size: 14px;
    }
  }
}

.controls,
.play-pause-btn {
  transition: opacity 0.3s ease;
}

.controls.hidden,
.play-pause-btn.hidden {
  opacity: 0;
  pointer-events: none;
}

.controls.visible,
.play-pause-btn.visible {
  opacity: 1;
  pointer-events: auto;
}

.signal-video-script--container {
  background-color: rgba(0, 0, 0, 0.67);
  position: absolute;
  top: 148px;
  z-index: 99;
  resize: both;
}
.signal-video-script,
.signal-video-script:focus {
  background-color: transparent !important;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  border: none !important;
  width: 638px;
  height: 355px;
  resize: both;
  max-height: 360px;
  max-width: 640px;
}

.time-track {
  -webkit-appearance: none;
  appearance: none;
  width: 80%;
  height: 8px;
  background: var(--track-color, #ddd);
  outline: none;
  cursor: pointer;
}

.time-track::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--thumb-color, #fff);
  border-radius: 50%;
  margin-top: -6px;
}

.time-track::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--thumb-color, #fff);
  border-radius: 50%;
  position: relative;
  top: 50%;
  margin-top: -6px;
  transform: translateY(-50%);
}

.time-track::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 4px;
}

.time-track {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: var(--track-color, #ddd);
  outline: none;
  border-radius: 4px;
}

/* Styles for the thumb in WebKit browsers (Chrome, Safari, etc.) */
.time-track::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
}

/* Styles for the thumb in Firefox */
.time-track::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
  transform: none;
  border: none;
}
/* Styles for the thumb in IE and Edge */
.time-track::-ms-thumb {
  width: 20px;
  height: 20px;
  background: var(--thumb-color, #fff);
  border-radius: 50%;
  cursor: pointer;
}
// /* Adjusting caption position */
// video::cue {
//   position: relative;
//   top: 50px;
//   background: rgba(0, 0, 0, 0.5); /* Optional: Adds background to make text more readable */
//   color: white; /* Ensures captions are white */
//   font-size: 16px; /* Adjust size as needed for visibility */
//   padding: 2px; /* Padding around text */
// }

.tv-tooltip {
  position: absolute;
  bottom: 37px;
  background-color: white;
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.tv-tooltip-container:hover .tv-tooltip,
.tv-tooltip-container:focus-within .tv-tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.6s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #fff;
  background-color: #3367f2;
  border: none;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}

.large-play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #3367f2;
  border: none;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}

.video-container:hover .large-play-pause-btn {
  display: block;
  /* Show the button when the user hovers over the video container */
}

.video-default-border {
  border: 2px solid black;
  background-color: black;
  border-radius: 5px;
  width: 640px;
  height: 480px;
}

.default-settings-font {
  font-size: 14px;
  .et--card--main {
    text-align: center;
  }
}

.default-setting-subheader-font {
  font-weight: bold;
}

// .default-settings-container{
//   display: flex;
// }

.default-settings-container {
  display: flex;
  align-items: center;
}

.default-settings-container span {
  flex: 1;
  /* Allow the text to grow to fill available space */
}

.switch-container {
  margin-left: 10px;
  /* Adjust margin as needed */
}

.settings-text {
  text-align: center;
}

.tv-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
}

.tv-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tv-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tv-slider:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  top: 1px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .tv-slider {
  background-color: #a54ab5;
}

input:focus + .tv-slider {
  box-shadow: 0 0 1px #a54ab5;
}

input:checked + .tv-slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.tv-slider.round {
  border-radius: 34px;
}

.tv-slider.round:before {
  border-radius: 50%;
}

.tv-input-box {
  width: 250px;

  > * {
    margin-bottom: 16px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.video-library-parent-container {
  width: 100%;
  padding: 10px 30px;

  .tv-icon-logo {
    color: #666;
  }
}

.video-library--control-tools {
  padding: 16px 0;
}

.settings-icon {
  font-size: 1.5em;
  color: grey;
}

.video-library--top-row {
  display: flex;
  justify-content: space-between;

  .video-count {
    display: flex;
    align-items: center;
  }
}

.video-library--bottom-row {
  display: flex;
  align-items: center;
  margin-top: 16px;

  p {
    margin: auto 8px;
    font-weight: bold;
  }

  .order-button {
    color: $gray900;
    background-color: white;
    border: none;
    transition: transform 300ms ease-in-out;
    margin-left: 8px;
    padding: 8px;

    &.is-asc {
      transform: rotate(180deg);
    }
    &:hover {
      background-color: gray !important;
    }
  }
}

.video-library--entry {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.tv-video-thumbnail-container {
  position: relative;

  .tv-video--thumbnail {
    flex-shrink: 0; /* Prevent the image from shrinking */
    width: auto; /* Maintain aspect ratio */
    height: 125px; /* Fixed height for the image */
    margin-right: 20px; /* Space between the image and the text */
  }

  .tv-video--empty {
    background-color: #939393;
    color: #000;
    min-width: 166px;

    .tv-video--empty-text {
      text-align: center;
      line-height: 125px;
    }
  }

  .thumbnail-play-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: $lightPurple;
    // &:hover {
    //   opacity: 1;
    //   transform: scale(1.0375);
    //   visibility: visible !important;
    //   cursor: pointer;
    // }
  }

  .thumbnail-video-length {
    pointer-events: none;
    position: absolute;
    bottom: 10%;
    right: 15%;
    font-size: 12px;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #fff;
    width: 45px;
    text-align: center;
  }
}

.tv-video--preview-modal {
  margin: auto;
}

.tv-video--info {
  flex-grow: 2; /* Allows the text section to take up the remaining space */
  padding: 16px;
  margin-top: auto;
  margin-bottom: auto;

  .tv-video--name {
    font-size: 16px;
    font-weight: bold;
  }

  .tv-video--metrics {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.tv-video--action {
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-between;
  .tv-video-action--icon {
    font-size: 30px;
    margin: 4px;
    background-color: unset;
    border: none;
  }
}

.countdown {
  position: absolute;
  z-index: 10000; // try to ensure countdown is on top
  padding: 0;
  border: none;
  background: rgba(39, 109, 250, 0.33) url('../images/countdown-overlay_b.png') no-repeat;
  background-size: 100%;
  animation: fadeInAnimation ease 0.2s;

  .fade-long {
    animation: fadeInAnimation ease 0.5s;
  }

  span.count {
    font-size: 20em;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 50px;
    margin-top: -25px;
  }

  span.fa-spinner {
    font-size: 10em;
    opacity: 0.7;
  }
}

.tv-recorder-buttons {
  padding: 1.6em;
  display: flex;
  bottom: 0;
  position: absolute;
  z-index: 6;
  width: 640px;
  justify-content: space-between;
  > :only-child {
    margin-left: auto;
  }
}

.tv-recorder-top-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  top: 100px;
  flex-direction: row;
  right: 0;
}

.overlay-instructions {
  z-index: 7;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.67);
  text-shadow: 1px 1px 1px #000;
  height: 100%;
  width: 100%;
  top: 32px;
  left: 16px;
  right: 16px;
  bottom: 72px;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border: none;
  overflow: auto;
}

.tv-dropdown-item-icon {
  margin-right: 8px;
}

.play-pause svg {
  fill: white;
  height: 37px !important;
  width: 37px !important;
  margin-left: 5px;
  margin-top: 5px;
}

.file-uploader-tv {
  height: 100px;
  margin-bottom: 10px;
}

.recorder-top-buttons {
  width: 640px;
  position: absolute;
  top: 80px;
  z-index: 6;
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
  color: white;
  gap: 10px;
  margin-right: 20px;
}

.device-selector {
  position: relative;
  display: inline-block;
}

.device-selector-trigger {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 35px;
  height: 35px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.device-selector-trigger span {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.device-selector-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.device-selector-dropdown li {
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}

.device-selector-dropdown li:hover,
.device-selector-dropdown li:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.device-selector-dropdown li.selected {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
  outline: 0;
}

.recorder-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: transparent;
}

.countdown-number {
  font-size: 14em;
  color: $purple;
  background-size: 100%;
  border: none;
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.resolution-dropdown {
  position: relative;
  display: inline-block;
}

.resolution-icon {
  cursor: pointer;
}

.resolution-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.resolution-dropdown-menu li {
  padding: 0.25rem 1.5rem;
  cursor: pointer;
}

.resolution-dropdown-menu li:hover {
  background-color: #f8f9fa;
}

.resolution-dropdown-menu li.active {
  background-color: #007bff;
  color: white;
}

.center-modal {
  text-align: -webkit-center;
}

.timestamp {
  background: #28292c;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px 4px 28px;
  margin-right: auto;
  border-radius: 2px;
  align-self: flex-start;
  position: relative;
}

.timestamp::before {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #c8321a;
  position: absolute;
  left: 8px;
  top: 7px;
}

.top-left-buttons {
  left: 0;
  margin-left: 20px;
  position: absolute;
}

.top-right-buttons {
  margin-top: -5px;
  display: contents;
}

.upload-container {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #f2f7ff;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;

  &:not(:has(.preview-container)) {
    border: 2px dashed $purple;
  }

  &:focus:not(:has(.preview-container)) {
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.3);
  }

  .preview-container {
    position: relative;
    display: inline-block;
  }

  .preview-image {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
  }

  .remove-button {
    position: absolute;
    top: 0;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  .upload-icon {
    font-size: 40px;
  }

  .upload-text {
    margin: 10px 0;
    font-weight: 500;
  }

  .upload-text-underline {
    text-decoration: underline;
    color: #000000;
  }

  .supported-formats {
    font-size: 10px;
    color: #939393;
  }
}

.info-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 8px;

  .gt-icon {
    flex-shrink: 0;
    margin-right: 8px;
    font-size: 16px;
  }

  .info-text {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #666;
    flex-grow: 1;
  }
}

.preview-container {
  position: relative;
  display: inline-block;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
}

.remove-button {
  position: absolute;
  top: 10px;
  right: -5px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.remove-button:hover {
  background-color: #454545;
  color: white;
}

.accordion {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.accordion.open-left .accordion-header {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.accordion.open-left .accordion-icon {
  margin-right: 0.5rem;
  margin-left: 0;
}

.accordion-icon {
  transition: transform 0.3s ease-in-out;
}

.accordion-open .accordion-icon {
  transform: rotate(-90deg);
}

.chevron-margin-top {
  margin-top: 3px;
}

.accordion-content {
  padding: 0;
}

.accordion-icon {
  transition: transform 0.3s ease-in-out;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.mt-4 {
  margin-top: 1rem;
}

.justify-end {
  justify-content: flex-end;
}

.accordion.open-left .accordion-header .left-options-container {
  height: 572px;
  overflow-y: scroll;
}

.fixed-left {
  height: 500px;
}

.start-recording::before {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #fff;
  left: 8px;
  top: 7px;
  margin-right: 8px;
}

.stop-recording::before {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  background-color: #fff;
  margin-right: 8px;
  left: 8px;
  top: 7px;
}

.overlay-instructions-textarea {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  padding: 16px;
  resize: none;
  width: 400px;
  height: 200px;
}

.overlay-instructions-textarea::placeholder {
  color: white;
  font-style: italic;
  content: 'Click here to insert text';
}

.overlay-instructions-textarea:focus {
  outline: none;
}

.button-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-spin {
  animation: spin 2s linear infinite;
}

.thankview-spinner {
  display: inline-block;
  border-top: 2px solid #595959;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #595959;
  border-left: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;

  &.small {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.medium {
    width: 10rem;
    height: 10rem;
  }

  &.large {
    width: 3rem;
    height: 3rem;
  }
}

.processing-video-container {
  text-align: center;
  height: 400px;
  width: 500px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 20px;
    font-weight: 400;
  }
}

.spinner-tv {
  font-size: 200px;
  color: #a54ab5;
  animation: spin 2s linear infinite;
}

i.fa-spinner-tv {
  font-size: 200px;
  color: #a54ab5;
  animation: spin 2s linear infinite;
}

.cursor-pointer {
  display: flex;
  margin-right: 13px;
  justify-content: flex-end;
  margin-top: 10px;
  cursor: pointer;
}

.color-picker {
  .player-color {
    display: flex;
    align-items: center;
    flex-wrap: unset;
    justify-content: space-between;

    label {
      margin-right: 8px;
      font-weight: 500;
    }

    input[type='color'] {
      width: 50px;
      height: 32px;
    }
  }

  .track-color {
    display: flex;
    align-items: center;
    margin-top: 8px;
    flex-wrap: unset;
    justify-content: space-between;

    label {
      margin-right: 8px;
      font-weight: 500;
    }

    input[type='color'] {
      width: 50px;
      height: 32px;
    }
  }
}

.default-body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 20px;
}
.default-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.default-h1 {
  text-align: center;
  color: #333;
}
.default-accordion-header {
  color: #939393;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.default-accordion-header:hover {
  background-color: #f0f0f0;
}
.default-accordion-content {
  padding: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
}
.default-toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  flex-shrink: 0;
}
.default-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.default-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.default-slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .default-slider {
  background-color: #8e44ad;
}
input:checked + .default-slider:before {
  transform: translateX(26px);
}
.default-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}
.default-setting span {
  flex-grow: 1;
}
.default-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.default-info {
  font-size: 12px;
  color: #666;
}
.default-change-defaults {
  text-align: right;
  color: black;
  margin-top: 20px;
  font-size: 10px;
  text-decoration: underline;
}
.default-change-defaults a {
  color: black;
  text-decoration: none;
}
.default-left-options-container {
  margin-top: 10px;
}

.date-range-filter {
  position: relative;
  display: inline-block;
  width: 300px;
}

.date-range-filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -10px;
  width: 250px;
  border-radius: 30px;
}

.date-range-filter__date-display {
  font-weight: bold;
  text-align: center;
}

.date-range-filter__dropdown-icon {
  font-size: 16px;
}

.date-range-filter__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.date-range-filter__date-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.date-range-filter__date-input {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.date-range-filter__options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.date-range-filter__options-item {
  padding: 8px 12px;
  cursor: pointer;
}

.date-range-filter__options-item.active {
  background-color: #f0f0f0;
}

.font-bold {
  font-weight: bold;
}

.video-title-input {
  margin-bottom: 10px;
}

.tv-video-upload-button {
  position: absolute;
  margin-right: auto;
  right: 10%;
}

.captions-processing-banner {
  background: -webkit-linear-gradient(left, #6618a6, #ff4e56);
  color: white;
  padding: 17px 12px;
  font-weight: 300;
  position: absolute;
  width: 640px;
  z-index: 100;
  top: 121px;
  font-size: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
}

.tv-video-preview--cc-icon {
  position: absolute;
  color: white;
  top: 3px;
  right: 25px;
  font-size: 22px;
}

.tv-loading-bar-container {
  width: 70%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 15px auto;
  overflow: hidden;
}

.tv-loading-bar {
  height: 100%;
  background-color: $purple;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.trim-controls {
  position: relative;
  display: contents;
  width: 100%;
  left: -10px;

  .trim-controls-sliders {
    width: 87%;
    position: relative;
  }

  .trim-btn {
    padding: 0 7px;
    i {
      font-weight: bold;
    }
  }

  .trim-controls-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;

    .time-display {
      min-width: 0;
      padding: 0 7px;
    }
  }
}

.trim-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  pointer-events: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
}

.trim-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: auto;
  width: 12px;
  height: 27px;
  border-radius: 2px;
  background: white;
  border: 1px solid #ccc;
  cursor: ew-resize;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 16 25'%3E%3Cline x1='5' y1='6' x2='5' y2='19' stroke='%23666' stroke-width='2'/%3E%3Cline x1='11' y1='6' x2='11' y2='19' stroke='%23666' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.trim-slider.start {
  z-index: 4;
}

.trim-slider.start::-webkit-slider-runnable-track {
  background: linear-gradient(to right, white 0%, transparent 100%);
  height: 100%;
}

.trim-slider.end {
  z-index: 3;
}

.trim-slider.end::-webkit-slider-runnable-track {
  background: linear-gradient(to left, white 0%, transparent 100%);
  height: 100%;
}

.time-track {
  z-index: 1;
  position: relative;
}

.trim-controls-sliders {
  .time-track {
    background: #b7b7b7;
  }
}

.trim-highlight {
  position: absolute;
  background-color: white;
  height: 8px;
  pointer-events: none;
  z-index: 3;
  top: 50%;
  transform: translateY(-40%);
  margin-left: 2px;
}

// Firefox support
.trim-slider::-moz-range-thumb {
  width: 16px;
  height: 25px;
  border-radius: 2px;
  background: white;
  border: 1px solid #ccc;
  cursor: ew-resize;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 16 25'%3E%3Cline x1='5' y1='6' x2='5' y2='19' stroke='%23666' stroke-width='2'/%3E%3Cline x1='11' y1='6' x2='11' y2='19' stroke='%23666' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.trim-processing-container {
  display: flex;
  .loading-spinner {
    animation: pace-spinner 400ms linear;
    animation-iteration-count: infinite;
    display: block;
    z-index: 2000;
    border: solid 2px transparent;
    border-top-color: $gray500;
    border-left-color: $gray500;
    border-radius: 80px;
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
