.event-icon {
  display: inline-block;
  position: relative;
  background-color: $gray105;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  border-radius: 2px;
  border: 1px solid $border;
}

.event-icon--network-icon {
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: -4px;
  bottom: -4px;
  border-radius: 3px;
  border: 1px solid $white;
}
