.interaction-meta--name {
  color: $gray500;
  display: block;

  .text-removed-user {
    color: $blue300;
    font-style: normal;
    font-weight: normal;
  }
}

.interaction-meta--header-name {
  @extend .interaction-meta--name;
  color: $gray900;
}
