.export-data {
  margin-bottom: 30px;
}

.export-data-section-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 16px 8px;
}

.export-data-section-title {
  font-size: 16px;
  color: $gray900;
  font-weight: 500;
}

.export-data-table-header {
  background: $blue106;
  border-top: 1px solid $blue200;
  height: 42px;
  display: flex;
  align-items: center;
  * {
    @include flex-basis(25%);
    padding: 16px;
    &:last-child {
      text-align: right;
    }
  }
}
