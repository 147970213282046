.tasks--nav {
  @extend .sub-nav;
}

.tasks-toolbar-dropdown {
  width: 120px;
  margin-right: 16px;
}

.tasks-toolbar-dropdown-extended {
  width: 160px;
  margin-right: 16px;
}

.task-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #347799;
  border-radius: 10px;
  font-size: 11.5px;
  width: 27px;
  text-align: center;
  margin-left: 5px;
  font-weight: bold;
  font-family: "Roboto";
  height: 20px;
}

.task-notification--dynamic {
  width: auto;
  padding: 0 6px;
}

.tasks-contact-details-info {
  font-size: 12px;
  font-style: italic;
  font-weight:700;
  margin-left: 3px;
  line-height: 14.06px;
}
