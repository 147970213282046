$matchedCheckboxSize: 26px;
$matchActionWidth: 25%;
$matchedAvatarSize: 60px;
$matchedAvatarPadding: 15px;

// Shared styles on match-constituent and match-facebook modals
.match-modal {
  table {
    margin: 0;
  }

  td {
    padding: 0;
    border-bottom: none;
  }

  .contact-card td:first-child {
    width: $matchedAvatarSize + $matchedAvatarPadding;
  }

  .user-card--avatar,
  .contact-card--avatar {
    width: $matchedAvatarSize;
  }
}

.match-modal--intro {
  padding: 15px;
  border-bottom: 1px solid $border;
}

.match-modal--header {
  margin: 0 15px;
  padding: 15px 0;
  border-bottom: 1px solid $border;

  .checkbox--box {
    margin-right: 26px;
    cursor: default;
  }
}

.match-modal--body {
  padding: 15px 15px 0;
  background: $background;
  position: relative;
  height: 350px;
  overflow-y: scroll;
}

.match-result-list {
  min-height: 60px;
  text-align: left;
  border: solid 1px $border;
  border-bottom: none;
  border-radius: 2px;
  background: $white;
  margin-bottom: 15px;

  li {
    padding: 15px;
    border-bottom: solid 1px $border;
  }
}

.match-result--profile {
  @include vertical_align();
  @include calc(width, "100% - #{$matchActionWidth}");
}

.match-search-box {
  margin-top: 10px;
  display: block;
}

.match-constituent--nav {
  padding: 10px 0 0;
  position: relative;
  z-index: 1;

  .nav-item {
    width: 50%;
    text-align: center;
    margin-right: 0;
    padding-bottom: 10px;

    a,
    button {
      padding-bottom: 13px;
    }
  }

  &::before {
    @include triangle(18px, $border, down);
    @include position(absolute, null null -10px 50%);
    content: "";
    display: block;
  }

  &::after {
    @include triangle(18px, $white, down);
    @include position(absolute, null null -9px 50%);
    content: "";
    display: block;
  }
}

.match-result--checkbox {
  @include vertical_align();
  width: $matchActionWidth;
  text-align: right;

  .checkbox--box {
    float: right;
    margin-right: 10px;
    // Move to special custom checkbox place?
    border-radius: $matchedCheckboxSize / 2;
    width: $matchedCheckboxSize;
    height: $matchedCheckboxSize;
  }

  input.checkbox--input:checked + .checkbox--box {
    background: $purple500;
    border-color: $purple500 !important;

    &::before {
      color: $white;
      font-size: $fontSizeLarge;
      font-weight: 100;
      top: 1px;
      left: -1px;
      width: $matchedCheckboxSize;
      height: $matchedCheckboxSize;
    }
  }
}

.match-result:hover {
  cursor: pointer;
}

.match-check-box--hover .checkbox--box,
.match-result:hover .checkbox--box {
  border-color: $purple500 !important;

  &::before {
    @include position(absolute, 1px 0px 0px -1px);
    content: "\f050";
    display: block;
    font-family: "Givingtree";
    color: $purple500;
    font-size: $fontSizeLarge;
    font-weight: 100;
    width: $matchedCheckboxSize;
    height: $matchedCheckboxSize;
  }
}

.match-constituent--contact-card {
  @include calc(width, "100% - #{$matchActionWidth}");
  margin: 0;

  td {
    vertical-align: top;
  }
}

.match-constituent--suggestion-text {
  font-size: $fontSizeSmall;
  text-align: center;
  margin: 5px 0 15px;
  line-height: 1.5;
}
// end shared styles

// Matching constituent to social profile
.match-constituent--modal {
  .match-modal--header .match-result--checkbox {
    display: table-cell;
    vertical-align: middle;
  }
}

.match-url-validation {
  color: $red500;
  text-align: center;
  padding: 0 45px;
}

.match-result--empty-profile {
  width: 100%;
}

.match-result--avatar {
  @include vertical_align();
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 3px;
}

.match-result--name {
  @include vertical_align();
}

.match-by-id--searchbox {
  position: relative;
  padding-bottom: 25px;

  input {
    padding: 18px 100px 18px 32px;
  }

  .search-box--search-icon {
    top: 21px;
  }

  .search-box--clear {
    top: 19px;
    right: 100px;
  }
}

.match-by-id--submit {
  color: $linkText;
  position: absolute;
  right: 7px;
  top: 8px;

  &.is-disabled,
  &.is-disabled:focus {
    color: $gray150;
  }
}

.match-constituent--help-page {
  min-height: 320px;
  background-color: $background;
  padding: 20px;

  .help-text {
    font-size: $fontSizeLarge;
    margin-bottom: 15px;
  }

  img {
    padding: 10px 0 15px;
  }
}

// Matching facebook profile to constituent
.match-facebook--modal {
  .match-modal--header {
    border-bottom: none;

    .match-constituent--contact-card {
      @include vertical_align();
    }
  }
}

.match-facebook--modal-loading {
  min-height: 400px;
}

// Matching User to Remote ID User
.match-user--modal {
  .user-card--avatar {
    margin-right: $matchedAvatarPadding;
  }

  .user-card--details {
    @include calc(width, "100% - #{$matchedAvatarPadding} - #{$matchedAvatarSize}");
  }

  .match-modal--header {
    border-bottom: none;
  }

  .match-modal--body {
    border-top: 1px solid $border;
    padding: 15px;
    height: 115px;
    overflow-y: visible;
  }
}

.match-user--body {
  padding: 16px;
}

input.match-user--input {
  width: 40%;
  display: inline-block;
  vertical-align: middle;

  &.is-empty {
    width: 100%;
    color: $gray500;
  }
}

.match-modal--user-header {
  font-weight: bold;
  color: $gray500;
}

.match-user--contact-match-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.match-user--dropdown-wrapper {
  padding-top: 10px;
  width: 60%;
  display: inline-block;
  vertical-align: middle;
}

.match-user--or {
  width: 45px;
  padding: 16px;
  vertical-align: middle;
  display: inline-block;
  color: $gray500;
}

.match-user--dropdown {
  @include calc(width, "100% - 45px");
  background: $white;
  float: none;
  vertical-align: middle;
  display: inline-block;
}

.match-user--result {
  padding: 5px 0;
}

.match-user--create {
  color: $gray900;
}

.unlink-user--text {
  padding: 20px;
}
