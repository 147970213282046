.update-due-date-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.date-picker-section {
  width: 50%;
}

.section-label {
  font-weight: bold;
  margin-left: 20px;
}

.due-date-info {
  width: 45%;
  text-align: left;
}

.current-date h4,
.updated-date h4 {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
}

.updated-date-disabled {
  color: $gray500;
  font-style: italic;
}

.custom-calendar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.custom-calendar .DayPicker-Day--today {
  background-color: #4a90e2 !important;
  color: white !important;
  border-radius: 50%;
}

.custom-calendar .DayPicker-Day--outside {
  background-color: white !important;
}

.task-complete-label {
  font-weight: bold;
  font-size: 15px;
  display: block;
  margin-bottom: 15px;
}

.checkbox-small {
  @include checkbox-style {
    background: #a54ab5;

    &::before {
      display: flex;
      align-items: center;
      color: #ffffff;
      width: 18px;
      height: 18px;
      font-size: 15px;
      font-weight: bold;
    }
  }

  input.et--checkbox--input:checked + .et--checkbox--box.is-partial::before {
    content: '\2013';
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    width: 100%;
    height: 100%;
    font-weight: bold;
  }
}

.task-reassign-label {
  font-weight: bold;
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}

.bulk-tasks-ressign--message {
  background-color: $yellow108;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
