.date-time-picker--wrapping-div {
  padding-top: 10px;
  position: absolute;
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $purple500;
    &:hover {
      background-color: $purple500;
    }
  }
  .DayPicker-Day {
    border-radius: 4px;
  }
  .DayPicker-Caption {
    padding: 0px;
    .gt-icon-chevron-left,
    .gt-icon-chevron-right {
      font-size: 20px;
      color: $border;
      display: inline;
      margin: 0px;
    }
  }
  .DayPicker-Caption-text {
    font-size: 16px;
    color: $linkText;
    display: inline;
  }
  .DayPicker-Month {
    margin-top: 0px;
  }
  .DayPicker-NavBar {
    position: absolute;
  }
}
