$commentDateWidth: 145px;
$commentImageWidth: 45px;
$commentPadding: 15px;
$commentActionIconWidth: 34px;

// ---------------------------------------
// Comments List
// ---------------------------------------
.comments:not(:empty) {
  padding: $commentPadding;
  position: relative;

  .vertical-line {
    @include calc(left, "#{$commentDateWidth} + #{$commentPadding} + #{$commentImageWidth}/2");
    position: absolute;
    height: 100%;
    top: 0;
  }
}

.comments--more {
  display: block;
  background: $gray105;
  padding: 10px;
  color: $gray300;
  text-align: center;
  border-top: solid 1px $border;
}

// ---------------------------------------
// Comment List Item
// ---------------------------------------
.comment {
  @include clearfix;
  @include outer-container;
  padding: 10px 0;
}

.comment--date {
  @include calc(width, "#{$commentDateWidth} - 15px");
  float: left;
  text-align: right;
  line-height: 1.2;
  margin: 15px 15px 0 0;

  .comment--date-time {
    font-size: $fontSizeSmall;
  }
}

.comment--image {
  float: left;
  position: relative;
  width: $commentImageWidth;
  margin-top: 3px;

  img {
    width: $commentImageWidth;
    border-radius: $commentImageWidth / 2;
  }
}

.comment--content {
  @include omega();
  @include calc(width, "100% - #{$commentDateWidth} - #{$commentImageWidth} - 20px");
  margin-left: 20px;
  padding: $commentPadding;
  background: $background;
  float: left;
  border: 1px solid $border;
  border-radius: 3px;
  position: relative;

  &-type {
    font-weight: bold;
    color: $gray500;
    margin-right: 5px;
  }

  &-title {
    margin-left: 5px;
  }

  &-description {
    font-size: $fontSizeSmall;
    font-style: italic;
    line-height: 1.3;
    margin: 5px 0;
  }

  &::before {
    @include triangle(24px, $border, left);
    @include position(absolute, 16px null null -12px);
    content: "";
    display: block;
  }

  &::after {
    @include triangle(24px, $background, left);
    @include position(absolute, 16px null null -11px);
    content: "";
    display: block;
  }
}

.comment--name {
  color: $gray500;
  font-weight: bold;
  margin-bottom: 10px;
}

.comment--actions {
  @include position(absolute, 0px 0px null null);

  .dropdown--toggle {
    padding: 15px 20px;
    color: $gray500;
    font-size: 0.8em;

    &:hover {
      color: $purple500;
    }
  }

  .dropdown--body {
    right: 0px;
    left: inherit;
    width: 120px;
    min-height: 0;
    margin-top: -5px;
  }
}

.comment-actions--item {
  display: block;

  i {
    padding-left: 10px;
    width: $commentActionIconWidth;
  }

  span {
    @include calc(width, "100% - #{$commentActionIconWidth}");
    display: inline-block;
    padding: 10px 10px 10px 0;
    border-bottom: solid 1px $border;
  }

  &:last-child span {
    border-bottom: none;
  }
}

.comment--meta {
  font-size: 12px;
  color: $gray500;
  text-align: right;

  a {
    display: inline-block;
    margin: 3px 0 0 8px;
  }
}

.comment--text {
  border: solid 1px $border;
  border-radius: 3px;
  padding: 5px 10px;
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: $white;
}

.comment--mention {
  background: none;
  font-weight: bold;

  // Firefox hack
  &[type="button"] {
    border: none;
    display: inline;
    color: $gray900;
    background: $gray150;
    padding: 0;
    line-height: 1em;
  }
}

.comment--ref {
  @extend .text-info;

  // Firefox hack
  &[type="button"] {
    border: none;
    display: inline;
    background: none;
    padding: 0;
    line-height: 1em;
  }
}

.comment-category--icon {
  @include position(absolute, 0 null null 0);
  font-size: 1.1em;
  color: $white;

  i {
    font-size: 2em;
    width: $commentImageWidth;
    height: $commentImageWidth;
    border-radius: $commentImageWidth / 2;
    background-color: $gray150;

    &::before {
      position: relative;
      top: 6px;
    }
  }
}

// ---------------------------------------
// Comment Dropdown Autocomplete Styles
// ---------------------------------------
.atwho-view {
  max-height: 300px;
  overflow: auto;

  .cur {
    background: $gray105;
    color: $gray900;

    strong {
      color: $purple500;
    }
  }

  strong {
    color: $gray900;
  }

  ul li {
    max-width: 450px;
    min-width: 300px;
    padding: 0;

    table {
      margin: 0;

      tr td {
        border-bottom: none;
      }
    }

    .contact-card--avatar {
      width: 40px;
    }

    tr td:first-child {
      width: 60px;
      text-align: center;
    }
  }
}

// ---------------------------------------
// Comment Filter
// ---------------------------------------
.comments--filter {
  padding: 15px;
  border-bottom: 1px solid $border;
}

.comments--filter-dropdown {
  width: 40%;
}
