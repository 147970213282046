.admin-page {
   .module--header {
      button {
         margin: 1px 0 1px 5px;
      }
   }
}

.admin-page--header {
   .main-sub-header {
      height: 75px;
      background: $white;
      padding: 12px 32px;
   }

   h3 {
      font-size: 22px;
      padding-top: 2px;
   }

   .badge-warning {
      margin-left: 8px;
      vertical-align: text-top;
   }
}

.admin-page--body {
   @include position(absolute, 115px 0px 0px 0px);
   overflow: auto;
}

.admin-title {
   margin-bottom: 20px;
}

.admin-table {
   .table--header .ellipsis-overflow {
      overflow: visible;
      white-space: normal;
   }

   .dropdown {
      float: none;
   }

   .dropdown--header a {

      &.is-open,
      &:hover {
         background: transparent;
      }
   }

   .menu a {
      font-weight: normal;
      color: $linkMainColor;
   }
}

.admin-table--pagination {
   margin-top: 15px;
}

input.admin-table--search {
   width: 40%;
   margin-bottom: 0;
}

input.admin-table--feature-filter {
   width: 20%;
   margin-bottom: 0;
}

.admin-page--header-title {
   font-weight: lighter;
}