.profile-notes {
  width: 100%;

  .profile-notes--feed {
    background: transparent;
  }

  .profile-card {
    padding-top: 0;
  }

  .profile-card--inner {
    border: none;
    background: transparent;
  }

  .contact-profile--body {
    margin: 0;
    padding: 20px;
  }
}
.contact-profile--notes {
  position: relative;
  padding: 0 !important;
}

.profile-notes--empty {
  color: $gray500;
  text-align: center;
  padding: 0px 16px 16px;
}

.profile-notes--filter-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.profile-notes--filter-header-controls {
  display: flex;
  justify-content: space-around;
}

.profile-notes--filter-header-controls a {
  margin: 0 15px;
}

.profile-notes--filter-header-count {
  color: $gray500;
}

.profile-notes--category {
  margin-left: 8px;
}

.profile-notes--pagination {
  padding: 16px;
  text-align: center;
  font-size: 16px;
  background-color: $white;
  border: 1px solid $border;
}

.profile-notes--creator {
  padding: 16px;

  &.is-empty {
    border-bottom: none;
  }
}

.profile-notes--interaction-create-header {
  &.profile-card-header {
    margin-bottom: 0;
  }
  .profile-card-header--content {
    padding-top: 10px;
  }
}

.profile-notes--feed {
  min-height: 72vh;
  background: #fafafa;

  .profile-card-header {
    margin-left: 60px;
    margin-bottom: 0px;
    padding: 16px;
    background: $white;
    border: solid 1px $border;
    border-bottom: none;
  }

  .note-feed--list {
    background: transparent;

    .interaction-list-item:last-child {
      border-bottom: 1px solid $border;
    }
  }
}

.profile-recent-notes {
  width: 100%;

  .profile-notes--feed {
    position: relative;
    background: $white;
  }

  .profile-notes--pagination {
    border-top: 1px solid $border;
  }
}

.note-textarea {
  padding: 16px;
  position: relative; // Need this so that if input box is disabled it only disables this area (and doesnt go further up the dom)
}

.note-textarea--avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 16px;
}

.note-textarea--input-wrapper {
  @include calc(width, "100% - 56px");
  display: inline-block;
  vertical-align: top;
  border: solid 1px $border;
  border-radius: 2px;
}

.note-textarea--input {
  @include transition(all 0.15s ease-in);
  min-height: 68px;
  margin-bottom: 0;
  background: $gray105;
  padding: 16px;
  padding-bottom: 0;
  white-space: pre-wrap;

  &:empty::before {
    color: $gray500;
    content: attr(placeholder);
    line-height: 1.1em;
    display: block;
  }

  &.is-active {
    box-shadow: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .comment--mention {
    background: none;
    font-weight: bold;
  }
}

.note-textarea--mentions {
  padding: 0 16px 16px 0;
  text-align: right;
  background: $gray105;
}

.note-textarea--action {
  display: inline-block;
  color: $gray500;
}

.note-textarea--mentionlink {
  font-size: 16px;
  margin-right: 16px;
  vertical-align: middle;
}

.note-textarea--form-actions {
  padding: 16px 0;
  text-align: right;

  .btn,
  .btn-secondary {
    padding: 16px;
    margin-left: 16px;
  }
}

.note-feed--year {
  font-size: 14px;
  font-weight: 500;
  margin: 4px 4px;
  color: $gray500;
  padding-top: 8px;
}

.note-feed-item--header {
  margin-bottom: 4px;
}

.note-feed-item--image {
  width: 56px;
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
  position: relative;

  img {
    border-radius: 40px;
    width: 40px;
    height: 40px;
  }
}

.note-feed-item--body {
  margin-top: 12px;
  position: relative;

  &:not(.is-gt-comment) {
    border: solid 1px $border;
    border-radius: 2px;
    padding: 12px;
  }

  &.is-gt-comment {
    border: solid 1px $border;
    background: $gray105;
    padding: 16px;

    .comment--mention {
      background: none;
      font-weight: bold;
    }
  }
}

.note-feed-item--summary {
  font-weight: bold;
  margin-bottom: 8px;
  color: $gray900;
}

.note-feed-item--content {
  @include calc(width, "100% - 56px");
  display: inline-block;
  background: $white;
  border: solid 1px $border;
  border-radius: 2px;
  margin-bottom: 16px;
  padding: 16px;

  &.is-snippet {
    cursor: pointer;

    &:hover {
      background: $gray105;
    }
  }
}

.note-feed-item--text {
  white-space: pre-wrap;
}

.note-feed-item--menu {
  @include position(absolute, 8px 8px null null);
  color: $gray500;
}

.note-feed-item--menu-icon {
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: $purple500;
  }
}

.note-feed-item--menu-inner {
  width: 100px;
  border: solid 1px $border;
  border-radius: 2px;
  padding: 4px 0;

  > li {
    padding: 8px;
    cursor: pointer;
    color: $linkText;

    i {
      font-size: 18px;
      margin-right: 8px;
    }

    &:hover {
      color: $purple500;
    }
  }
}

.note-feed-item--icon {
  color: $gray500;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 8px;
  font-size: 16px;
  margin-right: 16px;
  border: solid 2px $gray500;
  background: $white;
}

.note-modal {
  padding: 16px 16px 0 16px;

  .note-textarea--avatar {
    display: none;
  }

  .note-textarea--input-wrapper {
    width: 100%;
  }
}
