.loading-container {
  position: relative;
}

.loading-overlay {
  @include position(absolute, 0px 0px 0px 0px);
  background: fade-out($white, 0.5);
  z-index: 50;
}

.loader {
  @include animation(pace-spinner 400ms linear);
  @include animation-iteration-count(infinite);
  display: block;
  z-index: 2000;
  border: solid 2px transparent;
  border-top-color: #f7941e;
  border-left-color: #f7941e;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  margin: 10px;

  &.is-absolute-centered {
    @include position(absolute, 50% -15px -15px 50%);
    margin: 0px;
  }

  &.is-top-centered {
    @include position(relative, 30px null null null);
    margin: 0 auto;
  }

  &.is-centered {
    margin: 10px auto;
  }
}

.loader-small {
  vertical-align: middle;
  display: inline-block;
  width: 15px;
  height: 15px;
}

.loader-icon {
  position: relative;
}

// UI V2 Loader
.loading {
  @extend .loading-overlay;
  background: fade-out($white, 0.2);
}

.loading-no-overlay{
  background: none;
}

.loading-modal {
  height: 400px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading--wrapper {
  width: 100px;
  height: 100px;
  text-align: center;

  &.is-top {
    @include position(absolute, 20px null null 50%);
    margin: 0 0 0 -50px;
  }

  &.is-bottom {
    @include position(absolute, null null 50px 50%);
    margin: 0 0 0 -50px;
  }

  &.is-center {
    @include position(absolute, 50% null null 50%);
    margin: -50px 0 0 -50px;
  }
}

.loading--wrapper-small {
  width: 40px;
  height: 40px;

  &.is-top {
    @include position(absolute, 8px null null 50%);
    margin: 0 0 0 -20px;
  }

  &.is-bottom {
    @include position(absolute, null null 0px 50%);
    margin: 0 0 0 -20px;
  }

  &.is-center {
    @include position(absolute, 50% null 50%);
    margin: -20px 0 0 -20px;
  }
}

.loading--spinner {
  @extend .loader;
  border: solid 4px transparent;
  border-top-color: $gray500;
  border-left-color: $gray500;
  border-radius: 80px;
  width: 80px;
  height: 80px;
}

.loading--spinner-small {
  width: 32px;
  height: 32px;
  margin: 4px;
}

.loading--text {
  margin-left: -150px;
  margin-right: -150px;
  font-size: 1.6em;
  line-height: 1.4;
  color: $gray500;
  margin-top: 15px;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
