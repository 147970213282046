.ag-reporting-header {
  background-color: $white;
}

.ag-reporting-header--filters {
  height: 80px;
  border-bottom: 1px solid $border;
}

.ag-reporting-header--title {
  display: inline-block;
  line-height: 1;
  font-size: 20px;
  margin-top: 16px;
  margin-left: 24px;
}

.ag-reporting-header--dropdown {
  width: auto;
  display: inline-block;
  margin-left: 24px;
  margin-top: 4px;
}

.ag-reporting-header--test-tag {
  @include vertical_align;
  background-color: $white;
  border: 1px solid transparent;
  padding: 5px 8px;
  color: $white;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 12px;
  line-height: 1;

  &.is-live {
    color: $green200;

    &:hover {
      border-color: mix($white, $green200);
      background-color: $gray105;
    }
  }

  &.is-test {
    background-color: mix($white, $red300, 0.75);
    color: $white;

    &:hover {
      background-color: $red300;
    }
  }
}

.ag-pages-dropdown--caret {
  margin-left: 12px;
}

.ag-reporting-header--extra-controls {
  float: right;
  vertical-align: top;
  margin-top: 24px;
  margin-right: 16px;
}

.ag-reporting-header--date-dropdown {
  // @extend .advanced-combobox-title;
  @include vertical_align;
  width: auto;
  // popover-trigger ag-reporting-header--date-dropdown date-picker-dropdown advanced-combobox is-open

  .date-picker--header {
    font-size: 14px;
    line-height: 1;
    padding: 6px;
    box-shadow: none;
    background: $white;
  }

  &:hover,
  &.is-open,
  &.is-open:hover {
    .date-picker--header {
      background-color: $white;
      color: $purple500;
    }
  }

  .fa-angle-down {
    position: relative;
    top: auto;
    right: auto;
    color: $gray500;
    width: 18px;
  }
}

.ag-reporting-header--clear-date {
  display: inline-block;
  padding: 4px 8px;
  margin-top: 16px;
  margin-left: 16px;
}
