$poolContactsHeader: 109px;

.pool-contact-list--header {
  border-radius: 12px;
  border-bottom: solid 1px $border;
  height: $poolContactsHeader;
  color: $gray500;
}

.pool-contact-list--header-title {
  padding: 8px 16px;
  font-size: 16px;
}

.pool-contact-list--header-actions {
  border-top: solid 1px $border;
  padding: 16px;
}

.pool-contact-list--header-search {
  @include calc(width, "100% - 48px");
  display: inline-block;
  vertical-align: middle;
}

.pool-contact-list--header-controls {
  display: inline-block;
  vertical-align: middle;
}

.pool-contact-list--action-label {
  margin-left: 8px;
  vertical-align: text-top;
}

.pool-contact-list--add {
  width: auto;
  padding: 8px;
  height: 40px;
  width: 40px;

  i {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 0;
  }

  .gt-icon-chevron-down {
    margin: 0px;
    display: none;
  }
}

.pool-contact--list-content {
  height: calc(100vh - 450px);
  overflow: auto;
}
