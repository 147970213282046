.ag-pages-dropdown {
  .checkbox {
    width: 100%;
  }

  .dropdown--toggle {
    @include vertical_align;
    line-height: 1;
    background: $white;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 0 4px;
    font-size: 20px;
    box-shadow: none;
    height: 26px;

    &:hover,
    &.is-open {
      background: $background;
      border-color: $border;
    }

    .fa-angle-down {
      position: relative;
      top: auto;
      right: auto;
      color: $gray500;
      width: 18px;
    }
  }

  .ag-pages-dropdown--selected-text {
    font-size: 14px;
    width: auto;
    display: inline-block;
  }

  .advanced-combobox--item {
    padding-right: 4px;
  }

  .dropdown--body {
    width: 420px;
    border-top: 1px solid $border;

    &.is-open {
      @include calc(top, "100% - 1px");
    }
  }
}

.ag-pages-dropdown--item {
  @include vertical_align;
  @include calc(width, "100% - 25px");
  display: inline-block;
  // padding-top: 4px;
  // padding-bottom: 4px;
}

.ag-pages-dropdown--icon {
  @include vertical_align;
  background-position: no-repeat center center;
  background-size: cover;
  width: 42px;
  height: 35px;
  max-height: 40px;
  margin: 0 10px;
  border-radius: 2px;
  background-color: $gray105;
}

.ag-pages-dropdown--label {
  @include vertical_align;
  @include calc(width, "100% - 65px");
  font-weight: normal;
}

.ag-pages-dropdown--label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ag-pages-dropdown--tag {
  @include vertical_align;
  // float: right;
  // border: 1px solid transparent;
  padding: 2px 6px;
  margin: 4px 0 4px 4px;
  line-height: 1;
  font-size: 12px;
  border-radius: 2px;
  color: $white;

  &.is-archived {
    background-color: $gray500;
  }

  &.is-default {
    background-color: $blue200;
  }
}

.ag-pages-dropdown--controls {
  padding: 8px;
  border-top: 1px solid $border;
}

.ag-pages-dropdown--info {
  float: left;
  margin-top: 12px;
}

.ag-pages-dropdown--options {
  float: right;
}

.ag-pages-dropdown--option {
  padding: 4px;
}
