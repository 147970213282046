.add-volunteers-modal {
  padding: 16px 20px;
}

.add-volunteers-modal--label {
  margin: 24px 0 4px 0;
  font-weight: 400;

  &:first-child {
    margin-top: 8px;
  }
}

.add-volunteers-modal--textbox {
  margin: 16px 0;
}