// Assign Contact Modal
.assign-contact--modal table {
  margin: 0;
}

.assign-contact--header {
  padding: 0 20px;
  border-bottom: 1px solid $border;
  z-index: 1;
  position: relative;

  &::before {
    @include triangle(20px, $border, down);
    @include position(absolute, null null -11px 50%);
    content: "";
    display: block;
  }

  &::after {
    @include triangle(20px, $white, down);
    @include position(absolute, null null -10px 50%);
    content: "";
    display: block;
  }

  .contact-card td {
    border-bottom: none;
    padding: 0;

    &:first-child {
      width: 64px;
      padding-left: 4px;
    }

    &:last-child {
      padding-left: 22px;
    }
  }

  .contact-card--avatar {
    float: left;
  }
}

.assign-contact--body {
  padding: 24px;
  background: $background;
  position: relative;
}

.assign-contact--table {
  table-layout: auto;

  td,
  th {
    border-bottom: none;
  }

  th {
    padding: 0;
    font-weight: normal;
    color: $gray500;
  }

  td {
    padding: 8px 12px 8px 0;

    &:last-child {
      padding: 8px 0;
    }
  }

  .assign-contact--last-dd {
    padding-right: 8px;
  }
}

.assign-contact--row {
  width: 100%;
  border-bottom: 1px solid $border;
}

.assign-contact---remove-row {
  font-size: 20px;
  width: auto;
  color: $gray150;

  &:hover {
    color: $red300;
  }
}

.assign-contact--cell {
  max-width: 160px;

  .dropdown--body {
    min-width: 250px;
  }

  .advanced-combobox--list {
    max-height: 250px;
  }
}

.assign-contact--cell-shrink {
  white-space: nowrap;
  width: 1%;
}

.assign-contact--new-row {
  padding-top: 15px;

  &.disabled a {
    color: $gray150;
    cursor: not-allowed;
  }
}

.assign-contact--add-icon {
  font-size: 20px;
  margin-right: 8px;
}

// RM Assignment contact modal
.rm-assign-contact--name {
  color: $linkText;
}

.rm-assign-contact--key-icon {
  color: $linkText;
  padding-right: 6px;
}

.rm-assign-contact--person-icon {
  color: $gray500;
  font-size: 16px;
  padding-right: 4px;
}

.rm-assign-contact--modal {
  .et--modal-confirm--body {
    overflow-y: inherit;
    overflow-x: inherit;
  }
}

.rm-assign-contact--modal .et--flex-table--cell.rm-assign-contact--remove {
  justify-content: center;
}

.rm-assign-contact--modal .et--flex-table--header-cell {
  background: $background;
}

.rm-assign-contact--modal thead {
  width: 100%;
  flex: 1;
  display: flex;
}

.rm-assign-contact--modal .et--flex-table--wrapper {
  display: inline;
  overflow: auto;
}

.rm-assign-contact--modal .et--flex-table--body {
  display: inline;
}

.rm-assign-contact--remove {
  flex: 100px;
}

.rm-assign-contact--title {
  text-transform: uppercase;
  font-size: 12px;
}

.rm-assign-contact--body {
  padding: 24px 16px 80px;
  background: $background;
  position: relative;
}

.rm-assign-contact--table td {
  background: $white;
}

.rm-assign-contact--table tbody > tr {
  border-left: 1px solid $border;
  border-right: 1px solid $border;
  min-height: 50px;
}

.rm-assign-contact--no-assignments {
  font-style: italic;
  text-align: center;
  color: $gray150;
}

.rm-assign-contact--new-row {
  padding: 16px;
  margin-top: 16px;
  display: block;
  background: $gray105;
  border: 1px solid $border;
}
