.calendar-clock--wrapper-closed {
  width: 100%;
  height: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.calendar-clock--content-closed {
  width: 100%;
  height: 100%;
  border: 1px solid $linkText;
  position: relative;
}
.calendar-clock--content-button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.calendar-clock--selected-time {
  width: 70%;
  height: 100%;
  padding: 4px;
  float: left;
}
.calendar-clock--dropdown-button {
  height: 100%;
  float: right;
  color: $linkText;
}
.calendar-clock--wrapper {
  width: 100%;
  padding: 8px;
  height: 160px;
}
.calendar-clock--content {
  width: 100%;
  height: 100%;
  border: 1px solid $linkText;
}
.calendar-clock--list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.clock-list-item {
  font-size: 14px;
  height: 28px;
  padding-left: 4px;
}
.clock-list-button {
  width: 100%;
  height: 100%;

  &:hover {
    background-color: $purple500;
  }
}
.selected-time {
  background-color: $blue300;
}
