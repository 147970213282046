.number-range {
  display: flex;
  vertical-align: middle;

  .number-range--input {
    display: inline-block;
    vertical-align: middle;
  }
}

.number-range--separator {
  @include calc(width, "6% - 10px");
  text-align: center;
  display: inline-block;
  border-bottom: solid 2px $border;
  height: 1px;
  vertical-align: middle;
  margin: 0 7px;
  color: $white;
}

.search-box {
  position: relative;
  display: block;

  input {
    @include appearance(none);
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 10px 11px 10px 38px;
    margin-bottom: 0;

    @include placeholder {
      color: $gray500;
    }

    &:disabled {
      background: $white;
    }

    &::-ms-clear {
      display: none;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  button {
    margin: 2px 0px 0px -2px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    i {
      margin-bottom: 2px;
    }
  }
}

.search-box--search-icon {
  @include position(absolute, 13px 0 0 12px);
  color: $gray200;
  font-weight: bold;
}

.search-box--invalid {
  border: 1px solid $red300;
}

.search-box--clear {
  @include position(absolute, 10px 7px null null);
  cursor: pointer;
  color: $gray200;
  font-size: 20px;

  &:hover {
    color: $purple500;
  }
}

.search-box--link {
  @include position(absolute, 20px 15px 0 0);
  color: $linkMainColor !important;
}

.search-box--wrapper {
  position: relative;
}

// Date Range Fields
.date-range {
  margin-top: 5px;
}

.date-range--label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

input.date-range--input {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  margin-bottom: 0;
}

.search-box--et-components-shim {
  input {
    padding-left: 36px;
  }
}
