$profileHeaderAvatar: 60px;
$profileHeaderPadding: 16px 32px 0px 0;
$profileHeaderHeight: 114px;

.profile-header {
  padding: $profileHeaderPadding;
  background: $white;
  border-bottom: none;

  .btn {
    margin-right: 8px;
  }
}

.profile-header--cadence {
  background: #f2fafc;
  color: #007c9e;
  border-radius: 12px;
  font-weight: 500;
  padding: 0 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0 0 0 4px;
    font-size: 12px;
    font-weight: 600;
  }
}

.profile-header--avatar {
  width: $profileHeaderAvatar;
  height: $profileHeaderAvatar;
  border-radius: $profileHeaderAvatar;
  margin-right: 16px;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .loading {
    background: none;
    top: 4px;
  }

  .loading--spinner {
    width: $profileHeaderAvatar + 5px;
    height: $profileHeaderAvatar + 5px;
    border-radius: $profileHeaderAvatar + 5px;
    margin: 6px;
  }

  .et-avatar-delete {
    display: none;
  }

  &:hover {
    .et-avatar-delete {
      cursor: pointer;
      display: block;
      position: absolute;
      right: -10px;
      bottom: 0px;
      color: $gray500;
    }
  }
}

.profile-header--user {
  @extend .ellipsis-overflow;
  max-width: 70%;
  padding: 4px 0;
  font-size: $baseFontSize;
  line-height: 22px;
  display: inline-block;
  vertical-align: middle;

  h3 {
    font-weight: bold;
    font-size: 1.4em;
    display: inline-block;
    margin-top: -5px;
  }

  a {
    font-family: $baseFontFamily;
  }
}

.profile-header--name-wrapper {
  display: inline-block;
}

.profile-header--name {
  font-weight: bold;
  font-size: 1.4em;
  display: inline-block;
  margin-top: -5px;

  &.is-deceased {
    color: $red500;
  }
}

.profile-header--data-icon {
  margin: 0 8px 0 -2px;
  font-size: $baseFontSize;
}

.profile-header--deceased {
  font-size: 12px;
  vertical-align: text-bottom;
  margin-left: 12px;
}

.profile-header--back {
  display: block;
  align-self: center;
}

.profile-header--buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.profile-header--buttons > button:nth-child(n + 2) {
  margin-left: 16px;
}

//buttons css onClick/focus
.round-button-focused {
  padding: 11px !important;
  color: #a54ab5;
  border: solid 1px #a54ab5;
  pointer-events: none;
}

.round-task-button-focused {
  padding: 11px !important;
  color: #a54ab5;
  border: solid 1px #a54ab5;
}

.profile-header--contact {
  .bio-info--section {
    padding: 16px 20px;
  }
}

.profile-nav {
  @extend .sub-nav;
}

.profile-detail-header {
  height: 80px;
  padding: $profileHeaderPadding;
  background: $white;
  border-bottom: solid 1px $border;
  z-index: 300;

  .profile-header--back {
    top: 26px;
  }

  .btn {
    margin-right: 8px;
  }
}

.profile-detail-header--avatar {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  width: 42px;
  border-radius: 42px;
  margin-right: 16px;
}

.profile-detail-header--user {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 22px;
  width: 60%;

  .gt-icon-double-chevron-right {
    vertical-align: text-top;
  }
}
