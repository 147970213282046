.ask-amount-modal {
  padding: 16px 44px;
  text-align: center;

  .new-contact-card {
    text-align: left;
    border-bottom: 1px solid $border;
    margin-bottom: 24px;
    width: auto;
  }

  // disable spinner for num inputs
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.ask-amount-modal--assigned-to {
  color: $gray500;
}

.ask-amount-modal--joint-avatars {
  display: inline-block;
  position: relative;
}

.ask-amount-modal--primary-avatar {
  margin-bottom: -26px;
  margin-left: 26px;
}
