.facebook-unmatched-engagers-controller {
  .facebook-header-wrapper {
    width: auto;
    position: fixed;
    top: 65px;
    background: none;
    left: $layoutNavWidth + 1;
  }
  .facebook-unmatched-engagers-content {
    position: absolute;
    top: 160px;
    left: 0px;
    right: 0px;
  }

  .facebook-pages-table {
    top: 0px;
  }
}

.nav-is-collapsed {
  .facebook-unmatched-engagers-controller {
    .facebook-header-wrapper {
      left: 0;
    }
  }
}

.facebook-unmatched-engagers-filters {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
  position: relative;
  top: 80px;
  background-color: $background;
  .et--button-toggle {
    min-width: 130px;
  }
}
