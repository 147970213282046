$filterDrawerHeight: 75px;
$filterDrawerTransformSpeed: 0.2s;
$filterToggleWidth: 142px;

.filter-drawer--wrapper {
  position: relative;
  height: $filterDrawerHeight;
  z-index: 200;

  &.is-docked {
    bottom: 0;
    height: auto;

    .filter-drawer--drawer {
      @include position(absolute, $filterDrawerHeight 0px 0px 0px);
      box-shadow: none;
    }

    .filter-drawer--content {
      @include position(absolute, 0px 0px $filterDrawerHeight 0px);
      overflow: auto;
    }

    .filter-drawer--footer {
      @include position(absolute, null 0px 0px 0px);
      height: $filterDrawerHeight;
      background: $white;
      box-shadow: 0 -1px 2px $gray150;
    }
  }
}

.filter-drawer--header {
  @include outer-container;
  background: $white;
  height: $filterDrawerHeight;
  position: relative;
  z-index: 10;

  &.is-open {
    z-index: 0;
  }
}

.filter-drawer--header-content {
  height: $filterDrawerHeight;
  padding-left: $filterToggleWidth;
}

.filter-drawer--header-overlay {
  @include position(absolute, 0px 0px 0px $filterToggleWidth);
  background: fade-out($gray105, 0.4);
  z-index: 1;
}

.filter-drawer--toggle {
  width: $filterToggleWidth;
  float: left;
  height: $filterDrawerHeight - 1px; // 1px border
  line-height: $filterDrawerHeight - 8px; // 4px padding top & bottom
  text-align: center;
  color: $blue500;
  font-weight: 500;
  font-size: 1.1em;
  background: $white;

  &:hover {
    cursor: pointer;
  }

  &.is-open {
    height: $filterDrawerHeight; // overlap border
  }

  .gt-icon-filter {
    margin-right: 10px;
  }

  .gt-icon-chevron-down,
  .gt-icon-chevron-up {
    margin-left: 10px;
    color: $gray500;
  }
}

.filter-drawer--toggle-wrap {
  margin: 4px;
  &.is-disabled {
    color: $border;
    cursor: default;
    .gt-icon-chevron-down,
    .gt-icon-chevron-up {
      color: $border;
    }
  }
}

.filter-drawer--overlay {
  @include position(absolute, 0px 0px 0px 0px);
  background: fade-out($black, 0.5);
  z-index: 120;
}

.filter-drawer--drawer {
  @include transition(transform $filterDrawerTransformSpeed ease-out);
  @include transform(translateY(-100%));
  background: $white;
  border-radius: 0 0 10px 10px;
  border-bottom: solid 1px $border;
  z-index: 9;
  visibility: hidden;
  min-height: 363px;

  &.is-open {
    @include transform(translateY(0));
    box-shadow: 0px 2px 5px rgba($black, 0.08);
    visibility: visible;
  }
}

.filter-drawer--footer {
  @include clearfix;
  display: flex;
  align-items: center;
}

.filter-drawer--apply {
  float: right;
  padding: 16px 32px;
  text-align: right;
  width: 50%;
  display: inline-block;

  button {
    padding: 12px 16px;
    width: 130px;
    margin-left: 15px;

    &.is-disabled {
      background-color: $white;
      border: solid 1px $border;
      color: $gray150;
      font-weight: bold;
    }
  }
}

.filter-drawer--clear {
  width: 50%;
  display: inline-block;
  padding: 16px 32px;

  a {
    color: $red500;
  }

  i {
    vertical-align: text-bottom;
    font-size: 18px;
  }
}
