.google-search {
   width: 400px;
   padding: 16px 16px 0 16px;
   border: solid 1px $border;
   box-shadow: 0 0 1px $border;
}

.google-search--suggestion {
   display: block;
   padding-bottom: 16px;
}

.google-search--icon {
   display: inline-block;
   width: 16px;
   height: 16px;
   text-align: left;
   margin: 2px 16px 0 0;
   font-size: 17px;
   background: url($googleIconSprite);
   background-repeat: no-repeat;
   background-size: 32px;
}

.google-search--label {
   @include calc(width, "100% - 32px");
   display: inline-block;
   vertical-align: top;
}
