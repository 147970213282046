.range-dropdown {
  width: 240px;
  position: relative;

  .fa-angle-down {
    @include position(absolute, 15px 15px null null);
  }

  &.is-open {
    .range-dropdown--header {
      background: $gray110;

      &:hover {
        background: $gray110;
      }
    }
  }
}

.range-dropdown--header {
  padding: 10px 20px;
  border-radius: 20px;
  color: $gray500;
  border: solid 1px $gray150;

  &:hover {
    cursor: pointer;
    background: $gray108;
  }
}

.range-dropdown--wrap {
  width: 400px;
  padding: 16px;
  background: $white;
  border: 1px solid $border;
  box-shadow: 0 1px 2px $gray150;
}
