.profile-giving-controller {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile-giving-controller--title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-giving-controller--recurring-gift {
  color: $green300;
  font-size: 12px;
  margin-left: 18px;
}

.profile-giving-controller--recurring-text {
  margin-left: 4px;
}

.profile-giving-controller--category-dropdown {
  width: "100%";
  font-weight: 400;
  font-size: 14px;
}
