// Neat - Grid Options
$layoutHeaderHeight: 60px;
$layoutBannerHeight: 60px;
$layoutLeftBorder: $gray150;
$layoutHeaderBackground: $white;

@mixin layout-nav-animation($position) {
  @include transition(transform 0.2s ease-out);

  &.nav-is-collapsed {
    @include transform(translateX(-($layoutNavWidth - $layoutNavWidthCollapsed)));

    &.nav-is-done-animating {
      right: -($layoutNavWidth - $layoutNavWidthCollapsed);
    }
  }
}

#main-container {
  border-top: solid $layoutHeaderHeight $layoutHeaderBackground;
}

#layout-outer {
  @include position(absolute, 0px 0px 0px 0px);
}

#layout-container {
  @include position(absolute, 0px 0px 0px 0px);

  &.is-showing-banner,
  &.is-impersonating {
    top: $layoutBannerHeight;
  }

  &.is-showing-banner.is-impersonating {
    top: $layoutBannerHeight * 2;
  }
}

#layout-impersonation-banner {
  @include position(absolute, 0px 0px null 0px);
}

#layout-banner {
  @include position(absolute, 0px 0px null 0px);

  &.is-impersonating {
    top: $layoutBannerHeight;
  }
}

#layout-nav {
  @include position(absolute, 0px null 0px 0px);
  @include backface-visibility(hidden);
  @include perspective(1000);
  width: $layoutNavWidth;
  background: $white;
  overflow: hidden;
}

#layout-content {
  @include position(absolute, 0px 0px 0px $layoutNavWidth);
  @include layout-nav-animation($layoutNavWidthCollapsed);
  @include backface-visibility(hidden);
  background: $background;
  border-left: 1px solid $layoutLeftBorder;

  &.nav-is-collapsed {
    .layout-page--loader {
      left: -$layoutNavWidthCollapsed;
    }
  }
}

#layout-outer {
  button.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
  }
  button.skip-main:focus,
  button.skip-main:active {
    color: white;
    left: auto;
    top: auto;
    width: 200px;
    height: auto;
    overflow: auto;
    font-size: 1.2em;
    z-index: 999;
  }
}

#layout-header {
  @include elevation4();
  border-width: 0 0 1px 0;
  height: $layoutHeaderHeight;
  background: $layoutHeaderBackground;
}

.beamerTrigger {
  // class applied by focus-visible shim in main.coffee
  &.focus-visible {
    @include pink-focus();
  }
}

#layout-page {
  @include position(absolute, $layoutHeaderHeight 0px 0px 0px);
  overflow: auto;

  .layout-page--loader {
    left: -$layoutNavWidth;
  }
}

#layout-overlay {
  z-index: 1001;
}

.fixed-page-wrapper {
  @include position(absolute, 0px 0px 0px 0px);
}

.page-container {
  @include clearfix;
  padding: $pageContainerPadding;
}

.meta-controller--tooltip {
  @include position(absolute, 0px 0 0 0px);
}
