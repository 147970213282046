.tabbed-modal {
  position: relative;

  .modal-confirm--header {
    border-bottom: none;
  }
}

.tabbed-modal--nav {
  @extend .sub-nav;
  padding: 0 !important;

  li.nav-item {
    margin-right: 0px;
    text-align: center;
    padding: 0;

    > a,
    button {
      display: inline-block;
      width: 100%;
    }
  }
}

.tabbed-modal--content {
  padding: 16px;
}
