.filters-label {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.filters-label--prefix,
.filters-label--divider {
  display: inline-block;
  text-transform: uppercase;
  color: $gray500;
  font-size: 0.6em;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 0 4px;
}

.filters-label--prefix {
  padding-left: 0;
}
