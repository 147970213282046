.outreach-settings-header {
  padding-bottom: 25px;
  padding-left: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

.call-settings-custom-number-header {
  margin-bottom: 7px;
  padding-left: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
}

.call-settings-custom-number-label {
  padding-left: 0px;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.41px !important;
  display: flex;
}

.call-settings-custom-number-input {
  width: 125px !important;
  height: 36px !important;
  position: absolute;
  right: 225px;
}

.call-settings-custom-number-verify {
  padding: 8px 0 0 20px;

}

.call-settings-verify-button {
  width: fit-content;
  height: fit-content;
}

.call-settings-custom-extension-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.41px !important;
  display: flex;
  min-width: 100px;
}
.call-settings-custom-extension-input {
  width: 88px !important;
  height: 36px !important;
}

.custom-number-container{
  display: flex;
}
