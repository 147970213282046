.advanced-combobox {
  width: 100%;
  color: $gray900;

  .dropdown--header {
    position: relative;

    .fa,
    .gt-icon-chevron-down {
      @include position(absolute, 15px 15px null null);
    }

    .icon-left {
      @include position(absolute, 18px null null null);
      font-size: 22px;
    }

    &.is-disabled {
      .dropdown--toggle {
        background: $gray110;
        border: solid 1px $gray150;
        box-shadow: none;
        cursor: default;
        color: $gray500;
      }
    }
  }

  .dropdown--toggle {
    @include transition(background);
    background: $white;
    border-radius: 50px;
    color: $baseFontColor;
    border: 1px solid $gray150;
    padding: 8px 16px;

    &:hover {
      background: $gray105;
    }

    &:focus {
      background: $purple105;
      border: solid 1px $purple300;
    }
  }

  .dropdown--toggle-focus {
    border: solid 1px $purple300;
  }

  .dropdown--body {
    width: auto;
    min-width: 250px;
    min-height: 0;
    left: 0;
    right: 0;
    margin-top: 2px;

    .advanced-combobox-item--icon {
      @include position(absolute, 50% 2px null null);
    }
  }

  &.is-opened-up {
    .dropdown--body {
      bottom: 103%;
      top: inherit;
    }
  }
}

.advanced-combobox-secondary {
  a.dropdown--toggle {
    background: $white;
    border: solid 1px $gray150;

    &.is-open {
      background: $gray105;

      &:hover {
        background: $gray105;
      }
    }

    &:hover:not(.is-open) {
      background: fade-out($gray105, 0.5);
    }
  }
}

.advanced-combobox-title {
  .dropdown--header {
    display: inline-block;

    &:hover {
      background: none;
    }
  }

  a.dropdown--toggle {
    background: none;
    box-shadow: none;
    border: none;
    display: inline-block;
    font-size: 20px;
    width: auto;
    padding: 0px;

    &.is-open {
      background: none;
      color: $purple500;

      &:hover {
        background: none;
        color: $purple500;
      }
    }

    &:hover:not(.is-open) {
      background: none;
      color: $purple500;

      .fa {
        color: $purple500;
      }
    }

    .fa {
      @include position(relative, 0px null null 0px);
      margin-left: 4px;
      color: $gray500;
    }
  }

  .advanced-combobox--selected-text {
    width: auto;
    padding: 0;
  }
}

.advanced-combobox--selected-text {
  display: inline-block;
  padding-right: 40px;
  width: 100%;
}

.advanced-combobox--search {
  display: block;
  padding: 16px;
  border-bottom: solid 1px $border;

  .search-box {
    display: block;
    width: 100%;
  }
}

.advanced-combobox--selection {
  border-top: solid 1px $border;
  box-shadow: 0 -1px 0 0 fade-out($gray105, 0.7);
  padding: 7px 16px;

  > a {
    padding: 3px;
    display: inline-block;
  }
}

.advanced-combobox--list {
  max-height: 600px;
  overflow-y: auto;
  padding: 8px;
  text-align: left;

  &.is-grouped {
    padding: 0px;
  }
}

.advanced-combobox--group {
  border-top: solid 1px $border;
  padding: 8px;

  &:first-child {
    border-top: none;
  }
}

.advanced-combobox--group-label {
  padding-left: 8px;
  display: block;
  margin-bottom: 4px;
}

.advanced-combobox--item {
  @extend .clearfix;
  @include transition(background, 0.1s ease-in);
  padding: 4px 30px;
  color: $gray900;
  line-height: 23px;
  position: relative;
  cursor: pointer;

  .text-highlight {
    background: $green106;
  }

  .checkbox {
    float: left;
    margin-bottom: 0px;
  }

  &.is-selected,
  &:focus,
  &:hover {
    background: $gray105;
    color: $purple500;
    display: block;

    .advanced-combobox-item--icon {
      color: $purple500;
    }
  }

  &.is-disabled {
    color: $gray500;

    &:hover {
      color: $gray500;
    }
  }

  &.is-multiple {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.is-with-icon {
    padding-right: 28px;
  }

  &.is-left-icon {
    padding-right: 0px;
    padding-left: 25px;
    .checkbox-small {
      float: right;
    }
  }
}

.advanced-combobox-item--selected,
.advanced-combobox-item--selected--icon-left {
  color: $purple500;
  line-height: 23px;
  font-weight: bold;
  font-size: 13px;
  width: 26px;
}

.advanced-combobox-item--selected {
  @include position(absolute, null null null 0px);
}

.advanced-combobox-item--selected--icon-left {
  @include position(absolute, null 0px null null);
}

.advanced-combobox-item--icon,
.advanced-combobox-item--icon--icon-left {
  line-height: 1.5em;
  margin-right: 10px;
  margin-top: -10px;
}

.advanced-combobox-item--icon--icon-left {
  @include position(absolute, 38% null null -2px);
  font-size: 20px;
  + span {
    margin-left: 5px;
  }
}

.advanced-combobox-item--label {
  word-break: break-word;
  vertical-align: middle;
}

.advanced-combobox--confirm {
  width: 100%;
  padding: 10px;
  border-top: solid 1px $border;

  .btn-secondary {
    @include calc(width, "50% - 5px");
    margin-right: 10px;
  }

  .btn {
    @include calc(width, "50% - 5px");
  }
}
