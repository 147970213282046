.fs-exclude-data {
  transition: filter 0.2s, background 0.2s, color 0.2s, background-color 0.2s;
}

body.is-redacted-fs {
  .fs-exclude-data {
    &,
    &:hover {
      color: transparent !important;
      background-color: transparent !important;
      box-shadow: inset 0 0 0 1px $border;

      background: repeating-linear-gradient(45deg, #fff, #fff 10px, $gray105 10px, $gray105 20px) !important ;

      * {
        // background-color: #fff !important;
        color: transparent !important;
      }
    }
  }

  a.fs-exclude-data,
  .fs-exclude-data a {
    &,
    &:hover {
      box-shadow: inset 0 0 0 1px #def;
      background: repeating-linear-gradient(45deg, #fff, #fff 10px, #def 10px, #def 20px) !important ;
    }
  }

  img.fs-exclude-data,
  .fs-exclude-data img {
    filter: contrast(0) brightness(180%);
  }

  svg.fs-exclude-data,
  .fs-exclude-data svg {
    display: none;
  }
}

// CIA Black Marker Style

body.is-redacted-cia {
  .fs-exclude-data {
    &,
    &:hover {
      color: #000 !important;
      background: #000 !important;
      box-shadow: 0 0 1px #000;

      * {
        color: transparent !important;
      }

      &::selection,
      & *::selection {
        background-color: #000 !important;
        color: transparent !important;
      }
    }
  }

  input.fs-exclude-data,
  .fs-exclude-data input {
    background-color: #000 !important;
  }

  img.fs-exclude-data,
  .fs-exclude-data img {
    filter: contrast(0) brightness(0%);
    box-shadow: 0 0 1px #000;
  }

  svg.fs-exclude-data,
  .fs-exclude-data svg {
    display: none;
  }
}

// Blur Style

body.is-redacted-blur {
  .fs-exclude-data {
    &,
    &:hover {
      filter: blur(6px);
    }
  }

  img.fs-exclude-data,
  .fs-exclude-data img {
    &,
    &:hover {
      filter: grayscale(1) blur(6px);
    }
  }

  svg.fs-exclude-data,
  .fs-exclude-data svg {
    display: none;
  }
}
