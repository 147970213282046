.status-indicator {
  &.is-collapsed {
    width: $baseNavCollapsedWidth;
    text-align: center;
    float: right;

    &.status-indicator--dot {
      margin: 0px;
    }
  }
}

a.status-indicator--link {
  color: $baseNavText;

  &:hover {
    text-decoration: underline;
    color: $baseNavText;
  }
}

.status-indicator--dot {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 12px 0 7px;
  border-radius: 99px;

  &.critical {
    background-color: $red500;
  }

  &.major {
    background-color: $red500;
  }

  &.minor,
  &.maintenance {
    background-color: $yellow150;
  }

  &.none {
    background-color: $green200;
  }
}

.status-indicator--description {
  font-weight: $bold;
}
