.profile-nav-section--box {
  @include display(flex);
  @include flex-direction(row);
  @include flex-wrap(wrap);
}

.profile-card--wrapper {
  display: flex;

  .profile-card {
    flex: 1;
  }
}

.profile-card-header {
  padding: 16px 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray110;

  &.include-metadata {
    flex-wrap: wrap;
  }
}

.profile-card-header--icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: solid 2px $gray150;
  color: $gray150;
  margin-right: 4px;
  text-align: center;
  margin: 0 8px 0 -4px;
  vertical-align: middle;

  &::before {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;
    text-align: center;
  }

  &.gt-icon-dollar {
    font-size: 18px;
    line-height: 14px;
  }
}

.profile-card-header--title {
  font-size: 16px;
  line-height: 20px;
  color: $gray500;
  font-weight: 500;
}

.profile-card-subtitle {
  @extend .clearfix;
  padding-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $gray500;
}

.profile-card-subtitle--actions {
  float: right;
  text-transform: none;
  letter-spacing: 0;
}

.ai-tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #b7b7b7;
}

.ai-tooltip-text {
  visibility: hidden;
  width: 320px;
  background-color: white;
  color: #454545;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  position: absolute;
  bottom: 100%;
  margin-bottom: 5px;
  opacity: 0;
  z-index: 101;
  transition: opacity 0.5s ease;
}

.ai-tooltip-container:hover .ai-tooltip-text {
  visibility: visible;
  opacity: 1;
}