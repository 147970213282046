.selected-contacts-addto {
  height: 100%;

  .popover--body {
    margin-top: 12px;
    margin-left: 0;
    left: -38px;

    &::before,
    &::after {
      left: 68px;
      bottom: 100%;
    }

    &::before {
      margin-bottom: 1px;
    }
  }
}

.selected-contacts-addto--btn {
  color: $linkMainColor;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: $purple500;
  }

  &.is-disabled,
  &.is-disabled:hover {
    color: #707070;
    cursor: default;
  }

  i {
    text-align: right;
  }
}

.selected-error-modal {
  text-align: center;
  padding: 20px 60px;
  font-size: 20px;

  .et--modal-confirm--header {
    display: none;
  }

  .et--button:focus {
    background-color: #007c9e;
    border-color: #007c9e;
  }
}