.solicitor-panel {
  @include position(absolute, 0px 0px 0px 0px);
  background: $white;
  overflow: hidden;
}

.solicitor-panel--header {
  background: $white;
  height: 100px;

  .pool-solicitor-card {
    border: none;
    cursor: default;
    padding: 0px;
    margin: 0px 16px;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  .pool-solicitor-card--menu {
    display: none;
  }

  .new-contact-card--name {
    @extend .ellipsis-overflow;
  }
}

.solicitor-panel--contact-card {
  .pool-solicitor-card--card {
    padding: 0px;
  }
}

.solicitor-panel--close {
  @include position(absolute, 16px 16px null null);
  color: $gray500;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $purple500;
  }
}

.solicitor-panel--body {
  @include position(absolute, 116px 0px 0px 0px);
  overflow: auto;
  padding: 0 24px;

  dt {
    font-weight: bold;
    color: $gray900;
  }

  .profile-card-subtitle {
    margin-bottom: 16px;
    margin-top: 24px;
  }
}

.solicitor-panel--gift {
  font-size: 18px;
  line-height: 30px;
}
