.filter-display-title {
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  .string-search-display strong {
    font-weight: normal;
  }
}

.filter-display-title--operator {
  display: inline-block;
  text-transform: uppercase;
  color: $gray500;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 0 4px;
}

.filter-display-title--overflow {
  display: inline-block;
}

.filter-display-title--ruler {
  display: inline-block;
  position: absolute;
  height: 0px;
  visibility: hidden;
}
