.interaction-label-filter--name {
  @include vertical_align;

  &.is-selected {
    @extend .segment-filter-row--col;
  }
}

.interaction-label-filter--value {
  @include vertical_align;
}
