.proposal-summary--wrapper {
  padding: 16px 20px 20px;
}

.proposal-summary--amount {
  font-size: 18px;
  font-weight: 500;
}

.proposal-summary--empty {
  color: $gray500;
}

.proposal-value-summary {
  padding-top: 16px;
}
