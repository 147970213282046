.rich-tooltip {
  display: inline-block;
}

.rich-tooltip--content {
  box-shadow: 0 2px 7px 0 $gray500;
  border-radius: 3px;
}

.rich-tooltip--header {
  background: $gray900;
  border-radius: 3px 3px 0 0;
  color: $white;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
}

.rich-tooltip--close {
  @include position(absolute, 13px 16px null null);
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: fade-out($white, 0.5);
  }
}

.rich-tooltip--body {
  padding: 16px;
}
