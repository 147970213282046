$filterBarHeight: 60px;
$filterBarInnerHeight: 58px;

.filter-bar {
  height: $filterBarHeight;
  width: 100%;
  border-top: 1px solid $blue300;
  border-bottom: 1px solid $blue300;
}

.filter-bar--icon {
  float: left;
  line-height: 4;
  text-align: center;
  color: $gray500;
  padding: 0 15px;
}

.filter-bar--title {
  font-size: $fontSizeLarge;
  font-weight: bold;
  padding-left: 5px;
}

.filter-bar--filters {
  float: left;
}

.filter-bar--filter {
  float: left;
  height: $filterBarInnerHeight;
  border-left: 1px solid $blue300;
  background: $white;

  &:last-child {
    border-right: 1px solid $blue300;
  }

  .dropdown,
  .advanced-combobox {
    height: $filterBarInnerHeight;
  }

  .advanced-combobox {
    background: $white;
    border: none;
  }

  .dropdown,
  .dropdown--body {
    width: 100%;
  }

  .dropdown--body {
    left: -1px;
    right: -1px;
    width: auto;
  }

  .dropdown--header {
    height: 100%;
    line-height: 2;

    .fa-angle-down {
      top: 18px;
    }
  }

  .dropdown--toggle {
    padding: 10px;
    line-height: 1;

    &:hover {
      background: none;
    }

    &:hover:not(.is-open) {
      background: $gray105;
    }
  }
}

.filter-label {
  color: $gray500;
}

.filter-selected {
  margin-top: 10px;
  font-size: $fontSizeLarge;
}

.filter-arrow-down {
  line-height: 2;
}
