.validate-contacts-textbox {
  textarea {
    height: 140px;
  }
}

.validate-contacts-textbox--taglist {
  height: 140px;
  border: solid 1px $border;
  border-radius: 2px;
  overflow: auto;
  position: relative;
}

.validate-contacts-textbox--edit {
  @include position(absolute, null 8px 8px null);
}

.validate-contacts-textbox--error {
  background-color: fade-out($red500, 0.9);
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  overflow: auto;
}

.validate-contacts-textbox--continue {
  padding: 8px 0;
  margin-top: 8px;
}
