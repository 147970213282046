.logout--wait-container {
  font-size: 1.5em;
  color: $gray500;

  .loading {
    background: $background;
  }

  .loading--wrapper {
    width: 500px;

    &.is-center {
      margin: -50px 0 0 -250px;
    }

    .loading--spinner {
      display: inline-block;
    }
  }
}
