.simple-contacts-table {
  border-collapse: collapse;

  > tbody > tr:last-child {
    td {
      border-bottom: none;
    }
  }

  > thead > tr > th,
  > tbody > tr > td {
    border-right: solid 1px $border;
    border-top: solid 1px $border;
    padding: 16px 24px;

    &:last-child {
      border-right: none;
    }
  }
}

.simple-contacts-table--header {
  background: darken($gray105, 3%);
  color: $gray500;
}

.simple-contacts-table--body {
  .contact-fixed-table--contact-cell {
    padding-left: 18px !important;
  }

  .contact-card--avatar {
    width: 48px;
    vertical-align: middle;
  }
}
