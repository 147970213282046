$filtersHeaderHeight: 75px;
$filtersFooterHeight: 64px;
$filtersPadding: $gutter;

$filtersTransformSpeed: 0.2s;

$filterButtonSize: 130px;
$filterButtonPadding: 16px;

.segment-filters {
  @include position(absolute, 0px 0px null 0px);
  height: $filtersHeaderHeight;
  z-index: 200;

  &.is-docked {
    bottom: 0;
    height: auto;

    .segment-filters--drawer {
      @include position(absolute, $filtersHeaderHeight 0px 0px 0px);
      box-shadow: none;
    }

    .segment-filters--content {
      @include position(absolute, 0px 0px $filtersFooterHeight 0px);
      overflow: auto;
    }

    .segment-filters--footer {
      @include position(absolute, null 0px 0px 0px);
    }

    .segment-filters--btn {
      display: none;
    }
  }

  .et--advanced-combobox--selected-text,
  .et--advanced-combobox-item {
    text-transform: capitalize;
  }
}

.segment-filters--header {
  @include outer-container;
  background: $white;
  height: $filtersHeaderHeight;
  position: relative;
  z-index: 10;

  &.is-open {
    background: $gray105;
    color: $gray150;

    a {
      color: $gray150;
      cursor: default;
    }

    .selected-contacts-string {
      color: $gray150;
    }

    .filter-display-title--operator {
      color: $gray150;
    }

    .click-to-input--text {
      color: $gray150;
    }
  }
}

.segment-filters--toggle {
  width: 142px;
  float: left;
  height: $filtersHeaderHeight - 1px; // 1px border
  line-height: $filtersHeaderHeight - 8px; // 4px padding top & bottom
  text-align: center;
  color: $blue500;
  font-weight: 500;
  font-size: 1.1em;
  background: $white;

  &:active,
  &:hover,
  &:focus {
    color: $blue500;

    &:hover {
      color: $blue500;
    }
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $blue500;
    box-shadow: none;
  }

  &.is-open {
    height: $filtersHeaderHeight; // overlap border
  }

  .gt-icon-filter {
    margin-right: 10px;
  }

  .gt-icon-chevron-down,
  .gt-icon-chevron-up {
    margin-left: 10px;
    color: $gray500;
  }
}

.segment-filters--toggle-wrap {
  margin: 4px;
}

.segment-filters--label {
  @include calc(width, "100% - 400px - 142px");
  float: left;
  font-size: 20px;
  line-height: 30px;
  padding: 12px 16px;

  .icon-link {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9em;
    cursor: pointer;

    .tooltip {
      width: 125px;
      left: -50px;
      height: 35px;

      &::after {
        left: 49%;
        top: -24px;
      }
    }

    a:hover .icon-link--i {
      color: $red500;
    }
  }

  .click-to-input {
    width: 90%;
    line-height: 1.2em;
  }
}

.segment-filters--state {
  width: 400px;
  float: left;
  text-align: right;
  position: relative;
  margin-top: -5px;
  padding: 18px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 85px;

  &.is-open {
    .btn {
      border: solid 1px $border;
      background-color: transparent;
      box-shadow: none;
      color: $gray150;
      cursor: default;
    }

    .segment-filters--revert {
      border-right-color: $white;
    }
  }

  .btn-action {
    padding: $filterButtonPadding;
    margin-left: 8px;
    width: $filterButtonSize;
  }

  .fa-plus-circle {
    vertical-align: middle;
  }
}

.segment-filters--changes {
  display: inline-block;
  vertical-align: middle;
}

.reset-button-margin {
  margin-right: 15px;
}

.segment-filters--revert {
  padding-right: 6px;
  margin-right: 6px;
  margin-left: 3px;
  width: 48px;
  height: 49px;
  border-right: solid 1px darken($gray500, 8%);
  border-radius: 3px 0 0 3px;
  vertical-align: middle;

  &:hover {
    color: #007C9E;
  }
}

.segment-filters--updates {
  border-radius: 0 2px 2px 0;
  vertical-align: middle;
  padding: 16px;
  height: 49px;
}

.segment-filters--overlay {
  @include position(absolute, 0px 0px 0px 0px);
  background: fade-out($black, 0.5);
  z-index: 120;
}

.segment-filters--drawer {
  @include transition(transform $filtersTransformSpeed ease-out);
  @include transform(translateY(-100%));
  background: $white;
  border-bottom: solid 1px $border;
  display: none;

  &.is-open {
    @include transform(translateY(0));
    box-shadow: 0px 2px 3px fade-out($black, 0.7);
    display: block;
  }
}

.segment-filters--content {
  padding: 16px 20px;

  &.is-single {
    .segment-filter-row {
      width: 100%;
    }
  }

  .and-or-toggle {
    top: 10px;
    vertical-align: top;
    width: 120px;
    padding-right: 7px;
  }

  .segment-filter-row {
    @include calc(width, "100% - 120px");
    display: inline-block;
  }
}

.segment-filters--content-list-row {
  position: relative;
}

.segment-filters--content-list-row-connector {
  position: absolute;
  top: 28px;
  left: 57px;
  bottom: -28px;
  border-left: 1px solid $border;
  width: 64px;

  &.is-initial {
    border-top: 1px solid $border;
  }
}

.segment-filters--footer {
  @include outer-container;
  padding: 12px 20px;
  height: $filtersFooterHeight;
  background: $white;
}

.segment-filters--inside-actions {
  @include span-columns($grid-columns / 2);

  &.is-indented {
    padding-left: 120px;
  }

  i {
    vertical-align: text-bottom;
    font-size: 24px;
  }
}

.segment-filters--clear {
  margin-left: 24px;

  i {
    font-size: 18px;
  }
}

.segment-filters--apply {
  @include span-columns($grid-columns / 2);
  text-align: right;
}

.segment-filters--apply-button {
  margin-left: 16px;
}

.segment-filter-rows--initial {
  @include vertical_align();
  width: 120px;
  position: relative;
}

.segment-filter-row--sub-component-container {
  position: relative;
}