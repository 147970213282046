.list-detailed-selector--header {
  .modal-confirm--header {
    padding: 16px;
  }
}

.list-detailed-selector--search {
  padding: 24px 16px;

  .search-box {
    display: block;

    input[type="search"]:focus {
      border-color: $border;
    }

    .fa-search.is-active {
      color: $green300;
    }
  }
}

.list-detailed-selector--container {
  position: relative;
  max-height: 325px;
  overflow: auto;
}

.list-detailed-selector--label {
  padding-left: 16px;
  font-weight: normal;
}

.list-detailed-selector--list {
  color: $gray500;

  > li {
    position: relative;
    margin: 0 8px;
    padding: 8px;
    padding-left: 32px;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &:hover,
    &.is-selected {
      background: $gray105;
      cursor: pointer;

      a {
        color: $purple500;
      }
    }
  }
}

.list-detailed-selector--item-icon {
  @include position(absolute, 50% 16px null null);
  font-size: 1.3em;
  margin-top: -10px;
  color: $gray150;

  &.fa-lock {
    right: 20px;
    font-size: 1.5em;
  }
}

.list-detailed-selector--footer {
  padding: 12px;
  text-align: center;
  border-top: solid 1px $border;
}

.list-detailed-selector--footer-link {
  padding: 16px;
}
