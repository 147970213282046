.dropdown {
  position: relative;
  display: block;
  float: left;
}

.dropdown--header {
  @extend .ellipsis-overflow;

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 20px;

    &:hover {
      background: fade-out($white, 0.5);
    }
  }
}

.dropdown--toggle {
  &.is-open {
    background: $white;

    &:hover {
      background: $white;
    }
  }
}

.dropdown--body {
  @include position(absolute, 100% 0 0 0px);
  display: none;
  background: $white;
  @include elevation12();
  border-radius: 16px;
  width: 245px;
  min-height: 150px;
  z-index: $dropdownBodyZindex;

  &.is-open {
    display: block;
  }

  &.is-right {
    right: 0px;
    left: inherit;
  }
}

.dropdown-button {
  .dropdown--header a {
    @extend .btn-secondary;
  }

  .dropdown--body {
    width: 100%;
  }
}
