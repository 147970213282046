$etScoreProgressHeight: 12px;
$etScoreProgressLabel: 24px;

.score-progress--wrapper {
  @include calc(width, "100% - #{$etScoreProgressLabel}");
  height: $etScoreProgressHeight;
  background: $background;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
}

.score-progress--inner {
  height: $etScoreProgressHeight;
  border-radius: 2px;
}

.score-progress--label {
  width: $etScoreProgressLabel;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;

  &.is-empty {
    font-size: 11px;
    text-align: right;
  }
}

.score-progress--total {
  color: $gray500;
  font-size: 16px;
  margin: 0 4px;
}
