.copy-input--share-input {
  @include calc(width, "100% - 100px");
  display: inline-block;
  vertical-align: middle;
  padding: 11px;
  border-radius: 3px 0 0 3px;
  border: solid 1px $border;
  border-right: none;
  box-shadow: inset 0 1px 2px $gray105;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;

  &:focus {
    border: solid 1px $border;
    border-right: none;
  }
}

.copy-input--copy {
  width: 100px;
  border-radius: 0 3px 3px 0;
  font-weight: bold;
}
