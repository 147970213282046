.contact-export-button--wrapper {
   display: inline-block;
}

.contact-export-button {
   border: 1px solid $border;
   padding: 12.5px 21px;
   border-radius: 3px;
   background-color: #ffffff;
   position: relative;

   &.disabled {
      opacity: 0.5;
      cursor: default;
   }
}
