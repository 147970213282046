.task-counter-card {
  border-radius: 24px;
  display: flex;
}

.task-counter-card--details td {
  padding: 0;
  vertical-align: top;
  text-align: center;
  vertical-align: middle;
  border-bottom: 0;
}

.task-counter-card--title {
  display: flex;
  border-bottom: 0;
  align-items: center;
  justify-content: center;
}

.task-counter-home--title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #454545;
  padding-bottom: 18px;

  &.alternate {
    padding-bottom: 0px;
  }
}

.task-counter-home--subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #454545;
  text-align: center;
}

.task-counter-check-icon {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.paused-task-counter-icons--container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .icon-count-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    p {
      margin: 0px;
    }
  }

  .icon-background {
    background-color: #f9f9f9;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
