.gt-give-settings--module {
  margin-bottom: 20px;
}

.give-notification-settings--table {
  border: 1px solid $border;
  max-width: 570px;

  th {
    border-bottom: 1px solid $border;
  }
}

.give-notification-settings--table-headers {
  background-color: $gray105;
  color: $gray300;
}

.give-notification-settings--table-header {
  padding-left: 10px;
  font-weight: bold;
  text-align: center;
}

.give-notification-settings--table-cell {
  font-size: 14px;
  height: 61px;
  padding: 15px 0px;
  text-align: center;
}

.give-notification-settings--toggle-container {
  position: relative;
}

.give-notification-settings--saved {
  color: $green200;
  text-align: right;
  position: absolute;
  right: 17px;
  top: 4px;
}

.give-notification-settings--tag {
  margin: 0px 5px;
  padding: 5px 8px;
  border-radius: 2px;
  color: $white;

  &.is-default {
    background-color: $blue200;
  }

  &.is-live {
    background-color: $green200;
  }

  &.is-test {
    background-color: $red300;
  }

  &.is-archived {
    background-color: $gray105;
    color: $gray500;
  }
}
