.featured-links-container {
  max-width: 800px;
  margin: 0 auto;
}

.section-container {
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s ease;
}

.section-header:hover {
  background-color: #e9ecef;
}

.section-title {
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.section-icon {
  font-size: 20px;
  color: #666;
}

.links-list {
  margin-top: 16px;
  padding-left: 32px;
}

.list-item {
  margin: 8px 0;
  list-style-type: disc;
}
