.pool-prospects--progress {
  width: 204px;
  font-size: $baseFontSize;
  display: inline-block;
  vertical-align: middle;
  color: $gray500;
  margin-left: 8px;
}

.pool-prospects--pagination {
  display: block;
  padding: 16px;
  text-align: center;
  background: $white;
  border-top: solid 1px $border;
}

.pool-prospects--list {
  .action-menu {
    float: right;
  }

  .pool-contact-list--header-search {
    @include calc(width, "100% - 250px");
    margin-left: 16px;
  }
}

.pool-prospects--assign {
  min-width: 180px;
  .et--dropdown--toggle {
    background: transparent;
    border: solid 1px $border;
    box-shadow: none;
    padding: 9px 16px;
    height: 40px;
    color: $linkText;
  }

  .et--advanced-combobox--item {
    padding: 8px 8px;
  }

  .new-contact-card {
    padding: 0px;
    line-height: 16px;
  }

  .new-contact-card--name {
    @extend .ellipsis-overflow;

    strong {
      font-weight: normal;
    }
  }

  .new-contact-card--avatar {
    width: 30px;
  }

  .new-contact-card--class {
    font-size: 12px;
  }

  .et--dropdown--body {
    width: 300px;
  }

  &.is-disabled {
    .dropdown--toggle {
      color: $gray500;
    }
  }
}

.pool-prospects--assign-solicitor {
  background: transparent;
  color: $linkText;
  box-shadow: none;
  border: solid 1px $border;
  font-weight: normal;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: $gray105;
    color: $purple500;
    border-color: $border;
  }
}

.pool-prospects--unassign {
  @include flex-center();
  @include flex-wrap(wrap);

  i {
    margin: 0 16px;
  }

  .new-contact-card {
    display: inline-block;
    vertical-align: middle;
    width: 42%;
  }

  .new-contact-card--name {
    @extend .ellipsis-overflow;
  }

  .new-contact-card--avatar {
    margin: 0;
    width: 40px;
  }

  .new-contact-card--content {
    @include calc("width", "100% - 40px");
  }
}

.pool-prospects--unassign-header {
  @include flex-basis(100%);
}

.pool-prospects--filter {
  float: right;

  .advanced-combobox-title {
    font-size: $baseFontSize;

    a.dropdown--toggle {
      font-size: $baseFontSize;
    }
  }

  .dropdown--body {
    width: 200px;
    right: 0px;
    left: auto;
  }
}

.pool-prospects--remove-assignment-contact-info {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
