.tasks-welcome-page-card {
  background-color: $gray105;
  border-radius: 12px;
  padding: 20px;
  margin: 20px;
  text-align: left;
  width: 50%;
}

.tasks-welcome-page-icon {
  font-size: 26px;
  color: $gray300;
  position: relative;
  left: -8px;
  padding-bottom: 14px;
}

.tasks-welcome-page-header {
  font-size: 20px;
  font-weight: $bold;
  padding-bottom: 12px;
}

.tasks-welcome-page-subheader {
  padding-bottom: 11px;
}

.tasks-welcome-page-list-header {
  font-weight: $bold;
}

.tasks-welcome-page-footer {
  padding-top: 20px;
}
