.slideshow--header {
  padding: 24px;
  font-size: 22px;
  text-align: center;
}

.slideshow--content {
  height: 320px;
  font-size: 16px;
  text-align: center;
  background: $background;
  box-shadow: inset 0 1px 3px $gray150;
  overflow: hidden;
  position: relative;
}

.slideshow--slide {
  @include position(absolute, 0px 0px 0px 0px);
  padding: 26px 26px 0 26px;

  &.is-active {
    z-index: 1;
  }
}

.slideshow--body-text {
  margin-bottom: 16px;
}

.slideshow--bullets {
  text-align: left;
  list-style-type: disc;

  li {
    margin: 15px 50px 15px 80px;
  }
}

.slideshow--right,
.slideshow--left {
  @include circle(74px, fade-out($gray500, 0.5), $white);
  margin-top: -37px;
  padding-top: 16px;
  cursor: pointer;
  z-index: 2;
  font-size: 40px;

  &:hover {
    background: fade-out($gray500, 0.2);
  }
}

.slideshow--right {
  @include position(absolute, 50% -37px null null);
  padding-left: 4px;
  text-align: left;

  i {
    text-align: left;
  }
}

.slideshow--left {
  @include position(absolute, 50% null null -37px);
  padding-right: 4px;
  text-align: right;

  i {
    text-align: right;
  }
}

.slideshow--footer {
  padding: 16px 26px;
  border-top: solid 1px $border;
}

.slideshow--ignore,
.slideshow--paging {
  display: inline-block;
  vertical-align: middle;
  width: 22%;
}

.slideshow--paging {
  width: 56%;
  text-align: center;
}

.slideshow--dot {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: $gray150;
  margin: 0 6px;
  cursor: pointer;

  &.is-active {
    background: $gray500;
  }
}

.slideshow--try {
  font-weight: bold !important;
  font-size: 1.1em;
  padding: 12px;
  width: 22%;
  border-bottom: solid 2px darken($purple500, 10%);
}
