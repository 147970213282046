.pause-journey-header {
  padding-left: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
}

.pause-journey-warning {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
}

.pause-journey-date-container {
  display: flex;
  align-items: baseline;
}

.pause-journey-date-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
}

.pause-journey-date-picker {
  border: solid 1px #d7d7d7;
  color: #737373;
  font-weight: normal;
}

.pause-journey-buttons {
  margin-top: 33px;
  margin-bottom: 24px;
}

.journeys-pause-container {
  display: flex;
  flex-wrap: wrap;
  background: #f6f6f6;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 30px 40px;
  border-radius: 12px;

  &.alternate {
    padding: 30px 20px;
    margin: 16px 0;
  }

  .pause-message-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .icon-background {
    background-color: #454545;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
