.display-widget-results--button {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-bottom: 1px $gray110 solid;

  &.active {
    color: $purple500;
    border-bottom: 3px $purple500 solid;
  }
}

.display-widget--results-modal {
  margin: -16px -16px 0 -16px;
}

.display-widget-results--table {
  overflow: auto;
  height: 540px;

  &.is-tabbed-view {
    height: 490px;
  }
}

.display-widget-results--small {
  flex: 100px;
}

.display-widget-results-overflow-width--proposal {
  width: 140px;
}

.display-widget-results-overflow-width--contact {
  width: 160px;
}

.display-widget-results-table--trip-col {
  flex: 50%;
}

.display-widget-results-overflow-width--interaction,
.display-widget-results-overflow-width--trip {
  width: 150px;
}

.display-widget-results-col--right {
  justify-content: flex-end;
  text-align: right;
}
