.pool-membership-modal {
  min-height: 15vh;
}

.pool-membership-modal--pools {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}

.pool-membership-modal--pool-section {
  align-self: center;
  width: 100%;
  padding-bottom: 8px;
}

.pool-membership-modal--assignment-section {
  padding-left: 64px;
}

.pool-membership-modal--line {
  margin: 0 0 0 64px;
}

.pool-membership-modal--pool-label {
  padding: 0 0px 12px 24px;
}
