$formFieldLabelWidth: 100px;

.form-field {
  padding: 8px 0;

  &.is-aligned-middle {
    .form-field--label,
    .form-field--control {
      vertical-align: middle;
    }
  }

  &.is-label-top {
    margin-bottom: 8px;

    .form-field--label {
      display: block;
      width: 100%;
      line-height: $baseLineHeight;
    }

    .form-field--label--centered {
      text-align: center;
    }

    .form-field--control {
      width: 100%;
    }
  }
}

.form-field--label {
  display: inline-block;
  vertical-align: text-top;
  line-height: 40px;
  width: $formFieldLabelWidth;

  .small {
    font-size: 11.5px;
  }
}

.form-field--label-wrap {
  line-height: $baseLineHeight;
  display: inline-block;
}

.form-field--control {
  width: calc(100% - #{$formFieldLabelWidth});
  display: inline-block;
  vertical-align: text-top;
}

.form-field--required {
  text-transform: uppercase;
  margin-left: 8px;
  border-radius: 5px;
  color: $white;
  background-color: $green500;
  padding: 2px 8px;
  font-size: $fontSizeSmall;
}

.form-field--required-disabled {
  background-color: #737373;
}
