.remove-match-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remove-match-modal--info {
  border: 2px solid $red500;
  border-radius: 8px;
  padding: 12px 24px;
  margin: 20px 0 12px;
  display: inline-block;
  position: relative;
}

.remove-match-modal--info-title {
  text-transform: uppercase;
  color: $red500;
  position: absolute;
  top: -12px;
  left: 8px;
  padding: 0px 8px;
  background: $white;
}
