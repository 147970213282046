$panelBodyTitleHeaderHeight: 40px;

.panel-button {
  width: 100%;
  height: $layoutHeaderHeight;
  top: 0px;
}
.panel-button--innerbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .panel-button--button-icon {
    width: 100%;
  }
  .gt-icon-todolist {
    font-size: 1.5em;
  }
  &.focus-visible {
    @include pink-focus();
  }
}

.panel-body {
  height: 100vh;
  background-color: $border;
  position: absolute;
  width: 400px;
  top: 0;
  right: 0px;
  z-index: 300;
  box-shadow: -1px -2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: transform 200ms;
  transform: translateX(100%);
  overflow-y: hidden;
  &.exited {
    transform: translateX(100%);
  }
  &.entered {
    transform: translateX(0);
  }
}

.panel-body--title {
  width: 100%;
  padding-top: 12px;
}
.panel-body--header {
  width: 100%;
  text-align: left;
  padding-left: 12px;
  font-size: 16px;
  color: $gray500;
}
.panel-body--table {
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
}

.todo--tab-bar {
  width: 100%;
  padding: 0px;
  text-align: center;
  display: table;
  table-layout: fixed;
}

.todo--tab-bar-item {
  font-size: 12px;
  display: table-cell;
  padding-top: 4px;
  cursor: pointer;
}

.todo--selected-tab {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
}

.panel-body--top {
  display: flex;
  align-items: center;
  height: $mainHeaderHeight;
  color: $white;
  padding: 0px 8px;
  background-color: #39495a;
  font-weight: 400;
  font-size: 18px;
  align-items: center;
  padding-left: 20px;
}
.panel-body--close-icon {
  margin-left: auto;
  font-size: 30px;
}

.panel-body--content {
  height: 100%;
  padding: 0 8px;
}
.panel-body--overlay {
  background: rgba(0, 0, 0, 0.23);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
}
