.meeting-details--wrapper {
  border: 1px $border;
}

.meeting-details--body {
  padding: 16px 0;
}

.meeting-details--datalist {
  margin-bottom: 16px;
  padding-right: 16px;

  dt {
    text-align: right;
    width: 150px;
    padding-right: 16px;
    display: inline-block;
    vertical-align: text-top;
    margin-top: 0px;
    color: $gray500;
  }

  dd {
    @include calc("width", "100% - 150px");
    display: inline-block;
    vertical-align: text-top;
    white-space: pre-wrap;
  }
}

.meeting-details--description {
  white-space: pre-wrap;
}

.meeting-details--footer {
  border-top: solid 1px $border;
  padding: 16px;
  text-align: right;
}

.meeting-details--contact {
  margin-bottom: 8px;

  .et-avatar {
    vertical-align: middle;
    margin-right: 8px;
  }

  .contact-card td:first-child {
    width: 48px;
  }

  &:last-child {
    margin: 0;
  }
}