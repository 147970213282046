.events-controller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.events-controller--filters-wrapper {
  height: 75px;
  position: relative;
}

.events-controller--controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}
.events-controller--searchbox {
  width: 400px;
  margin-left: 0;
}

.events-controller--controls-right {
}

.events-controller--sort-dropdown {
  display: "inline-block";
  float: right;
  margin-left: 16px;
}

.events-controller--banner-wrapper {
  padding: 8px 16px;
}

.events-controller--warning {
  background: #fff;
  border: 1px solid $border;
  border-left: 3px solid #5598ab;
  border-radius: 2px;
  margin-bottom: 0;
}

.events-controller--events-table-wrapper {
  padding: 8px 0;
  height: 100%;
}
