.solicitor-management table {
  margin: 0;

  tbody {
    background: $white;
  }

  tr {
    border-bottom: none;
  }

  th {
    height: 42px;
    padding: 0;
    justify-content: center;
    text-align: center;
  }

  td {
    justify-content: center;
    text-align: center;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      padding-left: 20px;
    }
  }
}

.solicitor-management--top {
  justify-content: space-between;
}

.self-selection-btn {
  margin-right: 16px;
}

.solicitor-management--table-wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 183px;
  bottom: 0px;

  .et--flex-table--header-cell {
    z-index: 0;
  }
}

.solicitor-mgmt-row--solicitor {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 200px;
}

.solicitor-mgmt-row--avatar {
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.solicitor-mgmt-row--cell {
  position: relative;
}

.solicitor-mgmt-row--count {
  font-weight: bold;
  font-size: 18px;
}

.solicitor-mgmt-row--actions-icon {
  font-size: 30px;
}

.solicitor-mgmt-row--action-menu {
  font-size: 14px;

  .et--actions-menu--menu-option {
    padding: 8px;

    i {
      margin-right: 8px;
    }

    &:hover {
      color: $purple500;
    }
  }
}

// add new solicitor modal
.new-solicitor-modal--text {
  margin-bottom: 4px;
}

.new-solicitor-modal--dropdown {
  margin-bottom: 20px;
}

// remove solicitor modal
.remove-solicitor-modal--text {
  display: block;
  margin-top: 8px;
  margin-bottom: 16px;
}
