.admin-design-controller {
   padding: 48px;
}

.admin-design--module {
   padding: 32px;
}

.admin-design--swatch {
   float: left;
   margin: 5px;
   text-align: center;
   font-size: 11px;
}

.admin-design--swatch-color {
   width: 100px;
   height: 80px;
}

.admin-design--row {
   margin-bottom: 32px;
}

.admin-design--modal-content {
   padding: 32px;
}
