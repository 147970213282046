.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border;
  padding: 16px 32px;
  background: $white;
}

.dashboard-title {
  font-size: 20px;
}

.dashboard--toolbar-wrapper {
  padding: 16px 16px 4px 16px;
}

.dashboard--toolbar {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;

  > * {
    margin-right: 16px;
  }
}

.dashboard--solicitor-dropdown {
  min-width: 200px;

  .et--advanced-combobox--selected-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
}

.dashboard-right--wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard--setting-icon {
  display: flex;
  margin-left: 20px;

  i:hover {
    color: $purple500;
  }
}

.dashboard-days {
  font-weight: 500;
}

.dashboard-days--subtext {
  color: $gray500;
}

.dashboard-can-edit--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.dashboard-can-edit--save {
  margin-right: 16px;
}

.dashboard-can-edit--text {
  color: $purple500;
}

.dashboard-widgets {
  padding: 0 12px 12px;
  position: relative;
}

.dashboard-widgets--reordering {
  border: 2px solid $purple500;
  border-radius: 4px;
  padding-top: 12px;
}

.dashboard-widget {
  a,
  a:hover {
    color: inherit;
    cursor: inherit !important;
  }
}

.dashboard-empty {
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-empty--icon {
  font-size: 60px;
  margin-bottom: 30px;
}

.dashboard-empty--text {
  width: 375px;
}

.dashboard-settings-popover {
  padding: 18px;
  min-width: 250px;
}

.dashboard-settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.dashboard-widget-modal--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.dashboard--loading {
  margin-top: 100px;
}

.dashboard-widget--disabled {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 80px;
  background-color: $gray105;
  height: 100%;
  border-radius: 0 0 12px 12px;
}
