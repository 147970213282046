.mfa-controller {
   display: none;

   &.is-active {
      @include position(fixed, 0px 0px 0px 0px);
      display: block;
      background-color: rgba(0, 0, 0, .6);
      z-index: 10000;
      overflow: auto;
   }
}
