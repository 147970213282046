.filters-block {
  padding: 12px;
  padding-left: 48px;
  position: relative;
}

.filter-block--bracket {
  position: absolute;
  top: 0;
  left: 24px;
  width: 24px;
  height: 48px;
  border-left: 1px solid $border;
  border-bottom: 1px solid $border;
}

.filters-block--wrapper {
  display: inline-block;
  background-color: $background;
  border: 1px solid $border;
  border-radius: 2px;
  min-width: 650px;
  max-width: 962px;
}

.filters-block--title {
  padding-bottom: 12px;
}

.filters-block--row {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.filters-block--item {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  padding: 12px;
}

.filters-block--label {
  font-weight: 500;
  margin-bottom: 8px;
}

.filters-block--component {
  .advanced-combobox,
  .et--advanced-combobox {
    .et--dropdown--toggle,
    .dropdown--toggle {
      background-color: $white;
      box-shadow: none;
    }
  }

  .dropdown--toggle {
    background-color: $white;
    box-shadow: none;
  }

  .date-picker--header {
    background-color: $white;
    box-shadow: none;
  }

  .search-box--input {
    height: 43px;
    border-radius: 3px;
    border: 1px solid $border;
    box-shadow: none;
  }
}
