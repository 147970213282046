.events-unmatched-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.events-unmatched-toolbar--search {
  width: 300px;
  input {
    width: 100%;
  }
}
