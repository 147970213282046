.results-count {
  display: flex;
  align-items: center;
  margin-right: 0;

  .text-container {
    margin-left: 10px;
  }
  p {
    margin-bottom: 0;
  }
}

.results-count-label {
  margin-left: 4px;
}
