$listDetailHeaderHeight: 75px;
$listDetailSubHeaderHeight: 50px;
$listDetailActivityWidth: 345px;

.list-detail {
  > .empty-state-message {
    margin-top: 100px;
  }
}

.list-detail--header {
  height: $listDetailHeaderHeight;
  background: $white;
  padding: 12px $gutter;
}

.list-detail--description {
  float: left;
  width: 70%;
}

.list-detail--label {
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  // min-height: 40px;

  .click-to-input {
    // the input is floated by default, so this makes it actually have a height
    width: 100%;
    @include clearfix;
  }

  .click-to-input--pencil {
    bottom: 6px;
  }
}

.list-detail--info {
  display: flex;
  align-items: center;

  .emma-list-timestamp {
    .emma-list--spacer {
      margin: 0 10px;
    }
    line-height: 14px;
  }
  .selected-contacts-string {
    margin-top: 0;
  }
}

.list-detail--content {
  @include position(absolute, $listDetailHeaderHeight + $listDetailSubHeaderHeight 0px 0px 0px);
  overflow: hidden;
}

.list-detail--body {
  top: 72px;
}

.list-detail--actions {
  float: right;
  margin-top: 2px;
}

.list-detail--sub-header {
  height: $listDetailSubHeaderHeight;
  padding: 8px $gutter;
  background: $white;

  .collaborator-icon-list--icon {
    width: 32px;
    height: 32px;
    border-radius: 30px;
  }
}

.list-detail--contact-table {
  @include transition(right 0s linear 0.4s);
  right: $listDetailActivityWidth - 1px;

  &.is-activity-collapsed {
    @include transition(right 0s linear 0s);
    right: 0px;
  }
}

.list-detail--activity-toggle {
  @include position(absolute, 1px listDetailActivityWidth + 50 null null);
  @include transition(right 0.1s linear);
  display: block;
  background: $white;
  height: 50px;
  width: 50px;
  padding: 12px 10px 10px 10px;
  z-index: 100;
  border-right: solid 1px $border;
  border-bottom: none;

  &.is-collapsed {
    top: 0px;
    right: -100px;
    width: 150px;
    height: 52px;
    border: solid 1px $border;
    box-shadow: 0px 0px 2px 0 fade-out($black, 0.9);

    .gt-icon {
      line-height: 20px;
    }
  }

  &:hover {
    &.is-collapsed {
      right: 0px;
    }
  }

  .gt-icon {
    width: 30px;
    vertical-align: text-bottom;
    margin-right: 10px;
    font-size: 24px;
    line-height: 28px;
  }
}

.list-detail--activity-panel {
  @include position(absolute, 0px 0px 0px null);
  z-index: 80;
}

.list-detail--activity {
  @include position(absolute, 0px 0px 0px null);
  width: $listDetailActivityWidth;
  box-shadow: 0px 0px 2px 0 fade-out($black, 0.9);
  border-left: solid 1px $border;

  .module {
    border-left: none;
    border-radius: 0;
  }

  .list-activity {
    @include position(absolute, 52px 0px 0px 0px);
    overflow: auto;
  }

  .similar-donors--content {
    @include position(absolute, 115px 0px 0px 0px);
    background: $white;
    overflow: auto;
  }
}

.list-detail--progress-bar {
  width: 100%;
  padding: 32px 40% 0;

  .progress-bar--outer {
    background-color: $gray105;
    border-color: $gray150;
  }

  .progress-bar--inner {
    background-color: $gray500;
  }
}

.list-toolbar-pagination {
  position: inherit;
}
