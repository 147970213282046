.engagement-icon {
   display: inline-block;
   position: relative;
   background-size: 100% 100%;
}

.engagement-icon--network-image {
   border-radius: 50%;
   background-size: 100% 100%;
   background-color: $white;
   position: absolute;
}
