.company-constituents--container {
  padding: 15px;
}

.company-constituents--more {
  margin-left: 5px;
}

.company-constituents--content {
  .contact-card {
    border-bottom: 1px solid $gray150;
    font-size: 12px;
    line-height: 16px;

    &:first-child {
      border-top: 1px solid $gray150;
    }
  }
}
