@mixin badge($background, $text) {
  color: $text;
  background: $background;
  padding: 3px 7px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 1em;
  font-weight: bold;
}

.badge {
  @include badge($purple500, $white);
}

.badge-inverse {
  @include badge($white, $purple500);
}

.badge-accent {
  @include badge($purple500, $white);
}

.badge-warning {
  @include badge($yellow150, $white);
}

.badge-sq-error {
  @include badge($white, $red500);
  border-radius: 2px;
  border: solid 1px $red500;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

.circle-notification-badge {
  width: 8px;
  height: 8px;
  background-color: #007c9e;
  -webkit-border-radius: 50px;
  /* Safari, Chrome */
  -moz-border-radius: 50px;
  /* Firefox */
  border-radius: 50px;
  /* CSS3 */
  transition: all 0.2s ease;
  box-shadow: 0 0 0 0 #000, 0 0 0 0 #ffffff;
}

.circle-notification-badge:hover {
  box-shadow: 0 0 0 4px #f9f9f9, 0 0 0 5px #007c9e;
}
