.color-input--picker-label,
.color-input--divider,
.color-input--hex {
   @include vertical_align;
   padding: 8px;
   border-radius: 2px;
   font-size: $baseFontSize;
   height: 36px;

   &,
   &:focus {
      border: 1px solid $border;
   }
}

.color-input--picker-wrap {
   height: 0;
   width: 0;
   overflow: hidden;
   margin: 0;
}

.color-input--picker-label {
   margin: 0;
   width: 45%;
   cursor: pointer;
   padding: 3px;
   background-color: $white;
}

.color-input--picker-label-color-box {
   height: 100%;
   text-align: center;
   padding-top: 4px;
   border-radius: 2px;
}

.color-input--divider {
   width: 5%;
   border: none;
}

.color-input--hex {
   width: 50%;
}

