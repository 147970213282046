.ag-mini-gifts-table {
  border: 1px solid $border;
  border-radius: 3px;
  background-color: $white;
  min-height: 315px;

  table {
    margin: 0;
    table-layout: auto;
  }

  th {
    font-weight: bold;
    padding: 12px 16px;
    background-color: $gray105;

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
    }
  }

  td {
    border-top: 1px solid $gray105;
    padding: 12px 16px;
  }
}

.ag-mini-gifts-table--label {
  text-align: left;
}

.ag-mini-gifts-table--count-header {
  cursor: pointer;
  text-align: right;
}

.ag-mini-gifts-table--count {
  text-align: right;
}

.ag-mini-gifts-table--total-header {
  cursor: pointer;
  text-align: right;
}

.ag-mini-gifts-table--total {
  text-align: right;
}

.ag-mini-gifts-table--footer {
  text-align: center;
  padding: 8px;
}
