.profile-gift-summary {
  padding-top: 16px;
  @extend .clearfix;

  &.has-pledges {
    .profile-gift-summary--item {
      width: 25%;
    }
  }

  .kpi-card--value {
    color: $green300;
  }
}

.profile-gift-summary--totals {
  background: $white;
  border: solid 1px $border;
  padding: 16px;
}

.profile-gift-summary--totals-label {
  font-size: 16px;
}

.profile-gift-summary--totals-amount {
  font-size: 16px;
  float: right;
}

.profile-gift-summary--row-wrap {
  border-top: solid 1px $border;
  padding: 16px 0;
}

.profile-gift-summary--recognition-row {
  padding-bottom: 16px;
}

.profile-gift-summary--giving-row {
  font-weight: bold;
  margin-left: 40px;
}

.profile-gift-summary--credit-row {
  margin-left: 80px;
}

.profile-gift-summary--pledge-row {
  margin-left: 40px;
  padding-bottom: 0;
}

.profile-gift-summary--gift-modal {
  .modal-confirm--body {
    padding: 16px;
    background: $background;
  }
}
