// TODO: Copied from date-picker.scss
$dateRangeWidth: 160px;
$dateRangeTodayColor: #41565e;

@mixin current-date-selector() {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid $dateRangeTodayColor;
  border-left: 8px solid transparent;
}

.calendar-picker-dropdown {
  max-width: 300px;
}

.calendar-picker-dropdown--icon {
  color: $gray200;
  margin-right: 8px;
}

.calendar-picker {
  .date-picker {
    width: 100%;
    border: none;
    border-top: solid 1px #eee;
    padding-top: 5px;
    height: 300px;
  }

  .dp-nav-view {
    text-transform: uppercase;
    font-size: 12px;
    color: $linkText;
  }

  .dp-nav-table {
    margin: 0 !important;
  }

  .dp-cell,
  .dp-table {
    border: none;
  }

  .dp-week-day-name {
    color: $gray500;
    font-size: 12px;
    text-transform: uppercase;

    &:hover {
      background: $white !important;
      color: $gray500;
    }
  }

  .dp-table {
    margin: 0;

    .dp-row {
      border: none !important;
    }

    .dp-cell {
      border: none !important;

      &.dp-prev,
      &.dp-next {
        color: $gray150;

        &:hover {
          color: $gray900;
        }
      }

      &.dp-in-range {
        background: fade-out($purple500, 0.95);

        &:hover {
          background: $gray150;
          border-radius: 2px;
        }
      }

      &.dp-value {
        color: $white;
        background: $purple500;
        border-radius: 2px;
      }

      &:not(.dp-value):hover {
        background: $gray150;
        border-radius: 2px;
      }

      &.dp-current {
        position: relative;

        &::before {
          @include current-date-selector();
          top: 0;
          right: 0;
        }
      }

      &.dp-disabled {
        background: $gray105;
      }
    }
  }

  .dp-footer {
    color: $linkText;
  }

  .dp-footer-today {
    position: relative;

    &::before {
      @include current-date-selector();
      top: 8px;
      left: 0;
    }

    &:hover {
      color: darken($linkText, 10%);
    }
  }

  .dp-footer-selected {
    &:hover {
      color: darken($linkText, 10%);
    }
  }
}

.calendar-picker--selected-date {
  background: $purple500 !important;
  color: $white !important;
  border-radius: 2px;
}

.calendar-picker--label {
  @include vertical_align;
  text-transform: uppercase;
  font-weight: bold;
}

.calendar-picker--header {
  display: flex;
  padding: 0 12px;
}

.calendar-picker--input {
  padding: 4px 8px;
  margin: 8px auto;
  border: solid 1px $border;
  border-radius: 2px;
  width: 99px;
  font-size: $baseFontSize;

  &:focus {
    border: solid 1px $border;
    border-radius: 2px;
  }
}
