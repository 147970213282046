.admin-user-gates--filters {
  padding: 16px 30px;
}

.admin-user-gates--table {
  @include position(absolute, 75px 0px 0px 0px);

  .table--header th,
  .table--body td {
    padding: 8px 16px;
  }

  .table--header th {
    height: 50px;
    line-height: 25px;

    &:last-child {
      border-right: none;
    }
  }

  .table--body td:last-child {
    border-right: none;
  }
}

.admin-user-gates--org-inherit {
  background: $gray105;

  .text-muted {
    color: $gray150;
    opacity: 0.5;
  }
}

.admin-user-gates--org-setting-link {
  margin-left: 16px;
}

.admin-user-gates--product-filter {
  float: left;
  width: 250px;
}

.admin-user-gates--gate-filter {
  float: left;
  max-width: 500px;
  width: 40%;
  margin-left: 16px;

  input {
    height: 42px;
  }
}

.admin-user-gates--confirm {
  padding: 24px;
  font-size: 16px;

  strong {
    font-size: 18px;
    display: block;
    margin-bottom: 8px;
  }

  .advanced-combobox {
    float: none;
    margin-top: 16px;
  }
}
