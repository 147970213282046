.tasks-alert .form-field,
.tasks-alert .form-field--control {
  display: flex !important;
  align-items: center;
}

.tasks-alert .form-field--control > span {
  margin-right: 15px;
}

.time-dropdown .et--dropdown--toggle {
  width: 160px;
}

.tasks-alert .form-field {
  padding: 15px 0;
}
