.profile-gifts-controller {
  overflow-y: auto;
  padding: 20px;
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
}

.profile-gifts-controller--in-module {
  padding: 0;
  position: relative;
  top: 0;
  border-radius: 12px;

  .gifts-feed {
    border: 0;
    min-height: 80px;
  }

  .gifts-feed--list {
    padding: 0;
  }
}
