$margin: 0 16px;

.constituents-controller {
  max-width: 1100px;
  .filters-bar {
    margin: $margin;
    padding: 4px 0;
  }
}

.constituents-controller-count {
  display: flex;
  margin: 20px 16px;
  justify-content: space-between;
  align-items: center;
}

.constituents-controller-title {
  color: $gray500;
  font-weight: $bold;
  font-size: 16px;
  margin-bottom: 12px;
}

.constituents-table-placeholder {
  position: relative;
  margin: $margin;
}

.constituents-table-placeholder-th {
  border-top: 1px solid $blue200;
  background-color: $blue106;
  height: 30px;
}

.constituents-table-placeholder-tr {
  background-color: $gray108;
  height: 30px;
  margin-top: 4px;
}

.constituents-table-placeholder-button {
  position: absolute;
  top: 12px;
  left: 47%;
}
