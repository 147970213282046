.tasks-lists-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.tasks-lists-table,
.ai-tasks-lists-table {
  overflow: hidden;
  table {
    min-height: 400px;

    tbody {
      display: block;
      max-height: 60vh;
      overflow-y: auto;
      tr {
        min-height: 60px;
      }
    }
  }
  .et--flex-table--header-row {
    min-height: 1px;
  }
}

.ai-tasks-lists-table thead th,
.tasks-lists-table thead th {
  position: sticky;
  top: 0;
}

.tasks-toolbar {
  padding: 0px 16px 26px 16px;
  height: 55px;
  width: 90%;
}

.blue-button {
  color: #007c9e;
}

.task-icon-col {
  flex-grow: 1;
}

.paused-task-icon {
  color: $gray150;
}

.dash-task-circle::before {
  content: '\f03c';
  color: #d3d3d3;
  cursor: pointer;
  font: normal normal normal 32px/1 'GivingTree2';
}

.check-circle--clickable::before,
.check-circle::before {
  content: '\f00e';
  color: #d3d3d3;
}

.check-circle--clickable::before {
  cursor: pointer;
}

.check-circle--clickable:hover::before {
  content: '\f000';
  cursor: pointer;
  color: #73c166;
}

.check-circle--small::before,
.check-circle--small:hover::before,
.checked-circle--small::before {
  font: normal normal normal 25px/1 'GivingTree2';
}

.check-circle--big::before,
.check-circle--big:hover::before,
.checked-circle--big::before {
  font: normal normal normal 32px/1 'GivingTree2';
}

.checked-circle--clickable::before,
.checked-circle::before {
  content: '\f000';
  color: #73c166;
}

.checked-circle--clickable::before {
  cursor: pointer;
}

.paused-icon--clickable::before {
  cursor: pointer;
}

.paused-icon--clickable::before,
.paused-icon::before {
  content: '\f0dc';
  color: $gray150;
  font: normal normal normal 32px/1 'GivingTree';
}

.paused-icon--clickable:hover::before {
  content: '\f000';
  cursor: pointer;
  font: normal normal normal 32px/1 'GivingTree2';
  color: #73c166;
}

.trash-btn::before {
  content: '\f10d';
  font: normal normal normal 16px/1 'GivingTree';
  cursor: pointer;
}

.trash-btn:hover::before {
  color: #969696;
}

.tasks--due-date {
  p {
    margin: 0;
  }
}

.task-table-pagination {
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  width: 90%;
}

.tasks-table--cadence {
  background: $gray110;
  color: $gray500;
  border-radius: 12px;
  font-weight: 500;
  padding: 0 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0 0 0 4px;
    font-size: 9px;
  }
}

.hidden-header {
  background-color: transparent;
  border: transparent;
}

.header-copy {
  color: #30006c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.suggested-task-table-header {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: #fcf8fc;
  padding: 10px;
  border-radius: 10px;
}

.ai-disabled--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: auto;
  padding: 15px;
  text-align: center;
  max-width: 550px;

  p {
    margin: 0;
  }
}

.tasks-lists-table .et--empty-state-message {
  position: absolute !important;
}

//checbox visibility classes
.task-checkbox {
  opacity: 0;
  transition: opacity 0.2s;
}

.task-checkbox.visible {
  opacity: 1;
}

/* Make the checkboxes visible on row hover */
.et--flex-table--row:hover .task-checkbox {
  opacity: 1;
}

.header-checkbox {
  opacity: 1;
}

.task-checkbox-cell {
  max-width: 50px;
}

//merged complete toggle and task name classes
.task-name-cell {
  display: flex;
  align-items: left;
  gap: 10px;
}

.task-name-toggle {
  flex-shrink: 0;
}

.task-name-content {
  flex: 1; /* Expand task name to fill available space */
}
