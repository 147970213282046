.security-controller--module {
  margin-bottom: 30px;
}

.security-controller--header {
  font-size: 20px;
  font-weight: 500;
}

.security-controller--contents {
  margin-bottom: 20px;
  max-width: 580px;
}

.security-controller--verification-info {
  @include clearfix;
  list-style-type: none;
}

.security-controller--verification-segment {
  display: inline-block;
}

.security-controller--verification-phone {
  float: right;
  max-width: 300px;
}

.security-controller--protection-since {
  font-size: 16px;
}

.security-controller--phone-number-display {
  background-color: $gray105;
  border: 1px solid $gray150;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 8px;
}

.security-controller--remembered-container {
  margin-top: 20px;
}

.security-controller--remembered-header {
  font-weight: bold;
  font-size: 16px;
}

.security-controller--remembered-status {
  margin-left: 10px;
  font-size: 16px;

  &.is-enabled {
    color: $green300;
  }

  &.is-disabled {
    color: $red500;
  }
}

.security-controller--disable-mfa {
  margin-top: 20px;
  background-color: $red500;
  color: $white;
}

.security-controller--disclaimer {
  margin: 8px 0 0 0;
  font-size: 12px;
  font-style: italic;
}
