.my-portfolio-picker--search {
  padding: 16px;
  border-bottom: solid 1px $border;
}

.my-portfolio-picker--contacts {
  max-height: 400px;
  min-height: 100px;
  overflow-y: auto;
  position: relative;
}

.my-portfolio-picker--group:not(:first-child) {
  border-top: solid 1px $border;
  margin-top: 16px;
}

.my-portfolio-picker--group-header {
  font-weight: 500;
  font-size: 16px;
  padding: 16px 16px 8px 16px;
}

.my-portfolio-picker--group-contacts {
  .contact-card--wrapper {
    width: 75%;
    display: inline-block;
    vertical-align: middle;

    .contact-card td {
      padding: 4px 8px;

      &:first-child {
        width: 54px;
      }
    }
  }
}

.my-portfolio-picker--group-contact {
  padding: 0 16px;
  display: flex;
  align-items: center;

  &:hover {
    background: $gray105;
  }

  &.is-disabled {
    color: $gray500;

    .contact-name {
      color: $gray500;
    }
  }
}
