.facebook-reactions-dropdown {
  .et--advanced-combobox--item.is-with-icon {
    padding-right: 8px;
  }
}

.facebook-reactions-dropdown--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.facebook-reactions-dropdown--icon {
  width: 20px;
  height: 20px;
}
