.drawers-container {
  // styles to create an invisible "bar" fixed at the bottom of the screen
  height: 0px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  // populate the drawers from RIGHT to LEFT, across bottom of the screen
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  // A little space on the right, don't go all the way across the page
  right: 4%;
  width: 90%;
}

// Styles for Drawers inside the container
.drawers-container {
  .drawer {
    margin-left: 8px; // just to give a little buffer between drawers
  }
}

// Styles for OPEN Drawers
.drawer {
  height: 100%; // ensures drawer div is the "full" height of the drawer, so content can use all the space inside.
  min-height: 550px; // baseline height. Must be hardcoded pixel value (to avoid flexing)

  // General styling
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
}

.drawer--content {
  position: relative;
  height: 100%; // fill the entire drawer
  &.scrollable {
    overflow-y: scroll; // optional prop for drawer. makes content scrollable
  }
}

// Styles for MINIMIZED Drawers
.drawer.minimized {
  height: auto;
  min-height: 0px;
  width: 280px;
  min-width: 250px;
}

.drawer--content-hidden {
  position: relative;
  height: 0%;
  display: none;
}

// Header Styles
.drawer--header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  align-items: center;

  .drawer-on-call {
    border-radius: 50%;
    height: 13px;
    width: 13px;
    margin-right: 6px;
    background-color: $green300;
  }

  .drawer-on-call--minimized {
    padding: 4px;
    border-radius: 40px;
    background-color: $green300;
  }
}

.drawer--header--button-group {
  display: flex;
  align-items: center;
}

.drawer--header-button {
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
}

.drawer--header-title {
  @extend .ellipsis-overflow;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 470px;
}

.drawer--header-title--minimized {
  max-width: 128px;
}

.drawer--header-icon {
  margin-right: 8px;
  position: relative;
  top: 1px;
}

// SPECIFIC DRAWERS
.emailer-drawer {
  width: 603px;
  height: 550px; //explicitly adding height as after adding the DnD the unused space in email body is not considered in the body height.
}

.ai-drawer {
  width: 388px;

  .drawer--header {
    align-items: center;
    background-color: white;
    color: #454545;
    border-bottom: 1px solid #f3f3f3;
  }

  .drawer--header-button {
    color: #939393;
  }
}

.dialer-drawer {
  width: 603px;
}

/* Styles for full-screen mode */
.drawer.fullscreen {
  position: fixed;
  top: 7.5%; /* Adding 7.5% because its half of the 15% of the screen space left. This centres the drawer */
  left: 7.5%;
  width: 85%;
  height: 85%;
  z-index: 1000; /* Ensure it stays on top of everything */
  border-radius: 10px 10px 0px 0px;
  /* Ensure content fills the full-screen drawer */
  .drawer--content.emailer-drawer {
    width: 100%; /* Override to take up the full width in full-screen mode */
    height: 100%; /* Make sure it also takes full height in full-screen */
  }
  .drawer--content.ai-drawer {
    width: 100%; /* Override to take up the full width in full-screen mode */
  }

  .tv-video-upload-button {
    position: absolute;
    margin-right: auto;
    right: 5%;
  }
}

.drawer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Should be below the full-screen drawer */
}
