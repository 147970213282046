.tasks-filters-bar {
  padding: 15px 16px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.constituent-filter {
  padding-right: 16px;
  width: 285px;
}

.add-filter-btn {
  color: #007c9e;
}
