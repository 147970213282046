$tripHeaderHeight: 75px;
$tripControlsHeight: 60px;
$tripBodyTop: $tripHeaderHeight + $tripControlsHeight;

.trip-page {
  overflow: hidden;
}

.trip--header {
  @include display(inline-flex);
  @include align-items(center);
  width: 100%;
  height: $tripHeaderHeight;
  background: $white;
  padding: 16px;
  position: relative;
}

.trip--back {
  @include position(absolute, 22px null null 0px);
}

.trip--title {
  margin-right: auto;
  padding-left: 24px;
}

.trip--header-icon {
  @include vertical_align;
  background: $gray105;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  color: $gray150;
  font-size: 24px;
  padding: 8px;
  margin-right: 16px;
}

.trip--owner {
  color: $gray500;
  font-weight: normal;
  font-size: $baseFontSize;
}

.trip--data {
  @include vertical_align;
  font-size: 20px;
  font-weight: 500;

  .trip-date {
    font-size: $baseFontSize;
    font-weight: normal;
  }
}

.trip--controls {
  height: $tripControlsHeight;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.trip--body {
  @include position(absolute, $tripBodyTop 0px 0px 0px);
}

.trip--log-toggle {
  transition: width 0.1s ease-in;
  display: flex;
  align-items: center;
  width: 170px;
  background: $white;
  line-height: $tripControlsHeight - 8px;
  padding-left: 18px;

  .gt-icon {
    vertical-align: middle;
  }
}

.trip--log-toggle-label {
  vertical-align: sub;
}

.trip--log-panel {
  @include animation-fill-mode(none);
}

.trip--facepile-tooltip {
  line-height: 18px;
}
