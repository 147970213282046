.proposal-form--header {
  padding: 16px 0;
  font-size: 20px;
  font-weight: bold;

  span {
    font-size: $baseFontSize;
    font-weight: normal;
  }
}

.proposal-form--form-field {
  flex: 1;
}

.proposal-form--form-field-dropdown {
  width: 244px;
}

.proposal-form--cancel-wrapper {
  display: flex;
  justify-content: flex-start;

  .boolean-radio .radio {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.proposal-form--form-field-label {
  margin-top: -12px;
}

.proposal-form--row {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.proposal-text-container {
  .form-field {
    margin-bottom: 4px;
    padding: 8px 0 0;
  }
}

.proposal-form--launchpad {
  justify-content: space-between;
  width: 81%;
}

.proposal-form--amounts {
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
  gap: 35px;
  margin: 8px 0px 20px;

  hr {
    border-bottom: 1px solid #a54ab5;
    width: 48px;
    display: none;
    margin: 15px 0 0;
  }

  .proposal-form--form-field {
    flex: 0;
    padding: 0px;
    margin: 0px;
  }
}

.proposal-form--form-field-date-picker {
  width: 180px;

  .DayPickerInput {
    width: 100%;
  }

  .DayPickerInput-OverlayWrapper {
    width: 100%;
  }

  .DayPickerInput-Overlay {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.proposal-form--form-field-wide {
  width: 520px;
  max-width: 520px;
}

.proposal-form--form-field-amount {
  max-width: 120px;
}

.proposal-form--form-field-amount-focus {
  border: 1px solid #a54ab5 !important;
}

.proposal-form--form-field-percent {
  width: 100px;
}

.proposal-form--error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 30px;
}

.proposal-form--error-button {
  margin-top: 20px;
}

.proposal-form--cancel {
  color: $gray300;
  cursor: pointer;
  font-size: 24px;
  align-self: center;

  &:hover {
    color: $red500;
  }
}

.proposal-form--list-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border;
  height: 60px;
  justify-content: space-between;

  hr {
    border-bottom: 1px solid #a54ab5;
    width: 48px;
  }
}

.proposal-form--add-item-row {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  height: 60px;
}

.proposal-form--solicitor-column {
  display: flex;
  align-items: center;
}

.proposal-form--solicitor-required {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .form-field--label-wrap {
    margin-bottom: 2px;
    padding-right: 10px;
  }
}

.proposal-form--solicitor-title {
  width: 150px;
  text-align: left;
  padding-right: 16px;
}

.proposal-form--contact-card {
  margin-top: 8px;
  padding: 0px;
}

.proposal-form--textarea {
  width: 100%;
}

.proposal-form--designation-column {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.proposal-form--designation-column-focus {
  border: 1px solid #a54ab5;
  border-radius: 5px;
  padding: 7px;
}

.proposal-character-counter {
  margin-bottom: 16px;
}

.proposal-form--form-field.proposal-form--field-no-space.form-field.is-label-top {
  position: relative;
}

.proposal-form--form-field.proposal-form--field-no-space.form-field.is-label-top::after {
  border-bottom: 1px solid #a54ab5;
  width: 36px;
  margin: 0;
  position: absolute;
  content: "";
  bottom: 20px;
  right: -35px;
}

.confidence-score {
  margin-left: 35px;

  .form-field--label {
    .form-field--label-wrap {
      width: 176px;
    }
  }
}

.proposal-form--form-field-date-picker {
  .dropdown--header {
    height: 40px;
  }
}
