$switch-on: $gray500;
$switch-off: $blue300;

$switch-on-text: $white;
$switch-off-text: $gray500;

$switch-border: $gray500;
$switch-border-radius: 50px;

$switch-width: 45px;
$switch-height: 27px;

$switch-handle-size: 25px;

.onoffswitch {
  @include user-select(none);
  position: relative;
  width: $switch-width;
  display: inline-block;
  vertical-align: middle;
}

input.onoffswitch-checkbox {
  display: none;
  vertical-align: middle;
  margin: 4px 0 0;
  z-index: $onOffSwitchZindex;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: $switch-border-radius;
  margin-bottom: 5px;
}

.onoffswitch-inner {
  @include transition(all 0.2s ease-in 0s);
  background: $switch-off;
  height: $switch-height;
  border-radius: $switch-border-radius;
  box-shadow: inset 0 1px 2px fade-out($black, 0.8);
}

.onoffswitch-switch {
  @include transition(all 0.2s ease-in 0s);
  width: $switch-handle-size;
  height: $switch-handle-size;
  margin: 1px;
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  border-radius: $switch-border-radius;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  background: $switch-on;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner {
  background: $gray105;
  cursor: default;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  cursor: default;
}

.onoffswitch--text-label {
  display: inline-block;
  font-weight: normal;
  margin-left: 10px;
}
