.journeys-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.journeys-banner--action-buttons {
  @include flex-center;
  padding: 16px;
  gap: 16px;
}

.journey-details-title {
  white-space: normal;
}
