.tooltip-trigger--content {
  border-radius: 2px;
  line-height: 18px;
  padding: 8px;
  text-align: center;
  font-weight: normal;
  font-size: $baseFontSize;
  white-space: nowrap;

  &.is-dark {
    color: $white;
    border-bottom-width: 2px;
    background-color: darken($gray900, 3%);
  }
}
