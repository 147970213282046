$baseHeaderHeight: $layoutHeaderHeight;
$baseHeaderText: $white;
$baseHeaderHighlight: $white;
$baseSearchIconWidth: 40px;
$baseHeaderDropdownZIndex: 999;
$baseHeaderIconHoverColor: $gray900;
$baseHeaderIconColor: $gray500;

#base-header {
  @include display(flex);
  @include flex-direction(row);
  @include justify-content(space-between);
  height: $baseHeaderHeight;

  .dropdown--body {
    margin-top: 0px;
    z-index: $baseHeaderDropdownZIndex;
  }

  .autocomplete--body {
    z-index: $baseHeaderDropdownZIndex;
  }
}

.base-header--search-container {
  @include flex-grow(2);
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.base-header--options {
  @include align-self(flex-end);
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

.base-header--performance,
.base-header--notifications,
.base-header--help {
  @include vertical_align();
  height: $baseHeaderHeight;
  width: 55px;
  text-align: left;
  color: $baseHeaderIconColor;

  &:hover {
    color: $baseHeaderIconHoverColor;
    cursor: pointer;
  }

  .dropdown--header {
    a {
      color: $baseHeaderIconColor;
      &:hover {
        color: $baseHeaderIconHoverColor;
      }
    }
  }

  .dropdown--body {
    color: $baseFontColor;
    &:hover {
      color: $baseFontColor;
    }
  }

  .gt-icon-performance,
  .gt-icon-notifications,
  .gt-icon-notifications-none {
    margin-top: 7px;
    font-size: 1.8em;
  }

  .notification--badge {
    right: auto;
    left: 3px;
  }
}

.base-header--help {
  @include vertical_align();
  height: $baseHeaderHeight;
  width: 55px;
  text-align: left;
}

.base-header--help-dropdown {
  width: 100%;

  .dropdown--header a {
    height: $baseHeaderHeight;
    padding: 18px 0 0 0;
    font-weight: bold;
    position: relative;
    text-align: center;
  }

  .dropdown--body {
    min-height: inherit;
  }
  .dropdown--toggle.focus-visible {
    @include pink-focus();
  }
}

.base-header--help-dropdown-icon {
  margin-top: 2px;
  font-size: 1.8em;
}

.base-header--beamer-icon {
  font-size: 1.8em;
  width: 100%;
  padding: 18px 0 0 0;
}

.base-header--help-dropdown-content {
  > li > a {
    padding: 8px;
    cursor: pointer;
  }
}

.base-header--user {
  @include vertical_align();
}

.beamer_icon.active,
#beamerIcon.active {
  @include badge($purple500, $white);
  border: solid 2px $white !important;
  display: inline-block;
  border-radius: 10px !important;
  width: initial !important;
  height: initial !important;
  bottom: initial !important;
  left: initial !important;
  right: 27px !important;
  top: 6px !important;
  font-size: 12px !important;
  font-family: Roboto !important;
  line-height: 1em !important;
  font-weight: bold !important;
  -webkit-animation: beamer_bounce 0s linear infinite !important;
  animation: beamer_bounce 0s linearinfinite !important;
}

.base-header--tasks {
  padding: 19px 0 0 0;
  i {
    font-size: 1.8em;
    display: block;
    margin: auto;
  }
}
