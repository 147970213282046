.profile-relationships-controller--empty-message {
  margin-bottom: 35px;

  i.empty-state-message--icon {
    font-size: 28px;
  }
}

.profile-relationships-controller--all-relationship {
  padding: 16px;
}

.profile-relationships-controller--relationship {
  width: 50%;
  display: inline-block;
  padding: 0 24px 24px 24px;
  vertical-align: top;

  &.is-of-three {
    width: 33%;
  }
}

.profile-relationships-controller--relationships-list {
  padding-top: 10px;
}
