$mfaBorderRadius: 5px;

.mfa-modal {
  width: 320px;
  margin: 100px auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: $mfaBorderRadius;
}

.mfa-modal--header {
  background-color: #4b899a;
  border-radius: $mfaBorderRadius $mfaBorderRadius 0 0;
  height: 130px;
  position: relative;
}

.mfa-modal--header-phone-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 100px;
  width: 200px;
  margin-left: -100px;
}

.mfa-modal--heading {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.mfa-modal--content {
  background-color: $gray105;
  border-radius: 0 0 $mfaBorderRadius $mfaBorderRadius;
  padding: 20px 30px;
}

.mfa-modal--note {
  text-align: center;
  margin-top: 26px;
  font-size: 12px;
  color: $gray500;
}

.mfa-modal--subtext {
  text-align: center;
  margin-bottom: 10px;
}

.mfa-modal--text {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

// using `.code-input` to raise specificity above UI v2 input styles

.code-input.mfa-modal--input {
  padding: 12px 16px;
  border: 1px solid $green300;
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 10px;
  height: auto;

  &:focus {
    border: 1px solid $green300;
  }

  &.is-error {
    border-color: $red300;
    margin-bottom: 2px 10px;
  }
}

.mfa-modal--error-text {
  color: $red300;
  padding: 5px;
  margin-bottom: 5px;
}

.mfa-modal--trust-label {
  display: inline-block;
  line-height: 1;
  padding-left: 6px;
  vertical-align: middle;
}

.mfa-modal--button {
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
}

.mfa-modal--resend-link-area {
  text-align: center;
  padding: 10px;
}

.mfa-modal--resend-status,
.mfa-modal--resend-link {
  display: inline-block;
  padding: 6px 10px;
}
