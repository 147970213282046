.donor-potential-score--header {
  padding: 16px 16px 0 16px;
  color: $gray500;
  font-size: 16px;
  font-weight: 500;
}

.donor-potential-score--score {
  text-align: center;
  padding: 16px;
}

.donor-potential-score--score-value {
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  color: #454545;
}

.donor-potential-score--score-bar {
  font-size: 14px;
  font-weight: bold;
  color: $gray500;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 5px;
}

.donor-potential-score {
  .slideshow--header {
    display: none;
  }

  .slideshow--content {
    height: 394px;
    background: $white;
    box-shadow: none;
    text-align: left;
    font-size: $baseFontSize;
  }

  .slideshow--slide {
    border-radius: 2px;
    margin: 0 auto;
    padding: 0px;
    left: 16px;
    right: 16px;
  }

  .slideshow--footer {
    border-top: none;
  }

  .slideshow--paging {
    width: 100%;
  }
}
