.events-filters {
   padding: 32px;
   padding-bottom: 0;

   .date-picker-dropdown.advanced-combobox {
      float: none;
      display: inline-block;
      vertical-align: middle;
      width: 45%;
      max-width: 400px;
   }

   .filter-text-input {
      width: 100%;
   }
}

.events-filters--col {
   width: 45%;
   max-width: 420px;
   padding-right: 16px;
   margin-right: 16px;
   position: relative;
   display: inline-block;
   vertical-align: middle;

   &.is-active {
      border-right: solid 1px $border;

      &::before {
         @include triangle(18px, $border, right);
         @include position(absolute, 33% -9px null null);
         @include calc(top, "50% - 9px");
         content: "";
      }

      &::after {
         @include triangle(18px, $white, right);
         @include position(absolute, 33% -8px null null);
         @include calc(top, "50% - 9px");
         content: "";
      }
   }
}
