.events-table {
  border-left: solid 1px $border;
  position: relative;
  height: 100%;

  tr,
  th {
    height: 51px;
    padding-left: 40px;
  }

  .fixed-table--body .table--body tr td {
    text-align: left;
    padding-left: 10px;
    height: 92px;
  }

  .fixed-table--horizontal {
    span {
      margin-left: 14px;
    }
  }

  .fixed-table--vertical {
    overflow-y: overlay;
  }
}

.events-table--footer {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid $border;
  margin-bottom: 40px;
}

.events-table--event {
  white-space: nowrap;
  padding-right: 16px;
}

.events-table--placeholder {
  background-color: $gray105;
  margin: 14px 14px 14px 4px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  height: 60px;
  width: 60px;
  text-align: center;

  i {
    font-size: 24px;
    margin-top: 30%;
    opacity: 0.25;
    vertical-align: middle;
  }
}

.events-table--picture {
  display: inline-block;
  margin: 14px 14px 14px 4px;
  vertical-align: middle;
}

.events-table--text {
  @include calc(width, "100% - 80px");
  display: inline-block;
  vertical-align: middle;
}

.events-table--total-engagers {
  float: right;
  margin-right: 16px;
}

.events-table--event-type {
  i {
    margin-right: 8px;
  }
}

.events-table--event-counts {
  text-align: right;
  padding-right: 16px;
}

.events-table--eventbrite {
  width: 16px;
  margin-right: 12px;
  margin-left: 4px;
  vertical-align: text-top;
}

.events-table--title {
  font-size: 16px;
}

.events-table--location {
  padding-right: 16px;
}
