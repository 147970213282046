$myTripsHeaderHeight: 75px;
$myTripsControlsHeight: 68px;
$myTripsBodyTop: $myTripsHeaderHeight + $myTripsControlsHeight;

.my-trips--cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.my-trips--header {
  @include display(inline-flex);
  @include justify-content(space-between);
  @include align-items(center);
  height: 75px;
  background: $white;
  padding: 16px;
  width: 100%;
}

.my-trips--title {
  @include flex(7);
  width: 40%;
}

.my-trips--user-dropdown {
  .advanced-combobox {
    height: 22px;
  }
}

.my-trips--calendar {
  @include flex(0);
  @include flex-basis(14%);
  font-size: 14px;

  @media screen and (max-width: 1080px) {
    @include flex(2.5);
  }

  a {
    @include display(inline-flex);
    @include align-items(center);
    font-weight: $bold;
  }
}

.my-trips--controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 68px;
  padding: 0 16px;
  align-items: center;
}

.my-trips--empty {
  text-align: center;
}

.my-trips--body {
  @include position(absolute, $myTripsBodyTop 0px 0px 0px);
  padding: 16px;
  overflow: auto;

  .loading--wrapper {
    width: 200px;
    margin-left: -100px;
  }

  .loading--spinner {
    margin: 0 auto;
  }
}

.my-trips--time-heading {
  margin-bottom: 16px;
}

.my-trips--time-heading__no-search-results {
  font-style: italic;
}

.my-trips--sort {
  width: 140px;
  float: right;

  i.sort-dropdown--icon {
    margin: 0;
  }

  i.gt-icon-long-arrow-up {
    margin-right: 12px;
    margin-left: -4px;
  }
}

.my-trips--upcoming {
  margin-bottom: 16px;
}

.my-trips-actions {
  @include flex(1);

  .action-menu-item--icon {
    font-size: 18px;
    line-height: 23px;
  }
}

.trip-form-calendar--body {
  width: 550px;

  .calendar-date-picker--range {
    display: none;
  }

  .calendar-date-picker--from,
  .calendar-date-picker--to {
    width: 50%;
  }
}

.trip-form--error {
  clear: both;
  text-align: right;
  padding-top: 4px;
}

.trip-selector--empty {
  padding: 32px;
  text-align: center;
  color: $gray500;
}

.trip-selector {
  .msg-container {
    text-align: center;
    margin: 3.8rem 0;
    line-height: 2.7;

    .msg-icon {
      display: block;
      margin: auto;
      font-size: 32px;
    }
    .msg {
      font-size: 17px;
      display: block;
    }
  }
}
