.ag-reporting-overview-controller--funnel {
   padding: 16px;
}


.ag-reporting-overview-controller--table {
   width: 50%;
   display: inline-block;
   vertical-align: top;
   padding: 16px;
}
