.gt-email-settings--module {
  margin-bottom: 25px;
}

.gt-email-settings {
  padding: 12px;
}

.gt-email-settings--prompt {
  margin-bottom: 12px;
  font-weight: bold;
}

.gt-email-settings--status {
  color: $green200;
  margin-left: 12px;
}

.gt-email-settings--option {
  padding: 4px;
}
