.profile-engagement {
  display: flex;

  .profile-card {
    position: relative;

    .filter-drawer--header {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }

    .filter-drawer--toggle {
      border-top-left-radius: 12px;
    }

    .filter-drawer--overlay {
      border-radius: 12px;
    }
  }

  .filter-drawer--content {
    overflow: inherit;
  }
  .filter-drawer--clear {
    width: auto;
    padding: 28px 32px;

    a {
      &:hover {
        color: $red500;
      }
    }
  }
  .filter-drawer--apply {
    width: auto;
  }
  .facebook-filters--col {
    width: 75%;
  }
  .facebook-filters {
    .date-picker-dropdown.advanced-combobox {
      width: 71%;
      margin-top: 16px;
    }
  }
}

.profile-engagement--category-dropdown {
  float: right;
  max-width: 116px;
  margin-right: 12px;
}

.profile-engagement--filter-header {
  @extend .clearfix;
  padding: 16px 32px 16px 16px;

  .overflow-text {
    @include calc(width, "100% - 280px");
    float: left;
    padding-top: 10px;
  }
}

.profile-engagement--filter-results {
  position: relative;
  top: 74px;
  min-height: 200px;
}

.profile-engagement--filter-results-tv {
  position: relative;
}

.profile-engagement--sort {
  float: right;
}

.profile-engagement--all-engagements {
  margin-top: 16px;
  padding-bottom: 75px;

  .empty-state-message--icon {
    color: $facebookBlue;
    line-height: 50px;
  }
}

.profile-engagement--pagination {
  border-top: solid 1px $border;
  display: block;
  padding: 16px;
  font-size: 16px;
  text-align: center;
}

.profile-engagement--filter-loading {
  padding-top: 10px;
  vertical-align: middle;
  display: inline-block;
}

.profile-engagement--engagement-list {
  .loading {
    max-height: 110px;
  }
}
