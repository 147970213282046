$keyMetricsFontSize: 28px;
$keyMetricsInnerContentHeight: 74px;

.key-metrics {
  padding: 16px;
  .profile-card-header {
    margin-bottom: 16px;
  }
}

.key-metrics--col {
  width: 25%;
  float: left;
  height: 146px;
  border-right: solid 1px $border;
  padding: 8px 16px 0 16px;
  position: relative;

  .profile-card-subtitle {
    border-bottom: none;
  }

  &:last-child {
    border-right: none;
  }

  &:hover:not(:disabled) {
    background: lighten($gray105, 1%);
    cursor: pointer;
  }
}

.key-metrics--lifetime-giving {
  padding-bottom: 8px;
  padding-top: 4px;

  strong {
    font-weight: bold;
    font-size: $keyMetricsFontSize;
    color: $green300;
    display: block;
    margin-bottom: 4px;
  }

  &.is-empty {
    height: $keyMetricsInnerContentHeight - 20px; // subtrack half of chart height
  }
}

.key-metrics--last-gift {
  height: $keyMetricsInnerContentHeight;
  padding-top: 4px;

  strong {
    font-weight: bold;
    font-size: $keyMetricsFontSize;
    color: $green300;
    display: block;
    margin-bottom: 4px;
  }
}

.key-metrics--assignee {
  height: $keyMetricsInnerContentHeight;

  .et-avatar {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
  }
}

.key-metrics--assignee-text {
  @include calc(width, "100% - 66px");
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}

.key-metrics--connections {
  width: 200px;

  .et-avatar {
    width: 55px;
    height: 55px;
    border-radius: 60px;
    border: solid 2px $white;
    float: left;
    position: relative;
    margin-left: -20px;
    margin-bottom: 8px;
  }
}

.key-metrics--connections-icons {
  margin-left: 20px;
}

.key-metrics--facebook {
  @extend .clearfix;
  margin-bottom: 12px;
  margin-top: -6px;
  line-height: 16px;

  strong {
    font-size: $keyMetricsFontSize;
    line-height: 1em;
  }
}

.key-metrics--reactions-count {
  display: inline-block;
  padding-right: 8px;
  vertical-align: middle;
}

.key-metrics--facebook-unmatched {
  margin-top: 8px;
}

.key-metrics--proposals {
  padding: 0px;
}
