.facebook-engagement-card {
  padding: 8px 16px;
}

.facebook-engagement-card--wrapper {
  @extend .clearfix;
  border-bottom: solid 1px $border;
  padding-bottom: 16px;
}

.facebook-engagement-card--image {
  width: 62px;
  height: 62px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url($facebookEngagementDefault);
  background-color: $gray150;
  border-radius: 3px;
  margin-top: 4px;
  margin-bottom: 8px;
  float: left;
  border: solid 1px $gray150;
}

.facebook-engagement-card--type-icon {
  @include position(absolute, null -12px -12px null);
  width: 32px;
  height: 32px;
  background: $gray150;
  border-radius: 32px;
  border: solid 2px $white;
  color: $white;
  text-align: center;
  line-height: 29px;
}

.facebook-engagement-card--data {
  @include calc(width, "100% - 62px");
  float: left;
  padding-left: 32px;
}

.facebook-engagement-card--comment {
  border: solid 1px $border;
  background: fade-out($blue300, 0.8);
  padding: 8px;
  border-radius: 3px;
  margin: 8px 0;
}

.facebook-engagement-card--post {
  width: 350px;

  .facebook-post--actions {
    display: none;
  }
}
