$notificationIconSize: 35px;
$notificationIconMargin: 12px;

.notification-dropdown {
  width: 100%;
  height: 100%;

  .dropdown--body {
    width: 350px;
    background: $gray105;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: auto;
  }

  .dropdown--header {
    height: 100%;
    a {
      padding: 0;
      font-weight: bold;
      height: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .dropdown--toggle.focus-visible {
    @include pink-focus();
  }
}

.notification-list--empty {
  @extend .text-empty-message;
}

.notification-item {
  @include clearfix;
  display: block;
  padding: 10px;
  border-bottom: solid 1px $border;
  background: transparent;
  cursor: pointer;

  &.is-unread {
    background: $white;
    border-left: solid 2px $purple500;
  }

  &.is-static {
    cursor: default;
  }

  &.is-disabled {
    cursor: default;
    color: $gray500;

    .notification-item--icon {
      color: $gray500;
    }
  }

  &:hover:not(.is-disabled):not(.is-static) {
    background: fade-out($white, 0.5);
    color: inherit;
  }
}

.notification-item--icon {
  width: $notificationIconSize;
  height: $notificationIconSize;
  float: left;
  margin: 3px $notificationIconMargin 0 0;
  border-radius: 3px;

  &.gt-icon-facebook,
  &.gt-icon-linkedin {
    margin-top: 1px;
    font-size: 34px;
  }

  &.gt-icon-community {
    color: $green300;
  }

  &.gt-icon-cloud-download {
    color: $linkText;
  }
  &.gt-icon-segment {
    color: $green300;
  }
  &.gt-icon-et-pyramid {
    color: #975cd0;
  }
}

.notification-item--message {
  font-size: 1.2em;
  word-wrap: break-word;
}

.notification-item--caption {
  font-size: 0.9em;
}

.notification-item--comment {
  .comment--name {
    display: none;
  }

  .comment--content {
    width: 100%;
  }

  .comment--text {
    background: $white;
    border-bottom-width: 2px;
    margin-bottom: 0;
  }
}

.notification-item--content {
  float: left;
  width: 82%;
}

.notification-item--with-mention {
  position: relative;

  .notification-item--content {
    margin-left: $notificationIconSize + $notificationIconMargin;
  }

  .comment--content {
    margin-left: 0;
    border: none;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  .comment--image {
    @include position(absolute, 10px null null 10px);
    width: $notificationIconSize;
    position: absolute;

    img {
      border-radius: 3px;
    }
  }
}

.notification--badge {
  @include position(absolute, 8px 3px 0 0);
  @include badge($purple500, $white);
  border: solid 2px $white;
}

.notification-details--header {
  height: 75px;
  background: $white;
  padding: 12px $gutter;
  border-bottom: solid 1px $border;
}

.notification-details--label {
  font-size: 20px;
  line-height: 30px;
}

.notification-details--toolbar {
  height: 72px;
}

.notification-details--table {
  @include position(absolute, 147px 0px 0px 0px);
}
