.journeys--contact-card--container {
  display: flex;
  align-items: center;
}

.journeys--contact-card--content {
  padding: 16px 8px;
}

.journeys--contact-card-main {
  display: flex;
}

.journeys-contact-card-name {
  color: #007c9e;
}
.journeys-contact-card-name:hover {
  color: #a54ab5;
}
