@import '~@evertrue/et-variables/src/styles/variables.scss';

$dateRangeWidth: 160px;
$dateRangeTodayColor: #41565e;

@mixin current-date-selector() {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 8px solid $dateRangeTodayColor;
  border-left: 8px solid transparent;
}

// Add-to and override default date-picker styles
.calendar-date-picker {
  .date-picker {
    width: 100%;
    border: none;
    border-top: solid 1px #eee;
    padding-top: 5px;
    margin-top: 10px;
    height: 300px;
  }

  .dp-nav-view {
    text-transform: uppercase;
    font-size: 12px;
    color: $linkText;
  }

  .dp-nav-table {
    margin: 0 !important;
  }

  .dp-cell,
  .dp-table {
    border: none;
  }

  .dp-week-day-name {
    color: $gray500;
    font-size: 12px;
    text-transform: uppercase;

    &:hover {
      background: $white !important;
      color: $gray500;
    }
  }

  .dp-table {
    margin: 0;

    .dp-row {
      border: none !important;
    }

    .dp-cell {
      border: none !important;

      &.dp-prev,
      &.dp-next {
        color: $gray150;

        &:hover {
          color: $gray900;
        }
      }

      &.dp-in-range {
        background: fade-out($purple500, 0.95);

        &:hover {
          background: $gray150;
          border-radius: 2px;
        }
      }

      &.dp-value {
        color: $white;
        background: $purple500;
        border-radius: 2px;
      }

      &:not(.dp-value):hover {
        background: $gray150;
        border-radius: 2px;
      }

      &.dp-current {
        position: relative;

        &::before {
          @include current-date-selector();
          top: 0;
          right: 0;
        }
      }
    }
  }

  .dp-footer {
    color: $linkText;
  }

  .dp-footer-today {
    position: relative;

    &::before {
      @include current-date-selector();
      top: 8px;
      left: 0;
    }

    &:hover {
      color: darken($linkText, 10%);
    }
  }

  .dp-footer-selected {
    &:hover {
      color: darken($linkText, 10%);
    }
  }
}

.calendar-date-picker--range {
  float: left;
  height: 360px;
  width: $dateRangeWidth;
  background: $gray105;
}

.calendar-date-picker--shortcut {
  display: block;
  width: 100%;
  padding: 6px 12px;
  color: $linkText !important;
  border-bottom: 1px solid $border;

  &:hover {
    color: $purple500 !important;
    background-color: $gray105;
  }
}

.calendar-date-picker--selected-date {
  background: $purple500 !important;
  color: $white !important;
  border-radius: 2px;
}

.calendar-date-picker--from,
.calendar-date-picker--to {
  @include calc(width, '(100% - #{$dateRangeWidth})/2 ');
  float: left;
  padding: 12px;
  border-left: 1px solid $border;
  text-align: center;
}

.calendar-date-picker--label {
  @include vertical_align;
  text-transform: uppercase;
  font-weight: bold;
}

.calendar-date-picker--input {
  @include vertical_align;
  padding: 4px 8px;
  margin: 0 8px;
  border: solid 1px $border;
  border-radius: 2px;
  width: 99px;
  font-size: $baseFontSize;

  &:disabled {
    background: $gray105;
    border: none;
  }

  &:focus {
    border: solid 1px $border;
    border-radius: 2px;
  }
}

.date-picker-dropdown {
  float: right;
  width: 240px;
  position: relative;

  .fa-angle-down {
    @include position(absolute, 15px 15px null null);
  }

  &.is-open {
    .date-picker--header {
      &:hover {
        background: $gray110;
      }
    }
  }
}

.date-picker-dropdown-secondary {
  .date-picker--header {
    background: $white;
    box-shadow: inset 0 -3px 0 0 $gray105;
    border: solid 1px $border;
    border-radius: 3px;
  }
}

.date-picker--header {
  padding: 10px 20px;
  border-radius: 20px;
  color: $gray500;
  border: solid 1px $gray150;

  &:hover {
    cursor: pointer;
    background: $gray108;
  }
}

.date-picker--wrap {
  width: 700px;
  background: $white;
  border: 1px solid $border;
  box-shadow: 0 1px 2px $gray150;
}

.date-picker--actions {
  border-top: 1px solid $border;
  width: 100%;
}

.data-picker--extra-actions {
  overflow: hidden;
}

.date-picker--action-btn {
  width: 100px;
  margin: 10px 10px 10px 0;
  float: right;
  padding: 12px 16px;

  &.btn {
    border: solid 1px $gray500;
  }
}

.date-picker--dropdown {
  display: flex;
  flex-direction: column;

  .et--button-simple {
    text-align: end;
    padding: 0 15px 7px;
  }
}

.date-range-picker {
  // Today's Date
  .DayPicker-Day--today {
    font-weight: 700;
  }

  // Selected Date
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $purple500 !important;
  }

  // Selected Date in middle of range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: $purple105 !important;
    color: $purple500;
  }

  // // Disabled Dates in middle of range
  // .DayPicker-Day--selected.DayPicker-Day--disabled:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(
  //     .DayPicker-Day--outside
  //   ) {
  //   background-color: $gray110 !important;
  //   color: $purple500;
  // }

  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover {
    background-color: $purple105 !important;
  }

  // Start of Range
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  // End of Range
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
