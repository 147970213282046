.dnd-controller--list {
  height: 500px;
  overflow: auto;
}

.dnd-controller--is-dragging {
  cursor: move;
}

.dnd-controller--dragged-over-list {
  cursor: move;
}
