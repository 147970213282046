.map-contacts {
  overflow: hidden;
  background: $white;
  border-top: solid 1px $border;
  border-right: solid 1px $border;
  width: 20%;
  z-index: 0;

  table {
    margin: 0;
  }

  tr > td {
    border-bottom: solid 1px lighten($border, 5%);
    padding: 0px;
  }
}

.map-contacts--header {
  @include position(absolute, 0px 0px 0px 0px);
  height: 75px;
  padding: 16px;
  border-bottom: solid 1px lighten($border, 5%);
}

.map-contacts--wrapper {
  @include position(absolute, 75px 0px 0px 0px);
  overflow: auto;
}

.map-contacts--contact {
  &:hover {
    background: $gray105;
  }
}

td.map-contacts--checkbox {
  width: 45px;
  text-align: right;
}

.map-contacts--card {
  tr > td {
    padding: 12px 8px 0 8px;
    border: none;
  }

  .contact-card--avatar {
    width: 55px;
    height: 55px;
    border-radius: 55px;
  }

  .contact-card > td:first-child {
    width: 80px;
    text-align: center;
    padding-top: 0px;
  }
}

td.map-contacts--score {
  padding: 0 0 8px 8px !important;

  .score-progress {
    width: 124px;
  }
}
