$exportDataSettingsBtnWidth: 160px;

.export-item {
  @include display(flex);
  align-items: center;
  color: $gray500;

  width: 100%;
  border-bottom: solid 1px $border;

  button {
    margin-top: 4px;
  }

  div {
    @include flex-basis(25%);
    &:last-child {
      text-align: left;
    }
  }
}

.export-item > div {
  padding: 16px;
}

.export-item--heading {
  color: $gray900;
  font-size: 16px;
  font-weight: $bold;
}

.export-item--edit-schedule {
  color: $linkText;
  margin-right: 24px;
}

.export-item--cancel-schedule {
  color: $red300;

  &:visited {
    color: $red300;
  }
}

.export-item--manual-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button,
  a {
    i {
      color: $gray500;
      font-size: 24px;
      &:hover {
        color: $gray500;
      }
    }

    &:disabled {
      i {
        color: $gray500;
        font-size: 21px;
      }
    }
    .gt-icon-file-export {
      font-size: 20px;
    }
  }
}

.export-item--datepicker .et--dropdown {
  width: 250px;
}
