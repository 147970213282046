.profile--enrichment {
  display: flex;
  .empty-state-message--text {
    font-size: 12px !important;
    color: $gray500;
    text-align: center;
    font-weight: normal;
  }
}

.profile-enrichment--accent {
  color: $purple500;
  font-weight: bold;
}

.profile-enrichment--accent-light {
  color: $purple500;
  font-weight: 400;
}

.profile--enrichment-left {
  min-width: 300px;
  max-width: 350px;
}

.profile--enrichment-right {
  flex-grow: 2;
}

.profile--enrichment-inner {
  padding: 16px 16px 8px;
  &.columns {
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
  }
  .career-template {
    padding-bottom: 5px;
  }
}

.profile--enrichment-inner-edit {
  background-color: #fffef4;
  cursor: pointer;
}

.profile-enrichment--more {
  margin-left: 19px;
}

.profile-enrichment--view-all {
  float: right;
}

.profile-enrichment--more-modal {
  padding: 20px;
}

.profile--enrichment-group {
  margin-bottom: 14px;
}

.profile--enrichment-group-title {
  font-size: 14px;
  font-weight: 500;
  color: $gray900;
}

.career-template--dates {
  font-size: 14px;
  font-style: italic;
  color: $gray500;
}

.profile--enrichment-bio--delete {
  float: right;
  font-size: 20px;
}

.profile--enrichment-bio-location {
  margin-bottom: 15px;
}

.profile--enrichment-bio--info {
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.profile--enrichment-delete-button {
  color: $red300;
  font-size: 20px;
  position: absolute;
  right: 0;
}

.profile--enrichment-rejected-list {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid $border;
}

.profile-enrichment--item {
  display: flex;
  align-items: flex-start;
  position: relative;
  min-width: 250px;
  border-left: 5px solid $gray105;
  padding-left: 8px;
  margin: 6px 0;
  .career-template {
    padding-bottom: 0;
  }
}

.profile--enrichment-undo-button {
  position: absolute;
  right: 0;
}

.profile-enrichment--modal-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  padding: 10px 16px 0px;
}

.career-edit-modal {
  padding: 20px;
}

.profile--enrichment--addresses {
  .slideshow--header {
    display: none;
  }

  .slideshow--content {
    height: 394px;
    margin-top: 16px;
    background: $white;
    box-shadow: none;
    text-align: left;
    font-size: $baseFontSize;
  }

  .slideshow--slide {
    border-radius: 2px;
    margin: 0 auto;
    padding: 0px;
    left: 16px;
    right: 16px;
  }

  .slideshow--footer {
    border-top: none;
  }

  .slideshow--paging {
    width: 100%;
  }
}

.profile-enrichment--icon {
  color: #a7a7a7;
  font-size: 18px;
}

.profile-enrichment--match {
  padding-right: 5px;
  color: #757575;
  font-size: 12px;
}

.profile-enrichment--verified--icon {
  color: $green200;
  font-size: 18px;
}

.profile-enrichment--lists:not(:last-child) {
  margin-bottom: 15px;
}

.profile-enrichment--list {
  margin: 5px 19px;
  text-transform: capitalize;

  p {
    @extend .ellipsis-overflow;
    margin-bottom: 10px;
  }
}

.profile--enrichment-pro-interest {
  margin-left: 20px;
}

.profile-enrichment-interest-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.profile--enrichment-meta {
  font-size: 12px;
  color: $gray500;
  text-align: center;
  font-weight: normal;
  width: 100%;
  padding-top: 8px;
}

.profile--enrichment-meta-modal {
  font-size: 12px;
  color: $gray500;
  font-weight: normal;
  width: 100%;
  padding-top: 8px;
}

.profile--enrichment-meta--footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  * {
    margin: 0 2px;
  }
}

.profile--enrichment-restore {
  display: flex;
  justify-content: space-between;

  &.toggle-deleted {
    float: right;
  }
}

.profile--enrichment-wealth--networth {
  .profile-enrichment--list {
    font-size: 16px;
    font-weight: bold;
  }
}

.profile-enrichment-wealth-title {
  color: #707070;
}

.profile--enrichment-social-profile {
  margin-bottom: 15px;
  display: inline-flex;
}

.profile--enrichment-social-profile-name {
  display: flex;
  align-items: center;
  position: relative;
}

.profile--enrichment-social-body {
  display: block;
  margin: 10px;
}

.profile--enrichment-social-followers {
  span {
    margin-right: 10px;
  }
}

.profile--enrichment-social-icon {
  margin-top: 10px;
}

.profile--enrichment-additional-socials {
  font-size: 12px;
  color: #757575;
  width: 100%;
  text-align: center;
}

.profile--enrichment-followers-confidence {
  display: inline-flex;
}

.profile--enrichment-social-follower-count {
  margin-left: "70px";
}

.profile--enrichment-social-edit {
  padding: 20px;

  .empty-state-message--text {
    font-size: 16px;
  }

  .empty-state-message--icon {
    font-size: 24px;
  }
}

.profile-enrichment-teaser {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  margin: 10px auto;
  width: 80%;
}

.profile--enrichment--confidence-high {
  color: $green200;
  font-size: 12px;
}

.profile--enrichment--confidence-med {
  color: #f5a933;
  font-size: 12px;
}

.profile--enrichment--confidence-low {
  color: #c8321a;
  font-size: 12px;
}

.profile--enrichment--confidence-info {
  color: #a7a7a7;
  font-size: 12px;
}

.profile--enrichment--additional-employments {
  font-size: 12px;
  color: #757575;
  width: 100%;
  text-align: center;
}

.profile--enrichment--employment-item {
  border-bottom: 0px !important;
  padding: 2px 0 !important;
}

.contact-interests-enrichment {
  .profile-enrichment-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .profile--enrichment-delete-button {
      position: static;
    }
  }
}
