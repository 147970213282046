.contact-add-to {
  min-width: 384px;
}

.contact-add-to--types {
  display: flex;
  padding: 16px 16px 0;
  width: 100%;

  .button-select--item {
    padding: 8px;
    flex: 1;
  }
}
