.todo--section-header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $gray150;
  border-bottom-width: thin;
  margin-bottom: 8px;
  float: left;

  .gt-icon-add-circle {
    margin-left: 4px;
    margin-right: 4px;
    color: $linkText;
    font-size: 20px;
    padding-top: 16px;
    cursor: pointer;
  }
}

.todo--seperation {
  width: 100%;
  border-bottom: 1px solid $gray150;
  border-bottom-width: thin;
  margin-bottom: 4px;
  padding-left: 8px;
  color: $gray500;
  font-size: 12px;
  margin-top: 4px;
}

.todo--upcoming-cell {
  border: 2px solid $gray150;
  border-width: thin;
  box-shadow: 1px 1px 2px $gray150;
}

.todo-item--cell-date {
  float: right;
  text-align: right;
  margin-right: 5px;
  font-size: 15px;
}

.todo--list-info-text-holder {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray150;
  height: 50px;
  .gt-icon-add-circle {
    margin-left: 4px;
    margin-right: 4px;
    color: $linkText;
    font-size: 20px;
    cursor: pointer;
  }
}

.todo--list-info-text {
  color: #9b9b9b;
  font-size: 15px;
  width: 85%;
  padding-left: 16px;
  padding-top: 16px;
  float: left;
}
