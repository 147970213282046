.capacity-info--header {
  padding: 16px 16px 0 16px;
  color: $gray500;
  font-size: 16px;
  font-weight: 500;
}

.capacity-info--score {
  text-align: center;
  padding: 16px;
}

.capacity-info--score-value {
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  color: $green300;

  &.is-empty {
    font-size: $baseFontSize;
    font-weight: normal;
    line-height: $baseFontSize;
    color: $gray150;
  }
}

.capacity-info {
  .slideshow--header {
    display: none;
  }

  .slideshow--content {
    height: 394px;
    background: $white;
    box-shadow: none;
    text-align: left;
    font-size: $baseFontSize;
  }

  .slideshow--slide {
    border-radius: 2px;
    margin: 0 auto;
    padding: 0px;
    left: 16px;
    right: 16px;
  }

  .slideshow--footer {
    border-top: none;
  }

  .slideshow--paging {
    width: 100%;
  }
}
