$segmentFilterRowRemove: 65px;
$segmentFilterRowHeight: 60px;

.segment-filter-row {
  position: relative;
  padding: 10px 0;

  .number-range {
    .numeric-input--input {
      margin-bottom: 0;
      width: 110px;
    }
  }

  .number-range--separator {
    width: 10px;
  }

  .slide-range {
    width: 200px;
  }

  .date-range--input {
    @include position(relative, -2px 0 0 0);
    padding: 13px;
  }

  .advanced-combobox--selected-text {
    @extend .ellipsis-overflow;
    padding-right: 24px;
  }

  .advanced-combobox .dropdown--body {
    min-width: 130px;
  }

  .advanced-combobox .dropdown--header {
    padding: 5px; // needed to see focus when using tab for accessability
  }
}

.segment-filter-row--container {
  @include calc(width, '100% - #{$segmentFilterRowRemove}');
  display: inline-block;
  vertical-align: middle;
}

.segment-date-range--picker-opened {
  margin-top: 17px;
}

.segment-filter-row--col {
  border-right: solid 1px $border;
  padding-right: 16px;
  margin-right: 16px;
  position: relative;

  &::before {
    @include triangle(18px, $border, right);
    @include position(absolute, 33% -9px null null);
    @include calc(top, '50% - 9px');
    content: '';
  }

  &::after {
    @include triangle(18px, $white, right);
    @include position(absolute, 33% -8px null null);
    @include calc(top, '50% - 9px');
    content: '';
  }
}

.segment-filter-row--filter {
  display: inline-block;
  vertical-align: middle;
  padding-right: 16px;
  margin-right: 16px;
  max-width: 40%;
  width: 35%;

  .et--advanced-combobox {
    width: 100%;
    max-width: 100%;
  }

  .fa-caret-down {
    margin-left: 40px;
  }

  .dropdown--body {
    width: auto;
    min-width: 300px;
    left: -1px;
    right: -1px;
    border-top: solid 1px $border;
  }
  .gt-icon-new {
    float: right;
    margin-left: 10px;
    margin-right: 0;
    color: $purple500;

    &::before {
      font-size: 35px;
      line-height: 18px;
      vertical-align: top;
    }
  }
  .gt2-icon-updated {
    float: right;
    margin-left: 10px;
    margin-right: 0;
    color: $purple500;

    &::before {
      font-size: 20px;
      line-height: 18px;
      vertical-align: top;
    }
  }
}

.segment-filter-row--val-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.segment-filter-row--value {
  display: inline-flex;
  vertical-align: middle;
  width: 55%;
  align-items: center;

  .boolean-radio {
    display: inline-block;
    margin: 8px 0 0 8px;
  }

  .slide-range {
    display: inline-block;
    vertical-align: middle;
    height: 27px;
    padding: 0 16px;
  }

  &.segment-filter-row--col {
    .advanced-combobox {
      min-width: 112px;
    }
  }
}

.segment-filter-row--remove {
  width: $segmentFilterRowRemove;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 24px;

  .gt-icon-cancel {
    color: $gray200;
  }

  a:hover .gt-icon-cancel {
    color: $red500 !important;
  }
}

.segment-filter-row--operator-value,
.segment-filter-row--operator-value-v2 {
  display: inline-block;
  max-width: 40%;
  min-width: 112px;
}

.segment-filter-row--operator-value {
  vertical-align: middle;
}

.segment-filter-row--sub-value {
  display: inline-block;
  vertical-align: middle;
  width: 55%;
}

.segment-filter-row--sub-value-v2 {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 15px;
}

.segment-filter-row--read-only {
  border: solid 1px $border;
  border-radius: 2px;
  padding: 9px 20px;
  height: 41px;
  font-weight: bold;
  display: inline-block;

  i {
    margin-right: 8px;
  }

  &::after {
    display: none;
  }
}

.segment-filter-row--min-width {
  min-width: 200px;
}

.segment-filter-row--date-range {
  .advanced-combobox {
    min-width: 250px;
  }
}

.segment-filter-row--help {
  vertical-align: middle;

  .fa-question-circle {
    color: $gray200;
    line-height: 40px;
    width: 25px;
    text-align: center;

    &:hover {
      color: $purple500;
    }
  }
}

.segment-filter-row--select-width {
  width: 275px;
}

.evertrue-index--filter-item {
  .score-progress {
    width: 124px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
  }

  label {
    width: 100%;
  }
}

.segment-filter--compound-value {
  @include calc(width, '100% - 50px');
  max-width: 500px;
  display: inline-block;
  vertical-align: middle;
}

.filter-date-range {
  @include calc(width, '100% - 50px');
  max-width: 500px;
  display: inline-block;

  .date-picker-dropdown {
    width: 100%;
    float: none;
    max-width: 300px;
  }

  .date-picker--header {
    position: relative;
    padding-right: 30px;

    span {
      @extend .ellipsis-overflow;
    }
  }

  .segment-filter-row--operator-value {
    width: 40%;
    max-width: 200px;
  }

  .fa-angle-down {
    @include position(absolute, 15px 15px null null);
  }
}
