.ag-page-edit-nav {
  @include position(absolute, 0px null 0px 0px);
  background: $white;
  border: solid 1px $border;
  border-right: 0px;
  width: 240px;
  overflow: auto;
}

.ag-page-edit-nav--title {
  font-size: 20px;
  line-height: 25px;
  padding: 24px;
}

.ag-page-edit-nav--subtitle {
  color: $gray500;
  font-size: $baseFontSize;
}

.ag-page-edit-nav--item {
  display: block;
  padding: 16px 24px;
  padding-left: 27px;
  cursor: pointer;
  font-size: 16px;

  &.is-selected {
    background: $gray105;
    border-left: solid 3px $gray500;
    padding-left: 24px;
  }
}
