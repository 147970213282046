.event-filter-dropdown {
  .dropdown--body {
    width: 400px;
  }

  .advanced-combobox--item {
    padding-right: 8px;
  }
}

.event-filter-dropdown--date {
  font-size: 12px;
  color: $gray500;
}
