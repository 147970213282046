.has-header-dropdown .table--header > tr > th {
  .dropdown {
    width: 100%;
  }

  .dropdown--body {
    width: 101%;
    margin-left: -1px;
    min-height: 0;
    line-height: 1.5em;
  }

  .dropdown--toggle {
    position: relative;

    &:hover {
      &::after {
        @include position(absolute, 5px 5px null null);
        content: "\f000";
        font: normal normal normal 14px/1 GivingTree;
        text-rendering: auto;
        color: $gray500;
      }
    }
  }

  .ellipsis-overflow,
  .dropdown--toggle {
    overflow: visible;
    white-space: normal;
  }
}
