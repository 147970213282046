.user-card {
   padding: 10px;
}

.user-card--avatar {
   @include vertical_align;
   width: 40px;
   height: 40px;
   border-radius: 40px;
   margin-right: 10px;
}

.user-card--details {
   @include vertical_align;
   @include calc(width, "100% - 50px");
}

.user-card--email {
   @extend .ellipsis-overflow;
}
