$interactionSearchToolbarHeight: 72px;
$interactionSearchSortWidth: 174px;

.interaction-search-controller--toolbar {
  height: $interactionSearchToolbarHeight;
  padding: 16px 16px 16px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.interaction-search-controller--sort {
  @include vertical_align;
  width: $interactionSearchSortWidth;
}

.interaction-search-controller--feed-wrapper {
  @include position(absolute, $interactionSearchToolbarHeight 0px 0px 0px);
  overflow: auto;
}

.interaction-search-controller--feed {
  padding: 0px 32px;
}

.interaction-search-controller--pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
