$annualGivingScoreHeight: 12px;
$annualGivingScoreLabel: 70px;

.annual-giving-score--wrapper {
  @include calc(width, "100% - #{$annualGivingScoreLabel}");
  display: inline-block;
  height: $annualGivingScoreHeight;
  background: $background;
  border-radius: 2px;
  vertical-align: middle;
}

.annual-giving-score--inner {
  height: $annualGivingScoreHeight;
  border-radius: 2px;
  background-color: $green300;
}

.annual-giving-score--label {
  width: $annualGivingScoreLabel;
  display: inline-block;
  font-size: 14px;
  text-align: center;
}

.annual-giving-score--numerator {
  @include vertical-align;
  line-height: 1;
  font-weight: bold;
  color: $gray500;
}

.annual-giving-score--divider {
  @include vertical-align;
  line-height: 1;
  color: $gray500;
  margin: 0 4px;
}

.annual-giving-score--denominator {
  @include vertical-align;
  line-height: 1;
  color: $gray500;
}
