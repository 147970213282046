$interactionSnippetImageSize: 52px;
$interactionSnippetIconSize: 40px;

.interaction-snippet-card {
  padding: 12px 0 0 16px;
}

.interaction-snippet-card--image {
  width: $interactionSnippetImageSize;
  display: inline-block;
  vertical-align: top;
}

.interaction-snippet-card--content {
  @include calc(width, "100% - #{$interactionSnippetImageSize}");
  display: inline-block;
  vertical-align: top;
  padding-bottom: 12px;
  padding-right: 16px;
}

.interaction-snippet-card--heading {
  color: $gray500;
}

.interaction-snippet {
  margin-top: 12px;

  &:not(.is-gt-comment) {
    border: solid 1px $border;
    border-radius: 2px;
    padding: 12px;
  }
}

.interaction-snippet--summary {
  font-weight: bold;
  margin-bottom: 8px;
  color: $gray900;
}

.interaction-snippet--body {
  &.is-gt-comment {
    border: solid 1px $border;
    border-radius: 2px;
    padding: 12px;
    background: fade-out($blue300, 0.8);
    white-space: pre-wrap;
    position: relative;
    display: block;

    .comment--mention {
      background: none;
      font-weight: bold;
    }
  }
}
