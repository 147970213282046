// most of this styling is borrowed from `interaction.scss`
// controller
.pinned-comments-controller {
  padding: 16px;
  min-height: 50px;
}

.pinned-comments-controller--footer {
  display:flex;
  justify-content: center;
  border-top: 1px solid #f3f3f3;
  padding: 4px;
}

// list item
.pinned-comment-list-item {
  display: flex;
  justify-content: space-between;
  background: $white;
  width: 100%;
  padding: 16px 0px;
  border-top: none;
  cursor: pointer;
  white-space: normal;
  // this is a link so override default link color
  color: unset;

  &:hover {
    background: $gray105;
  }

  &:last-child {
    border-bottom: none;
  }

  .note-feed-item {
    flex: 3;
  }

  .interaction-meta {
    flex: 1;
  }
}

// pinned comment component
.pinned-comment {
  clear: both;
  position: relative;
}

.pinned-comment--image {
  width: 56px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.pinned-comment--icon {
  @include flex-center();
  color: #007c9e;
  padding: 8px;
  font-size: 20px;
}

.pinned-comment--content {
  @include calc(width, "100% - 56px");
  display: inline-block;

  &.is-snippet {
    .pinned-comment--text {
      white-space: normal;
    }
  }
}

.pinned-comment--body {
  position: relative;

  &.is-gt-comment {
    .comment--mention {
      background: none;
      font-weight: bold;
    }
  }

  &.is-empty {
    border: none;
    margin-top: 0;
  }
}

.pinned-comment--details {
  font-weight: 500;
  font-size: 14px;
  color: $gray500;
}

.pinned-comment--text {
  word-break: break-word;
  white-space: pre-line;
}
