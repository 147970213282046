.text-input-form {
  display: flex;
  display: flex;
  align-items: center;
  border: 1px solid #a54aa5;
  border-radius: 5px;
  padding: 6px;
  background-color: #f6f6f6;
  width: 100%;
  justify-content: space-between;

  input {
    border: none;
    background-color: #f6f6f6;
  }

  input:focus {
    outline: none;
  }

  i {
    font-size: 21px;
    cursor: pointer;
    margin-left: 10px;
  }

  i:hover {
    opacity: 0.8;
  }

  div {
    display: flex;
    align-items: center;
  }
}

.signatures-modal--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.email-footer-btns {
  display: flex;
  justify-content: space-between;
  width: 115px;
  align-items: center;
  margin-left: 25px;
}

.email-signatures-btn,
.email-attachment-btn {
  font-size: 22px;
  cursor: pointer;
  color: black;
}

.delete-email-btn {
  font-size: 18px;
  cursor: pointer;
  color: black;
  align-self: center;
}

.email-signatures-btn:hover,
.email-attachment-btn:hover {
  opacity: 0.8;
}

// Email Signature Library
.email-signature-library {
  border-right: 1px solid #f6f6f6;
  width: 40%;
  padding: 15px 23px 0;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .email-signatures--add-btn {
    width: 125px;
  }
}

.email-signature-library--header {
  background-color: #f6f6f6;
  padding: 12px 5px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

.email-signature-library--list {
  margin-top: 17px;

  .email-signature-library--item {
    display: flex;
    margin: 20px 0;
    align-items: center;
  }
}

.email-signature--radio {
  display: flex;

  .et--radio {
    label {
      @extend .ellipsis-overflow;
      max-width: 231px;
    }
  }
}

// Email Signature Form
.email-signature-form {
  width: 60%;
  padding: 15px 10px;
}

.email-signature--editor {
  border: 1px solid #f6f6f6;
  border-radius: 10px;

  .ql-container.ql-snow {
    height: 185px;
  }
}

.email-signature--editor {
  .ql-toolbar {
    .ql-formats {
      .ql-picker {
        .ql-picker-options {
          bottom: auto;
        }
      }
      button,
      .ql-picker {
        &::after {
          top: -20px;
        }
      }
    }
  }
}

.email-signature-body-footer {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #eee;
  padding: 8px 10px;
  text-align: right;
  background-color: #f6f6f6;
  margin-bottom: 130px;

  .saved-btn {
    padding-right: 25px;
  }
}

.default-signature-label {
  text-transform: uppercase;
  margin-left: 8px;
  border-radius: 5px;
  color: $white;
  background-color: #a54ab5;
  padding: 0px 8px;
  font-size: $fontSizeSmall;
  line-height: 22px;
}
