.update-time-in-stage-modal--body {
  padding: 16px;
}

.update-time-in-stage-modal--top-text {
  text-align: center;
  padding-bottom: 8px;
}

.update-time-in-stage-modal--light-text {
  color: $gray500;
}
