// Icon Color Variations

.icon-accent {
  color: $purple500;
}

.icon-highlight {
  color: $purple500;
}

.icon-muted {
  color: $gray500;
}

.icon-error {
  color: $red300;
}

.icon-success {
  color: $green200;
}

.icon-info {
  color: $gray500;
}

.icon-action {
  color: $linkText;
}

.gt-app-icon {
  background: url($gtAppIcon) no-repeat;
  background-size: 100%;
  width: 35px;
  height: 35px;
  float: left;
  margin: 3px 8px;

  &.is-disabled {
    background-image: url($gtAppIconDisabled);
  }
}

.show-disabled {
  background: transparent url($notAvailableTile) repeat;
}

// Default evertrue close icon to user pointer and to be gray
.et-icon-close {
  color: $gray150;
  cursor: pointer;
}

.icon-link {
  text-align: center;

  a:hover .icon-link--i {
    color: $purple500;
  }
}

.icon-link--i {
  @include transition(color 0.2s ease-in);
  color: $gray150;
}

.icon-linkedin-brand {
  color: $linkedinBlue;
}

.icon-facebook-brand {
  color: $facebookBlue;
}

.icon-eventbrite-brand {
  color: $eventbriteOrange;
}

.avatar--blue {
  height: 44px;
  width: 50px;
  display: inline-block;
  background-image: url($avatarBlue);
  background-size: 50px 44px;
}
