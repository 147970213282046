.contact-picker {
   .contact-card--wrapper {
      .contact-card {
         td:first-child {
            width: 56px;
            padding-top: 8px;
         }

         td {
            padding: 8px;
         }
      }
   }
}

.contact-picker--input {
   margin-top: 16px;
}
