.table-toolbar {
  //TODO: This should be re-written when more items are added to this toolbar
  // Just adding 55% width for now to avoid hacky stuff
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 12px 0 12px 12px;
  width: 55%;
}

.table-toolbar-result-count {
  margin: 0 13px;
}

.table-toolbar-pie {
  margin-right: 4px;
}

.table-toolbar-amount {
  margin-left: 4px;
}

.toolbar-container {
  height: 5%;
  align-items: center;
}
