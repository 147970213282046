.event-contacts-table {
   @include position(absolute, 190px 16px 0px 16px);
   border-left: solid 1px $border;

   tr, th {
      height: 51px;
   }

   .fixed-table--body .table--body tr td {
      text-align: left;
      padding-left: 16px;
      height: 92px;
      padding-right: 16px;
   }

   .fixed-table--horizontal {
      span {
         margin-left: 18px;
         font-weight: normal;
      }
   }

   .fixed-table--vertical {
      overflow-y: overlay;
   }

   .new-contact-card {
      z-index: 1;
   }
}

.event-contacts-table--response {
   padding-left: 10px;
   display: inline-block;
}

.event-contact-table--contact {
   white-space: nowrap;

   .checkbox--box {
      overflow: hidden;
      z-index: 2;
      margin-right: 0;
   }
}

.event-contact-table--checkbox,
.event-contact-table--card {
   display: inline-block;
   vertical-align: middle;
}

.event-contact-table--card {
   width: 100%;
}


