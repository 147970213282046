.signal-analytics-card {
  width: 30%;
  min-height: 320px;
  margin-right: 10px;
  min-width: 270px;

  .et--card--header-section {
    border-bottom: 0px solid #e0e0e0;
    padding-bottom: 0px !important;
  }

  .homepage-card {
    height: 100%;
  }
}

.signal-analytics-container {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  flex-flow: row wrap;
}

.signal-analytics-card-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signal-analytics-card-heading-emoji {
  width: 50px;
  padding-right: 10px;
}

.signal-analytics-card-heading-title {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  line-height: normal;
}

.signal-analytics-card-button--center {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.signal-analytics-card--center {
  margin: 0 -20px;
}
