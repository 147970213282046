.filters-inventory {
  margin: 16px;
}

.filters-inventory-group {
  margin: 20px;
  margin-bottom: 50px;
}

.filter-inventory-operators {
  margin: 4px;
  background: $gray105;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  display: inline-block;
}
