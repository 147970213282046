.bio-info--section {
  padding: 8px 16px;
}

.bio-info--title-more {
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0px;
}

.bio-info--contact-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bio-info--title-suggest {
  text-transform: none;
  letter-spacing: 0px;
  font-size: 14px;
}

.bio-info--contact-label {
  font-weight: bold;
  float: left;
  padding-left: 16px;
}

.bio-info--contact-info-header {
  margin-bottom: 4px;
}

.bio-info--item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  min-width: 250px;
  border-left: 5px solid $gray105;
  padding-left: 8px;
  margin: 6px 0;
}

.bio-info--confidence-score {
  font-size: 12px;
  color: $gray500;
  font-weight: normal;
  width: 100%;
}

.bio-info--social-link {
  width: 50%;
  display: inline-block;
  text-align: center;

  &:first-child {
    border-right: solid 1px $border;
  }
}

.bio-contact-info {
  padding-bottom: 30px;
}

.bio-info-view-linkedin {
  display: inline-block;
  margin-bottom: 16px;
}

.bio-social-link--empty {
  color: $blue300;
}

.career-template,
.education-template,
.phone-template,
.email-template,
.address-template,
.assignee-template {
  @extend .clearfix;
  padding-bottom: 16px;
}

.bio-info--contact-icon,
.career-template--icon,
.education-template--icon,
.phone-template--icon,
.email-template--icon,
.address-template--icon,
.assignee-template--icon {
  float: left;
  color: $gray500;
  margin-top: 3px;
  width: 16px;

  &.gt-icon-linkedin-box {
    @extend .icon-linkedin-brand;
  }
}

.career-template--industry-label {
  font-style: italic;
}

.phone-template--no-icon,
.email-template--no-icon,
.address-template--no-icon {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
}

.career-template--data,
.education-template--data,
.phone-template--data,
.email-template--data,
.address-template--data,
.assignee-template--data {
  @include calc(width, "100% - 32px");
  float: left;
  margin-left: 16px;

  &.is-empty {
    color: $gray150;
  }
}

.donor-info-name-row {
  display: flex;
  align-items: center;
}

.donor-info-key-relationship-icon {
  color: $linkText;
  width: 16px;
  margin-bottom: 1px;
  margin-left: 5px;
}

.career-template--label,
.education-template--label,
.phone-template--label,
.email-template--label,
.address-template--label,
.assignee-template--label {
  @extend .text-label;
}

.bio-contact-info {
  a.btn:focus {
    color: $white;
  }
}
