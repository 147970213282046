.giving-gift-controller--contents {
  padding: 24px 12px 0;
}

.giving-gift-panel {
  width: 100%;
  border: 1px solid $border;
  margin-bottom: 24px;
  background-color: $white;
  padding: 16px;
  margin-collapse: none;
}

.giving-gift-controller--row-1,
.giving-gift-controller--row-2 {
  @include display(flex);
}

.giving-gift-controller--column-1 {
  @include display(flex);
  @include flex(2);
  padding: 0 12px;
}

.giving-gift-controller--column-2,
.giving-gift-controller--billing-info {
  @include display(flex);
  @include flex(1);
  padding: 0 12px;
}

.giving-gift-controller--billing-info {
  @include align-self(baseline);
}

.gift-details--icon,
.giving-gift-controller-icon,
.gift-billing-info--icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: solid 2px $gray150;
  color: $gray150;
  margin-right: 4px;
  text-align: center;
  margin: 0 8px 0 -4px;
  vertical-align: middle;

  &::before {
    position: relative;
    top: 6px;
    width: 14px;
    height: 14px;
    text-align: center;
  }

  &.gt-icon-dollar {
    font-size: 18px;
    line-height: 14px;
  }
}
