.meeting-card {
  max-width: 245px;
  height: 136px;
  padding: 16px;
  cursor: pointer;

  &.timeblock-card {
    background: repeating-linear-gradient(
      45deg,
      lighten($border, 6%),
      lighten($border, 6%) 1px,
      $gray105 1px,
      $gray105 10px
    );
  }

  &:hover.timeblock-card {
    background: repeating-linear-gradient(
      45deg,
      lighten($border, 6%),
      lighten($border, 6%) 1px,
      $gray105 1px,
      $gray105 10px
    );
  }
}

.meeting-card--time {
  display: flex;
  justify-content: space-between;
}

.meeting-card--name {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: $linkMainColor;
}

.meeting-card--anchor {
  color: $gray500;
  font-size: 16px;
}

.meeting-card--anchor-label {
  color: $gray500;
  font-size: 12px;
  vertical-align: middle;
}

.meeting-card--location {
  color: $gray900;
  height: $baseLineHeight;
}

.meeting-card--avatars {
  margin-top: 8px;
}

.meeting-card--single-avatar {
  display: flex;
  align-items: center;
  .et-avatar {
    vertical-align: middle;
    margin-right: 8px;
  }
}

.meeting-empty-card {
  display: block;
  max-width: 245px;
  height: 135px;
  padding: 24px;
  text-align: center;
  background: $gray105;
  margin-bottom: 8px;

  &:hover .meeting-empty-card--icon {
    border-color: $purple500;
    color: $purple500;
  }
}

.meeting-empty-card--icon {
  @include transition(all 0.2s linear);
  display: block;
  font-size: 32px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: solid 2px $border;
  margin: 0 auto 8px auto;
  line-height: 48px;
  color: $border;
  background: $white;
}

// when meeting cards have a contact dragged over them
.meeting-calendar--col .dnd-controller--dragged-over-list {
  .meeting-card {
    border-color: $linkText;
    box-shadow: 0 0 0 1px $linkText;
    background: fade-out($linkText, 0.9);
  }

  .meeting-empty-card {
    border-color: $linkText;

    .meeting-empty-card--icon {
      background: $white;
      border-style: solid;
    }
  }
}
