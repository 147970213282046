$tripContactsSortHeaderHeight: 76px;

.trip-contacts--sort-header {
  height: $tripContactsSortHeaderHeight;
  background: $gray105;
  padding: 16px;

  .sort-dropdown {
    // Calculate width of dropdown - order toggle
    @include calc(width, "100% - 45px");
  }
}

.trip-contacts--body {
  overflow: auto;
  padding: 8px;
  height: 100%;
}

.trip-contacts--card {
  display: block;
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  .contact-name {
    font-weight: 500;
  }
}

.dnd-controller--is-dragging {
  .trip-contacts--card {
    transform: rotate(-5deg);
  }
}

.trip-contacts--card-content {
  display: block;
  padding: 8px 8px 12px 8px;
  color: $baseFontColor;
  position: relative;
}

.trip-contacts--ghost {
  .trip-contacts--card-content {
    background: $gray150;
    box-shadow: none;
    border-color: $gray150;
  }

  .trip-contacts--contact-card,
  .menu-popover {
    visibility: hidden;
  }
}

.trip-contacts--contact-card {
  margin: 0;
  color: $gray900;

  .gt-icon-info {
    margin-right: 4px;
  }

  .new-contact-card {
    margin-right: 24px;
    padding: 0;
  }
}

.trip-contacts--contact-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 16px;
}

.trip-contacts--meeting-menu {
  margin-left: 24px;
}

.trip-contacts--meeting-menu-inner {
  padding: 16px;
}

.trip-contacts--bio-info {
  padding: 16px;

  .profile-card-subtitle {
    margin-bottom: 16px;
  }

  .profile-card-subtitle--label {
    display: none;
  }
}
