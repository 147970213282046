$slideRangeBaseColor: $gray150;
$slideRangeHighlight: $gray500;
$slideRangeWidth: 100%;
$slideRangeBaseHeight: 5px;
$slideRangeBorderRadius: 3px;

.slide-range {
  @include user_select(none);
  width: $slideRangeWidth;
  padding: 10px 10px 35px 10px;
  position: relative;
  margin-top: 5px;
}

.noUi-base {
  z-index: 1000;
  cursor: pointer;
}

.noUi-horizontal {
  height: $slideRangeBaseHeight;

  .noUi-handle {
    cursor: pointer;
    background: $slideRangeHighlight;
    box-shadow: none;
    border: none;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    top: 100%;
    left: -10px;
    margin-top: -10px;

    &::before {
      background: $white;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      left: 50%;
      top: 50%;
      margin-top: -3px;
      margin-left: -3px;
    }

    &::after {
      @include triangle(10px, $slideRangeHighlight, down);
      background: none;
      bottom: -3px;
      left: 50%;
      top: auto;
      margin-left: -5px;
    }
  }
}

.noUi-background {
  background: $slideRangeBaseColor;
}

.noUi-connect {
  background: $slideRangeHighlight;
  box-shadow: none;
}

.noUi-marker {
  background: $slideRangeBaseColor !important;
}

.noUi-marker-normal {
  display: none;
}

.noUi-value-sub {
  font-size: 1em;
  color: inherit;
}

.noUi-value-large {
  height: 10px;
}

.noUi-value-horizontal {
  padding-top: 15px;
}

.noUi-pips-horizontal {
  padding-top: 0;
}
