$mapToolBarHeight: 40px;
$mapToolbarBoxShadow: 0 2px 2px fade-out($black, 0.7);

.map-search--container {
  // ensure search bar does not shrink
  flex: none;
  width: 300px;
  margin-left: 5px;
  display: flex;
}

input.map-search--input {
  border-radius: 8px 0px 0px 8px;
}

.map-search--button {
  border-radius: 0px 8px 8px 0px;

  // need to explicitly override some stylings from et-button
  width: $mapToolBarHeight;
  display: flex;
  justify-content: center;
  align-items: center;
}


// override stylings from google autocomplete API
.pac-container {
  box-shadow: $mapToolbarBoxShadow;
  border-radius: 8px;
  background: #f9f9f9;
}

// override stylings from google autocomplete API
.pac-item {
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  color: #454545;
}