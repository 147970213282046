.profile-giving-details {
  .modal-confirm--header {
    padding: 16px;
    font-size: 0.75em;
  }
}

.profile-giving-details--header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 28px;
}

.profile-giving-details--body {
  padding: 16px;
  background: $background;
  border-top: solid 1px $border;
}

.profile-giving-details--toggle {
  margin-bottom: 16px;

  .button-select {
    display: block;
  }

  .button-select--item {
    width: 50%;
    font-weight: normal;
    font-size: 16px;

    &:not(.is-selected) {
      background: #fafafa;
    }
  }
}

.profile-giving-details--toggle-num {
  margin-right: 8px;
}

.profile-giving-details--list {
  border: solid 1px $border;
  background: $white;
}

.profile-giving-details--item {
  border-bottom: solid 1px $border;
  padding: 16px;

  .gift-card {
    border: none;
    padding: 0;
  }

  .gift-card--amount {
    float: right;
    font-size: 16px;
    line-height: 26px;
  }

  &.is-designation {
    border-bottom: none;
  }
}

.profile-giving-details--label {
  font-size: 16px;
}

.profile-giving-details--designations {
  margin-top: 12px;
}

.profile-giving-details--tag {
  background: $gray105;
  border: solid 1px $border;
  border-radius: 2px;
  padding: 4px 8px;
  margin: 8px 8px 0 0;
  display: inline-block;
}

.profile-giving-details--bar-outer {
  background: $gray105;
  margin-top: 12px;
}

.profile-giving-details--bar-inner {
  background: fade-out($green300, 0.6);
  height: 8px;
}
