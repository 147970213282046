.empty-state-message {
  width: 100%;
  color: $gray500;
  text-align: center;
  font-size: 14px;
  padding: 16px;

  &.is-small {
    font-size: 14px;

    .empty-state-message--icon {
      font-size: 20px;
    }

    .empty-state-message--text {
      font-size: 16px;
      margin-top: 12px;
      margin-bottom: 2px;
    }
  }

  &.is-page {
    margin-top: 100px;
  }
}

.empty-state-message--icon {
  color: $gray150;
  font-size: 50px;
}

.empty-state-message--text {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1em;
}

.empty-state-message--subtext {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1em;
}

.empty-state-message--action-area {
  margin-top: 16px;
}

.empty-state-message--action-btn {
  display: block;
  max-width: 146px;
  margin: 16px auto 0 auto;
}

.todays-due-empty-state {
  color: white; 
  background-color: #009900; 
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  font-size: 35px; 
}
