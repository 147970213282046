.contact-card--wrapper {
  margin: 0;

  td {
    border: none;
    padding: 0px 0px 0px 4px;
  }

  .contact-card td {
    padding: 8px 8px 4px 8px;

    &:first-child {
      width: 60px;
      vertical-align: top;
      padding-top: 12px;
    }
  }
}

.contact-card--name {
  &.is-deceased {
    color: $red500;
  }

  &:hover.is-deceased {
    color: $red500;
  }
}

.contact-card--label {
  color: darken($gray500, 2%);
}

.contact-card--value {
  font-weight: 500;
}

.contact-card--deceased {
  @extend .badge-sq-error;
  font-size: 12px;
  line-height: 24px;
}

.contact-card-list {
  table {
    margin: 0;
  }

  .contact-card {
    td {
      padding: 12px 0;
      border-bottom: none;

      &:first-child {
        width: 80px;
      }
    }
  }
}

.contact-card--avatar {
  width: 40px;
  border-radius: 40px;
}
