.task-header-container {
  display: flex;
  flex-direction: row;
}
.task-header-back {
  align-self: center;
  color: #939393;
  cursor: pointer;
}

.task-header-settings-icon {
  margin-left: 16px;
  color: #939393;
  cursor: pointer;
}
