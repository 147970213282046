// Page Header Layout
$pageHeaderHeight: 75px;

.page-header {
   background-color: $white;
   height: $pageHeaderHeight;
   position: relative;
}

.page-header--left {
   display: inline-block;
   vertical-align: middle;

   &::before {
      content: " ";
      display: inline-block;
      height: $pageHeaderHeight;
      width: 16px;
      vertical-align: middle;
   }
}

.page-header--left-items {
   font-size: 20px;
   line-height: 1;
   display: inline-block;
   vertical-align: middle;
}

.page-header--right {
   float: right;
   height: 100%;

   &::after {
      content: " ";
      display: inline-block;
      height: $pageHeaderHeight;
      width: 16px;
      vertical-align: middle;
   }
}

.page-header--right-items {
   display: inline-block;
   vertical-align: middle;
}

.page-header--slash {
   margin-left: 8px;
   margin-right: 8px;
}

.page-header-banner {
   height: 40px;
}

.page-header--contents {
   @include position(absolute, 80px 0px 0px 0px);
   overflow-y: auto;

   &.has-banner {
      top: 120px;
   }
}