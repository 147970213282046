.interaction-custom-fields-form--input {
  @include display(flex);
  @include align-items(center);

  > span {
    display: block;
    width: 100%;
  }
}

.icon-link {
  margin-left: auto;
}

.interaction-custom-fields-form--cancel {
  color: $gray150;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    color: $red500;
  }
}

.interaction-custom-fields-form--small-input {
  width: 160px;
}
