// Navigation Items
.sub-nav {
  @extend .clearfix;
  background: $white;
  margin: 0 16px;
  border-bottom: solid 1px $gray110;

  .nav-item {
    display: inline-block;
    margin-right: 30px;

    a,
    button {
      color: $gray500;
      height: 35px;
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: solid 3px $white;
      display: inline-block;
      padding-top: 8px;
      margin-top: 2px;
    }

    .is-active {
      color: $gray900;
      border-bottom: solid 3px $purple500;
    }
  }
}

.nav-button {
  flex: none;
}

button.nav-button:focus:not(:disabled) {
  // Overwrite standard button focus colors 
  background-color: #f2fafc;
  color: #007c9e;
  border-color: #00a0cc;
}

button.nav-button--active {
  background-color: #f2fafc;
  color: #007c9e;
  border-color: #00a0cc;
}

button.nav-button--inactive {
  border-color: #d7d7d7;
  color: #454545;
  font-weight: normal;
}

.nav-item-icon {
  font-size: 1.2rem;
  position: relative;
  top: 1px;
}

.menu {
  > li > a {
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px 20px;

    &:hover {
      background: $gray105;
    }
  }
}

.menu--bordered {
  > li {
    border-bottom: solid 1px lighten($border, 10%);

    &:last-child {
      border-bottom: none;
    }
  }
}

.menu--header {
  background: lighten($gray105, 2%);
  padding: 5px 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: lighten($gray900, 40%);
  font-size: 0.9em;
}

.value-list {
  margin-bottom: 0;

  .card--value {
    padding-bottom: 0;
    padding-top: 10px;
  }

  dt {
    margin-top: 0;
    padding-bottom: 10px;
  }
}

.bullet-list {
  list-style-type: disc;

  > li {
    padding: 4px;
    margin-left: 15px;
  }
}

.list-picker--warning {
  padding: 16px 4px 0 4px;
  clear: both;
  color: $gray500;
}

.bullet-list-no-bullet {
  list-style-type: none;

  > li {
    padding: 4px;
    margin-left: 15px;
  }
}
