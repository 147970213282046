.helptip-item {
  width: 14px;
  display: inline-block;

  &.is-global .tooltip {
    visibility: hidden !important;
  }
}

.giving-pattern--helptip-info {
  margin-top: 10px;
}

.helptip-item--header {
  color: $white;
  font-size: 16px;
  padding: 12px 0 12px 12px;
}

.helptip-item--trigger {
  color: $gray200;
  cursor: pointer;
}
