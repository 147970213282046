.engagement-icon-list {
   display: inline-block;
   vertical-align: middle;
}

.engagement-icon-list--icon {
   position: relative;
   float: left;
   border-radius: 50%;
   border: solid 2px $white;


   position: relative;
   margin-left: -8%;

   &:first-child {
      margin-left: 0px;
   }

   &.is-disabled {
      @include filter(grayscale(100%));
   }
}
