.geolocation-error {
  position: relative;
  box-shadow: 0 1px 5px $gray500;
  background: $white;
  margin-top: 1px;
  border-radius: 3px;
  max-width: 412px;
  float: right;
}

.geolocation-error--header {
  background: $gray900;
  border-radius: 3px 3px 0 0;
  color: $white;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 1.15em;

  &::before {
    @include triangle(18px, $gray900, up);
    @include position(absolute, -9px 68px null null);
    content: "";
  }
}

.geolocation-error--close {
  float: right;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: fade-out($white, 0.5);
  }
}

.geolocation-error--body {
  padding: 16px;
}
