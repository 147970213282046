.contact-profile-v2,
.contact-profile-v2--empty-message {
  .empty-state-message {
    width: 100%;
  }

  .empty-state-message--icon {
    font-size: 24px;
  }

  .empty-state-message--text {
    font-size: $baseFontSize;
    margin: 0;
  }

  .empty-state-message--thankview {
    .empty-state-message--text {
      font-size: 24px;
    }

    .tv-icon-logo {
      font-size: 44px;
    }
  }
}

.contact-profile--not-found {
  width: 100%;
  color: $gray200;
  margin: 100px auto 0 auto;

  .empty-state-message--icon {
    font-size: 120px;
    color: lighten($gray200, 5%);
  }

  .empty-state-message--text {
    font-size: 24px;
  }
}

.contact-profile--body {
  overflow: auto;
  padding: 8px;

  .profile-nav-section:last-child .profile-nav-section--separator {
    display: none;
  }
}

.contact-profile--card-header-small {
  padding-bottom: 16px;

  &.profile-card-header {
    margin: 0;
  }
}

.contact-profile--giving {
  .contact-profile--giving-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    &.has-gift-feed {
      .contact-profile--card-score {
        display: block;
      }
    }
  }

  .contact-profile--card-score {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 16px;
    > * {
      flex-grow: 1;
      margin: 0 20px;
    }
  }
}

.contact-profile-details--content {
  .contact-profile--body {
    padding: 0 16px;
  }
}

.contact-profile--empty-button {
  margin: 20px;
}

.contact-profile--clarity {
  .profile-card--inner {
    padding: 16px;
  }
}

.profile-nav-section--separator {
  height: 1px;
  background: $border;
  margin: 16px 16px 0 16px;
}

.profile-search-banner {
  padding: 16px;

  .gt-icon-filter {
    @extend .profile-card-header--icon;
  }
}
