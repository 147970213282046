.invitation-header {
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.invitation-header--csv {
  display: flex;
  min-width: 365px;
  justify-content: space-evenly;
  align-items: center;
}

.invitation-body {
  margin: 1rem;
  overflow-y: visible;
}

.invitation-form {
  border: 1px solid $border;
  background-color: #f3f6f7;

  .row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem 0 1.5rem;
    width: 100%;

    &:last-child {
      padding-top: 0;
    }

    .duel-fields {
      display: flex;
      justify-content: space-evenly;

      .single-field {
        width: 49%;
      }
      .first {
        margin-right: 1rem;
      }
    }

    .field {
      width: calc(100% / 3 * 1);
      .error {
        border: #ff0000 solid 1px;
        border-radius: 3px;
      }
      .error-feedback {
        color: #ff0000;
        font-size: 12px;
        margin-bottom: -1.5rem;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
      .combo-box-height-adjustment {
        a {
          padding-top: 7px;
          padding-bottom: 8px;
        }
        ul {
          i {
            top: 5px;
          }
        }
        i {
          top: 13px;
        }
      }
    }
  }

  .et--advanced-combobox {
    max-width: 100%;
  }
}

.invitation-button-group {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 1.5rem 1rem 0;
}

.invitation-button--invite {
  margin-left: 16px;
}
