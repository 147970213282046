.journeys-table-filters {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.journeys-table--header {
  padding: 10px 16px;
  font-weight: 500;
}

.journeys-table--body {
  table {
    min-height: 400px;

    tbody {
      tr {
        min-height: 60px;
      }
    }
  }
}

.journey-name {
  @include flex-center;
}

.favorite-star {
  cursor: pointer;
  font: normal normal normal 15px / 1 'Givingtree2';
  padding-right: 25px;
}

.favorited {
  color: rgb(236, 203, 72);
}

.clickable-active-constituent {
  color: #007c9e;
  cursor: pointer;
}

.cadence-list-item {
  text-align: left;
}
