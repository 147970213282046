// with saved searches
.searches-table {
  table-layout: fixed;
  width: 100%;

  th {
    font-size: $fontSizeLarge;

    span {
      vertical-align: 2px;
    }

    i {
      margin-right: 10px;
      font-size: 1.5em;
    }
  }
}

.searches-header--name {
  width: 40%;
}

.searches-header--saved {
  width: 100px;
}

.searches-header--pref {
  width: 25%;
}

.searches-row--name {
  font-weight: 500 !important;
}

.searches-row--saved {
  color: $green200;
  text-align: right;
  padding-right: 3%;
}

.searches-row--pref-cell {
  .advanced-combobox {
    width: 60%;
  }
}

.searches-row--toggle {
  line-height: 41px;
  margin-right: 16px;
}

.searches-row--empty {
  text-align: center;
  font-style: italic;
  color: $gray500;
  padding: 8px;
  height: 62px;

  .fa-question-circle {
    color: $linkText;
    font-size: 1.1em;
  }
}

// without saved searches
.saved-searches--tutorial {
  margin-top: 25px;

  li {
    counter-increment: step-counter;
    margin-bottom: 40px;
    font-weight: 500;

    &::before {
      @include circle(32px, $gray300, $white);
      content: counter(step-counter);
      display: inline-block;
      font-weight: bold;
      line-height: 32px;
    }
  }
}

.saved-searches--list-item {
  margin-left: 15px;
  max-width: 583px;
  display: inline-block;
  vertical-align: middle;
}

.saved-searches--img {
  width: 397px;
  display: block;
  margin: 13px 0 0 45px;
}
