.interaction-icon {
  @include flex-center();
  color: $white;
  border-radius: 40px;
  padding: 8px;
  font-size: 20px;
  margin-right: 16px;
  border: solid 2px $gray500;
  background: $gray500;

  &.is-comment {
    background: $green200;
    border: solid 2px $green200;
  }
  &.is-secondary {
    background: $gray150;
    border: solid 2px $gray150;
  }
}

.interaction-icon--light {
  color: $gray150;
  border: solid 2px $gray150;
}
