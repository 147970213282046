.bulk-add-to-pool-modal {
  padding: 24px;
}

.bulk-add-to-pool-modal--heading {
  margin-bottom: 24px;
  font-weight: $bold;
}

.bulk-add-to-pool-modal--radios {
  padding: 0 16px;

  .et--radio--label {
    font-size: 18px;
  }
}

.bulk-add-to-pool-modal--second-radio {
  margin-left: 68px;
}
