$colHeaderHeight: 75px;
$colHeaderHeightTall: 95px;
$avatarWidth: 55px;
$contactCardPadding: 16px;
$contactCardHeight: 90px;
$contactCardHeightMed: 120px;
$contactCardHeightTall: 140px;
$scrollBarHeight: 50px;

.my-portfolio--filters .page-header--label {
  .selected-contacts-string {
    padding-top: 2px;
  }
}

.my-portfolio--title-dropdown {
  width: auto;
  display: inline-block;
  height: 28px;
  font-size: $baseFontSize;
  margin-bottom: 8px;
  min-width: 200px;

  .dropdown--body {
    width: 300px;
    border-top: 1px solid $border;
    font-size: $baseFontSize;

    &.is-open {
      @include calc(top, "100% - 1px");
    }
  }
}

.my-portfolio--toolbar {
  border-bottom: 1px solid $border;

  &.fixed-page-wrapper {
    top: 75px; // height of segment-filters
  }
}

.my-portfolio--sort {
  display: inline-block;
  margin-left: 20px;
}

.my-portfolio--sort-section {
  margin-left: auto;
}

.my-portfolio--wrapper {
  overflow-y: auto;
  top: 147px; // height of segment-filters + contact-table-toolbar
}

.my-portfolio--columns {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  display: inline-block;
  height: 100%;
  padding: 16px 16px 0;
}

.my-portfolio--col-header {
  width: 100%;
  padding: 16px 16px 16px 0;
  border: 1px solid $border;
  position: absolute;
  height: $colHeaderHeight;
  background: $white;
  z-index: 1;

  &.show-aging {
    height: $colHeaderHeightTall;
  }
}

.my-portfolio--col-title {
  @include calc(width, "100% - 48px");
  display: inline-block;

  a {
    display: block;

    &:hover {
      cursor: pointer;
    }
  }
}

.prospect-group--checkbox {
  display: inline-block;
  margin-right: 16px;
  vertical-align: top;
}

.status-title {
  font-size: $fontSizeLarge;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-portfolio--col {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
  width: 350px;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.has-scrollbar .my-portfolio--contact-table {
  @include calc(max-height, "100% - #{$colHeaderHeight} - #{$scrollBarHeight}");

  &.show-aging {
    @include calc(max-height, "100% - #{$colHeaderHeightTall} - #{$scrollBarHeight}");
  }
}

.my-portfolio--contact-table {
  @include calc(max-height, "100% - #{$colHeaderHeight}");
  margin-top: $colHeaderHeight;
  overflow-y: auto;
  border-bottom: 1px solid $border;

  table {
    margin: 0;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td {
    background: $white;
  }

  .prospect--checkbox {
    border-left: 1px solid $border;
  }

  &.show-aging {
    @include calc(max-height, "100% - #{$colHeaderHeightTall}");
    margin-top: $colHeaderHeightTall;
  }
}

.prospect--row {
  > td {
    height: $contactCardHeight;
    padding: 0;
  }

  &.show-aging > td,
  &.show-last-contact-date > td {
    height: $contactCardHeightMed;
  }

  &.show-aging.show-last-contact-date > td {
    height: $contactCardHeightTall;
  }
}

.prospect--checkbox {
  width: 36px;
  text-align: right;

  .checkbox--box {
    margin: 0;
  }
}

// Based off map-contacts--card
.prospect--card {
  border-right: 1px solid $border;

  tr > td {
    padding: 0;
    border: none;
  }

  .new-contact-card {
    white-space: normal;
    padding-left: 16px;
  }

  .new-contact-card--name {
    @extend .ellipsis-overflow;
  }
}

// contact cards on the my portfolio map
.my-portfolio--map.show-last-contact-date .map-contacts--contact > td {
  height: $contactCardHeightMed;
}

.my-portfolio--map .map-contacts--contact {
  .map-contacts--checkbox label {
    margin-bottom: 0;
  }

  .map-contacts--card tr > td {
    height: $contactCardHeight;
    padding: 0;

    &:first-child {
      @include calc(width, $avatarWidth + 2 * $contactCardPadding);
      padding: inherit;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .contact-card--avatar {
    display: block;
    margin-left: $contactCardPadding;
  }
}

td.prospect--empty-cell {
  text-align: center;
  background: $gray105;
  color: $gray200;
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}

.my-portfolio--col-spinner {
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}

.my-portfolio--empty-msg {
  text-align: center;
}

.my-portfolio--empty-text {
  margin: 50px 0 20px;
}

.my-portfolio--empty-btn {
  padding: 16px;
  margin-top: 16px;
}

.my-portfolio-status-change {
  background: $background;
  padding: 20px;
}

.my-portfolio-status-change--label {
  color: $gray500;
  margin-bottom: 8px;
}
