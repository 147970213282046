.meeting-form {
  .modal-confirm--body {
    padding: 16px;
  }
}

.meeting-form--phone-input {
  input {
    width: 435px;
  }
}

.meeting-form--calendar {
  width: 50%;
  margin-right: 8px;

  .dropdown--body {
    width: 250px;
  }
}

.meeting-form--invalid-time {
  .time-range--input {
    border-color: $red500;
  }
}

.meeting-form--anchor {
  text-align: right;
  margin-bottom: 8px;

  .boolean-radio {
    margin-left: 16px;

    .radio {
      margin-right: 8px;
    }
  }
}

.modal-confirm--helper-text:not(:empty) {
  margin: 24px 0 16px;
}
