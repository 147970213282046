.homepage-recommendations-card-left {
  @extend .title-subtitle-500-20;
  display: flex;
  flex-direction: column;
}

.homepage-recommendations-card-subtitle {
  @extend .title-subtitle-400-14;
  padding-top: 7px;
}

.homepage-recommendations-section-title {
  padding: 8px 0px;
  display: flex;
  align-items: center;
}

.homepage-recommendations-section-title > * {
  margin-right: 5px;
}

.homepage-recommendations-subtitle {
  color: #737373;
}

.homepage-recommendations-empty {
  color: #737373;
  padding-left: 23px; //this is including the icon size + margin in the section title: 18px + 5px.
}

.homepage-recommendations-windfall-content {
  padding: 8px 0px;
  text-align: center;
}

.homepage-recommendations-windfall-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: $gray500;
}
