$exportFieldOuterPadding: 20px;
$exportFieldInnerPadding: 13px;
$exportFieldHeight: 405px;
$exportFieldHeaderHeight: 35px;

.export-field-selector--container {
  @include outer-container;
  padding: $exportFieldOuterPadding;
  height: $exportFieldHeight;
  background: $background;
  border-top: solid 1px $border;
}

.export-field-selector--description {
  padding: $exportFieldOuterPadding;
}

.export-field-selector--categories {
  @include span-columns(6, block-collapse);
}

.export-field-selector--fields {
  @include span-columns(6, block-collapse);
}

.export-field-selector--heading {
  font-size: 18px;
  height: $exportFieldHeaderHeight;
  padding-left: $exportFieldInnerPadding;
}

.export-field-selector--error {
  padding: 50px 20px;
  font-size: 1.3em;
  line-height: 1.3em;
  color: $gray500;
  text-align: center;
}

.export-field-selector--cat-list {
  > li {
    cursor: pointer;
    border: solid 1px transparent;
    padding: $exportFieldInnerPadding;
    font-weight: bold;
    color: $linkMainColor;
    line-height: 1em;
    border-radius: 2px 0 0 2px;
    background: $gray108;

    &:hover:not(.is-selected) {
      background: lighten($gray150, 5%);
    }
  }

  .et--checkbox {
    @include vertical_align();
    padding-right: 10px;
    margin-bottom: 0px;
  }

  .is-selected {
    border: solid 1px $border;
    border-right: none;
    background: $white;
    color: $purple500;
    position: relative;

    &::after {
      @include position(absolute, 0px -3px 0px null);
      content: "";
      width: 3px;
      background: $white;
    }
  }
}

.export-field-selector--sub-list {
  background: $white;
  padding: $exportFieldInnerPadding;
  border: solid 1px $border;
  border-radius: 2px 2px 0 0;
  height: $exportFieldHeight - $exportFieldOuterPadding - $exportFieldHeaderHeight;
  overflow-y: auto;
  overflow-x: hidden;

  .et--checkbox {
    padding-bottom: $exportFieldInnerPadding;
    text-align: left;
    font-weight: normal;
    margin-bottom: 0;
  }

  .et--checkbox--box {
    margin-right: 10px;
  }
}
