.profile-ai-nav {
  @extend .sub-nav;
  border-bottom: 0;
}

.profile-ai-summary {
  padding: 16px 16px 0px 16px;

  .loading {
    position: relative;
  }

  small {
    font-size: 12px;
  }
}

.loader-div {
  height: 30px;
}

.profile-summary-pill {
  background-color: #F6F2FB;
  padding: 3px 7px;
  width: fit-content;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.profile-summary-ai-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
.profile-ai-summary-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.profile-ai-summary-text {
  flex: 1;
}
}

.profile-ai-summary-actions {
  margin: 0px 0px 12px;

  .profile-ai-summary-refresh {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }

  .profile-ai-summary-copy {
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
}