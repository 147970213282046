//Override base.scss style for h1, ol and ul.
.resource-section-h1 {
  font-size: 32px;
  margin-bottom: 16px;
}

.resource-section-h2 {
  margin-bottom: 16px;
  margin-top: 24px;
}

.resource-section-h3 {
  margin-bottom: 16px;
  margin-top: 24px;
}

.resource-section-list {
  list-style-type: revert;
  margin-bottom: 16px;
  padding-left: 20px;
}

.resource-section-hr {
  margin-top: 24px;
  margin-bottom: 24px;
}

.resource-section-blockquote {
  border-left: 4px solid rgb(231, 235, 238);
  padding-left: 24px;
  margin: 16px 0px 0px;
  font-style: italic;
  p {
    strong {
      font-weight: 600;
    }
  }
}

.resource-section-links {
  margin: 10px 0;
}
