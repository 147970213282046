.portfolio-performance--nav {
  @extend .sub-nav;
}

.portfolio-performance--team-dropdown {
  max-width: 500px;
  min-width: 300px;

  .et--advanced-combobox .et--dropdown--toggle,
  .et--advanced-combobox .et--dropdown--toggle:hover {
    background: $white;
  }

  .et--advanced-combobox--selected-text {
    display: inline;
    padding-right: 0;
  }

  .et--advanced-combobox .et--dropdown--header .gt-icon {
    position: relative;
    display: inline;
    top: auto; // for overriding default styles
    right: auto; // for overriding default styles
  }

  .et--dropdown--toggle {
    padding: 0 0 2px;
    box-shadow: none;
    display: inline-block;
  }

  .et--advanced-combobox-content {
    font-size: $baseFontSize;
  }
}

.portfolio-performance--controls {
  padding-bottom: 8px;
  border-bottom: 1px solid $border;
}

.portfolio-performance--add-prospects {
  margin-right: 16px;
}

.portfolio-performance--tabs {
  margin-right: 16px;

  .button-select--item-icon {
    font-size: 16px;
  }
}

.portfolio-performance--toolbar {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;

  .selected-items-toolbar {
    margin-right: 16px;
  }

  .button-select,
  .button-select .button-select--item {
    height: 100%;
  }
}

.portfolio-performance--solicitor-dropdown {
  width: 200px;
  margin-right: 16px;

  .et--advanced-combobox--selected-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
}

.portfolio-performance--sort-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.portfolio-performance--filter-icon {
  background: $white;
  padding: 8px;
  border: 1px solid $border;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom: 2px;
  border-right: 0;
  height: 100%;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -3px 0 0 $gray110;

  &.is-disabled {
    color: $gray500;
  }
}

.portfolio-performance--body-wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 142px;
  bottom: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-right: 16px;
}

.portfolio-performance--body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 16px;
  margin-right: 16px;
  height: 100%;
}

.portfolio-performance--column-outer {
  flex: 0 0 auto;
  width: 390px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }
}

.portfolio-performance--column-inner {
  position: relative; // For loading spinners
  border-radius: 12px;
  background-color: $gray105;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portfolio-performance--column-header {
  display: flex;
  flex-direction: row;
  padding: 12px;
}

.portfolio-performance--column-header-text {
  font-size: 18px;
  color: $gray900;
  padding-right: 4px;
}

.portfolio-performance--column-total-text {
  color: $gray500;
  font-weight: 500;
}

.portfolio-performance--column-average-time-in-stage-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
}

.portfolio-performance--column-average-time-in-stage-text {
  font-size: 14px;
}

.portfolio-performance--column-average-time-in-stage-bold {
  font-weight: 600;
}

.portfolio-performance--column-cards {
  padding: 12px;
  margin-bottom: 12px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.portfolio-performance--dnd-list {
  height: 100%;
}

.portfolio-performance--load-more {
  background: $white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  padding: 10px 0;
}

.portfolio-performance--card {
  user-select: none;
  margin-bottom: 12px;
}

.dnd-controller--is-dragging {
  .portfolio-performance--card {
    transform: rotate(-5deg);
  }
}

.portfolio-performance--card-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 0 8px;
  margin-bottom: 12px;
  justify-content: space-between;
}

.portfolio-performance--card-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.portfolio-performance--card-checkbox {
  margin-bottom: 0;
  border-radius: 2px;
}

.portfolio-performance--card-avatar-wrap {
  position: relative;
}

.portfolio-performance--card-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-left: 12px;
  margin-right: 20px;
}

.portfolio-performance--card-proposal-icon {
  position: absolute;
  top: 36px;
  right: 14px;
  font-size: 18px;
}

.portfolio-performance--card-name {
  color: $linkText;
  font-weight: 500;
  padding-right: 2px;
}

.portfolio-performance--card-key-prospect-icon {
  color: $gray900;
}

.portfolio-performance--card-more {
  color: $gray150;

  i {
    margin-right: 0;
  }
}

.portfolio-performance--card-content {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
}

.portfolio-performance--card-content-label {
  color: $gray500;
}

.portfolio-performance--card-footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background-color: $gray105;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 6px 11px;
}

.portfolio-performance--card-footer-left {
  width: 75%;
}

.portfolio-performance--card-footer-right {
  width: 25%;
  text-align: right;
}

.pp-prospect-card--actions {
  font-size: 14px;

  .et--actions-menu--menu-option {
    padding: 8px;

    i {
      margin-right: 8px;
    }

    &:hover {
      color: $purple500;
    }
  }
}

// Empty States
.portfolio-performance--no-stages {
  color: $gray500;
  flex: 1;
  text-align: center;
  padding-top: 40px;
  font-size: 16px;
}

.portfolio-performance--empty-col {
  padding-top: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;

  i {
    color: $white;
    font-size: 80px;
    margin-bottom: 12px;
  }
}

.portfolio-performance--no-prospects {
  font-weight: 500;
  text-align: center;
}

.portfolio-performance--no-prospects-add {
  display: block;
  padding-top: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;

  i {
    color: $white;
    font-size: 40px;
  }
}

.portfolio-performance--map {
  overflow-y: auto;
  top: 225px;
}

.stage-move-checkbox {
  padding-top: 12px;
}

.portfolio-performance--header {
  .page-header--label {
    padding: 0;
    height: 100%;
    align-items: center;
  }

  .page-header--heading {
    display: inline;
    font-size: 14px;
  }

  .segment-filters--header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.portfolio-performance--title {
  padding-left: 16px;
  font-size: 20px;
}

.portfolio-performance--action-trigger {
  margin-left: auto;
}

.portfolio-performance--map-toggle {
  margin: 0 8px;
}

.assignment-type--dropdown {
  display: flex;
  margin-left: 16px;
  align-items: center;

  strong {
    margin-right: 15px;
  }
}