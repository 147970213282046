.interaction-list-item {
  display: flex;
  justify-content: space-between;
  background: $white;
  width: 100%;
  padding: 16px;
  border-top: none;
  cursor: pointer;
  white-space: normal;
  // this is a link so override default link color
  color: unset;

  &:hover {
    background: $gray105;
  }

  &:last-child {
    border-bottom: none;
  }

  .note-feed-item {
    flex: 3;
  }

  .interaction-meta {
    flex: 1;
  }
}

.interaction-list-item--pinned {
  background: rgb(245, 250, 245);
  }
