$contactFixedTableHeaderHeight: 72px;
$contactFixedTableHeaderPadding: 16px;

.contact-fixed-table {
  @include position(absolute, 0px 0px 0px 0px);
  overflow: hidden;

  .table--header > tr > th:first-child {
    border-right: none;
  }

  .contact-card--avatar {
    border-radius: 60px;
  }

  .table--body > tr > td {
    text-align: left;
    padding: 0 25px;
    position: relative; // For loading spinners

    &:first-child {
      padding-left: 16px;
      border-right: none;
    }
  }

  .loading {
    border-top: solid 1px $border;
  }

  .empty-state-message {
    @include position(absolute, 50% null null null);
    margin-top: -85px;
  }
}

.contact-fixed-table--empty {
  border-top: solid 1px $border;
  background: #fafafa;
}

// HEADER ----------------------------------------
.contact-fixed-table--header {
  height: $contactFixedTableHeaderHeight;
  padding: $contactFixedTableHeaderPadding;

  .is-empty {
    background: $white;
    color: $gray500;
  }
}

.contact-fixed-table--selector {
  float: left;
  padding: 10px;

  .popover--body {
    margin-top: -10px;
  }
}

.contact-cell--add-list {
  @include position(absolute, 50% -30px null null);
  @include circle(40px, $purple500, $white);
  margin-top: -25px;
  border: solid 4px $white;
  box-shadow: 1px 0 0 $border;
  line-height: 33px;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $white;
  }
}

.fixed-table--header > thead > tr > th > span > .et--checkbox {
  margin-left: 15px;
}

// BODY / TABLE HEADER ----------------------------------------
.contact-fixed-table--header-label {
  display: inline-block;
  line-height: 14px !important;
}

.contact-fixed-table--header-menu {
  > li > button {
    padding: 4px 16px;
    text-align: left;
    font-weight: normal;
    width: 100%;
  }

  .contact-fixed-table--edit-cols button {
    border-top: solid 1px $border;
    padding: 8px 16px;
    margin-top: 4px;
    width: 100%;
  }
}

.contact-fixed-table--sort {
  @include position(absolute, 16px 0 0 5px);
  font-weight: normal;
  color: $gray200;
}

// BODY / TABLE BODY ----------------------------------------
.contact-fixed-table--body {
  @include position(absolute, 0px 0px 0px 0px);
  overflow: hidden;

  .contact-fixed-table--edit-button {
    visibility: hidden;
  }

  // This hides the "Edit Columns" button in all rows except the first
  tr:first-child .contact-fixed-table--edit-button {
    visibility: visible;
  }
}

.contact-fixed-table--contact-cell {
  padding: 0 8px 0 0 !important;
}

.contact-fixed-table--donut {
  z-index: 0;
}

.contact-fixed-table--score-progress {
  width: 124px;
  margin: 0 auto;
}

.contact-fixed-table--score-progress-card {
  width: 220px;

  .rich-tooltip--body {
    padding: 0 20px;
  }

  &.rich-tooltip {
    box-shadow: 0 1px 2px $border;
  }

  &.is-legacy {
    border: solid 1px $border;
    border-bottom-width: 2px;
    border-radius: 2px;
  }
}

.contact-cell {
  position: relative;
  z-index: 1;

  .addlist-popover {
    position: static;

    .popover--body {
      left: 115%;

      &::before,
      &::after {
        top: 38px;
      }
    }
  }

  .show-hide-animate {
    height: 0px;
  }
}

.contact-fixed-table--col-spacer {
  border-bottom: none;
}

.contact-fixed-table--constituency,
.contact-fixed-table--custom-field-cell {
  word-break: break-word;
}

.contact-fixed-table--event-response-icon {
  display: inline-block;
  margin-right: 12px;
  margin-left: 4px;
  vertical-align: middle;
}

.contact-fixed-table--event-response-label {
  vertical-align: middle;
}

.contact-fixed-table--minus {
  cursor: pointer;
  color: #a54ab5;
  margin-left: 9px;
}
