.filter-text-input {
  display: inline-block;
  position: relative;
  width: 90%;
}

.filter-text-input--textbox {
  height: 40px;
  margin-bottom: 0 !important;
  width: 100%;
}

.filter-text-input--clear {
  @include position(absolute, 10px 7px null null);
  cursor: pointer;
  color: $gray200;
  font-size: 20px;

  &:hover {
    color: $purple500;
  }
}
