$baseNavWidth: $layoutNavWidth;
$baseNavCollapsedWidth: $layoutNavWidthCollapsed;
$baseNavBackground: $gray105;
$baseNavHoverBackground: $gray110;
$baseNavText: $gray500;
$baseNavTextHover: $gray900;
$baseNavFontSize: 14px;
$baseNavItemHeight: 32px;
$baseNavItemPadding: 20px;

#base-nav {
  @include position(absolute, 0px 0px 0px 0px);
  background: $baseNavBackground;
  color: $baseNavText;
  font-size: $baseNavFontSize;
  overflow: hidden;

  &.is-collapsed {
    .base-nav--header {
      @include transform(translateX(-($baseNavWidth - $baseNavCollapsedWidth)));
    }

    .base-nav-sub-item--icon {
      margin-right: 0;
    }

    .base-nav-sub-item--label,
    .base-nav--footer-text,
    .base-nav--data-update,
    .base-nav--db-data-tag,
    .status-indicator--description {
      @include transition(opacity 0.01s ease-out 0.19s);
      opacity: 0;
      display: none;
    }
  }

  .focus-visible:not(.btn-action) {
    &:focus:not(:disabled) {
      outline: none;
      box-shadow: inset -2px 0px 0 2px $purple300;
    }
  }
}

.base-nav--header {
  @include transition(transform 0.2s ease-out);
  transform: translate(0);
  height: $mainHeaderHeight;
  background-color: $baseNavBackground;
  padding-left: $baseNavItemPadding;

  .gt-icon-collapse {
    float: right;
    color: $gray200;
    width: $baseNavCollapsedWidth;
    height: $mainHeaderHeight;
    line-height: $mainHeaderHeight;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      color: $baseNavTextHover;
    }
  }

  .gt-icon-evertrue-circle {
    color: $green200;
    float: right;
    width: $baseNavCollapsedWidth;
    height: $mainHeaderHeight;
    line-height: $mainHeaderHeight;
    font-size: 36px;
    cursor: pointer;

    &:hover {
      color: $baseNavTextHover;
    }
  }
}

.base-nav--logo {
  background: url($logoFullColor) no-repeat left center;
  background-size: 100%;
  height: $mainHeaderHeight;
  width: 136px;
  float: left;
  cursor: pointer;
}

.base-nav--pyramid-logo {
  background: url($logoPyramid) no-repeat left center;
  background-size: 100%;
  height: $mainHeaderHeight;
  width: 46px;
  margin-left: 8px;
  cursor: pointer;
}

.base-nav--pyramid-logo:hover {
  opacity: 0.5;
}

.base-nav--db-data-tag {
  margin: 0 24px 8px;
}

.base-nav--content {
  @include position(absolute, $mainHeaderHeight 0px 110px 0px);
  padding: 16px 0 75px 0;
  overflow: auto;
}

.base-nav--line {
  border-bottom: 1px solid #e2e2e2;
  width: 85%;
  margin: 0px auto;
}

.base-nav--main-group {
  margin-bottom: 13px;
}

.base-nav--main-group-title {
  text-transform: uppercase;
  padding: 6px 20px;
  font-weight: bold;
  color: fade-out($white, 0.5);
  &.is-collapsed {
    padding: 6px 0;
    font-size: 12px;
    text-align: center;
  }
}

.base-nav--footer {
  @include position(absolute, null 0px 0px 0px);
  background: $baseNavBackground;
  padding: 8px 0;
  min-height: 200px;

  .gt-icon-access-time {
    margin-right: 5px;
    width: 25px;
  }
}

.base-nav--footer-item {
  padding: 8px 8px 8px 20px;
}

.base-nav--data-update {
  margin-left: 30px;
}

.base-nav-sub-item {
  @extend .ellipsis-overflow;
  background: $baseNavBackground;
  padding: 6px $baseNavItemPadding;
  height: $baseNavItemHeight;
  color: $baseNavText;
  width: 100%;
  display: flex;
  align-items: center;
  &:focus:not(:disabled),
  &:hover:not(:disabled),
  &.is-active {
    color: $baseNavTextHover;
    background: $baseNavHoverBackground;
  }

  &.is-active {
    box-shadow: inset 4px 0px 0px $green200;
  }

  &:visited {
    color: $baseNavText;
  }
}

.base-nav-sub-item--icon {
  margin-right: 20px;
  font-size: 1.3em;

  &.gt-icon-search {
    font-weight: bold;
    font-size: 1.2em;
  }
}

.base-nav-sub-item--tooltip {
  position: absolute;
  left: $baseNavCollapsedWidth + 20px;
  background: $green200;
  color: $white;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 2px;
  white-space: nowrap;
  letter-spacing: 0.5px;

  &::before {
    @include triangle(28px, $green200, left);
    @include position(absolute, 0px null 0px -14px);
    content: "";
  }
}

.base-nav--segments,
.base-nav--lists {
  border-top: solid 1px fade-out($white, 0.8);
  position: relative;
  margin-top: 28px;
  padding-top: 16px;
}

.base-nav--beta-badge {
  @include position(absolute, null 10px null null);
  background-color: fade-out($baseNavText, 0.3);
  padding: 0 3px;
  display: inline-block;
  color: $baseNavBackground;
  border-radius: 3px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 8px;
  line-height: 20px;
}

.gt-icon-interactions {
  font-size: 1.1em;
  margin-left: 4px;
}

.gt-icon-other-interaction {
  font-size: 1.2em;
  margin-left: 1px;
}
