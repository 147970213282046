.maps-controller {
  // create the flex box to hold contact list and map 
  display: flex;
  height: 95%;
  padding-top: 18px;
}

.map-containerv2 {
  // set container to fill the height/width of the flex box
  flex: auto;
  position: relative;
}

#mapv2 {
  // sets the map to fill the size of its container
  width: 100%;
  height: 100%;
  z-index: 1; // puts map "behind" map toolbar
}