$saveSegmentPadding: 15px;

.save-segment {
  text-align: left;

  hr {
    margin: 0;
  }
}

.save-segment--header {
  padding: $saveSegmentPadding;
}

.save-segment--options {
  @extend .clearfix;

  > li > a {
    padding: 15px 10px;
    background: $gray105;
    float: left;
    width: 50%;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9em;
    color: fade-out($gray900, 0.6);
    border-top: solid 1px $border;
    border-bottom: solid 1px $border;

    &:first-child {
      border-right: solid 1px $border;
    }

    &:hover {
      color: $purple500;
    }

    &.is-active {
      color: $gray300;
      position: relative;
      background: $white;
      border-bottom: none;
    }
  }
}

.save-segment--wrapper {
  padding: $saveSegmentPadding;

  input {
    padding: 12px;
  }
}

.save-segment--container {
  padding-top: $saveSegmentPadding;

  .advanced-combobox {
    width: 45%;
    float: right;
  }
}

.save-segment--switch {
  padding: 8px 0;
}

.save-segment--footer {
  padding: $saveSegmentPadding;
}

.save-segment--btn {
  width: 48%;
  padding: 12px;
}

.save-segment--empty {
  text-align: center;
  font-style: italic;
  color: $gray500;
  padding: 8px;

  .fa-question-circle {
    color: $linkText;
    font-size: 1.1em;
  }
}
