.journey-nav-tabs {
  color: #737373;
  height: 35px;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: solid 3px #ffffff;
  display: inline-block;
  margin-block: 8px;
  margin-left: 16px;

  &:focus {
    &:hover {
      color: #454545;
    }
  }

  &:hover {
    color: #454545;
  }

  &.is-active {
    color: #454545;
    border-bottom: solid 3px #a54ab5;
    &:hover {
      color: #454545;
    }
  }
}
