.profile-clarity--total-score {
  padding: 18px 0;
  border-bottom: solid 1px $border;
}

.profile-clarity--total-progress {
  .score-progress--wrapper {
    height: 21px;
    display: block;
  }

  .score-progress--inner {
    height: 21px;
  }

  .score-progress--label {
    font-size: 24px;
    color: $gray500;
    font-weight: bold;
    display: inline-block;
    margin-top: 16px;

    &.is-empty {
      font-size: 20px;
    }
  }
}
