.header-user-dropdown {
  float: none;
  text-align: left;
  height: $mainHeaderHeight;
  cursor: pointer;
  color: $white;
  padding: 10px;
}

.header-user-dropdown--trigger {
  color: $gray500;
  vertical-align: middle;
  padding: 10px 4px 10px 10px;

  &:active,
  &:hover,
  &:focus {
    color: $gray900;
    &:hover {
      color: $gray900;
    }
  }

  &.focus-visible {
    @include pink-focus();
  }
}

.header-user-dropdown--body {
  .menu li {
    > a,
    > div,
    > button,
    > button:hover:not(:disabled) {
      width: 100%;
      padding: 8px 16px;
      display: flex;
      align-items: center;
    }

    i {
      font-size: 1.7em;
      width: 20px;
      margin-right: 16px;
    }

    .fa-cloud-download {
      font-size: 1.5em;
    }

    > button:hover:not(:disabled) {
      background: $gray105;
    }
  }
}

.header-user-dropdown--info {
  margin: 16px;
  margin-bottom: 4px;
  padding-bottom: 16px;
  border-bottom: solid 1px $border;
  display: flex;
  align-items: center;
}

.header-user-dropdown--avatar {
  @include vertical_align();
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.header-user-dropdown--name {
  @include vertical_align();
  margin-left: 16px;
  max-width: 175px;
}

.header-user-dropdown--org-info {
  i {
    margin-left: 10px;
  }
}

.header-user-dropdown--user-state {
  padding: 8px 0;
  margin: 0 16px;
  border-bottom: solid 1px $border;
}

.header-user-dropdown--redaction-button {
  margin-right: 8px;
  border: 1px solid $border;
  overflow: hidden;

  &.is-active {
    border-color: $linkText;
  }
}

.header-user-dropdown--redaction-example {
  display: inline-block;
  text-align: center;
  width: 42px;

  &.is-not-redacted {
    color: transparent !important;
  }

  &.is-redacted-fs {
    background: repeating-linear-gradient(45deg, #fff, #fff 6px, $gray105 6px, $gray105 12px);
    color: transparent !important;
  }

  &.is-redacted-cia {
    background-color: #000;
    color: transparent !important;
  }

  &.is-redacted-blur {
    filter: blur(3px);
  }
}
