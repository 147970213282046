/* Accessible lighter text */
/* Default non-white background */
/* Green text, or white text on green e.g. success */
/* Large text, icons, graphs */
/* Standard brand color. OK for icons, graphs, etc */
/* Success */
/* Default color for links and buttons */
/* Hover e.g. buttons */
/* Selected icons, radios, checkboxes, etc */
/* Active state */
/* Alerts, Danger */
/* Danger */
/* Highlight */
:export {
  black: #000000;
  white: #ffffff;
  gray1400: #2c2c2c;
  gray900: #454545;
  gray500: #737373;
  gray300: #939393;
  gray200: #b7b7b7;
  gray150: #d7d7d7;
  gray110: #f3f3f3;
  gray108: #f6f6f6;
  gray105: #f9f9f9;
  green500: #3f7f34;
  green300: #53a744;
  green200: #73c166;
  green106: #f4faf4;
  blue500: #007c9e;
  blue300: #00a0cc;
  blue200: #00c0f5;
  blue106: #f2fafc;
  purple500: #a54ab5;
  purple300: #ba72c5;
  purple200: #cc9dd7;
  purple105: #fcf8fc;
  red500: #de1b2c;
  red300: #eb5c68;
  red106: #fef6f7;
  yellow150: #f3ca16;
  yellow108: #fdf7dc; }

.stage-report--section {
  text-align: center; }

.stage-report--icon {
  border-radius: 40px;
  padding: 8px;
  background-color: #f9f9f9;
  color: #ffffff;
  margin-top: 16px; }

.stage-report--not-enabled {
  text-transform: uppercase;
  color: #737373;
  font-weight: 500; }

.stage-report--stage-container {
  max-height: 260px;
  overflow-y: auto; }
  .stage-report--stage-container.is-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: unset;
    overflow-y: hidden;
    margin-top: 8px; }

.pool-report {
  padding: 16px; }
  .pool-report.is-mobile .et--card--main {
    display: flex;
    padding: 0; }
  .pool-report.is-mobile .et--kpi-card {
    flex-basis: 50%; }

.pool-report--performance {
  margin-top: 16px; }
  .pool-report--performance.is-mobile .et--card--header-section {
    border-bottom: 3px solid #f6f6f6; }
  .pool-report--performance.is-mobile .et--dropdown--header {
    position: static; }

.pool-report--performance-main {
  display: flex;
  justify-content: space-between;
  min-height: 200px;
  width: 100%; }
  .pool-report--performance-main.is-mobile {
    flex-direction: column; }

.pool-report--perf-section {
  position: relative;
  border-left: 1px solid #f6f6f6;
  flex-basis: 25%;
  min-width: 200px;
  padding: 16px; }
  .pool-report--perf-section:first-child {
    border: none; }
  .pool-report--perf-section.is-mobile {
    border: none;
    border-bottom: 1px solid #f6f6f6;
    padding: 24px 16px 34px; }
    .pool-report--perf-section.is-mobile:last-child {
      border-bottom: none; }

.pool-report--stage {
  display: flex;
  justify-content: space-between; }

.pool-report--perf-title {
  font-size: 16px;
  font-weight: 400; }
  .pool-report--perf-title .et--dropdown {
    display: flex;
    cursor: pointer; }
  .pool-report--perf-title .et--dropdown--body {
    width: max-content; }
  .pool-report--perf-title .et--dropdown--toggle {
    background: transparent;
    box-shadow: none;
    color: inherit; }
    .pool-report--perf-title .et--dropdown--toggle .is-open {
      background: transparent; }
    .pool-report--perf-title .et--dropdown--toggle:hover:not(.is-open) {
      background: transparent; }
  .pool-report--perf-title.is-mobile .et--dropdown--body,
  .pool-report--perf-title.is-mobile .et--dropdown--header {
    width: 100%; }

.pool-report--stage-title {
  color: #737373; }

.pool-report--label {
  color: #737373;
  letter-spacing: 1px; }

.pool-report--large-num {
  color: #454545;
  font-size: 28px;
  font-weight: 500;
  margin: 8px 0; }

.pool-report--kpi-section .et--kpi-card {
  margin-bottom: 0;
  min-height: unset; }

.pool-report--kpi-section.is-mobile .et--card--main {
  flex-wrap: wrap; }

.pool-report--progress-bar .progress-bar--inner {
  background-color: #00a0cc; }

.pool-report--goal-raised {
  display: block;
  margin-top: 8px;
  color: #00a0cc;
  font-weight: 500; }

.pool-report--bar-section {
  margin-top: 54px; }
  .pool-report--bar-section.is-mobile {
    margin-top: 24px; }

.pool-report--no-giving {
  flex-basis: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #737373; }

.pool-report--kpi-card.is-mobile .et--kpi-card--title {
  font-size: 12px; }

.pool-report--no-pad-section.is-mobile {
  padding: 0;
  padding-top: 24px; }

.pool-activity-report {
  display: flex;
  justify-content: space-between; }
  .pool-activity-report.is-mobile {
    flex-direction: column; }

.pool-activity-report--right {
  flex-basis: 50%;
  padding-left: 40px; }
  .pool-activity-report--right.is-mobile {
    padding: 12px; }

.pool-activity-report--left {
  flex-basis: 50%; }
  .pool-activity-report--left.is-mobile {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 12px;
    padding: 12px; }

.pool-activity-report--type {
  display: flex;
  align-items: center;
  margin: 12px 0; }

.pool-activity-report--logged {
  margin-top: 60px; }
  .pool-activity-report--logged.is-mobile {
    margin-top: 24px; }

.pool-activity-report--icon {
  color: #454545;
  margin-right: 4px; }

.pool-activity-report--number {
  margin-left: auto; }

.pool-activity-report--label {
  width: 50%; }

.volunteers-stats-table--main {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  height: 100%; }
  .volunteers-stats-table--main .et--card--main {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .volunteers-stats-table--main.is-mobile {
    background: transparent; }
    .volunteers-stats-table--main.is-mobile .et--card--main {
      height: 100%; }

.volunteers-stats-table--table {
  font-size: 14px;
  height: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: column; }
  .volunteers-stats-table--table .et--flex-table--header-row {
    background: #f9f9f9;
    color: #939393;
    border-bottom: 1px solid #f6f6f6;
    min-height: 44px; }
  .volunteers-stats-table--table .et--contact-card {
    padding: 0; }
  .volunteers-stats-table--table .et--flex-table--cell,
  .volunteers-stats-table--table .et--flex-table--header-cell {
    border: none;
    border-right: 1px solid #f6f6f6; }
  .volunteers-stats-table--table .et--flex-table--header-cell {
    justify-content: center; }
  .volunteers-stats-table--table .et--flex-table--row {
    position: relative;
    border-bottom: none;
    border-bottom: 1px solid #f6f6f6; }
  .volunteers-stats-table--table.is-mobile .et--contact-card {
    border-bottom: 1px solid #f6f6f6; }

.volunteers-stats-table--header {
  display: flex;
  align-items: center;
  padding-left: 28px;
  padding-top: 8px;
  padding-bottom: 8px; }
  .volunteers-stats-table--header.is-mobile {
    flex-direction: column;
    padding: 0; }

.volunteers-stats-table--heading {
  color: #737373;
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 14px; }

.volunteers-stats-table--search {
  margin-left: auto;
  width: 300px;
  padding-right: 4px;
  order: 0; }
  .volunteers-stats-table--search.is-mobile {
    width: 100%; }

.volunteers-stats-table--pagination {
  text-align: center;
  margin: 12px 0; }

.volunteers-stats-table--contact-col {
  padding-bottom: 0;
  padding-top: 0; }
  .volunteers-stats-table--contact-col.et--flex-table--header-cell {
    justify-content: flex-start; }
  .volunteers-stats-table--contact-col .et--flex-table--sort-header-cell {
    text-align: left;
    padding-left: 12px; }

.volunteers-stats-table--number-stat {
  font-weight: 500;
  font-size: 18px;
  justify-content: flex-end;
  padding-right: 32px; }

.volunteers-stats-table--last-login {
  color: #737373; }

.volunteers-stats-table--given-tag {
  color: #69d0d1;
  width: auto;
  margin-right: 8px; }

.volunteers-stats-table--last-updated {
  padding-top: 8px;
  font-size: 14px; }

.volunteer-stats-table--icon-container {
  display: flex;
  align-items: center;
  margin-top: 4px; }

.volunteers-stats-table--head-agent-icon {
  width: auto; }

.volunteers-stats-table--top-section.is-mobile {
  order: 1; }

.volunteer-stats-modal .pool-activity-report--right {
  border-left: 1px solid #f6f6f6; }

.volunteer-stats-modal .pool-activity-report--left {
  padding-right: 12px; }

.volunteer-stats-modal--dollars-section {
  flex-basis: 60%;
  padding-top: 4px; }

.volunteer-stats-modal--raised-card {
  padding: 0; }

.volunteer-stats-modal--contact-card {
  padding: 0; }
  .volunteer-stats-modal--contact-card .et--contact-card--body {
    padding: 0 8px; }

.volunteer-stats-modal--dollars-raised-label {
  color: #a2a2a2;
  letter-spacing: 1px;
  display: inline-block; }

.volunteer-stats-modal--activity-card {
  flex-basis: 66%;
  border: 1px solid #f6f6f6; }

.volunteer-stats-modal--stage-card {
  flex-basis: 33%;
  border: 1px solid #f6f6f6;
  margin-left: 12px; }
  .volunteer-stats-modal--stage-card.et--kpi-card {
    border-right: 1px solid #f6f6f6; }

.volunteer-stats-modal-activity--breakdown {
  margin-right: 8px;
  border-right: 1px solid #f6f6f6;
  padding-right: 8px; }

.volunteer-stats-modal-activity--numbers {
  margin-left: 4px; }
