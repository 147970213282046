.side-bar {
  position: fixed;
  bottom: 0;
  background-color: white;
  height: 75%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 12px;
  z-index: 10;
}

.side-bar-appear {
  right: -100%;
}

.side-bar-appear-active {
  right: 0%;
  transition: right 1s;
}

.side-bar-appear-done {
  right: 0%;
}

.side-bar-enter {
  right: -100%;
}

.side-bar-enter-active {
  right: 0%;
  transition: right 1s;
}

.side-bar-enter-done {
  right: 0%;
}

.side-bar-exit {
  right: 0%;
}

.side-bar-exit-active {
  right: -100%;
  transition: right 1s;
}

.side-bar-exit-done {
  right: -100%;
}
