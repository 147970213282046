.interaction-labels--item {
  display: inline-block;
  padding: 6px 8px;
  background: $gray105;
  border-radius: 2px;
  border: solid 1px $border;
  margin-right: 8px;
  margin-top: 8px;
  line-height: $baseFontSize;
}
