.nav-is-collapsed {
  .facebook-pages-controller {
    .facebook-header-wrapper {
      left: 0;
    }
  }
}

.facebook-pages-controller {
  .facebook-header-wrapper {
    width: auto;
    background: none;
    left: $layoutNavWidth + 1;
  }

  .facebook-pages-table {
    top: 190px;
    bottom: 0;
    overflow: auto;
  }
}

.facebook-pages-filters {
  display: flex;
  justify-content: space-between;
  margin: 18px;
}

.facebook-pages-table {
  position: absolute;
  width: 100%;
  thead {
    z-index: 0;
  }
  .et--flex-table--header-cell {
    justify-content: flex-end;

    &:first-child {
      min-width: 200px;
      text-align: left;
      justify-content: flex-start;
      .et--flex-table--sort-header-cell {
        text-align: left;
      }
    }
  }

  .facebook-pages-table-head--metric {
    .et--flex-table--sort-header-cell {
      text-align: right;
    }
  }

  .et--flex-table--cell {
    background-color: $white;
    justify-content: flex-end;
    a {
      font-weight: 500;
    }
    &:first-child {
      min-width: 200px;
      justify-content: flex-start;
    }
  }

  .facebook-pages-table--metric {
    font-size: 18px;
    font-weight: bold;
    padding-right: 15px;
  }

  .facebook-pages-table-head--center {
    justify-content: center;
    .et--flex-table--sort-header-cell {
      text-align: center;
    }
  }

  .facebook-pages-table--center {
    justify-content: center;
  }

  .facebook-pages-table--name {
    max-width: 175px;
    font-weight: bold;
  }

  .facebook-pages-table--avatar {
    width: 40px;
    height: 40px;
    max-width: 40px;
    margin-right: 16px;
  }
}

.facebook-pages-actions {
  .action-menu-item {
    width: 200px;
  }
}
