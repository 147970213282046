.email-form {
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 0.2fr 1fr 0.01fr 0.2fr;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  height: 100%;

  hr {
    margin: 0;
  }

  input,
  textarea {
    border: none;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  textarea:focus:not(:disabled) {
    background-color: #fff;
    outline: none;
    border: none;
  }
}

.email-form--label {
  display: flex;
  padding: 10px 0 10px 25px;
  align-items: center;
  align-self: center;

  strong {
    color: rgb(130, 130, 130);
  }
}

.email-subject {
  .email-form--label {
    width: 100%;
  }
}

.email-form-header {
  grid-area: 1 / 1 / 2 / 2;
}

.email-form-header-field {
  display: flex;
  position: relative;
  border-bottom: 1px solid #f6f6f6;
}

.email-form--recipients-container {
  align-items: center;
  display: flex;
  border-bottom: 1px solid #f6f6f6;
  .email-form-header-field {
    flex-grow: 1;
    -ms-flex-positive: 1;
  }
}

.email-form-header-field:first-of-type {
  border-bottom: 0;
}

.email-form-header-cc {
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;

  a:first-of-type {
    margin-right: 5px;
  }
}

.email-form-header-label {
  -ms-flex-align: top;
  position: relative;
  align-items: flex-start !important;
  display: flex !important;
}

.email-subject {
  align-items: center;
  display: flex;
  position: relative;

  input.email-form--input-field {
    position: relative;
    top: 0px;
  }
}

.email-recipients-field-tag {
  display: inline-block;
  background: $gray105;
  margin: 5px;
  padding: 5px;
  font-weight: 450;
}

input.email-recipients-field-input {
  width: 230px;
  display: inline-block;
  border: none !important;
  background: none !important;
}

.email-form--input-field {
  width: 96%;
  height: 2.5rem;
}

.email-form--body {
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: 2 / 1 / 3 / 2;
}

.dynamic-popup {
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
}

.email-form .email-form--body {
  background-color: transparent;
}

.email-form--body-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.email-body--files {
  grid-area: 3 / 1 / 4 / 2;
}

.email-form--footer {
  display: flex;
  align-items: center;
  padding: 15px 20px 0px 20px;
  margin-bottom: 60px;
  border-top: 1px solid #f6f6f6;
  grid-area: 4 / 1 / 5 / 2;
}

.oauth-controller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    margin-bottom: 8px;
    font-size: 30px;
  }

  p {
    font-size: 16px;
    color: #737373;
  }
}

.oauth-provider--btn {
  display: flex;
  align-items: center;
}

.ql-toolbar.ql-snow {
  border: none;
  position: absolute;
  z-index: 999;
}

.ql-container.ql-snow {
  border: none;
  height: 90%;
  margin-top: 41px;
}

.ql-tooltip {
  height: 38px;
}

.ql-snow .ql-tooltip {
  left: 120px !important;
  transform: translateY(-55px);
  -ms-transform: translateY(-55px);
  z-index: 999;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  bottom: 100%;
  top: auto;
  z-index: 998;
  border-radius: 5px;
}

.ql-toolbar.ql-snow .ql-formats:last-of-type,
.ql-toolbar.ql-snow .ql-formats:nth-of-type(2) {
  margin-right: 0;
}

// Uncomment for box shadow styling
// .ql-formats:has(> .ql-font, .ql-list) {
//   border: 1px solid #ccc;
//   box-sizing: border-box;
//   font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
//   padding: 8px;
//   border-radius: 15px;
//   box-shadow: 1px 2px 1px lightgrey;
// }

.ql-font,
.ql-size,
span button.ql-list:last-child {
  border-right: 1px solid lightgrey;
  padding-right: 8px;
}

button[value='right'] {
  border-right: 1px solid lightgrey !important;
}

.ql-container.ql-snow:focus {
  outline: 0;
}

.ql-editor:focus {
  outline: none;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}

.ql-editor {
  border-top: 1px solid #f6f6f6;
  min-height: 275px;
}
// .ql-link {
//   color: black;
//   position: absolute !important;
//   bottom: 5%;
//   left: 100px;
//   svg {
//     height: 24px !important;
//   }
// }

.ql-header,
.ql-font,
.ql-size {
  .ql-picker-label {
    svg {
      display: none;
    }
  }
}

.ql-header,
.ql-font,
.ql-size {
  .ql-picker-label:after {
    content: '\f107';
    font-family: 'FontAwesome';
    position: absolute;
    top: 1px !important;
    right: 0;
    color: $black !important;
  }
}

.ql-header.ql-expanded,
.ql-font.ql-expanded,
.ql-size.ql-expanded {
  .ql-picker-label:after {
    content: '\f106';
    font-family: 'FontAwesome';
    position: absolute;
    top: 1px !important;
    right: 0;
    color: $black !important;
  }
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: none;
}

.ql-formats {
  button,
  .ql-picker {
    &[class^='ql-'] {
      position: relative;

      &::after {
        white-space: nowrap;
        position: absolute;
        display: none;
        top: 36px;
        left: 50%;
        transform: translate(-50%, -10px);
        background-color: rgb(21, 24, 35);
        z-index: 999;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
      }

      &:hover {
        &::after,
        &::before {
          display: block;
        }
      }
    }

    &.ql-bold::after {
      content: 'Bold';
    }

    &.ql-italic::after {
      content: 'Italic';
    }

    &.ql-underline::after {
      content: 'Underline';
    }

    &.ql-strike::after {
      content: 'Strikethrough';
    }

    &.ql-font-style::after {
      content: 'Font Style';
    }

    &.ql-align {
      &[value='']::after {
        content: 'Align Left';
      }

      &[value='center']::after {
        content: 'Align Center';
      }

      &[value='right']::after {
        content: 'Align Right';
      }

      &[value='justify']::after {
        content: 'Align Justify';
      }
    }

    &.ql-list {
      &[value='bullet']::after {
        content: 'Bullet List';
      }

      &[value='ordered']::after {
        content: 'Numbered List';
      }
    }

    &.ql-blockquote::after {
      content: 'Blockquote';
    }

    &.ql-link::after {
      content: 'Link';
    }

    &.ql-image::after {
      content: 'Image';
    }

    &.ql-dynamic-fields::after {
      content: 'Dynamic Fields';
    }

    &.ql-clean::after {
      content: 'Clean Markup';
    }

    &.ql-color::after {
      content: 'Text Color';
    }

    &.ql-background::after {
      content: 'Background Color';
    }

    &.ql-align::after {
      content: 'Align Text';
    }

    &.ql-font::after {
      content: 'Font Style';
    }

    &.ql-size::after {
      content: 'Font Size';
    }
  }
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='13px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='13px']::before {
  content: 'Normal';
  font-size: 13px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before {
  content: 'Large';
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='19px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='19px']::before {
  content: 'Huge';
  font-size: 19px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before {
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Sans Serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Sans Serif']::before {
  content: 'Sans Serif';
  font-family: 'arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
  content: 'Arial';
  font-family: 'arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Times New Roman, Times, serif']::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman', Times, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Times New Roman, Times, serif']::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman', Times, serif;
  font-size: 10px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: 'Fixed Width';
  font-family: 'monospace';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial black']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial black']::before {
  content: 'Wide';
  font-family: 'arial black';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial narrow']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial narrow']::before {
  content: 'Narrow';
  font-family: 'arial narrow';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='comic sans ms']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='comic sans ms']::before {
  content: 'Comic Sans';
  font-family: 'comic sans ms';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='garamond']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='garamond']::before {
  content: 'Garamond';
  font-family: 'garamond';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before {
  content: 'Georgia';
  font-family: 'georgia';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before {
  content: 'Tahoma';
  font-family: 'tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='trebuchet ms']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet ms']::before {
  content: 'Trebuchet';
  font-family: 'trebuchet ms';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
  content: 'Verdana';
  font-family: 'verdana';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='brush script mt']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='brush script mt']::before {
  content: 'Cursive';
  font-family: 'brush script mt';
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='Baskerville, Baskerville Old Face, Hoefler Text, Garamond, serif']::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='Baskerville, Baskerville Old Face, Hoefler Text, Garamond, serif']::before {
  content: 'Baskerville';
  font-family: 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Garamond', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Helvetica, Arial, Verdana, sans-serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Helvetica, Arial, Verdana, sans-serif']::before {
  content: 'Helvetica';
  font-family: 'Helvetica', 'Arial', 'Verdana', 'sans-serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='impact']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='impact']::before {
  content: 'Impact';
  font-family: 'Impact', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
  font-family: 'serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Arial Black, Gadget, sans-serif']::before {
  content: 'Arial Black';
  font-family: 'Arial Black', Gadget, sans-serif;
  font-size: 13px;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Arial Black, Gadget, sans-serif']::before {
  content: 'Arial Black';
  font-family: 'Arial Black', Gadget, sans-serif;
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif']::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif']::before {
  content: 'Gill Sans';
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='Palatino, Palatino Linotype, Book Antiqua, Georgia, serif']::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='Palatino, Palatino Linotype, Book Antiqua, Georgia, serif']::before {
  content: 'Palatino';
  font-family: 'Palatino', 'Palatino Linotype', 'Book Antiqua', Georgia, serif;
}

.ql-image {
  margin-right: -16px;
  display: hidden;
}

.dynamic-fields {
  font-weight: bold;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.ql-picker.ql-font .ql-picker-options {
  max-height: 170px;
  overflow-y: auto;
}

.email-disconnect-alert {
  background-color: #f5f5f5;
  padding: 20px 20px;
  border-radius: 12px;
  margin: 15px 0px 27px;
  width: 461px;
}

.ql-toolbar.ql-snow .ql-formats:not(:last-of-type) {
  margin-right: 8px;
}

.ql-toolbar.ql-snow .ql-formats:last-of-type,
.ql-toolbar.ql-snow .ql-formats:nth-of-type(2) {
  margin-right: 0px;
}

.ql-toolbar .ql-link + .ql-dynamic-fields {
  padding-left: 0;
}

.split-button {
  background-color: #007c9e;
  border: solid 0px #007c9e;
  border-radius: 200px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .split-button-send {
    border-right: 1px solid white !important;
    border-radius: 0px;
    border: 0px solid #ccc;
  }

  .split-button-arrow {
    border: 0px solid white !important;

    padding: 0 10px;
    border-radius: 0;
    margin-left: 0px;
  }

  .split-button-arrow:hover,
  .split-button-arrow:focus,
  .split-button-arrow:focus-visible {
    height: 37px;
    background-color: rgb(165, 74, 181);
  }

  .split-button-arrow:focus:not(:disabled) {
    background-color: #007c9e;
  }

  .split-button-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100%;

    div {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #f6f6f6;
      }
    }
  }
}

.split-btn-disabled {
  border: solid 0px #007c9e;
  border-radius: 200px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #b7b7b7;

  .split-button-send {
    border-right: 1px solid white !important;
    border-radius: 0px;
    border: 0px solid #ccc;
  }

  .split-button-arrow {
    border: 0px solid white !important;
    padding: 0 10px;
    border-radius: 0;
    margin-left: 0px;
  }
}

.date-time {
  margin-left: 18px;
  color: #888;
}

.schedule-email-popover {
  width: 400px;
  padding: 10px;
  padding-bottom: 5px;
  flex-direction: column;

  .et--actions-menu--option-heading {
    color: $gray900;
    font-weight: 500;
  }
}

.scheudle-email-form-field {
  padding: 15px 15px 15px 30px !important;

  .form-field--control {
    padding-left: 40px;
  }

  .form-field--label {
    width: 80px;
    text-align: right;
    font-weight: 500;
  }

  .et--advanced-combobox {
    width: 171px;
  }

  .et--advanced-combobox--item.is-disabled {
    color: $gray300 !important;
    cursor: not-allowed;
  }
}

.outreach-card-header {
  flex-direction: column;
  position: relative;
}

.thread-indicator {
  height: 50px;
  top: 40px;
  left: 15px;
  position: absolute;
  background: url($vector);
  background-repeat: no-repeat;
  width: 22px !important;
}
