@import '~@evertrue/et-variables/src/styles/variables.scss';
//--------------------------------
// COLORS - Most colors should come from et-variables
//--------------------------------

// Social Colors
$linkedinBlue: #0077b5;
$facebookBlue: #3b5998;
$eventbriteOrange: #ff8000;
$twitterBlue: #1da1f2;
$instagramPink: #f00075;

// Brand Colors
$purple: #a54ab5;
$lightPurple: #d8c0ff;

//--------------------------------
// IMAGE PATHS
//--------------------------------

// Images Paths
$avatarBlue: '../images/et_avatar_blue2x.png';
$logo: '../images/gt-logo.png';
$logoWhite: '../images/evertrue-logo-white.png';
$logoGray: '../images/evertrue_logo_gray.png';
$logoFullColor: '../images/evertrue_logo_full_color.png';
$logoPyramid: '../images/evertrue_pyramid.png';
$btnAppstore: '../images/app-store-logo.png';
$btnAppstore2x: '../images/app-store-logo2x.png';
$loginMobileBg: '../images/mobile-devices.png';
$facebookLoading: '../images/fb_post_loader_loop.gif';
$contactLoading: '../images/contact_loader_loop.gif';
$gtAppIcon: '../images/gt_app_icon.png';
$gtAppIconDisabled: '../images/gt_app_icon_disabled.png';
$notAvailableTile: '../images/gray_diagonal_tile.png';
$progressImg: '../images/progress.gif';
$mobileDevicesCropped: '../images/mobile-devices-cropped.png';
$giveSetupBackground: '../images/give-setup-background.jpg';
$facebookEngagementDefault: '../images/fb-placeholder@2x.png';
$googleIconSprite: '../images/google_sprite.png';
$dragDropContactEmptyImage: '../images/drag-drop-empty.png';
$homepageBanner: '../images/homepage_banner.svg';
$clarityBanner: '../images/clarity_banner.png';
$microsoftIconSprite: '../images/microsoft_icon.svg';
$vector: '../images/vector.png';
//--------------------------------
// MISC VARIABLES
//--------------------------------

// Z Index Values
$googAutocompleteZindex: 999999;
$modalZindex: 9999;
$mainNavZindex: 900;
$filterToggleZIndex: 80;
$dropdownBodyZindex: 100;
$onOffSwitchZindex: 10;
$tripLogZindex: 11;
$loginHeaderZindex: 1;
$fbPostZindex: 1;
$fbActionsZindex: 1;

// Main Header values
$mainHeaderHeight: 60px;
$mainToolBarHeight: 67px;

// Bourbon NEAT Grid Variables Settings
$max-width: 100%;

// Make this available to all files
$layoutNavWidth: 220px;
$pageContainerPadding: 30px;
$layoutNavWidthCollapsed: 64px;
