$scrollbarBackground: fade-out(#828282, 0.5);
$scrollbarHeight: 12px;

.horizontal-viewport {
  @include position(absolute, 0px 0px 0px 0px);
  overflow: hidden;
}

.horizontal-viewport--content {
  height: 100%;
  min-width: 100%;
  position: relative;
}

.scrollbar {
  @include user-select(none);
  height: $scrollbarHeight;
  border-radius: 10px;
  background: fade-out(#7a7a7a, 0.9);
}

.scrollbar--handle {
  background: $scrollbarBackground;
  border-radius: 10px;
  position: relative;
  height: $scrollbarHeight;

  &:hover,
  &.is-dragging {
    background: fade-out($gray500, 0.5);
  }
}
