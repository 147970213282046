.schedule-exports-modal {
  padding: 15px;
  line-height: normal;
}

.schedule-exports-modal--form-field {
  .form-field--label {
    vertical-align: baseline;
  }
}

.schedule-exports-modal--dropdowns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule-exports-modal--time {
  display: inline-block;
  width: 120px;

  .advanced-combobox-item--label {
    white-space: pre;
  }
}

.schedule-exports-modal--timezone {
  width: 280px;
  display: inline-block;
}

.schedule-exports-modal--frequency {
  display: flex;
  align-items: center;
}

.schedule-switch-text {
  width: 250px;
  margin-left: 10px;
  color: #808080;
}
