.social-interaction {
  .social-icon {
    width: 25px;
    text-align: left;
    vertical-align: middle;
    margin-right: 7px;
  }

  .social-comment {
    margin: 10px 0 0 35px;
    max-width: 50%;
  }
}

.social-comment {
  position: relative;
  display: inline-block;
  border: 1px solid $border;
  height: auto;
  padding: 6px 12px;
  border-radius: 5px;
  background: $white;

  &::after,
  &::before {
    position: absolute;
    border: 8px solid transparent;
    color: $white;
    font-size: 1.4em;
    pointer-events: none;
    content: "▼";
    left: 10px;
    bottom: -23px;
    text-shadow: 0 2px 0 $gray200;
  }

  // Overwrite readmore css
  [data-readmore-toggle] {
    text-align: right;
  }
}

.facebook-posts {
  padding: 10px 14px;

  .social-comment {
    margin: 20px 20px 0 20px;
    max-width: 87%; // TODO - revisit to see if can scale this better
  }
}

.social-interaction--data {
  margin: 0 35px 20px;
  width: 800px;

  table {
    margin: 0;
  }

  td {
    border-bottom: none;
  }

  .contact-card td:first-child {
    width: 75px;
  }

  .contact-card--name {
    font-weight: normal;
    color: $gray300;
  }
}

.social-interaction--month {
  color: $purple500;
  text-align: center;
  margin: 20px auto;
}

.social-posts-timeline {
  .module--body {
    position: relative;
    min-height: 300px;
  }
}

.facebook-results {
  padding: 16px;
  padding-top: 10px;
}

.social-posts--spacer {
  height: 45px;
}

.contact-expanded--contact {
  width: 320px;
  padding-right: 10px;
}
