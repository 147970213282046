.chart-legend {
  padding: 8px 0 8px 16px;
  display: inline-block;
  border-radius: 2px;
}

.chart-legend--item {
  margin-right: 16px;
  display: inline-block;
  width: 125px;
}

.chart-legend--checkbox {
  font-weight: normal;

  .et--checkbox--box {
    margin-right: 5px;
    float: left;
  }

  input.et--checkbox--input:checked + .et--checkbox--box {
    background: $white;

    &::before {
      color: $white;
      top: 0px;
      left: 1px;
    }
  }
}

.chart-legend--color {
  margin-right: 5px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 1px;
}

.chart-legend--label {
  color: $gray500;
}
