.security-wizard--section {
  border-bottom: 1px solid fade_out(#0a5b50, 0.85);
  padding: 25px;
}

.security-wizard--header-container {
  color: rgb(167, 167, 167);
  padding: 15px;
}

.security-wizard--header {
  font-size: 24px;
}

.security-wizard--description {
  margin-bottom: none;
}

.security-wizard--description-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 6px;
}

.security-wizard--setup-question {
  font-size: 12px;
  font-weight: bold;
  color: $gray300;
  padding-top: 10px;
  padding-bottom: 10px;
}

.security-wizard--step-instructions {
  max-width: 75%;
  display: inline-block;
}

.security-wizard--step-number {
  color: $gray300;
  font-size: 18px;
}

.security-wizard--step-header {
  font-weight: bold;
  margin-bottom: 0;
}

.security-wizard--step-image {
  height: 100px;
  width: 120px;
  display: inline-block;
  margin-left: 16px;
}

.security-wizard--btn {
  width: 47.5%;
}

.security-wizard--next-step-button {
  margin-left: 27px;
}

.security-wizard--phone-container {
  background-color: $gray105;
}

.security-wizard--phone-header {
  font-weight: bold;
  display: block;
  float: left;
}

.security-wizard--phone-input {
  .intl-tel-input {
    width: 100%;
  }

  input {
    height: 44px;
  }
}

.security-wizard--error-message {
  color: $red300;
  padding: 4px;
}

.security-wizard--phone-disclaimer {
  margin-top: 10px;
  margin-bottom: -10px;
}

.security-wizard--confirmation-container {
  background-color: $gray105;
}

.security-wizard--confirmation-header {
  font-weight: bold;
  display: block;
  float: left;
}

.security-wizard--confirmation-no-receive {
  display: block;
  float: right;
  font-weight: 100;
  color: $blue200;
  cursor: pointer;

  &:hover {
    color: $purple500;
  }
}

.security-wizard--trust-box {
  margin-top: 20px;
}

.security-wizard--trust-label {
  margin-left: 5px;
}
