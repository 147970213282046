.share-segment {
  padding: 16px;
  background: $gray105;

  .module + .module {
    margin-top: 16px;
  }

  .module--body {
    padding: 12px;
  }
}

.share-segment--description {
  color: $gray500;
  margin-bottom: 16px;
}

.share-segment--copied {
  color: $green300;
  float: right;
  font-size: $baseFontSize;
  font-weight: bold;
  line-height: 30px;

  .gt-icon-check {
    margin: 0;
  }
}

.share-segment--invite-body {
  button.btn-action {
    width: 100px;
    padding: 12px 32px;
    font-weight: bold;
    border-bottom: solid 2px darken($purple500, 5%);

    &.is-disabled {
      background: none;
      border: solid 1px $border;
      color: $border;
    }
  }
}

.share-segment--notify {
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;

  .checkbox--box {
    background: none !important;
    margin-right: 8px;

    &::before {
      color: $purple500 !important;
    }
  }
}

.share-segment--note {
  height: 64px;
}
