.settings--accordion-body {
  margin: 0;
  padding: 20px;
}
.settings--accordion-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.settings--accordion-h1 {
  text-align: center;
  color: #333;
}
.settings--accordion-header {
  color: #939393;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.settings--accordion-header:hover {
  background-color: #f0f0f0;
}
.settings--accordion-content {
  padding: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}
.settings--toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  flex-shrink: 0;
}
.settings--toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.settings--slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.settings--slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .settings--slider {
  background-color: #8e44ad;
}
input:checked + .settings--slider:before {
  transform: translateX(26px);
}
.settings--setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
  text-align: left;
}
.settings--setting span {
  flex-grow: 1;
}
input[type='text'] .settings--text {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.url-warning {
  font-size: 12px;
  color: red;
  margin: 5px 0;
  opacity: 1;
}

.url-warning--hidden {
  opacity: 0;
}

.settings--change-defaults {
  text-align: right;
  color: black;
  margin-top: 20px;
  font-size: 10px;
  text-decoration: underline;
}
.settings--change-defaults a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.settings--left-options-container {
  margin-top: 10px;
}
