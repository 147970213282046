.social-actions {
  padding: 16px 20px;
  min-height: 50px;
  .et--plain-button {
    width: 100% !important;
  }
}

.social-actions--button {
  padding: 8px;
  background-color: #f2f6f7;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 2px;
  font-weight: 500;
  color: $gray500;

  &:hover {
    background-color: $gray108;
  }

  i {
    width: 16px;
    margin-right: 16px;
    font-size: 16px;
    &.unmatched {
      color: $gray500;
    }
  }

  a {
    font-weight: 500;
    color: $gray500;

    &:hover {
      color: $gray500;
    }
  }

  .google-search--icon {
    vertical-align: top;
  }
}

.social-actions--popover {
  width: 300px;
  padding: 8px;
  border: solid 1px $border;
  box-shadow: 0 0 1px $border;

  a {
    display: block;
    padding: 8px;
  }
}

.facebook-no-match-button {
  width: 100%;
}

.facebook-no-match {
  width: 300px;
}

.social-actions--button-arrow {
  position: absolute;
  right: 27px;
}
