$globalAutcompleteIconWidth: 40px;
$globalAutcompleteInactiveText: fade-out($white, 0.5);
$globalAutocompleteBackground: rgba(0, 160, 204, 0.05);
$globalAutocompleteBorder: rgba(0, 160, 204, 0.5);
$globalAutocompleteColor: #007c9e;

.global-autocomplete {
  position: relative;
  display: block;
  padding: 10px;
  max-width: 624px;
}

.global-autocomplete--icon {
  height: $baseHeaderHeight;
  width: $globalAutcompleteIconWidth;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  font-size: 1.2em;

  .icon-muted {
    line-height: $baseHeaderHeight;
    color: fade-out($white, 0.5);
    font-weight: bold;
  }

  .icon-highlight {
    line-height: $baseHeaderHeight;
    color: $white;
    font-weight: bold;
  }
}

.global-autocomplete--input {
  @include transition(max-width 0.2s ease-in);
  max-width: 100%;

  .search-box--input {
    @include transition(all 0.2s ease-in);

    &.focus-visible {
      @include pink-focus();
      border: none;
      border-radius: 50px;
    }

    @include placeholder {
      color: $globalAutocompleteColor !important;
    }
  }

  .search-box--search-icon {
    color: $globalAutocompleteColor;
  }

  &.is-inactive {
    max-width: 384px;

    .search-box--input {
      box-shadow: none;
      border-radius: 50px;
      background: $globalAutocompleteBackground;
      border: 1px solid $globalAutocompleteBorder;
      color: $globalAutocompleteColor;

      &:hover {
        border: 1px solid $globalAutocompleteBorder;
      }
    }
  }
}

.global-autocomplete--content {
  @include calc(width, "100% - #{$globalAutcompleteIconWidth}");
  display: inline-block;
  vertical-align: middle;
}

.global-autocomplete--body {
  @include position(absolute, 53px 10px 0 10px);
  background: $white;
  border: solid 1px $border;
  @include elevation12();
  border-top: none;
  border-radius: 12px;
  z-index: 500;
  box-sizing: content-box;

  &.is-open {
    display: block;
  }

  .new-contact-card {
    padding: 0;
  }

  .new-contact-card--avatar {
    width: 45px;
  }

  .new-contact-card--name {
    display: block;
  }
}

.global-autocomplete--list {
  overflow-y: auto;
  overflow-x: hidden;
}

.global-autocomplete--item {
  cursor: pointer;

  .interaction-list-item {
    border: none;
  }

  .interaction-snippet-card {
    padding: 0;
  }

  &:last-child {
    .interaction-snippet-card--content {
      border-bottom: none;
    }
  }
}

.global-autocomplete--group-header {
  border-top: solid 1px $border;
  cursor: pointer;
  padding: 16px 16px 8px 16px;
  &:first-of-type {
    border-top: none;
  }
}

.global-autocomplete--empty {
  padding: 24px 16px 16px 16px;
  font-weight: bold;
  color: $gray200;
}

.global-autocomplete--footer {
  width: 100%;
  padding: 16px;
  text-align: center;
  border-top: solid 1px $border;
  font-weight: bold;
  color: $blue500;
  text-wrap: auto;
}

.global-autocomplete--view-all {
  color: $blue500;
}
