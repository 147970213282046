$paginationBg: $white;
$paginationPadding: 5px 12px;
$paginationFontSize: 14px;

$paginationActiveColor: $purple500;
$paginationActiveBg: $purple105;

$paginationHoverColor: $purple500;
$paginationHoverBg: $gray105;

$paginationDisabledColor: $gray150;
$paginationDisabledBg: transparent;

.pagination {
  > .is-active {
    color: $paginationActiveColor;
    background: $paginationActiveBg;
    font-weight: bold;

    &:hover {
      color: $paginationActiveColor;
      background: $paginationActiveBg;
    }
  }

  > .is-disabled {
    color: $paginationDisabledColor;
    background: $paginationDisabledBg;
    cursor: default;

    &:hover {
      color: $paginationDisabledColor;
    }
  }
}

.pagination--item {
  display: inline-block;
  font-size: $paginationFontSize;
  padding: $paginationPadding;
  background: $paginationBg;
  border-radius: 40px;
  cursor: pointer;

  &:hover {
    background: $paginationHoverBg;
    color: $paginationHoverColor;
  }
}

.pagination--prev,
.pagination--next {
  background: none;

  &:hover {
    background: none;
  }
}

.pagination--prev {
  padding-left: 0px;
}

.pagination--next {
  padding-right: 0px;
}

.pagination--placeholder {
  color: $gray900;
  background: transparent;
  cursor: default;

  // Override .pagination--item
  &:hover {
    color: $gray900;
    background: transparent;
  }
}
