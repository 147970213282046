$itineraryListWidth: 366px;
$itineraryModuleHeaderHeight: 71px;

.itinerary-controller {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.itinerary--list {
  width: $itineraryListWidth;
  padding: 16px 0 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .itinerary--module-action {
    width: 170px;
  }
}

.itinerary--module-header {
  background: $gray105;
  height: $itineraryModuleHeaderHeight;
  padding: 16px;
  border-radius: 12px 12px 0 0;
}

.itinerary--module-title {
  font-weight: 500;
  font-size: 16px;
  float: left;
}

.itinerary--module-subtitle {
  font-weight: normal;
  font-size: $baseFontSize;
  color: $gray500;

  strong {
    color: $baseFontColor;
  }
}

.itinerary--module-action {
  float: right;

  .action-menu--button {
    width: 100%;
    margin: 0;
    padding: 12px;
  }
}

.itinerary--list-body {
  background: $gray105;
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
}

.itinerary--list-empty {
  text-align: center;
  padding: 16px;
  color: $gray500;

  .itinerary--module-action {
    float: none;
    margin: 16px auto;
  }
}

.itinerary--schedule {
  padding: 16px;
  flex: 1;
  position: relative;
}

.itinerary--schedule-body {
  @include position(absolute, $itineraryModuleHeaderHeight + 16px 16px 16px 16px);
  background: $gray105;
  overflow: auto;
  border-radius: 0 0 12px 12px;
}
