.filters--save {
  display: inline-block;
  padding-left: 10px;

  input {
    width: 250px;
    display: inline-block;
  }

  &.is-disabled {
    display: none;
  }
}

.filters-v2-switcher {
  background-color: $gray105;
  color: $gray900;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border;
  padding: 8px 16px;
  label {
    margin-bottom: 0px;
  }
  a {
    color: $gray900;
    text-decoration: underline;
  }
}

.filters-v2-switcher--label {
  margin-left: 16px;
}
