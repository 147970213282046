$sortDropdownHeight: 39px;

.sort-dropdown {
  display: flex;

  button {
    border-radius: 0 8px 8px 0;
    width: 200px;
    margin: 0;
  }

  height: $sortDropdownHeight;
}

.sort-dropdown--btn {
  background: $white;
  height: $sortDropdownHeight;
  width: 45px;
  padding: 10px 6px;
  font-weight: 500;
  display: inline-block;
  border-radius: 8px 0 0 8px;
  border: solid 1px $gray150;
  border-right: none;
  vertical-align: middle;
  margin: 0;

  &:hover,
  &:focus {
    background: fade-out($gray105, 0.5);

    .sort-dropdown--icon {
      color: $purple500;

      &.is-selected {
        color: $gray900;
      }
    }
  }
}

.sort-dropdown--icon {
  color: $blue300;
  margin: 0;
  width: 17px;

  &.is-selected {
    color: $gray900;
  }

  &:last-child {
    margin-left: -4px;
  }
}

.sort-dropdown--single {
  background: $white;
  border: solid 1px $gray150;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: $baseFontSize;
  color: $gray900;

  .gt-icon-long-arrow-up {
    margin-right: 12px;
    margin-left: -4px;
  }

  &:hover,
  &:focus {
    background: fade-out($gray105, 0.5);
    border-color: $border;

    .sort-dropdown--icon {
      color: $purple500;

      &.is-selected {
        color: $gray900;
      }
    }
  }
}
