$actionMenuIconSize: 45px;

.action-menu--button {
  margin-left: 8px;
}

.action-menu--content {
  @include elevation12();
  min-width: 300px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  border-radius: 16px;
}

// used on popover2 in action-menu2
.action-menu--popover {
  .action-menu--content {
    border: none;
  }
}

.action-menu-item {
  color: $linkText;

  &:hover {
    color: $purple500;
  }

  button {
    width: 100%;
  }

  button,
  a {
    display: flex;
    justify-content: center;
    padding-top: 3px;
  }

  &.is-disabled {
    color: $gray150;

    &:hover {
      color: $gray150;
    }
  }

  &:last-child .action-menu-item--label {
    border-bottom: none;
  }

  .gt-icon-trip-log {
    font-size: 18px;
    padding: 16px 12px;
  }
}

.action-menu-item--icon {
  width: $actionMenuIconSize;
  height: $actionMenuIconSize;
  padding: 8px;
  font-size: 22px;
}

.action-menu-item--label {
  @include calc(width, "100% - #{$actionMenuIconSize}");
  display: inline-block;
  padding: 9px 0;
  &.no-icon {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  }
}

.action-menu-basic-btn {
  background: none;
  color: $gray500;
  border: none;
  box-shadow: none;
  width: auto;
  margin: 0px;
  padding: 8px 0;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: none;
    color: $purple500;
    border: none;
    box-shadow: none;
  }
}

.action-menu-small {
  min-width: 150px;

  .action-menu-item--icon {
    display: none;
  }

  .action-menu-item--label {
    padding: 8px;
    width: 100%;
  }
}
