.giving-match-modal {
  content: "";
}

.giving-match-modal--header {
  @include display(flex);
  @include justify-content(flex-start);
  border-bottom: 1px solid $border;
  padding-top: 24px;
  padding-bottom: 24px;
}

.giving-match-modal--gift-summary,
.giving-match-modal--gift-billing-info,
.giving-match-modal--gift-billing-address {
  padding: 0 20px;
  height: 68px;
  width: 33%;
}

.giving-match-modal--gift-summary,
.giving-match-modal--gift-billing-info {
  border-right: 1px solid $border;
}

.giving-match-modal--gift-summary-title {
  color: $gray500;
}

.giving-match-modal--gift-billing-info-content {
  color: $gray500;
  margin-top: 4px;
}

.giving-match-modal--gift-summary-amount {
  font-size: 36px;
  font-weight: bold;
  color: $green300;
  margin-top: 16px;
  margin-bottom: 12px;

  &.is-test {
    color: $yellow150;
  }
}

.giving-match-modal--match-controls {
  background-color: $gray105;
  padding: 16px;
  height: 320px;
  overflow: auto;
}

.giving-match-modal--unmatch-controls {
  background-color: $gray105;
  padding: 16px;
  text-align: center;
}

.giving-match-modal--help-info {
  padding: 16px;
  text-align: center;
}

.giving-match-modal--results {
  margin-top: 16px;
}

.giving-match-modal--result {
  position: relative;
  border: 1px solid $border;
  background-color: $white;
  border-top: none;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid $border;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.giving-match-modal--empty-state {
  @extend .giving-match-modal--result;
  padding: 20px;
  text-align: center;
}

.giving-match-modal--contact-checkbox {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: 12px;
  cursor: pointer;
}

.giving-match-modal--contact-card {
  display: inline-block;

  .fa-external-link {
    float: none;
  }

  .contact-card--emails {
    width: 88%;
  }
}

.giving-match-modal--nav {
  text-align: center;
  padding-left: 0;

  .giving-match-modal--nav-item {
    padding: 0 24px 8px;
    margin-right: 0;
  }
}

.giving-match-modal--suggested-matches {
  display: inline-block;
  color: $gray500;
  font-weight: 500;
  padding-bottom: 8px;
}
