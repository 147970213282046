.map-radius-dropdown--option {
  cursor: pointer;
  padding: 4px 0;

  &.is-selected {
    font-weight: 500;
    color: $purple500;
  }
}

.map-radius-dropdown--clear {
  cursor: pointer;
  display: block;
  margin-top: 8px;
}

.map-radius-dropdown--disabled-helptip {
  padding: 8px;
}
