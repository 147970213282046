$poolVolunteerWidth: 425px;
$poolHeaderHeight: 110px;

.pool-detail--body {
  @include position(absolute, $poolHeaderHeight 0px 0px 0px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.pool-assignmenent--body {
  margin: 16px;
}

.pool-detail--reporting {
  padding: 16px;
}

.pool-detail--reporting-module {
  display: flex;
  padding: 12px;

  .et--kpi-card {
    width: 20%;
    margin-bottom: 0px;
    padding: 4px 4px 4px 24px;
    min-height: 0px;
  }

  .et--kpi-card--value {
    color: $gray500;
  }
}

.pool-detail--manage {
  display: flex;
}

.pool-detail--volunteers {
  width: $poolVolunteerWidth;
}

.pool-detail--prospects {
  flex-grow: 1;
}
