.trips-meetings-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #454545;
  padding-bottom: 18px;
}

.meeting-participants {
  place-self: center;
}

.trips-meetings--vertical-line {
  @extend .meeting-participants;
  border-left: 1px solid $gray150;
  height: 21px;
  width: 1px;
}

.trips-meetings--vertical-padding {
  padding-bottom: 20px;
}

.meeting-time-name,
.trips-meetings-content {
  display: flex;
  flex-direction: column;
}

.trips-meeting-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trips-meetings-details {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.meetings-details {
  @extend .trips-meeting-summary;
  padding-bottom: 20px;
}

.meeting-time {
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.trips-meetings-count,
.meeting-name,
.more-meetings,
.meeting-trip-name {
  font-weight: 500;
}

.trips-meetings--view-today {
  text-align: center;
}

.trips-meetings--view-weekly {
  padding-top: 20px;
}

.trips-meeting-empty {
  font-style: italic;
}

.trips-content,
.meetings-content {
  text-wrap: nowrap;
}
