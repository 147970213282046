.journey-detail-table--container {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
}

.journey-detail-table--body {
  position: relative;
  margin: 0px; // overwrite Card default
}

.step-table-header {
  max-width: 700px;
  padding: 8px;
  margin-bottom: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 24px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.step-table-header--item {
  flex-shrink: 0;
  border-right: 1px solid $blue300;
  flex: 2;
  
  &:first-child {
    padding-left: 4px;
    flex: 1;
  }

  &:last-child {
    flex: 1;
    border-right: none;
  }

  @media (max-width: 800px) {
    padding: 8px;
    border-right: none;
  }
}

.step-table-header--icon-group {
  display: flex;
  justify-content: space-around;
}

.task-counter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  &.is-toggled {
    color: $purple300;
    background-color: rgb(251, 248, 251);
    border-radius: 15px;
  }
}
.task-counter-icon-toggle {
  color: $blue300;
  display: flex;
  .journeys-task-table--task-icon {
    color: $blue300;
  }
  &.is-toggled {
    color: $purple300;
    background-color: rgb(251, 248, 251);
    border-radius: 15px;
    .journeys-task-table--task-icon {
      color: $purple300;
    }
  }
}

.add-step-number {
  padding-right: 60px;
}

.add-step-button {
  padding: 16px;
  font-weight: 500;
  color: #007c9e;
}

.journey-detail--empty-state {
  padding-bottom: 200px;
}

.journey-search--container {
  width: 240px;
  padding-left: 18px;
}
