// this corresponds to the render elements form eventbrite-unmatched-table-config

.events-unmatched-table--profile {
   display: flex;
   align-items: center;
   .match-result--avatar {
      border-radius: 50%;
   }
}

.events-unmatched-table--status {
   margin-left: 40px;
}

.events-unmatched-table--event-response-icon {
   display: inline-block;
   margin-right: 12px;
   margin-left: 4px;
   vertical-align: middle;
}

.events-unmatched-table--event-response-label {
   vertical-align: middle;
}
