.time-range {
  vertical-align: middle;
}

.time-range--input {
  width: 84px;
  height: 41px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 11px;
  border-radius: 2px;
  border: 1px solid $border;
}

.time-range--done {
  display: block;
  padding: 4px;
  text-align: center;
  border-top: 1px solid $border;
}

.time-range--separator {
  @include calc(width, "6% - 10px");
  text-align: center;
  display: inline-block;
  border-bottom: solid 2px $border;
  height: 1px;
  vertical-align: middle;
  margin: 0 7px;
  color: $white;
}

.time-picker--cols {
  display: flex;
  flex-direction: row;
}

.time-picker--col {
  // height: 200px;
  padding: 6px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
  border-right: 1px solid $border;

  &:last-child {
    border-right: none;
  }
}

.time-picker--item {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;

  &.is-selected {
    background: $gray105;
    font-weight: bold;
  }
}
