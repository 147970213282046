.bulk-assign--modal .assign-contact--dropdown {
  margin-bottom: 12px;

  .dropdown--body {
    min-width: 250px;
  }
}

.bulk-assign--body {
  padding: 12px 32px;
  background: $background;
  position: relative;
}

.bulk-assign--dd-title {
  color: $gray500;
  padding: 12px 0 8px;
}
