.emma-push-controller--contents {
  padding: 16px;
}

.emma-push-controller--description {
  padding-bottom: 16px;
  border-bottom: 1px solid $border;
}

.emma-push-controller--group-name {
  padding-top: 16px;
  padding-bottom: 16px;
}

.emma-push-controller--prompt {
  padding-bottom: 16px;
}

.emma-push-controller--account-option {
  @include clearfix;
  margin-bottom: 16px;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }
}

.emma-push-controller--radio-button {
  float: left;
}

.emma-push-controller--account-name {
  line-height: 1;
  margin-bottom: 8px;

  &.is-disabled {
    color: $gray500;
  }
}

.emma-push-controller--account-description {
  margin-left: 40px;
}

.emma-push-controller--push-text {
  color: $gray500;
  &.is-error {
    color: $red300;
  }
}

.emma-push-controller--push-date {
}
