.gift-card {
  border: solid 1px $border;
  padding: 16px;
  background: $white;

  &.is-designation {
    border-bottom: none;
  }
}

.gift-card--amount {
  float: right;
  font-size: $fontSizeLarge;
}

.gift-card--label {
  font-size: $fontSizeLarge;
}

.gift-card--tag {
  background: $gray105;
  border: solid 1px $border;
  border-radius: 2px;
  padding: 4px 8px;
  margin: 8px 8px 0 0;
  display: inline-block;
}
