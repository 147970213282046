$contactTableToolbarHeight: 72px;
$contactTableToolbarPadding: 8px 16px;

.contact-table-toolbar {
  background: $white;
}
.contact-table-toolbar--actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  height: $mainToolBarHeight;

  .button-select,
  .button-select .button-select--item {
    height: 100%;
  }
}

.contact-table-toolbar--pagination {
  padding: 5px 0;
  position: absolute; 
  left: 50%;
}
.contacts-fix-table--selected-message {
  display: flex;
  justify-content: center;
  background: #cce0e5;
  width: 100%;
  padding: 5px 0;
}

.contacts-fix-table--selection {
  color: $gray900;
  text-decoration: underline;
  cursor: pointer;
}
