.homepage-title {
  @include title-style($homepageBanner);
}

.homepage-settings-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
}

.homepage-settings-icon {
  color: #939393;
  cursor: pointer;
}

.homepage--nav {
  @extend .clearfix;
  background: $white;
  margin: 0 16px;
  border-bottom: solid 1px $gray110;

  .nav-item {
    display: inline-block;
    margin-right: 30px;

    a,
    button {
      color: $gray500;
      height: 35px;
      font-weight: 400;
      font-size: 15px;
      line-height: 23.44px;
      text-transform: capitalize;
      letter-spacing: 1px;
      border-bottom: solid 3px $white;
      display: inline-block;
      padding-top: 8px;
      margin-top: 2px;
    }

    .is-active {
      color: $gray900;
      border-bottom: solid 3px $purple500;
    }
  }
}

.homepage-title-containter {
  display: flex;
}
