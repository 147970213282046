.event-detail-page-header {
  height: 75px;
  position: relative;
  z-index: 10;
}

.event-detail-page-header--image {
  margin-left: 44px;
  margin-top: 16px;
  display: inlin-block;
  float: left;
}

.event-detail-page-header--placeholder {
  background-color: $gray105;
  display: inline-block;
  height: 43px;
  width: 43px;
  text-align: center;
  float: left;
  margin-left: 44px;
  margin-top: 16px;

  i {
    opacity: 0.25;
    font-size: 20px;
    vertical-align: middle;
    margin-top: 25%;
  }
}

.event-detail-page-header--label {
  @include calc(width, "100% - 400px");
  float: left;
  font-size: 20px;
  line-height: 30px;
  padding: 12px 16px;
}

.event-detail-page-header--subtitle {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
}

.event-detail-page-header--marker {
  color: #27a5dd;
}

.event-detail-page-header--status {
  width: 310px;
  float: left;
  position: relative;
  margin-top: -5px;
  padding: 18px 16px;

  .gt-icon-event {
    color: #9b9b9b;
  }

  .gt-icon-check-circle,
  .gt-icon-double-check {
    color: #7ebd37;
  }

  .gt-icon-cancel {
    color: #df5952;
  }

  .gt-icon-email {
    color: $gray900;
  }

  .gt-icon-star-circle {
    color: #f7d138;
  }

  .btn-action {
    padding: 16px;
    margin-left: 8px;
    width: 130px;
  }

  .advanced-combobox {
    width: auto;
  }

  .dropdown {
    position: absolute;
    right: 0;
  }

  .dropdown--header .advanced-combobox--selected-text {
    padding: 4px 20px 0 0;
    width: auto;
  }

  .dropdown--toggle {
    background: none;
    box-shadow: none;
    transition: none;
    font-size: 16px;
    width: auto;
    &:hover,
    &.is-open {
      background: none !important;
    }
  }

  .dropdown--body {
    font-size: 14px;
    left: auto;
  }
}

.event-detail-page-header--back {
  @include position(absolute, 22px null null 0px);
}

.event-detail-page-header--title {
  font-weight: 500;
  a {
    color: $gray900;
    &:hover {
      color: $linkText;
    }
  }
}
