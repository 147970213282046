.ai-task-row {
  display: flex;
  width: 100%;
}

.ai-task-title--container {
  max-width: 250px;
  display: flex-row;
  align-items: center;
  p {
    margin: 0;
  }
}

.ai-task-details--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.review--container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-type-icon {
  color: $purple300;
}

.suggested-task-body-text {
  margin: 0;
}

.suggested-task-body-title {
  &:hover {
    cursor: pointer;
  }
}

.review-btn::before {
  border-radius: 80%;
  cursor: pointer;
  color: #cfcece;
  transition: color 0.3s ease-in-out; /* Add transition property */
}

.ai-task-review {
  &:hover {
    // This targets the icon when the ai-task-review container is hovered
    .review-btn::before {
      color: $purple300;
    }
  }
}

.ai-header-icon {
  width: 30px;
  height: auto;
  max-height: 34px;
}
