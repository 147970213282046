.user-match-modal--header {
  padding: 15px 0;
  border-bottom: 1px solid $border;
}

.user-match-modal--section {
  padding: 20px 25px;
  line-height: 16px;
}

.user-match-modal--text-title {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  margin-bottom: 4px;
}

.user-match-modal--search-box {
  margin: 10px auto;
  display: block;
  width: 60%;
}

.user-match-modal--body {
  padding: 15px 15px 0;
  background: $background;
  position: relative;
  height: 350px;
  overflow-y: scroll;
}

.user-match-modal--result-list {
  min-height: 60px;
  border: solid 1px $border;
  border-bottom: none;
  border-radius: 2px;
  background: $white;
  margin-bottom: 15px;
}

.user-match-modal--result {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $border;

  .et--checkbox--box {
    margin-right: 25px;
  }

  &:focus {
    border-bottom: solid 1px $border;
  }
}

.user-match-modal--matched {
  font-size: 12px;
  text-align: right;
  margin-right: 25px;
  color: $gray900;
  line-height: 20px;
}

.user-match-modal--matched-tag {
  border: 1px solid $gray900;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 0 3px;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
}
