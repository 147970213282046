.journey-drawer-container{
    padding: 5px 10px 0 10px;
    min-width: 350px;
    overflow-y: scroll;
    //used fixed height to make it scrollable
    height: 100%;
}

.journey-drawer-table{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.journey-drawer-row{
    font-weight: 500;
}

.journey-drawer-header{
    display: flex;
    justify-content: space-between;
}

.journey-drawer-header{
    padding: 5px 0px 15px 0px;
}