.recently-viewed {
  margin: 40px 16px;
}

.recently-viewed-wrapper {
  display: flex;
  justify-content: space-between;
}

.recently-viewed-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
}

.recently-viewed-avatar--image {
  margin-bottom: 8px;
}

.recently-viewed-avatar--name {
  font-size: 12px;
}

.recently-viewed-avatar--show-all {
  color: $blue500;
}

.recently-viewed-avatar--location {
  font-size: 11px;
}
