.manual-exports-modal {
   padding: 16px 16px 36px;
}

.manual-exports-modal--custom-date {
   display: flex;
   align-items: baseline;
   justify-content: flex-end;
   padding: 8px;

   .advanced-combobox {
      flex-basis: calc(100% - 130px);
   }
}

.task-export-option {
   display: flex;
   align-items: baseline;
   justify-content: flex-start;
   padding: 8px;
}

.task-export-option--label {
   margin-right: 30px;
   width: 150px;
}