.lists-page-controller {
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lists-page--table,
.lists-page--table-folder {
  width: 100%;
  .et--flex-table--wrapper {
    overflow: visible;
  }
  .et--flex-table--sort-header-cell {
    text-align: left;
  }

  .et--flex-table--body {
    overflow: visible !important;
  }
}

.lists-page--table {
  width: 70%;
}

.lists-page--table-folder {
  width: 100%;
}

.lists-page--side {
  width: 30%;
  .et--card--heading {
    padding: 20px 0 0 20px;
  }
}

.lists-page--side-folder-card-icon {
  color: $gray300;
  font-size: 28px;
  cursor: pointer;
}

.lists-page--folder-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.folder-count {
  color: $gray300;
}

.lists-page--show-all {
  padding-top: 8px;
  text-align: center;
}

.lists-page--folders {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lists-page--search {
  width: 250px;
  margin: 20px;
}

.lists-page--recent {
  margin-bottom: 15px;
}

.lists-page--recent-item:not(:last-child) {
  display: block;
  margin-bottom: 8px;
}

.lists-page--icon {
  color: $gray500;
  margin-right: 5px;
}

.lists-page--avatar {
  display: flex;
  align-items: center;
  .et--avatar {
    margin-right: 10px;
  }
}
