// Impersonation Banner in the Header
$impersonation-banner-height: $layoutBannerHeight;
$impersonation-arrow-size: 10px;
$listHeaderHeight: 89px;

// Impersonation Header Banner
.impersonation-mode {
  .contacts-meta.is-stuck {
    top: $impersonation-banner-height + $mainHeaderHeight;
  }

  .list-contacts .contacts-meta.is-stuck {
    top: $impersonation-banner-height + $mainHeaderHeight + $listHeaderHeight;
  }
}

.impersonation-header {
  top: 0;
  left: 0;
  height: $impersonation-banner-height;
  position: fixed;
  background: $red500;
  width: 100%;
  z-index: 11;
}

.impersonation-wrapper {
  background: $white;
  float: left;
  padding: 5px;
  margin: 5px;
  position: relative;

  &::after {
    @include calc(top, "50% - #{$impersonation-arrow-size}");
    position: absolute;
    right: -$impersonation-arrow-size;
    width: 0;
    height: 0;
    border: $impersonation-arrow-size solid transparent;
    border-right: none;
    border-left: $impersonation-arrow-size solid $white;
  }
}

.impersonation-names,
.vertical-line,
.end-impersonation-btn {
  height: 40px;
}

.impersonation-names {
  margin: 0 10px;
}

.impersonation-names--org {
  font-size: $fontSizeSmall;
  color: $gray500;
}

.end-impersonation-btn {
  width: 195px;
  margin-right: 5px;
}

.impersonation-avatar {
  margin: 0 10px 0 5px;
}

.impersonation-text {
  height: $impersonation-banner-height;
  line-height: $impersonation-banner-height;
  margin-left: 20px;
  color: $white;
  font-size: $fontSizeLarge;
  font-weight: bold;
}

// Impersonation Modal
.impersonation-modal {
  padding-bottom: 20px;

  hr {
    margin: 0;
  }

  .btn {
    @include calc(width, "25% - 20px");
    height: 40px;
  }
}

.impersonation-modal--search {
  @include calc(width, "100% - 134px");
  display: inline-block;
  padding-right: 16px;
}

.impersonation-modal--empty {
  color: $gray200;
  text-align: center;
  font-size: 18px;
  padding: 40px;
}

.impersonation-modal--table {
  .et-icon-impersonate-user {
    color: $gray500;
    margin-right: 10px;
    font-size: 1.7em;
  }

  tr:hover {
    color: $linkText;

    .et-icon-impersonate-user {
      color: $blue200;
    }
  }
}

.impersonation-modal--wrap {
  padding: 20px;
}

// disabled sections during impersonation
.show-disabled .shared-connections--syncing {
  background-image: none;
}
