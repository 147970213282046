.dynamic-lists-controller {
  margin: 4px 16px 16px 16px;
}

.dynamic-lists-controller--table-settings {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
}

.dynamic-lists-controller--count {
  font-weight: $bold;
}

.dynamic-lists-table {
  table {
    min-height: 400px;

    tbody {
      tr {
        min-height: 60px;
      }
    }
  }
}

.dynamic-lists-table--list-type {
  flex: 0 0 160px;
}

.dynamic-lists-table--link {
  cursor: pointer;
  color: $blue500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modify-query-warning {
  background: rgb(254, 250, 236);
  padding: 8px 20px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  align-items: baseline;
}

.dynamic-list-warning {
  background: rgb(247, 241, 241);
  padding: 8px 20px;
  display: flex;
  left: 0;
  right: 0;
  align-items: baseline;
  position: absolute;
}

.contacts-table-toolbar {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  width: 96%;
  align-items: center;
}

.alerts-container {
  margin-bottom: 50px;
}

.alerts-options {
  width: 85%;
  display: grid;
  grid-template-columns: 55px 150px 230px;
  gap: 20px 30px;

  div,
  p,
  span {
    align-self: baseline;
  }
}

.alerts-time-options {
  width: 100%;
}

.alerts-description {
  background-color: rgb(242, 249, 242);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0 25px;
}

.alerts-calendar {
  border: solid 1px #d7d7d7;
  color: #454545;
  font-weight: normal;
}

.alerts-calendar:disabled {
  background-color: #f3f3f3;
  color: #737373;
  border: solid 1px #d7d7d7;
}

.alerts-calendar:hover {
  border: solid 1px #d7d7d7 !important;
  background: #f9f9f9 !important;
  color: #454545 !important;
}

.alerts-calendar:disabled:hover {
  background-color: #f3f3f3 !important;
  color: #737373 !important;
}

.date-picker-dropdown--btn > .date-picker-chevron {
  opacity: 65%;
}

.date-picker-dropdown--btn:hover > .date-picker-chevron {
  color: #007c9e;
  opacity: 100%;
}

.date-picker-dropdown--btn:disabled > .date-picker-chevron {
  color: #737373;
  opacity: 60%;
}

.display-alert {
  display: flex;
  font-size: 12px;
  text-align: center;
  background-color: rgb(245, 245, 245);
  display: inline-flex;
  border-radius: 10px;
  align-self: center;
  margin-left: 25px;
  padding-left: 3px;
  margin-bottom: 5px;
  padding-right: 5px;
  cursor: pointer;

  p {
    padding-left: 3px;
    margin-bottom: 0px;
  }

  icon {
    font-size: 15px;
  }
}

.display-alert:hover {
  background-color: rgb(242, 242, 242);
}

#layout-page-wrapper {
  .et--page--header {
    padding-bottom: 10px;
  }
}

.display-alert > .gt-icon-email {
  align-self: center;
}
