.profile-proposals {
  padding: 16px;

  .profile-card-header {
    margin-bottom: 8px;
  }
}

.profile-proposals--row {
  border-bottom: solid 1px $border;
  padding: 16px 8px;

  &:last-child,
  &:first-child {
    border-bottom: none;
  }
}

.profile-proposals--header {
  .profile-proposals--amount {
    @extend .text-label;
    text-transform: uppercase;
    text-align: right;
  }
}

.profile-proposals--summary {
  display: inline-block;
  vertical-align: top;
  width: 28%;
  color: $gray500;
}

.profile-proposals--amount {
  display: inline-block;
  width: 18%;
  text-align: right;
  color: $gray500;
}

.profile-proposals--value {
  color: $gray900;
  font-size: 18px;
  font-weight: 500;

  &.is-empty {
    color: $gray500;
  }
}

.profile-proposals--inactive-badge {
  width: 80px;
  text-align: center;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: $gray500;
  background: $gray105;
  border: solid 1px $gray500;
  border-radius: 2px;
}
