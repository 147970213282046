.profile-engagement-metrics--wrapper {
  @include display(flex);
  @include flex-direction(column);
  @include align-items(stretch);
  @include align-content(stretch);
  padding: 16px 20px;
}

.profile-engagement-metrics--content {
  padding-top: 16px;
  padding-bottom: 16px;
}

.profile-engagement-metrics--caption {
  margin-top: 2px;
}

.profile-engagement-metrics--column {
  width: 50%;
  display: inline-block;

  &.is-column-1 {
    border-right: 1px solid $border;
  }

  &.is-column-2 {
    padding-left: 16px;
  }
}

.profile-engagement-metrics--empty-message {
  color: $gray150;
}

.profile-engagement-metrics--count {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 26px;
}

.profile-engagement-metrics--icon-community {
  color: $blue300;
  font-size: 18px;
  vertical-align: text-top;
}

.profile-engagement-metrics--status {
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 26px;
  color: #00C0F5 ;
  margin-bottom: 10px;
}

.profile-engagement-metrics-tv{
  display: flex;
  justify-content: space-between;
}

.margin-bottom-dashboard{
  margin-bottom: 0px;
}

.profile-engagement-metrics-tv-label{
  color: #3367F2;
  align-self: flex-end;
  margin-right: 10px;
}

.profile-engagement-metrics-tv-ctalabel{
  color: #3367F2;
  align-self: flex-end;
  margin-right: 50px;
}
