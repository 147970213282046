.analytics--page.page-container {
  padding: 0;
  background: $white;
}

.analytics--header {
  height: 75px;
  padding: 12px 16px;
  line-height: 52px;
}

.analytics--header-label {
  font-size: 20px;
}

.analytics--toggles {
  margin-top: 4px;
  float: right;
}

.analytics-chart--container {
  position: relative;
  padding: $pageContainerPadding;
  background: $white;
  min-height: 500px; // Needed for IE
  border-bottom: 1px solid $border;
}

.analytics-chart--dropdown .dropdown--body {
  padding: 10px;
}

.analytics-chart--header {
  color: $gray500;
  font-size: $fontSizeLarge;
  line-height: 28px;
  margin-bottom: 5px;
}

.analytics--title {
  color: $gray900;
  font-size: $baseFontSize * 1.57;
  margin-bottom: 5px;
  font-weight: bold;
}

.positive-amount {
  color: $green300;
}

.negative-amount {
  color: $red300;
}

.analytics--total-num {
  font-size: $baseFontSize * 1.57;
}

.analytics--tooltip {
  background: $white;
  border-radius: 2px;
  opacity: 0.9;
  padding: 5px;

  .charting--tooltip-value {
    color: $green300;
  }
}

.analytics--tooltip-current {
  border: 2px solid $green300;
}

.analytics--tooltip-previous {
  border: 2px solid $gray150;
}

// Need height and width for IE
// IE Hack: This media query only works in IE 10+, so will add styles for IE 10+ only
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .analytics--chart {
    height: 350px;
    max-width: 800px;
  }
}

.analytics-bar--current {
  stroke: $white;
  stroke-width: 1;
}

.analytics-table--container {
  position: relative;
  background: $background;
  padding: $pageContainerPadding;
}

.analytics-table {
  max-width: 800px;
  background: $white;
  border: 1px solid $blue300;
  border-bottom: none;

  tr:hover td {
    cursor: auto;
    background-color: $gray105;
  }

  tr td,
  tr th {
    text-align: right;
    padding: 0 20px;
  }

  tr td:first-child,
  tr th:first-child {
    text-align: left;
  }
}
