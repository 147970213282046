.contact-summary {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 580px;
}

.contact-summary--content {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  /* for Firefox */
  min-height: 0;
}

.contact-summary--header {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
}

.contact-summary--body {
  flex-grow: 1;

  overflow: auto;

  /* for Firefox */
  min-height: 0;
}

.contact-summary--header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.contact-summary--header-row--button {
  padding: 5px 10px;
  font-size: 20px;
  background-color: #f2f2f2;
  color: #000000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}

.contact-summary-interaction-list-item {
  display: flex;
  padding: 16px;
  color: unset;

  &:hover {
    background: $gray105;
  }
}

.contact-summary-interaction {
  display: flex;
  clear: both;
  position: relative;
  width: 100%;
}

.contact-summary-interaction--image {
  width: 56px;
}

.contact-summary-interaction--content {
  @include calc(width, "100% - 56px");
  max-width: 500px;
  display: flex;
  justify-content: space-between;
}

.contact-summary-interaction--details {
  font-weight: 500;
  font-size: 14px;
  color: $gray500;
}

.contact-summary-interaction--text {
  word-break: break-word;
  white-space: pre-line;
}

.linkedin-link {
  margin-left: 16px;
}

.linkedin-icon {
  margin-right: 5px;
}

.household-header {
  padding: 4px;
  font-weight: bold;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.household-container {
  border-left: 1px solid #f3f3f3;
  flex: 1;
}
