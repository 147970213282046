$font-size-14: 14px;
$font-size-16: 16px;
$font-size-20: 20px;
$font-weight-400: 400;
$font-weight-500: 500;
$line-height-16: 16.41px;
$line-height-23: 23.44px;

.title-subtitle-400-14 {
  font-size: $font-size-14;
  font-weight: $font-weight-400;
  line-height: $line-height-16;
}

.title-subtitle-500-14 {
  font-size: $font-size-14;
  font-weight: $font-weight-500;
  line-height: $line-height-16;
}

.title-subtitle-400-16 {
  font-size: $font-size-16;
  font-weight: $font-weight-400;
  line-height: $line-height-16;
}

.title-subtitle-500-16 {
  font-size: $font-size-16;
  font-weight: $font-weight-500;
  line-height: $line-height-16;
}

.title-subtitle-500-20 {
  font-size: $font-size-20;
  font-weight: $font-weight-500;
  line-height: $line-height-23;
}
