.search-lists {
  margin: 0 16px 16px 16px;
  position: relative;
  .et-listbox--trigger {
    padding: 0;
  }
}

.search-list-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.search-list--search-name {
  cursor: pointer;
  color: $blue500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-list--col-name {
  flex-basis: 240px;
}

.search-list--col-link {
  justify-content: flex-end;
}

.search-list--selections {
  .et-listbox--label {
    font-weight: 500;
  }
}

.search-list--suggested-icon {
  margin-left: 16px;
  color: $purple500;
}

.search-list--suggested {
  color: $gray500;
}

.search-list--table {
  min-height: 200px;
}
