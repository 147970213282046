$loginHeaderBg: $gray500;
$loginHeaderHeight: $mainHeaderHeight;
$loginLogoSize: 140px;
$loginImageColor: #628391;
$loginWebBlue: $linkText;
$loginWebGrey: $background;
$loginTextDarkGrey: $gray500;
$loginMediumGrey: $gray500;
$loginPageBg: $gray900;

$loginContentWidth: 1000px;
$loginContentHeight: 220px;
$loginContentColor: $white;
$loginContentTextShadow: 0 1px 1px $black;
$loginContentFontSize: 16px;
$loginContentLineHeight: 1.3em;

$loginWebWidth: 330px;
$loginButtonHeight: 40px;
$mobileInputWidth: 272px;

$loginBtnPadding: 0px;
$loginBtnFontSize: 0.7em;
$loginBtnRadius: 5px;
$loginBtnColor: #19a4df;

$loginLoadingFontSize: 1.5em;

$loginSelectHeight: 40px;
$loginSelectPadding: 20px;

.login--header {
  max-width: $loginContentWidth;
  margin: 0 auto;
  background-image: url($logoWhite);
  background-size: $loginLogoSize;
  background-position: left center;
  background-repeat: no-repeat;
  height: $loginHeaderHeight;

  @include respond-to(tablet) {
    background-position: center;
  }
}

.login--header-outer {
  @include position(fixed, 0px 0px 0 0px);
  background-color: $loginHeaderBg;
  z-index: $loginHeaderZindex;

  @include respond-to(phone) {
    visibility: hidden;
  }
}

.login--wait-container {
  font-size: $loginLoadingFontSize;
  color: $loginTextDarkGrey;

  .loading {
    background: $loginWebGrey;
  }

  .loading--wrapper {
    width: 500px;

    &.is-center {
      margin: -50px 0 0 -250px;
    }

    .loading--spinner {
      display: inline-block;
    }
  }
}

// Login Org Picker
.login-page--btn {
  height: $loginSelectHeight;
  width: 100%;
}

.login-selectors {
  .login--header-outer {
    border-bottom: solid 1px $border;
  }
}

.login-selectors--container {
  margin: ($loginHeaderHeight + 60px) auto;
  width: 500px;
}

.login-selectors--submit {
  margin-top: 20px;
  display: block;
}

.login-selectors--org {
  margin-top: 20px;
  height: 500px;
}

.select-lg {
  margin-right: $loginSelectPadding;
}

.login-page--title,
.impersonation-modal--title {
  color: $gray900;
}

.login-page--title {
  margin-bottom: 20px;
}

// Change Org Modal
.change-org-modal {
  hr {
    margin: 0;
  }

  .modal-confirm--header {
    border-bottom: none;
  }
  .modal-confirm--body {
    .change-org--wrap {
      height: 450px;
      padding: 4px 20px 30px 20px;

      .org-selector {
        height: 100%;
      }
    }
  }
}

.org-selector {
  background: white;
  height: 100%;
}
