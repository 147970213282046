.cronofy-table {
  padding: 24px;
  padding-top: 16px;

  .module--header {
    border-bottom: none;
    padding: 10px 0;
  }

  .module--header-title {
    display: block;
    padding-bottom: 40px;
  }

  .module--body {
    padding: 0;
  }
}
.cronofy-table--icon {
  margin-right: 16px;
  @include rounded-icon();
}

.cronofy-table--top-copy {
  margin-top: 12px;
  margin-bottom: 24px;
}

.cronofy-table--group {
  margin-bottom: 24px;
}

.cronofy-table--group-header {
  background: $gray105;
  padding: 8px;
  border: 1px solid $border;
}

.cronofy-table--header {
  display: flex;
  align-items: center;
}

.cronofy-table--connect-btn,
.cronofy-table--add-calendar {
  margin-left: auto;
}

.cronofy-table--group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cronofy-table--disconnect {
  color: $red500;
}

.cronofy-table--connected-icon {
  color: $green300;
}

.cronofy-table--group-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}

.cronofy-table--profile-name {
  color: $gray500;
}

.cronofy-table--calendar-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid $border;
  border-top: none;
  padding: 8px;
}

.cronofy-table--calendar-empty {
  text-align: center;
  border: 1px solid $border;
  border-top: none;
  padding: 8px;
}

.empty-state-message--content {
  .cronofy-table--connect-btn {
    margin-top: 16px;
  }
}

.cronofy-table--empty-state {
  .gt-icon.empty-state-message--icon {
    font-size: 38px;
  }
}

.cronofy-table--connect-cta {
  @extend .btn-action;
}

.cronofy-table--add-trigger {
  margin-bottom: 16px;
}
