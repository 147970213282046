.trip-share {
  padding: 16px 24px;
}

.trip-share--description {
  margin-bottom: 16px;
}

.trip-share--manual-desc {
  padding: 10px 20px;
  font-size: 1.1em;
  color: $gray500;
}
