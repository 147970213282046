// Tooltip Variables
$popover-background: $white;
$popover-width: 300px;
$popover-font-size: $baseFontSize;
$popover-arrow-width: 25px;
$popover-arrow-height: 25px;
$popover-shadow-top: 0 2px 0 $border;
$popover-shadow-bottom: 0 -2px 0 $border;
$popover-shadow-left: 2px 0 0 $border;
$popover-shadow-right: -2px 0 0 $border;

.popover {
  position: relative;

  &.has-header {
    .popover--top::after {
      border-top-color: $gray105;
    }
    .popover--bottom::after {
      border-bottom-color: $gray105;
    }
    .popover--right::after {
      border-right-color: $gray105;
    }
    .popover--left::after {
      border-left-color: $gray105;
    }
  }
}

.popover--body {
  display: none;

  &.is-open {
    @include box-sizing(border-box);
    @include transition(all 0.2s ease-in-out);
    background-color: $popover-background;
    border-radius: 3px;
    display: block;
    line-height: 1.5em;
    z-index: 10;
    font-weight: normal;
    font-size: $popover-font-size;
    border: solid 1px $border;
    border-bottom-width: 2px;
    width: $popover-width;
  }
}

.popover--content {
  padding: 10px;
}

.popover--header {
  background: $gray105;
  border-bottom: solid 1px $border;
  color: $gray500;
  font-weight: bold;
  padding: 5px;
  border-radius: 3px 3px 0 0;
}

.popover--top {
  @include position(absolute, 0 0 140% 50%);
  margin-left: -($popover-width / 2);

  &::after,
  &::before {
    @include position(absolute, 0 0 (-$popover-arrow-width / 2) 50%);
    @include triangle($popover-arrow-width, $popover-background, down);
    content: "";
    margin-left: -$popover-arrow-width / 2;
  }

  &::before {
    bottom: (-$popover-arrow-width / 2) - 2;
    border-top-color: $border;
  }
}

.popover--bottom {
  @include position(absolute, 140% 0 0 50%);
  margin-left: -($popover-width / 2);

  &::after,
  &::before {
    @include position(absolute, 0 0 100% 50%);
    @include triangle($popover-arrow-width, $popover-background, up);
    content: "";
    margin-left: -$popover-arrow-width / 2;
  }

  &::before {
    bottom: calc(100% + 2px);
    border-bottom-color: $border;
  }
}

.popover--left {
  @include position(absolute, 0px 0 0 (-$popover-width - $popover-arrow-width));

  &::after,
  &::before {
    @include position(absolute, ($popover-arrow-height - 2) 0 0 100%);
    @include triangle($popover-arrow-width, $popover-background, right);
    content: "";
    margin-top: -$popover-arrow-height / 2;
  }

  &::before {
    left: calc(100% + 1px);
    border-left-color: $border;
  }
}

.popover--right {
  @include position(absolute, 0px 0 0 110%);

  &::after,
  &::before {
    @include position(absolute, ($popover-arrow-height - 2) 0 0 -12px);
    @include triangle($popover-arrow-width, $popover-background, left);
    content: "";
    margin-top: -$popover-arrow-height / 2;
  }

  &::before {
    left: -13px;
    border-right-color: $border;
  }
}

.addlist-popover {
  display: inline-block;
  vertical-align: middle;
  padding: 8px;
  background: none;
  border-radius: 2px;
}

// for popover2
.add-list--popover-wrap {
  margin-top: 8px;
}
