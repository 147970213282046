.card-analytics {
  padding: 16px 0;
}

.card-analytics--column {
  @include vertical_align();
  width: 33.3%;
  border-right: solid 1px $border;
  padding: 0 15px;

  &:last-child {
    border-right: none;
  }

  &.is-empty {
    .card-analytics--data {
      color: $gray900;
    }
  }
}

.card-analytics--data {
  font-size: 20px;
  color: $gray900;
  height: 22px;

  .gt-icon {
    font-size: 16px;
    line-height: 20px;
    position: relative;
    top: -2px;
    left: 4px;
  }
}
