.todo-add--wrapper {
  width: 100%;
  padding: 8px;
  float: left;
  border: 4px solid transparent;
  .gt-icon-alarm,
  .gt-icon-event,
  .gt-icon-check-circle {
    font-size: 14px;
    padding-top: 3px;
  }
  .gt-icon-notes {
    font-size: 12px;
    padding-top: 4px;
  }
  .gt-icon-delete {
    font-size: 20px;
  }
}
.todo-add--wrapper-in-edit-mode {
  border: 0px solid $loginBtnColor;
  padding: 0px;
  padding-bottom: 8px;
}
.todo-add--form-holder {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba($gray500, 0.5);
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.todo-add--form-holder-in-edit-mode {
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid $loginBtnColor;
}
.todo-add--title {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid $linkText;
  width: 100%;
  padding-top: 4px;
  color: $gray900;
  font-size: 14px;
  font-style: italic;
  &:focus {
    border-bottom: 1px solid $linkText;
  }
  margin-bottom: 8px;
}
.todo-add--date-holder {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}
.todo-add--duedate-holder {
  width: 50%;
  position: relative;
}
.todo-add--duedate {
  text-align: left;
  color: #9b9b9b;
  cursor: pointer;
}
.todo-add--reminder-holder {
  white-space: nowrap;
  width: 50%;
  position: relative;
  .todo-form--extra-style {
    left: auto;
    right: 0px;
  }
}
.todo-add--reminder {
  text-align: left;
  color: #9b9b9b;
  cursor: pointer;
}
.todo-add--description-holder {
  width: 100%;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
}
.todo-add--description-icon {
  width: 10%;
}
.todo-add--description-input {
  width: 90%;
}
.todo-add--description {
  color: $gray900;
  font-style: italic;
  text-align: left;
  border: none;
  font-size: 14px;
  padding: 0px;
  min-height: 300px;
  box-shadow: inset 0px 0px 0px 0px transparent;
  resize: none;
}
.todo-add--action-button-section {
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
}
.todo-add--submit-button-div {
  display: flex;
}
.todo-add--submit-button {
  margin-left: 16px;
}
.todo-add--delete-button {
  float: left;
  height: 32px;
}
.todo-form--extra-style {
  top: 30px;
  left: 0px;
  overflow: hidden;
  box-shadow: 0px 0px 2px grey;
  background-color: white;
  z-index: 20;
}
