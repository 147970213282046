$popoverOverlayBackground: $white;
$popoverOverlayArrowSize: 16px;

.popover-overlay {
  position: relative;
}

.popover-overlay--content {
  position: absolute;
  background: $popoverOverlayBackground;
  border-radius: 3px;
}

.popover-overlay--arrow {
  @include position(absolute, 0px null null 0px);

  &.is-bottom {
    @include triangle($popoverOverlayArrowSize, $popoverOverlayBackground, up);
  }

  &.is-top {
    @include triangle($popoverOverlayArrowSize, $popoverOverlayBackground, down);
  }

  &.is-right {
    @include triangle($popoverOverlayArrowSize, $popoverOverlayBackground, left);
  }

  &.is-left {
    @include triangle($popoverOverlayArrowSize, $popoverOverlayBackground, right);
  }
}
