.saved-searches {
  td,
  th {
    padding: 10px;
    border-color: $border;
  }
}

.saved-searches--header {
  padding: 10px;
  color: $gray500;
}

.saved-searches--title {
  font-size: 1.25em;
  font-weight: normal;
  color: $gray500;
  width: 40%;
}
