.events-header {
   padding: 16px 16px;
}

.events-header--text-wrapper {
   display: inline-block;
}

.events-header--text {
   font-size: 20px;
   padding-bottom: 2px;
}

.events-header--actions {
   position: absolute;
   right: 16px;
   top: 12px;
}
