// TODO: make this a mixin for different colored backgrounds
@mixin scrolling-shadows() {
  @include background(
    linear-gradient($white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), $white 70%) 0 100%,
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%
  );

  @include background(
    linear-gradient($white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), $white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%
  );

  overflow: auto;
  background-repeat: no-repeat;
  background-color: $white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  // Opera doesn't support this in the shorthand
  background-attachment: local, local, scroll, scroll;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin vertical_align {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

@mixin tooltip($width, $box_shadow) {
  min-width: $width;

  &::after {
    text-shadow: $box-shadow;
  }
}

@mixin circle($size, $color, $textColor) {
  width: $size;
  height: $size;
  border-radius: $size;
  background: $color;
  color: $textColor;
  text-align: center;
}

@mixin users_table {
  overflow: auto;
  min-height: 189px;

  tr td {
    text-align: center;
  }

  tr td:first-child {
    text-align: left;
  }
}

@mixin users_table__sub-header {
  background: $background;
  border-bottom: solid 1px $border;
  color: $gray300;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}

@mixin users_table__delete {
  width: auto;
  cursor: pointer;

  .tooltip {
    min-width: 100px;
    margin-left: -50px;
    padding: 5px;
    bottom: 140%;

    &::after {
      left: 40%;
      top: 17px;
    }
  }

  i:hover {
    color: $purple500;
  }
}

@mixin textContentPreview {
  p {
    margin: 0 0 8px 0;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    white-space: normal;
    margin-bottom: 8px;
  }

  ul > li {
    list-style: disc;
    margin-left: 50px;
    line-height: 16px;
  }

  ol > li {
    list-style: decimal;
    margin-left: 50px;
    line-height: 16px;
  }
}

@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: 768px) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      @content;
    }
  } @else if $media == medium_desktop {
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin rounded-icon {
  @include circle(30px, transparent, $border);
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px $border;
}

@mixin pink-focus {
  box-shadow: inset 0px 0px 0 2px $purple300;
  outline: none;
}

@mixin title-style($banner) {
  height: 100px;
  color: white;
  font-size: 28px;
  line-height: 32.81px;
  font-weight: 500;
  padding-left: 16px;
  display: flex;
  align-items: flex-end;
  background-image: url(#{$banner});
  background-size: cover;
  background-position: center;
}
