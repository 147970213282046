.recent-interactions {
  width: 100%;
}

.recent-interactions--creator {
  &.is-empty {
    border-bottom: none;
  }
}

.recent-interactions--header-create {
  &.profile-card-header {
    margin-bottom: 0;
  }
}

.recent-interactions--feed {
  position: relative;
  min-height: 100px;
  background: $white;

  .note-feed--loading {
    min-height: 250px;
  }

  .note-feed--list {
    background: transparent;
  }
}

.recent-interactions--view-all {
  display: block;
  padding: 16px 0;
  text-align: center;
  font-size: 16px;
  border-top: 1px solid $border;
}

.recent-interacions--empty {
  color: $gray500;
  text-align: center;
  padding: 16px 16px 16px;
}

.thankview-interaction-container{
   display: flex;
}
.thankview-video{
  margin-left: 0;
}

.thankview-content{
   float: right;
   margin-left: 15px;
   margin-top: 15px;
}

.thankview-outer-container{
  max-width: 300px;
}

.thankview-details-reply{
  display: flex;
}

.thankview-relative{
  max-width: 500px;
  margin-top: 15px;
}
.accordion-button{
  padding: 6px;
}
.dropdown-container{
  gap: 1rem;
  display: -webkit-box;
  max-width: 100%;
  overflow: auto;
}
.dropdown-text-details{
  margin-bottom: 4px !important;
  font-weight: bold;
  font-size: 14px;
  color: $gray900;
}
.dropdown-details{
  display: -webkit-box;
  margin-top: 4px;
  margin-left: 5px;
  gap: 1rem;
}

.see-reply:hover{
  border-bottom: #a54ab5 0.125em solid;
}

.accordion-content{
  margin-top: 6px;
}

.link-list{
  list-style-type: none;
  margin-left: 0;
  box-sizing: border-box;
}

.link-list-interaction{
  margin-top: 5px;
}