.progress-bar-with-label--flex-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar-with-label--bar {
  display: flex;
  align-items: center;

  .thin,
  .thin * {
    padding: 0px;
    height: 5px;
  }
}

.progress-bar-with-label--percent-label {
  padding-left: 5px;
  line-height: 5px;
  font-size: smaller;
  flex: none;
  font-weight: 500;
}
