/* Accessible lighter text */
/* Default non-white background */
/* Green text, or white text on green e.g. success */
/* Large text, icons, graphs */
/* Standard brand color. OK for icons, graphs, etc */
/* Success */
/* Default color for links and buttons */
/* Hover e.g. buttons */
/* Selected icons, radios, checkboxes, etc */
/* Active state */
/* Alerts, Danger */
/* Danger */
/* Highlight */
:export {
  black: #000000;
  white: #ffffff;
  gray1400: #2c2c2c;
  gray900: #454545;
  gray500: #737373;
  gray300: #939393;
  gray200: #b7b7b7;
  gray150: #d7d7d7;
  gray110: #f3f3f3;
  gray108: #f6f6f6;
  gray105: #f9f9f9;
  green500: #3f7f34;
  green300: #53a744;
  green200: #73c166;
  green106: #f4faf4;
  blue500: #007c9e;
  blue300: #00a0cc;
  blue200: #00c0f5;
  blue106: #f2fafc;
  purple500: #a54ab5;
  purple300: #ba72c5;
  purple200: #cc9dd7;
  purple105: #fcf8fc;
  red500: #de1b2c;
  red300: #eb5c68;
  red106: #fef6f7;
  yellow150: #f3ca16;
  yellow108: #fdf7dc; }

.cdc-controller label {
  color: #454545;
  font-weight: 400;
  font-family: "roboto";
  font-size: 14px; }

.cdc-controller .et-vertical-tab-panel--nav {
  background-color: #f9f9f9; }

.cdc-controller .et-vertical-tab-panel--nav-item {
  padding: 30px; }

.cdc-controller .et-vertical-tab-panel--nav-item-label {
  font-weight: 500; }

.cdc-controller .et-vertical-tab-panel--content {
  width: 100%;
  height: 460px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0px; }
  .cdc-controller .et-vertical-tab-panel--content:-webkit-scrollbar {
    background-color: #de1b2c; }

.cdc-controller--add {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
  min-height: 40px; }
  .cdc-controller--add .cdc-controller-icon {
    color: #007c9e; }

.cdc-controller--row {
  margin: 20px 15px; }
  .cdc-controller--row .cdc-controller-row--label,
  .cdc-controller--row .cdc-controller-row--label-active {
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #f6f6f6;
    width: 100%;
    padding-bottom: 5px; }
  .cdc-controller--row.cdc-controller-row--label-active {
    font-weight: 500; }

.cdc-controller--row-new {
  width: 150px;
  margin-top: 15px;
  font-size: 15px; }

.cdc-controller--value {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between; }

.cdc-controller--actions {
  min-width: 200px;
  display: flex;
  justify-content: space-around; }
  .cdc-controller--actions a {
    cursor: pointer; }

.cdc-controller--info {
  margin-top: 20px;
  padding: 17px;
  background-color: #f9f9f9; }
  .cdc-controller--info label {
    padding-bottom: 0px;
    border-bottom: 0px; }
  .cdc-controller--info textarea:disabled {
    color: #b7b7b7; }
  .cdc-controller--info .et--button-action.is-disabled {
    color: #00c0f5; }

.cdc-controller--info-cancel {
  font-size: 14px;
  margin-right: 35px;
  text-transform: capitalize; }

.cdc-controller--input-container input {
  height: 40px;
  width: 375px;
  border: 1px solid #d7d7d7;
  padding: 13px;
  font-size: 14px;
  color: #737373; }
  .cdc-controller--input-container input:focus {
    border: 1px solid #d7d7d7; }
  .cdc-controller--input-container input.cdc-controller--has-error {
    border: 1px solid #de1b2c;
    color: #de1b2c; }
  .cdc-controller--input-container input.cdc-controller--has-error:focus {
    outline: #de1b2c auto 5px; }
  .cdc-controller--input-container input::placeholder {
    color: #b7b7b7;
    opacity: 0.75; }

.cdc-controller--input-container textarea {
  margin-bottom: 0; }

.cdc-controller--address-form .cdc-controller--input-container {
  margin-bottom: 15px; }

.cdc-controller--address-form-row {
  display: flex;
  justify-content: space-between; }
  .cdc-controller--address-form-row .cdc-controller--input-container input {
    width: 180px; }

.cdc-controller--has-error {
  color: #de1b2c; }

.cdc-controller--input-label {
  color: #737373;
  margin-bottom: 15px; }

p.cdc-controller--has-error {
  margin: 0px; }

textarea.cdc-controller--has-error {
  border: solid 1px #de1b2c; }

.cdc-status--success,
.cdc-status--error {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  padding: 13px 12px 0px;
  color: #ffffff;
  height: 112px; }
  .cdc-status--success p,
  .cdc-status--error p {
    margin: 0 0 5px 10px; }

.cdc-status--success {
  background-color: #007c9e; }

.cdc-status--error {
  background-color: #de1b2c; }

.cdc-status--header {
  font-weight: 700;
  letter-spacing: 0.5px; }

.cdc-status-message {
  width: 90%; }

.cdc-controller--empty {
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 5px;
  font-size: 14px;
  margin: 20px 15px;
  letter-spacing: 0.5px; }

.cdc-modal--footer {
  color: #737373;
  background: #f9f9f9; }

.cdc-modal .et--modal-header--inner {
  display: flex;
  justify-content: space-between; }

.cdc-modal .et--modal-header--close {
  position: static; }

.cdc-modal.is-mobile {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .cdc-modal.is-mobile .cdc-controller {
    display: flex;
    flex: 2;
    flex-direction: column; }
  .cdc-modal.is-mobile .et--modal-header--close {
    margin-left: auto; }
  .cdc-modal.is-mobile .et-vertical-tab-panel--content {
    height: 100%; }
  .cdc-modal.is-mobile .et-vertical-tab-panel {
    flex-direction: column;
    height: 100%; }
  .cdc-modal.is-mobile .et-vertical-tab-panel--nav {
    flex-direction: row;
    justify-content: space-between; }
  .cdc-modal.is-mobile .et-vertical-tab-panel--nav-item {
    padding: 20px;
    width: 100%; }
  .cdc-modal.is-mobile .et--modal-footer-with-buttons {
    margin-top: auto; }
  .cdc-modal.is-mobile .cdc-controller--input-container {
    width: 100%; }
  .cdc-modal.is-mobile .cdc-controller--input-container input {
    width: 100%; }
  .cdc-modal.is-mobile .cdc-controller--actions {
    min-width: auto; }
  .cdc-modal.is-mobile .cdc-controller--edit-link {
    margin-right: 12px; }
