$companyProfileHeaderHeight: 75px;

.company-profile {
  @include position(absolute, 0px 0px 0px 0px);
  overflow: hidden;
}

.company-profile--body {
  @include position(absolute, $companyProfileHeaderHeight 0px 0px 0px);
  padding: 16px;
  overflow: auto;

  .module {
    margin-bottom: 16px;
  }

  .module--header-title {
    .et--plain-button {
      margin-left: 4px;
    }
  }
}

.company-profile--main-column {
  @include calc(width, "100% - 350px");
  display: inline-block;
  vertical-align: top;
  padding-right: 16px;
}

.company-profile--side-column {
  display: inline-block;
  width: 350px;
}

.company-header {
  background: $white;
  border-bottom: solid 1px $border;
  height: $companyProfileHeaderHeight;
  padding: 12px 16px;
}

.company-header--avatar {
  float: left;
  width: 50px;
  margin-right: 30px;

  img {
    border-radius: 50px;
  }
}

.company-header--name {
  float: left;

  h3 {
    font-size: 20px;
  }
}

.company-header--links {
  float: right;
  text-align: right;
  padding: 16px;
}

.company-header--matched {
  font-weight: 500;
}

.company-header--divide {
  color: $gray150;
  font-size: 20px;
}

.company-giving-cards {
  padding-top: 16px;

  .kpi-card--value {
    color: $green300;
  }
}

.company-participation-cards {
  padding-top: 16px;
}
