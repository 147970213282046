.active-journeys-modal--header {
  background-color: #f6f6f6;
  padding: 15px;
  margin: auto;
  border-radius: 9px;
}

.active-journey {
  margin: 20px 5px;
  display: flex;
  align-items: baseline;

  button {
    color: #007c9e;
  }

  i {
    color: $gray500;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
    color: #939393;
  }
}
